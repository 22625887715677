export const updateCamera = (
  steps,
  mapInstance,
  handleSetOrientation,
  userLocation,
  handleSetStepState
) => {
  let currentStepIndex = 0; // Inicializa en el primer paso
  const followRoute = () => {
    if (currentStepIndex >= steps.length) return;

    const step = steps[currentStepIndex];
    const nextIndex =
      currentStepIndex + 1 < steps.length
        ? currentStepIndex + 1
        : currentStepIndex;

    handleSetStepState({
      currentStep: {
        type: step.maneuver.type,
        distance: step.distance,
        duration: step.duration,
        name: step.name,
        bearing_after: step.maneuver.bearing_after,
        location: step.maneuver.location,
        instruction: step.maneuver.instruction,
        modifier: step.maneuver.modifier ? step.maneuver.modifier : "",
      },
      nextStep: {
        type: steps[nextIndex].maneuver.type,
        instruction: steps[nextIndex].maneuver.instruction,
        modifier: steps[nextIndex].maneuver.modifier
          ? steps[nextIndex].maneuver.modifier
          : "",
      },
      totalSteps: steps.length,
      actualStep: currentStepIndex,
    });

    const nextPoint = step.maneuver.location;
    const bearing = step.maneuver.bearing_after;

    handleSetOrientation({ nextPoint, bearing });

    if (mapInstance && userLocation) {
      mapInstance.flyTo({
        center: nextPoint,
        zoom: 18,
        pitch: 60,
        bearing: bearing,
        speed: 1.2,
        curve: 1,
        easing: (t) => t,
        essential: true,
      });
    }

    if (currentStepIndex + 1 < steps.length) {
      currentStepIndex++;
    }
  };

  followRoute();
};

export const updateRoute = (
  directionsControl,
  userLocation,
  waypoints,
  handleSetRouteGeneralInfo,
  functionUpdateCamera,
  mapInstance,
  handleSetOrientation,
  handleSetStepState
) => {
  directionsControl.removeRoutes();

  if (userLocation) {
    directionsControl.setOrigin([
      userLocation.longitude,
      userLocation.latitude,
    ]);
    waypoints.forEach((waypoint, index) => {
      directionsControl.addWaypoint(index, waypoint);
    });
    directionsControl.setDestination(waypoints[waypoints.length - 1]);
  } else {
    directionsControl.setOrigin(waypoints[0]);
    waypoints.slice(1).forEach((waypoint, index) => {
      directionsControl.addWaypoint(index, waypoint);
    });
    directionsControl.setDestination(waypoints[waypoints.length - 1]);
  }

  directionsControl.on("route", (event) => {
    const route = event.route[0];
    handleSetRouteGeneralInfo({
      distance: route.distance,
      duration: route.duration,
    });
    const steps = route.legs.map((step) => step.steps).flat();
    functionUpdateCamera(
      steps,
      mapInstance,
      handleSetOrientation,
      userLocation,
      handleSetStepState
    );
  });
};

export const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const location = { latitude, longitude };
        localStorage.setItem("userLocation", JSON.stringify(location));
        resolve(location);
      },
      (error) => {
        reject("error: ", error); // estoy teniendo errores con el geolocalizador
      }
      /* {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      } */
    );

    return { watchId };
  });
};

export const startAsignedRoute = (mapInstance, orientation, userLocation) => {
  if (userLocation === null || !mapInstance) {
    return;
  }

  mapInstance.flyTo({
    center: [userLocation.longitude, userLocation.latitude],
    zoom: 19,
    pitch: 60,
    bearing: orientation.bearing,
    speed: 1.2,
    curve: 1,
    easing: (t) => t,
    essential: true,
  });
};
