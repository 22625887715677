import { MoreOutlined } from "@ant-design/icons"
import { Button, Popover, Spin } from "antd"
import { dateTimeFormater } from "res/utils"
import "../mediaAll/MediaAll.css"

export default function MediaLinks({ links, loading }) {

  if (loading) {
    return (

      <Spin tip="Loading" size="small">
        Cargando
      </Spin>


    )
  }


  if (!links.length) {
    return (
      <h3>
        No hay links
      </h3>
    )
  }

  return (
    <section className="media-all-list">
      {links.map((item, index) => {
        const { message, date } = item

        return (
          <article key={index} className="media-all-item-link">


            <a href={item.url} target="_black">{item.url}</a>

            <div className="media-all-info">
              <header className="header-media-all-info">
                <h1 className="media-message-quoted">{message}</h1>

                <Popover
                  content={
                    <ul className="media-all-popover-list">
                      <Button type="text">Ver en el chat</Button>

                    </ul>
                  }
                  trigger="click"
                  rootClassName="media-all-options-btn-popover"
                >
                  <Button
                    icon={<MoreOutlined />}
                    className="media-all-options-btn"
                  >
                  </Button>
                </Popover>
              </header>

              <time dateTime={date} className="media-all-date">
                {dateTimeFormater({ date, dateStyle: "long" })}
              </time>
            </div>
          </article>
        )
      })}
    </section>
  )
}