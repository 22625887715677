import { Timeline } from "antd";
import React from "react";
import ROUTE from "./route.json";
import "./route.css";
import { ROUTE_MAP } from "router/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import GO_TO_MAP from "assets/icons/svg/MdiMapMarkerCircle.svg";

const TimelineRoute = () => {
  const history = useHistory();
  return (
    <div className="container-timeline-route">
      <h1>Ruta</h1>
      <Timeline
        className="timeline-container"
        mode="left"
        items={ROUTE.ruta.map((pointRoute, index) => {
          return {
            dot: (
              <span key={index} className="index-timeline">
                {index + 1}
              </span>
            ),
            children: (
              <article key={index} className="timeline-item">
                <p>{pointRoute.name}</p>
                <p>{pointRoute.address}</p>
                <p>{pointRoute.description}</p>

                {pointRoute.pedido.map((pedido) => (
                  <article className="timeline-sub-item">
                    <p>{pedido.item}</p>
                    <p>Cantidad: {pedido.quantity}</p>
                    <p>Precio: $ {pedido.price}</p>
                  </article>
                ))}
              </article>
            ),
          };
        })}
      />
      <span className="button-go-map" onClick={() => history.push(ROUTE_MAP)}>
        Ver ruta en el mapa
        <img width={25} src={GO_TO_MAP} alt="" />
      </span>
    </div>
  );
};

export default TimelineRoute;
