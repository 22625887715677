import { UID_AUTH } from "../persistent-managment/key.persistent";
import { setValueByKey } from "../persistent-managment/managment.persistent";
import {
  createAccountWhitEmailAndPasswordProvider,
  currentUser,
  resetPasswordByEmail,
  signInTokenGenerated,
  signInWhitEmailAndPasswordProvider,
  signInWithFacebookProvider,
  signInWithGoogleProvider,
  signOut,
} from "../services-firebase/auth";

const saveAuth = (value) => setValueByKey(UID_AUTH, value);

//errors provider
const OPERATION_NOT_ALLOWED = "Password sign-in is disabled for this project.";
const EMAIL_EXISTS = "The email address is already in use by another account.";
const WEAK_PASSWORD = "The password must be 6 characters long or more.";
const MISSING_PASSWORD = "Password is required in this request";
const INVALID_PASSWORD =
  "The password is invalid or the user does not have a password.";
const INVALID_EMAIL = "The email address is badly formatted.";
const EMAIL_NOT_FOUND =
  "There is no user record corresponding to this identifier. The user may have been deleted.";
const USER_DISABLED = "The user account has been disabled by an administrator.";

const OPERATION_NOT_ALLOWED_TRASLATE =
  "El inicio de sesión con contraseña está deshabilitado para este proyecto.";
const EMAIL_EXISTS_TRASLATE =
  "La dirección de correo electrónico ya está en uso por otra cuenta.";
const WEAK_PASSWORD_TRASLATE = "La contraseña debe tener 6 caracteres o más.";
const MISSING_PASSWORD_TRASLATE = "Se requiere contraseña en esta solicitud";
const INVALID_PASSWORD_TRASLATE =
  "La contraseña no es válida o el usuario no tiene una contraseña.";
const INVALID_EMAIL_TRASLATE =
  "La dirección de correo electrónico está mal formateada.";
const EMAIL_NOT_FOUND_TRASLATE =
  "No hay registro de usuario correspondiente a este identificador.\nEl usuario puede haber sido eliminado.";
const USER_DISABLED_TRASLATE =
  "La cuenta de usuario ha sido deshabilitada por un administrador.";
const GENERIC_ERROR = "Intenta nuevamente";

//state result
const USER_ERROR_RESULT = "undefined";
const CODE_SUCCESS = 200;
const CODE_ERROR = 400;

//error provider traslate
const buildText = (type) => {
  switch (type) {
    case OPERATION_NOT_ALLOWED:
      return OPERATION_NOT_ALLOWED_TRASLATE;
    case EMAIL_EXISTS:
      return EMAIL_EXISTS_TRASLATE;
    case WEAK_PASSWORD:
      return WEAK_PASSWORD_TRASLATE;
    case MISSING_PASSWORD:
      return MISSING_PASSWORD_TRASLATE;
    case INVALID_PASSWORD:
      return INVALID_PASSWORD_TRASLATE;
    case INVALID_EMAIL:
      return INVALID_EMAIL_TRASLATE;
    case EMAIL_NOT_FOUND:
      return EMAIL_NOT_FOUND_TRASLATE;
    case USER_DISABLED:
      return USER_DISABLED_TRASLATE;
    default:
      return GENERIC_ERROR;
  }
};

const signInWithGoogle = async (_) => {
  const res = await signInWithGoogleProvider();
  if (res) {
    const {
      user: { uid },
    } = res;
    saveAuth(uid);
    return { code: CODE_SUCCESS, user: uid };
  } else {
    return {
      code: CODE_ERROR,
      user: USER_ERROR_RESULT,
      message: GENERIC_ERROR,
    };
  }
};

const signInWithFacebook = async (_) => {
  const res = await signInWithFacebookProvider();
  if (res) {
    const {
      user: { uid },
    } = res;
    saveAuth(uid);
    return { code: CODE_SUCCESS, user: uid };
  } else {
    return {
      code: CODE_ERROR,
      user: USER_ERROR_RESULT,
      message: GENERIC_ERROR,
    };
  }
};

const signWithEmailAndPassword = async (email, pass) => {
  try {
    const res = await signInWhitEmailAndPasswordProvider(email, pass);
    if (res) {
      const {
        user: { uid },
      } = res;
      saveAuth(uid);
      return { code: CODE_SUCCESS, user: uid };
    }
  } catch (error) {
    return {
      code: CODE_ERROR,
      user: USER_ERROR_RESULT,
      message: buildText(error.message),
    };
  }
};

const signWithTokenGenerated = async (token) => {
  try {
    const res = await signInTokenGenerated(token);
    if (res) {
      const {
        user: { uid },
      } = res;
      saveAuth(uid);
      return { code: CODE_SUCCESS, user: uid };
    }
  } catch (error) {
    console.log(error);
    return {
      code: CODE_ERROR,
      user: USER_ERROR_RESULT,
      message: buildText(error.message),
    };
  }
};

const createAccountWhitEmailAndPassword = async (email, pass) => {
  try {
    const res = await createAccountWhitEmailAndPasswordProvider(email, pass);
    if (res) {
      const {
        user: { uid },
      } = res;
      saveAuth(uid);
      return { code: CODE_SUCCESS, user: uid };
    }
  } catch (error) {
    // if(error.message=== EMAIL_EXISTS){
    //     //llamar callback de auth con google
    //     //obtener const getCurrentUser el uid
    //     //saveAuth(uid)
    //     //return { code: CODE_SUCCESS, user: uid }
    //     return await signInWithGoogle()
    // }else{
    // return { code: CODE_ERROR, user: USER_ERROR_RESULT, message: buildText(error.message) }
    // }
    return {
      code: CODE_ERROR,
      user: USER_ERROR_RESULT,
      message: buildText(error.message),
    };
  }
};

const existUser = () => currentUser() !== null;

const SingOutUser = async () => await signOut();

const forgotPassword = async (email) => await resetPasswordByEmail(email);

export {
  SingOutUser,
  createAccountWhitEmailAndPassword,
  existUser,
  forgotPassword,
  signInWithFacebook,
  signInWithGoogle,
  signWithEmailAndPassword,
  signWithTokenGenerated,
};
