import React from "react";
import "./psCotizacion.css";
import { NewIconButton } from "../../../components/atoms/button/Button";
import SEND_ICON_SVG from "../../../assets/icons/svg/send.svg";
import ADD_ICON_SVG from "../../../assets/icons/svg/plus-square.svg";
import EDIT_ICON_SVG from "../../../assets/icons/svg/edit.svg";
import DELETE_ICON_SVG from "../../../assets/icons/svg/trash.svg";
import { Appbar, AppbarLogo } from "../../../components/atoms/appbar/Appbar";
import CurrencyFormat from "react-currency-format";

const ItemTemplate = ({ item, index, editItem, deleteItem }) => {
  const sumPrice = () => {
    const workforce = item.workforce;
    let materialPrice = 0;
    for (let material of item.materials) {
      const currentPrice = material.price_unit;
      const units = material.quantity;
      const total = currentPrice * units;
      materialPrice = materialPrice + total;
    }

    return materialPrice + workforce;
  };

  return (
    <div className="item-template-container">
      <div className="it-name-container">
        {index + 1}. {item.damage_type}
      </div>

      <div className="it-price-container">
        $
        {
          <CurrencyFormat
            value={parseInt(sumPrice())}
            thousandSeparator={true}
            displayType={"text"}
          />
        }
      </div>

      <div className="it-big-icons-container">
        <div
          className="it-icon-container"
          onClick={() => editItem(item, index)}
        >
          <img src={EDIT_ICON_SVG} className="it-icon" />
        </div>

        <div className="it-icon-container" onClick={deleteItem}>
          <img src={DELETE_ICON_SVG} className="it-icon" />
        </div>
      </div>
    </div>
  );
};

const ServiceProviderCotizacionItems = ({
  navigatorCotizacion,
  totalPrice,
  deleteItem,
  editItem,
  itemsList,
  navigatorSendCotizacion,
  serviceId,
}) => {
  return (
    <div className="ps-services-main-container new-service-container">
      <AppbarLogo />

      <div className="desktop-container">
        <div className="new-service-appbar-container">
          <Appbar
            smallText="Cotización"
            bigText={`Caso - ${
              serviceId.split("~").length > 1
                ? serviceId.split("~")[1]
                : serviceId
            }`}
          />
        </div>

        <div className="new-service-cotizacion-container">
          <div className="item-list-container">
            {itemsList.map((item, index) => (
                <ItemTemplate
                  key={index}
                  index={index}
                  item={item}
                  editItem={()=>editItem(index)}
                  deleteItem={() => deleteItem(item, index)}
                />
              ))}
          </div>

          <div className="register-botton-menu">
            <NewIconButton
              txt="Agregar Item"
              style="new-item-style"
              icon={ADD_ICON_SVG}
              callback={navigatorCotizacion}
            />
          </div>

          <div className="cotizacion-cost-container items-total-cost">
            <div className="cotizacion-cost-title">Total cotización</div>
            <div className="cotizacion-cost-price">
              ${" "}
              {
                <CurrencyFormat
                  value={parseInt(totalPrice)}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              }
            </div>
          </div>

          <div className="register-botton-menu">
            <NewIconButton
              txt="Enviar cotización"
              style="ab-style"
              icon={SEND_ICON_SVG}
              callback={navigatorSendCotizacion}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceProviderCotizacionItems;
