import React, { useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './pdfPreview.css'
import PDF_SVG from '../../../assets/icons/svg/pdf-svg-white.svg'

const PdfPreview = ({ title, pdfUrl, onError, key }) => {

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  const onDocumentLoaded = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div key={key} className='pdfPreview-container'  style={{minHeight: '100px'} } onClick={() => {
      window.open(pdfUrl, '_blank')
    }}>
      <Document
        file={pdfUrl}
        onLoadSuccess={onDocumentLoaded}
      >
        <Page
          pageIndex={pageNumber}
          pageNumber={pageNumber}
          className='pdfPreview'
          height={250}
        />
      </Document>
      <div className='title-container'>
          <img src={PDF_SVG} alt="" style={{height: 30, width:30, marginLeft:4, marginRight:8}} />
          <h4 style={{ flex: '1', overflow: 'hidden', textOverflow: 'ellipsis' }}>{`${title}.pdf`}</h4>
      </div>

    </div>
  );
};

export default PdfPreview;
