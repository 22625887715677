import moment from "moment";
import "moment/locale/es";

moment.locale("es");

const SET_SELECTED_SERVICES_TYPE_ACTION =
  "set_selected_services_type_request_service";
const SET_UPDATE_REQUEST_SERVICE_ACTION =
  "set_update_request_service_type_request_service";
const SET_TEXT_DESCRIPTION_ACTION = "set_text_description_type_request_service";
const SET_RESOURCE_LIST_ACTION = "set_resource_list_type_request_service";
const SET_PROVIDER_ACTION_REQUEST_SERVICE =
  "set_provider_action_type_request_service";
const SET_ID_ACTION_REQUEST_SERVICE = "set_id_action_type_request_service";
const SET_QUERY_ACTION_REQUEST_SERVICE =
  "set_query_action_type_request_service";
const SET_REQUEST_PRIORITY_ACTION_REQUEST_SERVICE =
  "set_request_priority_action_type_request_service";
const SET_REQUEST_SCHEDULE_DATE_START_ACTION_REQUEST_SERVICE =
  "set_request_schedule_date_satart_action_type_request_service";
const SET_REQUEST_SCHEDULE_TIME_END_ACTION_REQUEST_SERVICE =
  "set_request_schedule_time_end_action_type_request_service";
const SET_REQUEST_SCHEDULE_VISITING_TIME_ACTION_REQUEST_SERVICE =
  "set_request_schedule_visiting_time_action_type_request_service";
const SET_STATE_SERVICE_CREATION_DATE_ACTION_REQUEST_SERVICE =
  "set_state_service_creation_date_action_type_request_service";
const SET_STATE_SERVICE_UPDATE_DATE_ACTION_REQUEST_SERVICE =
  "set_state_service_update_date_action_type_request_service";
const SET_STATE_SERVICE_END_DATE_ACTION_REQUEST_SERVICE =
  "set_state_service_end_date_action_type_request_service";
const SET_STATE_STAGE_ACTION_REQUEST_SERVICE =
  "set_state_stage_action_type_request_service";
const SET_REQUEST_DATE_SORT = "set_request_date_sort_type_request_service";

const initialStateRequestService = {
  counter_unexpected: 0,
  provider: "001",
  id: "",
  query: "",
  service_provider: "",
  date_sort: "",
  request: {
    general_description: "",
    selected_services: [],
    resources: [],
    //0 -> Medium
    //1 -> High
    //2 -> Priority
    priority: 0, //
    schedule: {
      // date_start: moment(new Date()).day() === 5 ? new Date(moment(new Date()).add(4,'days')) : moment(new Date()).add(3,'days').day() !== 0 ? new Date(moment(new Date()).add(3,'days')) : new Date(moment(new Date()).add(4,'days')),
      date_start: "",
      time_start: "",
      visiting_time: "",
    },
  },
  state: {
    active_warranty: false,
    warranty_date: 0,
    service_creation_date: "",
    service_update_date: "",
    service_end_date: "",
    stage: 0,
  },
  metadata: {
    user: {
      register_data: {
        name: "",
        contact: {
          phone: "",
        },
      },
    },

    property: {
      location: {
        space: "",
        address: {
          street_type: "",
          street: "",
          corner: "",
          number: "",
        },
      },
      optional: {
        description: "",
        shopping_center: "",
        locale: "",
      },
    },
  },
};

const reducerRequestService = (state = initialStateRequestService, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_PROVIDER_ACTION_REQUEST_SERVICE:
      return {
        ...state,
        provider: payload,
      };
    case SET_ID_ACTION_REQUEST_SERVICE:
      return {
        ...state,
        id: payload,
      };
    case SET_QUERY_ACTION_REQUEST_SERVICE:
      return {
        ...state,
        query: payload,
      };
    case SET_TEXT_DESCRIPTION_ACTION:
      return {
        ...state,
        request: {
          ...state.request,
          general_description: payload,
        },
      };
    case SET_SELECTED_SERVICES_TYPE_ACTION:
      return {
        ...state,
        request: {
          ...state.request,
          selected_services: payload,
        },
      };
    case SET_RESOURCE_LIST_ACTION:
      return {
        ...state,
        request: {
          ...state.request,
          resources: payload,
        },
      };
    case SET_REQUEST_PRIORITY_ACTION_REQUEST_SERVICE:
      return {
        ...state,
        request: {
          ...state.request,
          priority: payload,
        },
      };
    case SET_REQUEST_SCHEDULE_DATE_START_ACTION_REQUEST_SERVICE:
      return {
        ...state,
        request: {
          ...state.request,
          schedule: {
            ...state.request.schedule,
            date_start: payload,
          },
        },
      };
    case SET_REQUEST_SCHEDULE_TIME_END_ACTION_REQUEST_SERVICE:
      return {
        ...state,
        request: {
          ...state.request,
          schedule: {
            ...state.request.schedule,
            time_start: payload,
          },
        },
      };
    case SET_REQUEST_SCHEDULE_VISITING_TIME_ACTION_REQUEST_SERVICE:
      return {
        ...state,
        request: {
          ...state.request,
          schedule: {
            ...state.request.schedule,
            visiting_time: payload,
          },
        },
      };
    case SET_STATE_SERVICE_CREATION_DATE_ACTION_REQUEST_SERVICE:
      return {
        ...state,
        state: {
          ...state.state,
          service_creation_date: payload,
        },
      };
    case SET_STATE_SERVICE_UPDATE_DATE_ACTION_REQUEST_SERVICE:
      return {
        ...state,
        state: {
          ...state.state,
          service_update_date: payload,
        },
      };
    case SET_STATE_SERVICE_END_DATE_ACTION_REQUEST_SERVICE:
      return {
        ...state,
        state: {
          ...state.state,
          service_end_date: payload,
        },
      };
    case SET_STATE_STAGE_ACTION_REQUEST_SERVICE:
      return {
        ...state,
        state: {
          ...state.state,
          stage: payload,
        },
      };
    case SET_UPDATE_REQUEST_SERVICE_ACTION:
      return payload;
    default:
      return state;

    case SET_REQUEST_DATE_SORT:
      return {
        ...state,
        date_sort: payload,
      };
  }
};

const changeProvider = (value) => {
  return { type: SET_PROVIDER_ACTION_REQUEST_SERVICE, payload: value };
};

const changeId = (value) => {
  return { type: SET_ID_ACTION_REQUEST_SERVICE, payload: value };
};

const changeQuery = (value) => {
  return { type: SET_QUERY_ACTION_REQUEST_SERVICE, payload: value };
};

const changeDateSort = (value) => {
  return { type: SET_REQUEST_DATE_SORT, payload: value };
};

const changeSelectedServices = (value) => {
  return { type: SET_SELECTED_SERVICES_TYPE_ACTION, payload: value };
};

const changeTextDescription = (value) => {
  return { type: SET_TEXT_DESCRIPTION_ACTION, payload: value };
};

const changeResourceList = (value) => {
  return { type: SET_RESOURCE_LIST_ACTION, payload: value };
};

const changeRequestPriority = (value) => {
  return { type: SET_REQUEST_PRIORITY_ACTION_REQUEST_SERVICE, payload: value };
};

const changeRequestScheduleDateStart = (value) => {
  return {
    type: SET_REQUEST_SCHEDULE_DATE_START_ACTION_REQUEST_SERVICE,
    payload: value,
  };
};

const changeRequestScheduleTimeStart = (value) => {
  return {
    type: SET_REQUEST_SCHEDULE_TIME_END_ACTION_REQUEST_SERVICE,
    payload: value,
  };
};

const changeRequestScheduleVisitingTime = (value) => {
  return {
    type: SET_REQUEST_SCHEDULE_VISITING_TIME_ACTION_REQUEST_SERVICE,
    payload: value,
  };
};

const changeStateServiceCreationDate = (value) => {
  return {
    type: SET_STATE_SERVICE_CREATION_DATE_ACTION_REQUEST_SERVICE,
    payload: value,
  };
};

const changeStateServiceUpdateDate = (value) => {
  return {
    type: SET_STATE_SERVICE_UPDATE_DATE_ACTION_REQUEST_SERVICE,
    payload: value,
  };
};

const changeStateServiceEndDate = (value) => {
  return {
    type: SET_STATE_SERVICE_END_DATE_ACTION_REQUEST_SERVICE,
    payload: value,
  };
};

const changeStateStage = (value) => {
  return { type: SET_STATE_STAGE_ACTION_REQUEST_SERVICE, payload: value };
};

const changeRequestService = (value) => {
  return { type: SET_UPDATE_REQUEST_SERVICE_ACTION, payload: value };
};

export {
  initialStateRequestService,
  reducerRequestService,
  changeProvider,
  changeId,
  changeQuery,
  changeDateSort,
  changeSelectedServices,
  changeTextDescription,
  changeRequestService,
  changeRequestPriority,
  changeRequestScheduleDateStart,
  changeRequestScheduleTimeStart,
  changeRequestScheduleVisitingTime,
  changeStateServiceCreationDate,
  changeStateServiceUpdateDate,
  changeStateServiceEndDate,
  changeStateStage,
  changeResourceList,
};
