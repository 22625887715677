import { Modal, Result } from "antd-mobile";
import { useEffect, useState } from "react";
import { uploadPossiblePayment } from "services-controller/http.cleinte.controller";
import { uploadMultipleEvidenceResources } from "services-controller/storage";
import { useSelector } from "react-redux";
import PayModal from "./PayModal";

const PayModalController = ({ data }) => {
  const [files, setFiles] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [uploadInvoiceModal, setUploadInvoiceModal] = useState(false);
  const [isSendedToPayRevision, setIsSendedToPayRevision] = useState(false);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(null);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (data) {
      setLoading(false);
      const open = data?.some((service) => service.wallet.is_to_paid);
      setOpenModal(open);
    }
  }, [data]);

  const onUploadInvoice = async () => {
    const uploadedFiles = await uploadMultipleEvidenceResources(files);
    //solo se le permite enviar un archivo
    const invoice = {
      ...uploadedFiles.at(0),
      uploaded_date: new Date(),
    };
    const response = await uploadPossiblePayment(id, invoice);
    if (response.status === 200) {
      setIsSendedToPayRevision(true);
    }
    setUploadInvoiceModal(false);
    setOpenModal(false);
  };

  const onDeleteFile = (index) => {
    setFiles(files.filter((file, i) => i !== index));
  };

  const onConfirm = (flag, id) => {
    setUploadInvoiceModal(flag === "pay_ready" && true);
    setId(id);
    setOpenModal(false);
  };

  let state = {
    files,
    setFiles,
    uploadInvoiceModal,
    setUploadInvoiceModal,
    openModal,
    setOpenModal,
  };

  let functions = {
    onUploadInvoice,
    onDeleteFile,
    onConfirm,
  };

  const style = {
    backgroundColor: "#652d52",
    color: "white",
    padding: "10px 20px",
  };

  return isSendedToPayRevision ? (
    <Modal
      visible={isSendedToPayRevision}
      content={
        <Result status="success" description="El pago se envio a revisión" />
      }
    />
  ) : (
    <PayModal
      open={openModal}
      data={data}
      loading={loading}
      functions={functions}
      style={style}
      state={state}
      user={auth.user}
    />
  );
};

export default PayModalController;
