const OPTIONS = {
  updateChatController: "update-chat",
  deleteChatController: "delete-chat",
  onRecording: "on-recording",
  stopRecording: "stop-recording",
  saveRecordedBlob: "save-recorded-blob",
  showAudioSaved: "show-audio-saved",
  cleanRecordedBlob: "clean-recorded-blob",
  updateTextMessage: "update-text-message",
  resourcesBrute: "resources-brute",
  openEmojiPicker: "open-emoji-picker",
  showRecording: "show-recording",
  closeEmojiPicker: "close-emoji-picker",
}
  ;

const initialChatControllerState = {
  messageText: "",
  showRecording: false,
  isRecording: false,
  recordedBlob: null,
  showAudio: false,
  resources: [],
  showEmojiPicker: false,
};

const chatControllerReducer = (state = initialChatControllerState, action) => {
  const { type, payload } = action;

  switch (type) {
    case OPTIONS.updateChatController:
      return {
        ...state,
        ...payload,
      };
    case OPTIONS.deleteChatController:
      return {
        ...initialChatControllerState,
      };
    case OPTIONS.onRecording:
      return {
        ...state,
        showAudio: false,
        isRecording: true,
      };
    case OPTIONS.stopRecording:
      return {
        ...state,
        isRecording: false,
      };

    case OPTIONS.saveRecordedBlob:
      return {
        ...state,
        recordedBlob: payload,
        showAudio: true,
      };
    case OPTIONS.showAudioSaved:
      return {
        ...state,
        showAudio: true,
        isRecording: false,
        showRecording: false,
      };

    case OPTIONS.showRecording:
      return {
        ...state,
        showRecording: true,
      };

    case OPTIONS.cleanRecordedBlob:
      return {
        ...state,
        recordedBlob: null,
        showAudio: false,
      };
    case OPTIONS.updateTextMessage:
      return {
        ...state,
        messageText: payload,
      };
    case OPTIONS.resourcesBrute:
      return {
        ...state,
        resources: payload,
      };
    case OPTIONS.openEmojiPicker:
      return {
        ...state,
        showEmojiPicker: !state.showEmojiPicker,
      };
    case OPTIONS.closeEmojiPicker:
      return {
        ...state,
        showEmojiPicker: false,
      };

    default:
      return state;
  }
};

const updateChatController = (payload) => ({
  type: OPTIONS.updateChatController,
  payload,
});

const deleteChatController = () => ({
  type: OPTIONS.deleteChatController,
});

const onRecording = () => ({
  type: OPTIONS.onRecording,
});

const stopRecording = () => ({
  type: OPTIONS.stopRecording,
});

const saveRecordedBlob = (payload) => ({
  type: OPTIONS.saveRecordedBlob,
  payload,
});

const showAudioSaved = () => ({
  type: OPTIONS.showAudioSaved,
});

const cleanRecordedBlob = () => ({
  type: OPTIONS.cleanRecordedBlob,
});

const showRecording = () => ({
  type: OPTIONS.showRecording,
});

const updateTextMessage = (payload) => ({
  type: OPTIONS.updateTextMessage,
  payload,
});

const updateResources = (payload) => ({
  type: OPTIONS.resourcesBrute,
  payload,
});

const openEmojiPicker = (payload) => ({
  type: OPTIONS.openEmojiPicker,
  payload,
});

const closeEmojiPicker = () => ({
  type: OPTIONS.closeEmojiPicker,
});

const chatRecordingActions = {
  onRecording,
  stopRecording,
  saveRecordedBlob,
  showAudioSaved,
  cleanRecordedBlob,
  showRecording,
};

export {
  chatControllerReducer,
  updateChatController,
  deleteChatController,
  OPTIONS,
  chatRecordingActions,
  updateTextMessage,
  updateResources,
  openEmojiPicker,
  closeEmojiPicker,
};
