import React, { useState } from 'react'
import './psPopups.css'
import { BaseInput, BaseCurrencyFormatInput } from '../../components/atoms/input/Input'
import { BasePopup } from '../../components/atoms/popup/Popup'
import { PrimaryButton } from '../../components/atoms/button/Button'
import LOGO_ICON_SVG from '../../assets/icons/svg/otros.svg'

const PopupInvitationCode = ({ object }) => {

    const {
        callback,
        text = 'Ingresa el codigo de invitación',
        notInvitation = false,
        placeholder,
        btnText,
        callbackInput,
        defaultValue,
        error = false,
        errorText

    } = object

    const [code, setCode] = useState('')

    const callbackChangeCode = (e) => {
        setCode(e.target.value)
    }

    return (
        <BasePopup content={
            <div className='pif-container'>
                <div className='pif-icon-container'>
                    <img className='pif-icon' src={LOGO_ICON_SVG} />
                </div>

                <div className='pif-main-text'>
                    {text}
                </div>

                <div className='code-input-container'>
                    {
                        !notInvitation ?
                            <BaseInput
                                defaultValue={code}
                                placeholder={'Codigo'}
                                callback={callbackChangeCode}
                                style='code-input'
                            />
                            :

                            <BaseCurrencyFormatInput
                                callback={callbackInput}
                                placeholder={placeholder}
                                defaultValue={defaultValue}
                            />

                    }


                </div>

                {
                    error ?
                        <div className='error-text'>{errorText}</div> :
                        null
                }

                <div className='pif-element-container pif-top-margin-code'>
                    {
                        !notInvitation ?
                            <PrimaryButton txt='Enviar' callback={() => callback(code)} style='pif-button-style' />
                            :
                            <PrimaryButton txt={btnText} callback={callback} style='pif-button-style' />

                    }
                </div>

            </div>
        } />

    )
}

export default PopupInvitationCode