import React from "react";
import { Image } from "antd";

const ImagePreview = ({ evidence }) => {
  return (
    <Image className="evidence-photo" src={evidence.url} alt={evidence.name} />
  );
};

export default ImagePreview;
