import { message } from "antd";
import { convertSnapshotToListV2 } from "callbacks/list";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { notification as NotificationComponent } from "antd";
import {
  getNotifications,
  notificationRemoveById,
} from "services-controller/database";
import {
  removeNotifications,
  updateNotification,
} from "services-controller/http.cleinte.controller";
import { countUnreadNotifications } from "utils/utils.notifications";

const useNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(null);
  const auth = useSelector((state) => state.auth);
  const { id } = auth.user;

  const updateList = useCallback((snapshot) => {
    if (typeof snapshot === "object") {
      const currentList = convertSnapshotToListV2(snapshot.val()).reverse();
      setNotifications(currentList);
      setUnreadNotifications(countUnreadNotifications(currentList));
    }
  }, []);

  useEffect(() => {
    const listener = getNotifications(updateList);

    let mounted = true;
    if (mounted) {
      listener();
    }
    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notificationOnClick = async (notification, history) => {


    switch (notification?.type) {
      case "new_message":
        history.push(
          `/services/chat/entry/${notification?.serviceId}/${notification?.entryId}`
        );
        break;
      case "new_entry":
        const route = notification.entry ?
          `/services/chat/entry/${notification?.serviceId}/${notification?.entry}`
          :
          `/details/${notification?.serviceId}/9`;
        history.push(
          route
        );
        break;
      case "general":
        NotificationComponent.info({
          message: notification.title,
          description: notification.message,
          duration: 0
        });
        break;
      default:

        break;
    }

    await updateNotification(notification?.unique_key, {
      ...notification,
      read: true,
    });
  };

  const handleMarkAsRead = async (idNotification) => {
    await updateNotification(idNotification, { read: true });
  };

  const handleRemoveById = async (idNotification, notification) => {
    switch (notification) {
      case "notifications":
        try {
          await removeNotifications(idNotification, id);
        } catch (error) {
          console.error(error);
        }
        break;
      case "notifications_appointments":
        await notificationRemoveById(id, notification);
        break;

      default:
        return;
    }
  };

  return {
    notifications,
    handleRemoveById,
    unreadNotifications,
    notificationOnClick,
    handleMarkAsRead,
  };
};

export default useNotifications;
