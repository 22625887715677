import React from 'react'
import { withRouter } from 'react-router-dom'
import { MENU_PATH } from '../../router/routes'
import './newServices.css'
import { Appbar } from '../../components/atoms/appbar/Appbar'
import { IconPopup } from '../../components/atoms/popup/Popup'
import CHECK_ICON_SVG from'../../assets/icons/svg/check-circle.svg'

const ServiceSent = ({ history }) => {

    const goMenu = () => history.push(MENU_PATH)

    return (
        <div className='new-service-container'>

            <div className='new-service-appbar-container'>
                <Appbar smallText='Solicitud de servicio' bigText='Enviado' />
            </div>
            <div className='request-sent-info'>
                <IconPopup callback={goMenu} text='Servicio enviado de manera exitosa.' btnText='Inicio' btnColor='popupPurple' icon={CHECK_ICON_SVG} />
            </div>
        </div>
    )
}

export default withRouter(ServiceSent)