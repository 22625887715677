import React from "react";
import Linkify from "react-linkify";
import "./messageInfo.css";
import LazyLoad from "../../../../../loading/LazyComponent";
import PdfPreview from "components/atoms/pdfPreview/PdfPreview";
import ExcelPreview from "components/atoms/excelPreview/ExcelPreview";
import { Image } from "antd";

const JUSTO_PROFILE =
  "https://firebasestorage.googleapis.com/v0/b/justo-pago.appspot.com/o/resources%2F77e1ff0f-cec0-56a1-9eb8-490964c2f9f3.png?alt=media&token=4d814f3a-7dfb-43fa-a9cc-82bf10d34d07";

const MessageInfo = ({ message, openMediaFilePopup }) => {
  const isTagInMessage = (text) => {
    if (message.tags?.tags && text) {
      for (let tagId in message.tags?.tags) {
        const tag = message.tags?.tags[tagId];
        if (text.includes(`@${tag.name}`)) {
          return true;
        }
      }
    }
    return false;
  };

  const getTagsFromMessage = (text) => {
    const foundTags = [];
    if (message.tags?.tags && text) {
      const tagNames = Object.values(message.tags.tags).map((tag) => tag.name);
      const tagThumbnails = Object.values(message.tags.tags).reduce(
        (thumbnails, tag) => {
          thumbnails[tag.name] = tag.thumbnail;
          return thumbnails;
        },
        {}
      );

      const regex = new RegExp(`@(${tagNames.join("|")})\\b`, "g");
      const matches = text.match(regex);
      if (matches) {
        matches.forEach((match) => {
          foundTags.push({
            name: match,
            thumbnail: tagThumbnails[match.substring(1)], // Remove '@' from the beginning
          });
        });
      }
    }
    return foundTags;
  };

  const shouldApplyClass = isTagInMessage(message.text);
  const tagsInMessage = getTagsFromMessage(message.text);

  const parts = [];
  let currentIndex = 0;

  tagsInMessage.forEach((tagObj) => {
    const { name, thumbnail } = tagObj;
    const index = message.text.indexOf(name, currentIndex);
    if (index !== -1) {
      parts.push(message.text.substring(currentIndex, index));
      parts.push({ tag: name, thumbnail });
      currentIndex = index + name.length;
    }
  });

  if (currentIndex < message.text.length) {
    parts.push(message.text.substring(currentIndex));
  }

  let commentInfoContainer = null;

  if (message?.type === "audio") {
    commentInfoContainer = (
      <div className="audio-message">
        <audio src={message.audioResource?.url} controls={true}></audio>
      </div>
    );
  } else if (message?.type === "imageFiles") {
    commentInfoContainer = (
      <div className="image-message-container">
        {message.text && message.text !== "" && (
          <div className="text-container">
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a
                  className={"message-link"}
                  target="blank"
                  href={decoratedHref}
                  key={key}
                >
                  {decoratedText}
                </a>
              )}
            >
              {parts.map((part, index) =>
                typeof part === "object" ? (
                  <span key={index} className="tag-thumbnail">
                    <img src={part.thumbnail || JUSTO_PROFILE} alt={part.tag} />
                    <span className={shouldApplyClass ? "tag-info" : ""}>
                      {part.tag}
                    </span>
                  </span>
                ) : (
                  <span key={index}>{part}</span>
                )
              )}
            </Linkify>
          </div>
        )}
        {message.resources?.map((resource, index) => (
          <div key={index} className="image-message">
            {resource.type === "image" && (
              <LazyLoad
                resource={resource}
                openMediaFilePopup={openMediaFilePopup}
                Component={() => {
                  return <Image src={resource.url} alt={resource.name} />;
                }}
              />
            )}
            {resource.type === "pdf" && (
              <LazyLoad
                type={"pdf"}
                resource={resource}
                openMediaFilePopup={openMediaFilePopup}
                Component={() => {
                  return (
                    <PdfPreview
                      pdfUrl={resource.url}
                      title={resource.name}
                      onError={() => console.log("error")}
                      key={index}
                    />
                  );
                }}
              />
            )}
            {resource.type === "excel" && (
              <LazyLoad
                resource={resource}
                openMediaFilePopup={openMediaFilePopup}
                type={"excel"}
                Component={() => {
                  return (
                    <ExcelPreview
                      excelUrl={resource.url}
                      title={resource.name}
                      onError={() => console.log("error")}
                      key={index}
                    />
                  );
                }}
              />
            )}
          </div>
        ))}
      </div>
    );
  } else if (
    message?.text &&
    message.text !== "" &&
    (!message.resources || message.resources.length === 0) &&
    !message.audioResource
  ) {
    commentInfoContainer = (
      <div>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              className={"message-link"}
              target="blank"
              href={decoratedHref}
              key={key}
            >
              {decoratedText}
            </a>
          )}
        >
          {parts.map((part, index) =>
            typeof part === "object" ? (
              <span key={index} className="tag-thumbnail">
                <img src={part.thumbnail || JUSTO_PROFILE} alt={part.tag} />
                <span className={shouldApplyClass ? "tag-info" : ""}>
                  {part.tag}
                </span>
              </span>
            ) : (
              <span key={index}>{part}</span>
            )
          )}
        </Linkify>
      </div>
    );
  }

  return <div>{commentInfoContainer}</div>;
};

export default MessageInfo;
