import React from 'react'
import { OneButtonsPopup } from '../components/atoms/popup/Popup'


const PopupAccesoCubierta = ({ object }) => {
    const {
        message,
        textPositive,
        callbackPositive,

    } = object
    return (
        <OneButtonsPopup
            text={message}
            btnOneColor='btnYellow'
            btnOneText={textPositive}
            callbackOne={callbackPositive} />
    )
}


export {
    PopupAccesoCubierta
}