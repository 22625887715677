import { Drawer, message, Tabs } from 'antd';
import useReduxState from 'custom-hooks/useReduxState';
import { useEffect, useMemo, useState } from 'react';
import { setMediaSectionActive } from 'reducers/bitacle.reducer';
import './MediaSection.css';
import MediaAll from './components/mediaAll/MediaAll';
import MediaLinks from './components/mediaLinks/MediaLinks';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getAllMediaFromChat } from '../../../../services-controller/http.cleinte.controller';

export default function MediaSection() {
  const [{ isMediaSectionActive, chatPhaseSelected }, dispatch] = useReduxState({ setMediaSectionActive, }, "bitacle")

  const [selectedTab, setSelectedTab] = useState("1");


  const [chatFiles, setChatFiles] = useState([]);
  const [chatDocs, setChatDocs] = useState([]);
  const [chatLinks, setChatLinks] = useState([]);
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const fetchChatMedia = async () => {
    setLoading(true)
    try {

      const { chatDocs, chatFiles, chatLinks } = await getAllMediaFromChat(id, chatPhaseSelected.key)
      setChatLinks(chatLinks ?? []);
      setChatFiles(chatFiles ?? []);
      setChatDocs(chatDocs ?? []);
      setLoading(false)
    } catch (err) {
      message.error("Error cargando " + err)
    }
  }

  useEffect(() => {
    fetchChatMedia();
  },
    [chatPhaseSelected])

  const tabOptions = useMemo(() => {
    return [
      {
        key: '1',
        label: 'Multimedia',
        children: <MediaAll filesList={chatFiles} loading={loading} />,
      },
      {
        key: '2',
        label: 'Archivos',
        children: <MediaAll filesList={chatDocs} loading={loading} />,
      },
      // {
      //   key: '3',
      //   label: 'Renders',
      //   children: <MediaRenders />,
      // },
      {
        key: '3',
        label: 'Enlaces',
        children: <MediaLinks links={chatLinks} loading={loading} />,
      },
      // {
      //   key: "5",
      //   label: "Otros",
      //   children: <MediaOthers />,
      // },
    ]
  }, [selectedTab, loading, chatPhaseSelected]) //eslint-disable-line

  return (
    <Drawer
      open={isMediaSectionActive}
      placement="right"
      closable={true}
      onClose={() => {
        dispatch.setMediaSectionActive(false)
      }}
      title="Lista de medios"
      size='large'
      maskClosable={false}
      destroyOnClose
      classNames={{
        body: "media-section-body",
      }}
    >
      <Tabs
        items={tabOptions}
        className="media-section-tabs"
        indicator={{
          size: (origin) => origin - 20,
          align: "center",
        }}
        onChange={(key) => {
          setSelectedTab(key)
        }}
      />
    </Drawer>
  )
}
