import { Button, Form, Mentions, Popover } from 'antd';
import { ReactComponent as MicrophoneIcon } from "assets/icons/svg/chat-microphone.svg";
import { ReactComponent as PaperclipIcon } from "assets/icons/svg/chat-paperclip.svg";
import { ReactComponent as SendIcon } from "assets/icons/svg/chat-send.svg";
import useReduxState from 'custom-hooks/useReduxState';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getEmisorFromUser } from 'res/utils';
import { createNewMessage } from 'services-controller/http.cleinte.controller';
import './ChatFooter.css';
import FileSelector from './components/fileSelector/FileSelector';
import SoundRecorder from './components/soundRecorder/SoundRecorder';

export default function ChatFooter() {
  const [form] = Form.useForm()
  const messageWatch = Form.useWatch("message", form)
  const [{ chat, chatPhaseSelected },] = useReduxState({}, "bitacle",)
  const textAreaRef = useRef(null)
  const { id } = useParams()
  const user = useSelector(state => state.auth.user)
  const [isFileSelectorOpen, setIsFileSelectorOpen] = useState(false)
  const [isSelectingMention, setIsSelectingMention] = useState(false);
  const [isSendBtnPopoverOpen, setIsSendBtnPopoverOpen] = useState(false)

  const onSubmit = useCallback(async () => {
    const { message } = form.getFieldsValue()
    const emisor = getEmisorFromUser(user)

    let obj = {
      timestamp: new Date().toISOString(),
      is_valid: false,
      position: chat.length,
      emisor: emisor,
      user: emisor.name,
      message: message,
      admin_read: true,
      admin_read_date: new Date().toISOString(),
      client_read: false,
      client_read_date: "",
      sp_read: false,
      sp_read_date: "",
      type: "message",
      images: [],
      avatar: emisor.thumbnail,
    };

    await createNewMessage(id, chatPhaseSelected.key, user?.register_type === 200 ? 'sp' : "client", obj, emisor)
    form.resetFields()

  }, [form.getFieldsValue()]) // eslint-disable-line

  useEffect(() => {
    textAreaRef.current?.focus()
  }, [])

  return (
    <footer className='bitacle-chat-footer'>
      <Form
        id='chat-form'
        form={form}
        className='chat-form'
        onFinish={() => {
          onSubmit()
        }}
      >
        <Form.Item
          name="message"
        >
          <Mentions
            className="message-input"
            size="large"
            autoFocus
            ref={textAreaRef}
            split=""
            allowClear
            prefix="$$"
            placeholder='Escribe tu mensaje'
            onSelect={() => {
              // Delay the "submit" to avoid send a message when selecting a mention
              setTimeout(() => {
                setIsSelectingMention(false)
              }, 40);
            }}
            onSearch={() => {
              setIsSelectingMention(true)
            }}
            onKeyDown={(mentionEvent) => {
              const event = mentionEvent.nativeEvent

              // Add a new line when pressing enter + (alt, shift, meta[from apple devices])
              if (
                (
                  event.metaKey ||
                  event.getModifierState("AltGraph") ||
                  event.altKey ||
                  event.shiftKey
                )
                && event.key === "Enter"
              ) {
                event.preventDefault()
                form.setFieldsValue({ message: `${form.getFieldValue("message")}\n` })

                // scroll text area to bottom
                setTimeout(() => {
                  event.target.scrollTop = event.target.scrollHeight
                }, 100);

                return
              }

              // Avoid adding new line which is the default of textarea
              if (event.key === "Enter" && !event?.target?.value?.trim().length) {
                event.preventDefault()
                return
              }

              if (event.key === "Enter") {
                event.preventDefault()

                if (!isSelectingMention) {
                  form.submit()
                }

                return
              }
            }}
            options={[
              {
                value: 'afc163',
                label: 'afc163',
              },
              {
                value: 'zombieJ',
                label: 'zombieJ',
              },
              {
                value: 'yesmeck',
                label: 'yesmeck',
              },
            ]}
          />
        </Form.Item>
      </Form>

      <Popover
        open={isSendBtnPopoverOpen}
        arrow={false}
        rootClassName="send-btn-popover"
        destroyTooltipOnHide
        content={
          <SoundRecorder
            popoverState={[isSendBtnPopoverOpen, setIsSendBtnPopoverOpen]}
          />
        }
      >
        <Button
          type="primary"
          form="chat-form"
          htmlType={messageWatch?.length ? "submit" : "button"}
          size="large"
          className="send-btn"
          icon={messageWatch?.trim()?.length ? <SendIcon /> : <MicrophoneIcon />}
          onClick={() => {
            if (!messageWatch?.trim().length) {
              setIsSendBtnPopoverOpen(!isSendBtnPopoverOpen)
            }
          }}
        >
        </Button>
      </Popover>

      <Popover
        trigger="click"
        rootClassName="file-selector-popover"
        arrow={false}
        placement="topRight"
        open={isFileSelectorOpen}
        destroyTooltipOnHide
        content={
          <FileSelector
            open={isFileSelectorOpen}
            setOpen={setIsFileSelectorOpen}
          />
        }
      >
        <Button
          size="large"
          icon={<PaperclipIcon />}
          className='upload-btn'
          onClick={() => {
            setIsFileSelectorOpen(!isFileSelectorOpen)
          }}
        >
        </Button>
      </Popover>
    </footer>
  )
}
