import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { signWithTokenGenerated } from "services-controller/auth";
import LoadingScreen from "views/loading/LoadingScreen";
import "./authLoader.css";
const AuthViewLoader = () => {
  const { token } = useParams();
  const signIn = async () => await signWithTokenGenerated(token);

  useEffect(() => {
    signIn();
  }, []);
  return (
    <div id="container_loader">
      <LoadingScreen />
    </div>
  );
};

export default AuthViewLoader;
