import React from 'react'
import Calendar from 'react-calendar/dist/entry.nostyle'
import './styledCalendar.css'

const StyledCalendar = ({
    callbackSelectedDate,
    defalutValueDate,
    servicesCalendar = [],
    minDate = false,
    minDateAviable=null,
    disableDays=false
}) => {

    return (
        <Calendar
            defaultValue={new Date()}
            activeStartDate={defalutValueDate}
            value={defalutValueDate}
            tileClassName='dayServiceSchedule'
            onClickMonth={() => { }}
            onClickDay={callbackSelectedDate}
            minDetail='month'
            formatShortWeekday={(_, date) => ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'][date.getDay()]}
            minDate={!minDate ? new Date() : minDateAviable}
            next2Label={null} prev2Label={null}
            navigationLabel={({ date }) => date.toLocaleString('es-CO', { month: 'long' })}
            tileContent= {( date ) => servicesCalendar.includes(date.date.toString()) ? <div className='serviceDot-container'><div className='serviceDot' /></div> : null }
            tileDisabled = {({activeStartDate, date, view }) => disableDays ? date.getDay() === 0 : null}
            tileClassName = {({activeStartDate, date, view }) => disableDays ? date.getDay() === 0 ? 'disable-day' : '' : null}
        />
    )
}

export default StyledCalendar;

