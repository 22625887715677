export function sendNotification(title, options) {
  // Check if the browser supports notifications
  if (!("Notification" in window)) {
    console.error("This browser does not support desktop notification");
    return;
  }

  // Check if notification permissions have already been granted
  if (Notification.permission === "granted") {
    // If it's okay, let's create a notification
    new Notification(title, options);
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        new Notification(title, options);
      }
    });
  }
}

// Example usage:
