import React, { useState } from 'react'
import './psPopups.css'
import LOGO_ICON_SVG from '../../assets/icons/svg/otros.svg'
import { BasePopup } from '../../components/atoms/popup/Popup'
import { IconInput } from '../../components/atoms/input/Input'
import USER_ICON_SVG from '../../assets/icons/svg/user.svg'
import MAIL_ICON_SVG from '../../assets/icons/svg/mail.svg'
import { PrimaryButton } from '../../components/atoms/button/Button'
import { createNewHelper } from '../../services-controller/http.cleinte.controller'
import { currentUser } from '../../services-firebase/auth'
import { REGEX_EMAIL } from '../../res/regex'

const PopupInvitationFormController = ({ object }) => {

    const {
        callback
    } = object

    const [name, setName] = useState('')

    const [email, setEmail] = useState('')

    const callbackChangeName = (e) => setName(e.target.value)

    const callbackChangeEmail = (e) => setEmail(e.target.value)

    const callbackAddNewHelper = async () => {

        const result = REGEX_EMAIL.test(email)

        if (result) {
            const uid = currentUser().uid
            const res = await createNewHelper(uid, email, name)
        }

        await callback()

    }

    return (
        <PopupInvitationForm
            callbackChangeName={callbackChangeName}
            defaultValueName={name}
            callbackChangeEmail={callbackChangeEmail}
            defaultValueEmail={email}
            callback={callbackAddNewHelper}
        />
    )

}

const PopupInvitationForm = ({
    callback,
    callbackChangeName,
    defaultValueName,
    callbackChangeEmail,
    defaultValueEmail,
}) => {

    return (
        <BasePopup content={
            <div className='pif-container'>
                <div className='pif-icon-container'>
                    <img className='pif-icon' src={LOGO_ICON_SVG} />
                </div>

                <div className='pif-main-text'>
                    ¿Deseas invitar un ayudante que no se encuentra registrado en la comunidad?
                </div>

                <div className='pif-secondary-text'>
                    Invítalo a ser parte de Justo Pago.
                </div>

                <div className='pif-main-text bold'>
                    Ingresa nombre y correo
                </div>

                <div className='pif-element-container'>
                    <IconInput
                        defaultValue={defaultValueName}
                        placeholder='Nombre completo'
                        icon={USER_ICON_SVG}
                        callback={callbackChangeName} />
                </div>

                <div className='pif-element-container'>
                    <IconInput
                        defaultValue={defaultValueEmail}
                        placeholder='Correo'
                        icon={MAIL_ICON_SVG}
                        callback={callbackChangeEmail} />
                </div>

                <div className='pif-element-container pif-top-margin'>
                    <PrimaryButton txt='Envíar invitación' callback={callback} style='pif-button-style' />
                </div>

            </div>
        } />

    )
}

export default PopupInvitationFormController