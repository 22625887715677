import { useSelector } from "react-redux";
export const AlertDot = ({
  statusMessages,
  //isNewAdminEntry,
  user,
  hasCarpenter,
}) => {
  const auth = useSelector((state) => state.auth);
  const newMessage =
    user === "client"
      ? statusMessages?.newClientMessage
      : statusMessages?.newSpMessage;
  const newEntry =
    user === "client"
      ? statusMessages?.isNewClientEntry
      : statusMessages?.isNewSpEntry;

  return (
    <div
      className={`${newMessage ? "time-container-pulse" : ""} ${
        newEntry && user !== "carpenty" ? "time-container-pulse-is-new" : ""
      }
      
      ${
        hasCarpenter && auth.user.user_type === 221 && newMessage
          ? "time-container-pulse-carpenty"
          : ""
      } `}
    ></div>
  );
};

export default AlertDot;
