// import { MoreOutlined } from "@ant-design/icons";
import { Avatar, Button, Image, Progress } from 'antd'
import userIconUrl from "assets/icons/svg/user.svg"
import PdfPreview from "components/atoms/pdfPreview/PdfPreview"
import { useEffect, useRef } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { moneyFormat, timeAgo } from 'res/utils'
import './ChatBody.css'

// const typesMsgCanBeEdited = ["message", "activity"]

export default function ChatBody() {
  const chat = useSelector((state) => state.bitacle.chat, shallowEqual)
  
  const chatBodyRef = useRef(null)
  const chatPhaseSelected = useSelector((state) => state.bitacle.chatPhaseSelected, shallowEqual)
  const user = useSelector(state => state.auth.user, shallowEqual)

  useEffect(() => {    
    chatBodyRef.current?.scrollTo(0, chatBodyRef.current?.scrollHeight)
  }, [chat, chatPhaseSelected,])

  return (
    <section className='bitacle-chat-body' ref={chatBodyRef}>
      {chat?.map((item, index, array) => {
        const { message, timestamp, type, detail, images, percent, total, title, emisor, audio, files } = item
        const prevItem = array?.[index - 1]
        const nextItem = array?.[index + 1]
        const prevSame = prevItem?.emisor?.id === emisor?.id
        const nextSame = nextItem?.emisor?.id === emisor?.id
        const auxType = type ?? "message"

        const prevMinutes = Math.abs(new Date(prevItem?.timestamp) - new Date(timestamp)) / 1000 / 60
        const prevMsgLate = prevMinutes > 30

        const nextMinutes = Math.abs(new Date(nextItem?.timestamp) - new Date(timestamp)) / 1000 / 60
        const nextMsgLate = Number.isNaN(nextMinutes) ? true : nextMinutes > 30

        const isOtherUser = emisor?.id !== user?.id

        return (
          <article
            key={index}
            id={`message-${item.position}`}
            data-key={item.position}
            className={
              [
                "chat-message",
                isOtherUser && "is-other-user",
                prevSame && "prev-same-user",
                nextSame && "next-same-user",
                prevMsgLate && "prev-msg-late",
              ]
                .filter(Boolean)
                .join(" ")
            }
            data-type={auxType}
          >
            <section className="message-wrapper">
              {title && (<h1 className="header-title">{title}</h1>)}

              {type === "message" && (
                <p className="message-paragraph">
                  {message}
                </p>
              )}

              {type === "advance" && (
                <Progress
                  percent={(percent * 100)}
                  size={[240, 4]}
                  className="special-message-progress"
                />
              )}

              {type === "unforeseen" && (
                <span>Tiempo aproximado...</span>
              )}

              {(type === "collection-bill" || type === "payment-invoice") && (
                <span>
                  {type === "collection-bill" && "Total a pagar"}
                  {type === "payment-invoice" && "Total pagado"}
                  {" "}
                  <strong>{moneyFormat({ value: total })}</strong>
                </span>
              )}

              {type === "collection-bill" && (
                <footer className="special-message-footer">
                  <Button>Ya pague</Button>
                  <Button>Pagar</Button>
                </footer>
              )}

              {type === "activity" && (
                <div className="activity-message-wrapper">
                  <p>{detail}</p>

                  <div className="activity-resources-wrapper">
                    {images?.length && images.map((url, index) => {
                      const isPdf = url.includes(".pdf");

                      if (isPdf) {
                        return (
                          <PdfPreview
                            key={index}
                            title="Evidencia"
                            pdfUrl={url}
                          />
                        )
                      }

                      return (
                        <Image
                          key={index}
                          src={url}
                          width={160}
                          height={160}
                        />
                      )
                    })}
                  </div>
                </div>
              )}

              {type === "audio" && (
                <audio
                  controls
                  className="audio-message-wrapper"
                >
                  <source
                    src={audio?.url}
                    type={audio?.type.includes("audio/") ? audio?.type : "audio/mp3"}
                  />
                </audio>
              )}

              {type === "file" && (
                <div className="file-message-wrapper">
                  {files?.length && files.map((file, index) => {
                    const { url, name } = file
                    const isPdf = url.includes(".pdf");

                    if (isPdf) {
                      return (
                        <PdfPreview
                          key={index}
                          title={name}
                          pdfUrl={url}
                        />
                      )
                    }

                    return (
                      <Image
                        key={index}
                        src={url}
                        width={160}
                        height={160}
                      />
                    )
                  })}
                </div>
              )}

              {/* <Popover
                trigger="click"
                rootClassName="options-msg-popover"
                arrow={true}
                placement={isOtherUser ? "right" : "left"}
                content={
                  <ul className="popover-msg-list">
                    {typesMsgCanBeEdited.includes(type) && (
                      <li>
                        <Button
                          size="small"
                        >
                          Editar
                        </Button>
                      </li>
                    )}

                    <li>
                      <Button
                        size="small"
                      >
                        Eliminar
                      </Button>
                    </li>

                    <li>
                      <Button
                        size="small"
                      >
                        Destacar
                      </Button>
                    </li>
                  </ul>
                }
              >
                <Button
                  icon={<MoreOutlined />}
                  size="small"
                  type="text"
                  className="options-msg-btn"
                  hidden
                >
                </Button>
              </Popover> */}
            </section>

            {
              (
                (isOtherUser && nextMsgLate) ||
                (isOtherUser && !nextSame) ||
                (!nextSame) ||
                (nextSame && nextMsgLate)
              ) && (
                <footer className="chat-message-footer">
                  {(
                    (isOtherUser && nextMsgLate) ||
                    (isOtherUser && !nextSame)
                  ) && (
                      <div className="footer-details">
                        <Avatar
                          size={32}
                          src={emisor?.thumbnail || userIconUrl}
                          alt={`Avatar de ${emisor?.name}`}
                          className='emisor-avatar'
                        />

                        <span className='emisor'>{emisor?.name}</span>
                      </div>
                    )}

                  {(
                    (!nextSame) ||
                    (nextSame && nextMsgLate)
                  ) && (
                      <time dateTime={timestamp} className="footer-date">
                        {timeAgo(timestamp)}
                      </time>
                    )}
                </footer>
              )
            }
          </article>
        )
      })}
    </section>
  )
}
