import PsNotificationsInformation from "components/molecules/notifications/PsNotificationInformation";
//import FROWN_ICON_SVG from "../../../assets/icons/svg/frown.svg";
import { NotificationsInformation } from "../information/Information";
import { Empty } from "antd";

export const NotificationArea = ({
  notifications,
  onHandleNotiClick,
  psNotification,
  getBitacleEntryInfo,
  handleRemoveById,
  handleMarkAsRead,
}) => {
  if (notifications.length === 0) {
    return <NoNotifications />;
  }

  if (psNotification) {
    return (
      <PsNotificationsInformation
        arr={notifications}
        getBitacleEntryInfo={getBitacleEntryInfo}
        onHandleNotiClick={onHandleNotiClick}
        handleRemoveById={handleRemoveById}
        handleMarkAsRead={handleMarkAsRead}
      />
    );
  } else {
    return (
      <NotificationsInformation
        arr={notifications}
        onHandleNotiClick={onHandleNotiClick}
        handleRemoveById={handleRemoveById}
        handleMarkAsRead={handleMarkAsRead}
      />
    );
  }
};

const NoNotifications = () => (
  <div className="no-assistants-container">
    <Empty
      description={"No hay notificaciones"}
      style={{ marginTop: "60px" }}
    />
  </div>
);
