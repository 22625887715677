import React, { useState, useEffect } from "react";
import { withRouter, useParams, Redirect } from "react-router-dom";
import RegisterPropHouse from "../../register/propertyTypes/RegisterPropHouse.jsx";
import RegisterNewPersonal from "../../register/propertyTypes/RegisterNewPersonal.jsx";

import {
  NEW_PERSONAL,
  APP_PATH,
  PROPERTY_EDIT_PATH,
} from "../../../router/routes";
import { convertTypeRegisterToManagementCode } from "../../../res/convert.codes";
import {
  changeNiackname,
  changeDescription,
  changePhone,
  changeDepartmentCode,
  changeCityCode,
  changeAddressStreetType,
  changeAddressStreet,
  changeAddressCorner,
  changeAddressNumber,
  changeNumberFloors,
  changeDeckAccess,
  changeShoppingCenter,
  changeSpace,
  updateProperty,
  changeOfficHoursStart,
  changeOfficeHourFinal,
  changeManagement,
} from "../../../reducers/form.property.reducer";
import {
  createPropertyById,
  userManagementsById,
  createManagementById,
  syncMailchimpInvitationForManager,
} from "../../../services-controller/http.cleinte.controller";
import md5 from "md5";
import { UID_AUTH } from "../../../persistent-managment/key.persistent";
import { getValueByKey } from "../../../persistent-managment/managment.persistent";
import {
  changePropertiesAddProperty,
  changeManagements,
  changeManagementsAddManagement,
  changeCurrentManagement,
  changePropertiesUser,
} from "../../../reducers/app.reducer";
import { PopupSimpleTextTwoOptions } from "../../../popups/PopupNoProperty.jsx";
import {
  PROPERTY_ALREADY_EXIST,
  CHECK,
  EXIT,
  MANAGEMENT_ALREADY_EXISTS,
  PROBLEMS_SENDING_MAILCHIMP,
  REQUIRED_ALL_PARAMS,
  REGISTER_PROPERTY_REQUIRED_PARAMS,
  REGISTER_MANAGEMENT_REQUIRED_PARAMS,
} from "../../../res/message";

import StateButtonModalHook from "../../../modal/StateButtonModalHook.jsx";
import {
  changeNameManagement,
  changePositionManagement,
  changePrimaryPhone,
  changeAuxiliaryPhone,
  changeEmailManagement,
} from "../../../reducers/form.management.reducer";
import { REGEX_EMAIL } from "../../../res/regex";
import { LoadingPopup } from "../../../components/atoms/popup/Popup";
import { PopupAccesoCubierta } from "../../../popups/PopupAccesoCubierta.jsx";
import { PopupEncargadoInquilino } from "../../../popups/PopupEncargadoInquilino.jsx";
import { convertTypePropertyByCodeToName } from "../../../res/convert.codes";
import { PopupSuccessEvent } from "../../../popups/psPopups/PopupSuccessEvent";
import { editProperty } from "../../../services-controller/http.cleinte.controller";
import { useDispatch, useSelector } from "react-redux";

const EditPropertyController = ({ history }) => {

  const dispatch = useDispatch()
  const state = useSelector(state => {
    return {
      form_property: state.form_property,
      form_management: state.form_management,
      app: state.app,
      form_user: state.form_user
    }
  })

  const { form_property, form_management, app, form_user } = state;

  const {
    management,
    nickname,
    contact: { phone },
    location: {
      space,
      country: { country_code },
      department: { department_code },
      city: { city_code },
      address: { street_type, street, corner, number },
    },
    metadata: { number_floors, deck_access, description },
    optional: {
      shopping_center,
      office_hours: { start, final },
    },
    property_type,
  } = form_property;

  const {
    employ: { position },
    register_data: {
      name,
      contact: { primary_phone, auxiliary_phone, email_associated },
    },
  } = form_management;

  const { managements, current_management, properties } = app;

  const { user_type } = form_user;

  //state view

  const [messageHandler, setMissageHandler] = useState(PROPERTY_ALREADY_EXIST);

  const [showPopUp, setShowPopUp] = useState(false);

  const [isLoader, setIsLoader] = useState(false);

  const [showAccesoCubierta, setShowAccesoCubierta] = useState(false);
  const [showEncargadoInquilino, setShowEncargadoInquilino] = useState(false);
  const [personalType, setPersonalType] = useState("");
  const [view, setView] = useState(PROPERTY_EDIT_PATH);

  const [showSuccessEvent, setShowSuccessEvent] = useState(false);

  useEffect(() => {
    getAllManagmentsService();

    return () => {};
  }, []);

  //start general

  const stringValidator = (str) => str !== "";

  const validatorPhone = (str) => {
    const result = str.length === 7 || str.length === 10 || str.length === 0;
    return result;
  };

  const validatorEmail = (str) => {
    const result = REGEX_EMAIL.test(str);
    return result;
  };

  const getAllManagmentsService = async () => {
    const uid = getValueByKey(UID_AUTH);

    const result = await userManagementsById(uid);

    const { code, object, message } = result;

    if (code === 200) {
      if (object.length !== 0) {
        dispatch(
          changeCurrentManagement(
            object.filter(
              (management_) => management_.unique_key === management
            )[0]
          )
        );
      }

      dispatch(changeManagements(object));
    } else console.log("error", message);
  };

  //__________

  //start general property

  const callbackPushNavigatorMenu = async () => {
    setIsLoader(true);

    const nickNameEvaluation = stringValidator(nickname);
    const streetEvaluation = stringValidator(street);
    const cornerEvaluation = stringValidator(corner);
    const numberEvaluation = stringValidator(number);
    const phoneEvaluation = validatorPhone(phone);
    const resultValidation =
      phoneEvaluation &&
      nickNameEvaluation &&
      streetEvaluation &&
      cornerEvaluation &&
      numberEvaluation;

    if (resultValidation) {
      const aux = [...properties];
      let index = -1;

      for (let property of aux) {
        if (property.unique_key === form_property.unique_key) {
          index = aux.indexOf(property);
        }
      }

      await editProperty(form_property.unique_key, form_property);

      aux.splice(index, 1, form_property);
      dispatch(changePropertiesUser(aux));

      setShowSuccessEvent(true);
    } else {
      setIsLoader(false);

      setMissageHandler(REQUIRED_ALL_PARAMS(REGISTER_PROPERTY_REQUIRED_PARAMS));
      setShowPopUp(true);
    }
  };

  const callbackSelectedManagement = (value) => {
    const management = JSON.parse(value.target.value);
    dispatch(changeCurrentManagement(management));
    dispatch(changeManagement(management.unique_key));
  };

  const callbackRegisterPositive = () => setShowPopUp(false);

  const callbackRegisterNegative = () => setShowPopUp(false);

  const callbackPushNavigatorNewPersonal = () => setView(NEW_PERSONAL);

  const callbackNickName = (value) =>
    dispatch(changeNiackname(value.target.value));

  const callbackDescription = (value) =>
    dispatch(changeDescription(value.target.value));

  const callbackSelectedDepartment = (value) =>
    dispatch(changeDepartmentCode(value.target.value));

  const callbackSelectedCity = (value) =>
    dispatch(changeCityCode(value.target.value));

  const callbackSelectedAddress = (value) =>
    dispatch(changeAddressStreetType(value.target.value));

  const callbackAddress = (value) =>
    dispatch(changeAddressStreet(value.target.value));

  const callbackCornerAddress = (value) =>
    dispatch(changeAddressCorner(value.target.value));

  const callbackNumberAddress = (value) =>
    dispatch(changeAddressNumber(value.target.value));

  const callbackPhone = (value) => dispatch(changePhone(value.target.value));

  const callbackNumberFloors = (value) =>
    dispatch(changeNumberFloors(value.target.value));

  const callbackDeckAccess = (value) => dispatch(changeDeckAccess(value));

  const callbackShoppingCenter = (value) =>
    dispatch(changeShoppingCenter(value.target.value));

  const callbackSpace = (value) => dispatch(changeSpace(value.target.value));

  const callbackChangeScheduleStart = (e) =>
    dispatch(changeOfficHoursStart(e.target.value));

  const callbackChangeScheduleEnd = (e) =>
    dispatch(changeOfficeHourFinal(e.target.value));

  //__________

  //start general management

  const callbackCreateManagementService = async () => {
    const id = md5(email_associated);

    const uid = getValueByKey(UID_AUTH);

    const object = {
      ...form_management,
      type: personalType,
      id: uid,
      management_type: convertTypeRegisterToManagementCode(user_type),
    };

    const result = await createManagementById(id, object);

    return [
      result,
      {
        ...object,
        unique_key: id,
      },
    ];
  };

  const callbackCreatePersonal = async () => {
    setIsLoader(true);
    const nameEvaluation = stringValidator(name);
    const positionEvaluation = stringValidator(position);
    const emailEvaluation = validatorEmail(email_associated);
    const phoneEvaluation = validatorPhone(primary_phone);

    const resultValidation =
      nameEvaluation && emailEvaluation && phoneEvaluation;

    if (resultValidation) {
      const res = await callbackCreateManagementService();

      const [result, object] = res;

      const { code, message } = result;

      if (code === 200) {
        setIsLoader(false);

        dispatch(changeCurrentManagement(object));
        dispatch(changeManagementsAddManagement(object));

        setView(PROPERTY_EDIT_PATH);
      } else if (code === 400) {
        setIsLoader(false);

        setMissageHandler(MANAGEMENT_ALREADY_EXISTS);
        setShowPopUp(true);
      } else console.log("error", message);
    } else {
      setIsLoader(false);
      setMissageHandler(
        REQUIRED_ALL_PARAMS(REGISTER_MANAGEMENT_REQUIRED_PARAMS)
      );
      setShowPopUp(true);
    }
  };

  const callbackNameManagement = (value) =>
    dispatch(changeNameManagement(value.target.value));

  const callbackPositionManagement = (value) =>
    dispatch(changePositionManagement(value.target.value));

  const callbackPrimaryPhone = (value) =>
    dispatch(changePrimaryPhone(value.target.value));

  const callbackAuxiliaryPhone = (value) =>
    dispatch(changeAuxiliaryPhone(value.target.value));

  const callbackEmailManagement = (value) =>
    dispatch(changeEmailManagement(value.target.value));

  const callbackPersonalType = (value) => {
    setPersonalType(value.target.value);
  };

  const callbackAccesoCubierta = () => {
    setShowAccesoCubierta(true);
  };

  const callbackHideAccessoCubierta = () => {
    setShowAccesoCubierta(false);
  };

  const callbackEncargadoInquilino = () => {
    setShowEncargadoInquilino(true);
  };

  const callbackHideEncargadoInquilino = () => {
    setShowEncargadoInquilino(false);
  };

  const callbackCloseSuccessEdit = () => {
    setShowSuccessEvent(false);
    history.goBack();
  };

  //__________

  const BuildView = (type) => {
    switch (type) {
      case PROPERTY_EDIT_PATH:
        return (
          <RegisterPropHouse
            type={convertTypePropertyByCodeToName(property_type)}
            callbackPushNavigatorMenu={callbackPushNavigatorMenu}
            callbackPushNavigatorNewPersonal={callbackPushNavigatorNewPersonal}
            listPersonal={managements}
            callbackSelectedManagement={callbackSelectedManagement}
            defaultValueManagement={current_management}
            callbackNickName={callbackNickName}
            defaultValueNickName={nickname}
            callbackDescription={callbackDescription}
            defaultValueDescription={description}
            callbackPhone={callbackPhone}
            defaultValuePhone={phone}
            //location
            callbackSelectedDepartment={callbackSelectedDepartment}
            defaultOptionDepartment={department_code}
            callbackSelectedCity={callbackSelectedCity}
            defaultOptionCity={city_code}
            //address
            callbackSelectedAddress={callbackSelectedAddress}
            defaultOptionAddress={street_type}
            callbackAddress={callbackAddress}
            callbackCornerAddress={callbackCornerAddress}
            callbackNumberAddress={callbackNumberAddress}
            defaultValueAddress={street}
            defaultValueCornerAddress={corner}
            defaultValueNumberAddress={number}
            //rest
            callbackNumberFloors={callbackNumberFloors}
            defaultValueNumberFloors={number_floors}
            callbackDeckAccess={callbackDeckAccess}
            defaultValueDeckAccess={deck_access}
            //ACCESO CUBIERTA TOOLTIP
            callbackAccesoCubierta={callbackAccesoCubierta}
            callbackScheduleStart={callbackChangeScheduleStart}
            defaultValueScheduleStart={start}
            callbackScheduleEnd={callbackChangeScheduleEnd}
            defaultValueScheduleEnd={final}
            callbackEncargadoInquilino={callbackEncargadoInquilino}
            callbackShoppingCenter={callbackShoppingCenter}
            defaultValueShoppingCenter={shopping_center}
            callbackSpace={callbackSpace}
            defaultValueSpace={space}
            editingProperty={true}
          />
        );

      case NEW_PERSONAL:
        return (
          <RegisterNewPersonal
            callbackCreatePersonal={callbackCreatePersonal}
            callbackNameManagement={callbackNameManagement}
            defaultValueNameManagement={name}
            callbackPositionManagement={callbackPositionManagement}
            defaultValuePositionManagement={position}
            callbackPrimaryPhone={callbackPrimaryPhone}
            defaultValuePrimaryPhone={primary_phone}
            callbackAuxiliaryPhone={callbackAuxiliaryPhone}
            defaultValueAuxiliaryPhone={auxiliary_phone}
            callbackEmailManagement={callbackEmailManagement}
            defaultValueEmailManagement={email_associated}
            callbackPersonalType={callbackPersonalType}
          />
        );

      default:
        return <Redirect to={APP_PATH} />;
    }
  };

  return (
    <>
      {BuildView(view)}
      {showPopUp ? (
        <StateButtonModalHook
          component={PopupSimpleTextTwoOptions}
          hook={[showPopUp, setShowPopUp]}
          object={{
            callbackRegisterPositive: callbackRegisterPositive,
            callbackRegisterNegative: callbackRegisterNegative,
            message: messageHandler,
            textPositive: CHECK,
            textNegative: EXIT,
          }}
        />
      ) : null}

      {showAccesoCubierta ? (
        <StateButtonModalHook
          component={PopupAccesoCubierta}
          hook={[showAccesoCubierta, setShowAccesoCubierta]}
          object={{
            message:
              "Acceso a cubierta: acceso al techo o a la cubierta sin necesidad de escaleras o andamios (de ser necesario que tenga estos equipos en el lugar)",
            textPositive: "Ok",
            callbackPositive: callbackHideAccessoCubierta,
          }}
        />
      ) : null}

      {showEncargadoInquilino ? (
        <StateButtonModalHook
          component={PopupEncargadoInquilino}
          hook={[showEncargadoInquilino, setShowEncargadoInquilino]}
          object={{
            encargadoText: "Persona a cargo del inmueble.",
            inquilinoText: "Persona que alquila el inmueble o parte del mismo.",
            textPositive: "Ok",
            callbackPositive: callbackHideEncargadoInquilino,
          }}
        />
      ) : null}

      {showSuccessEvent ? (
        <StateButtonModalHook
          component={PopupSuccessEvent}
          hook={[showSuccessEvent, setShowSuccessEvent]}
          object={{
            message: "Propiedad editada exitosamente",
            btnText: "OK",
            callback: callbackCloseSuccessEdit,
          }}
        />
      ) : null}

      <LoadingPopup state={isLoader} />
    </>
  );
};

export default withRouter(EditPropertyController);
