import React from 'react'
import './psPopups/psPopups.css'
import { BasePopup } from '../components/atoms/popup/Popup'
import { BaseInput } from '../components/atoms/input/Input'
import { BaseDropdown } from '../components/atoms/dropdown/Dropdown'
import { PrimaryButton } from '../components/atoms/button/Button'
import LOGO_ICON_SVG from '../assets/icons/svg/otros.svg'
import ADD_ICON_SVG from '../assets/icons/svg/plus-circle.svg'
import TRASH_ICON_SVG from '../assets/icons/svg/trash.svg'

const PopupInstabasicas = ({ object }) => {
    const {
        instabasicasArray,
        callbackAdd,
        callbackRemove,
        callback,
        callbackQuantity,
        defaultValue,
        callbackType,
        selectedInstabasicas,
        error
    } = object

    return (
        <BasePopup content={
            <div className='pif-container'>
                <div className='pif-icon-container'>
                    <img className='pif-icon' src={LOGO_ICON_SVG} />
                </div>

                <div className='pif-main-text'>
                    Selecciona el tipo de instabásica e indica la cantidad de cada una.
                </div>

                <div className='add-content-inst'>

                    <div className='inst-type'>
                        <div className='inst-text'>Tipo de instabasica</div>
                        <BaseDropdown
                            defaultSelected='Seleccionar'
                            style='box-style'
                            callback={callbackType}
                            items={instabasicasArray.map((type,key) =>
                                <option key={key}>{type}</option>
                            )}
                        />
                    </div>

                    <div className='inst-cant'>
                        <div className='inst-text'>Cant.</div>
                        <BaseInput
                            defaultValue={defaultValue}
                            placeholder=''
                            callback={callbackQuantity}
                            style='box-style'
                        />
                    </div>

                    <img src={ADD_ICON_SVG} className='add-instabasica-popup' onClick={callbackAdd} />

                </div>

                {
                    error ?
                        <div className='error-text'>La cantidad debe  ser mayor a 0</div> :
                        null
                }

                <div className='inst-selected-container'>

                    {selectedInstabasicas.map((instabasica, index) =>

                        <div key={index} className='inst-selected'>
                            <div>
                                <div className='text'>
                                    <span>Tipo: </span>
                                    {instabasica.type}
                                </div>
                                <div className='text'>
                                    <span>Cantidad: </span>
                                    {instabasica.quantity}
                                </div>
                            </div>
                            <img src={TRASH_ICON_SVG} onClick={()=>callbackRemove(index)} className='delete-inst' />
                        </div>

                    )}

                </div>



                <div className='pif-element-container pif-top-margin-code'>

                    <PrimaryButton txt='Guardar' callback={callback} style='pif-button-style' />

                </div>

            </div>
        } />
    )
}

export default PopupInstabasicas