import React, { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { shallowEqual, useSelector } from "react-redux";
import Chat from "./components/chat/Chat";
import Header from "./components/header/Header";
import MediaSection from "./components/mediaSection/MediaSection";
import {
  setInitialChat,
  addMessage,
  updateMessage,
  addMoreMessages,
} from "reducers/bitacle.reducer";
import "./NewBitacle.css";
import { useDispatcherFunction } from "custom-hooks/useReduxState";
import { useChatMessages } from "custom-hooks/useChatMessages";
import ActivitiesSection from "./components/activtiesSection/ActivitiesSection";

export default function NewBitacle() {
  const { id: serviceId } = useParams();
  const user = useSelector((state) => state.auth.user);
  const chatPhaseSelected = useSelector(
    (state) => state.bitacle.chatPhaseSelected,
    shallowEqual
  );
  const chat = useSelector((state) => state.bitacle.chat);

  const dispatchers = {
    saveMessages: useDispatcherFunction(setInitialChat),
    newMessage: useDispatcherFunction(addMessage),
    changedMessage: useDispatcherFunction(updateMessage),
    addMoreMessages: useDispatcherFunction(addMoreMessages),
  };

  const { chatBodyRef, loadMoreMessages, isLoading } = useChatMessages(
    serviceId,
    chatPhaseSelected.key,
    user.id,
    dispatchers,
    chat
  );

  useEffect(() => {
    const handleScroll = async () => {
      try {
        const chatElement = chatBodyRef.current;
        if (!chatElement) return;

        if (chatElement.scrollTop === 0 && !isLoading) {
          await loadMoreMessages();
        }
      } catch (error) {
        console.error("Error during scroll handling:", error);
      }
    };

    const element = chatBodyRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    return () => {
      element?.removeEventListener("scroll", handleScroll);
    };
  }, [chatBodyRef, loadMoreMessages, isLoading]);

  return (
    <main className="new-bitacle-view">
      <Header />
      <Chat chatBodyRef={chatBodyRef} />
      <MediaSection />
      <ActivitiesSection />
    </main>
  );
}
