import { Avatar } from 'antd';
import jpLogo from 'assets/icons/svg/user.svg';
import { useEffect, useState } from 'react';
import { userById } from 'services-controller/http.cleinte.controller';

const ImageProfileUser = ({ userId, state }) => {
  const [imageUrl, setUrlImgage] = useState("");

  useEffect(() => {
    async function getUserImage() {
      if (userId !== "") {
        let response = await userById(userId)
        setUrlImgage(response.object.register_data?.thumbnail)
      }

    }
    getUserImage()
  }, []
  )


  return (
    <>
      <Avatar
        src={imageUrl || jpLogo}
        size={82}
        style={{ border: "1px solid gray" }}
      />
    </>
  )
}

export default ImageProfileUser;
