import React, { useState } from 'react';
import './integerInput.css'

const IntegerInput = ({ title, maxValue, initialValue, activityGroupIndex, activityIndex, spaceIndex, handleInput, valueComplete, handleSpace }) => {
  const [inputValue, setInputValue] = useState(parseInt(valueComplete) || parseInt(initialValue));

  const isAvailable = initialValue < maxValue

  const handleInputChange = (event) => {
    if (isAvailable) {
      const newValue = event.target.value ? parseInt(event.target.value) : 0;

      setInputValue(Math.min(maxValue, Math.max(0, newValue)));

      const value = Math.min(maxValue, Math.max(initialValue, newValue));

      const valueComplete = value - parseFloat(initialValue)
      if (valueComplete >= 0) {
        handleInput(activityGroupIndex, activityIndex, spaceIndex, valueComplete);
        handleSpace(spaceIndex, value)
      }
      // Limitar el valor al rango permitido
    }
  };

  const handleIncrement = () => {
    if (isAvailable) {

      const valueComplete = inputValue + 1 - parseFloat(initialValue)
      let value = Math.min(maxValue, inputValue +1)
      setInputValue(Math.min(maxValue, inputValue + 1)); // Incrementar el valor y limitarlo al rango permitido
      if (valueComplete >= 0) {

        handleInput(activityGroupIndex, activityIndex, spaceIndex, valueComplete);
        handleSpace(spaceIndex, value)
      }
    }
  };

  const handleDecrement = () => {
    if (isAvailable) {
      const valueComplete = inputValue - 1 - parseFloat(initialValue)
      
      let value = Math.max(initialValue, inputValue - 1);
      setInputValue(Math.max(initialValue, inputValue - 1)); // Decrementar el valor y limitarlo al rango permitido
      if (valueComplete >= 0) {
        handleInput(activityGroupIndex, activityIndex, spaceIndex, valueComplete);
        handleSpace(spaceIndex, value)
      }
    }
  };

  return (
    <div className='number-input-container'>
      <div className='title-number-input'>
        <h3>{title}</h3>
      </div>
      <div className='number-input-content'>
        <input
          className={`number-input ${isAvailable ? '' : 'disabled'}`}
          type="number"
          value={inputValue}
          onChange={handleInputChange}
          max={maxValue}
        />
        <button className='number-button' onClick={handleIncrement} disabled={inputValue >= maxValue}>
          +
        </button>
        <button className='number-button' onClick={handleDecrement} disabled={inputValue <= 0}>
          -
        </button>
      </div>

    </div>
  );
};

export default IntegerInput;

