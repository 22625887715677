import { Empty } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import './Chat.css';
import ChatBody from './components/chatBody/ChatBody';
import ChatFooter from './components/chatFooter/ChatFooter';

export default function Chat({ chatBodyRef }) {
  const { chatPhaseSelected } = useSelector((state) => state.bitacle, shallowEqual)

  if (!chatPhaseSelected?.name) {
    return <Empty className='bitacle-chat-empty' description="Selecciona una fase para ver el chat" />
  }

  return (
    <section ref={chatBodyRef} className='bitacle-chat'>
      <ChatBody />
      <ChatFooter />
    </section>
  )
}
