
import PINTURA_ICON_SVG from '../assets/icons/svg/pintura.svg'
import ELECTRICIDAD_ICON_SVG from '../assets/icons/svg/electricidad.svg'
import INSTABASICAS_ICON_SVG from '../assets/icons/svg/instabasicas.svg'
import HUMEDADES_ICON_SVG from '../assets/icons/svg/humedades.svg'
import TECHOS_ICON_SVG from '../assets/icons/svg/techos.svg'
import PLOMERIA_ICON_SVG from '../assets/icons/svg/plomeria.svg'
import CERRAJERIA_ICON_SVG from '../assets/icons/svg/cerrajeria.svg'
import ALTURAS_ICON_SVG from '../assets/icons/svg/alturas.svg'
import OTROS_ICON_SVG from '../assets/icons/svg/otros.svg'
import SOLDADURAS_ICON_SVG from '../assets/icons/svg/soldadura.svg'
import CARPINTERIA_ICON_SVG from '../assets/icons/svg/carpinteria.svg'
import DISINFECTION_ICON_SVG from '../assets/icons/svg/desinfeccion.svg'

const PAINT_CODE = 0
const ELECTRICITY = 1
const BASIC_INSTALLATION = 2
const HUMIDITIES = 3
const ROOFS = 4
const PLUMBING = 5
const LOCLSMITH = 6
const HEIGHTS = 7
const WELDING = 8
const CARPENTRY = 9
const OTHERS = 10
const DISINFECTION = 11


const PAINT_CODE_NAME = 'Pintura'
const ELECTRICITY_NAME = 'Electricidad'
const BASIC_INSTALLATION_NAME = 'Instabásicas'
const HUMIDITIES_NAME = 'Humedades'
const ROOFS_NAME = 'Techos'
const PLUMBING_NAME = 'Plomería'
const LOCLSMITH_NAME = 'Cerrajería'
const HEIGHTS_NAME = 'Alturas'
const WELDING_NAME = 'Soldadura'
const CARPENTRY_NAME = 'Carpinteria'
const DISINFECTION_NAME = 'Desinfección'
const OTHERS_NAME = 'Otros'


const convertCodeToIconsByServices = (key) => {
    switch (key) {
        case PAINT_CODE: return PINTURA_ICON_SVG
        case ELECTRICITY: return ELECTRICIDAD_ICON_SVG
        case BASIC_INSTALLATION: return INSTABASICAS_ICON_SVG
        case HUMIDITIES: return HUMEDADES_ICON_SVG
        case ROOFS: return TECHOS_ICON_SVG
        case PLUMBING: return PLOMERIA_ICON_SVG
        case LOCLSMITH: return CERRAJERIA_ICON_SVG
        case HEIGHTS: return ALTURAS_ICON_SVG
        case WELDING: return SOLDADURAS_ICON_SVG
        case CARPENTRY: return CARPINTERIA_ICON_SVG
        case DISINFECTION: return DISINFECTION_ICON_SVG
        default: return OTROS_ICON_SVG
    }
}

const convertCodeToTitleByServices = (key) => {
    switch (key) {
        case PAINT_CODE: return PAINT_CODE_NAME
        case ELECTRICITY: return ELECTRICITY_NAME
        case BASIC_INSTALLATION: return BASIC_INSTALLATION_NAME
        case HUMIDITIES: return HUMIDITIES_NAME
        case ROOFS: return ROOFS_NAME
        case PLUMBING: return PLUMBING_NAME
        case LOCLSMITH: return LOCLSMITH_NAME
        case HEIGHTS: return HEIGHTS_NAME
        case WELDING: return WELDING_NAME
        case CARPENTRY: return CARPENTRY_NAME
        case DISINFECTION: return DISINFECTION_NAME
        default: return OTHERS_NAME
    }
}


export {
    PAINT_CODE,
    ELECTRICITY,
    BASIC_INSTALLATION,
    HUMIDITIES,
    ROOFS,
    PLUMBING,
    LOCLSMITH,
    HEIGHTS,
    WELDING,
    CARPENTRY,
    DISINFECTION,
    OTHERS,
    convertCodeToIconsByServices,
    PAINT_CODE_NAME,
    ELECTRICITY_NAME,
    BASIC_INSTALLATION_NAME,
    HUMIDITIES_NAME,
    ROOFS_NAME,
    PLUMBING_NAME,
    LOCLSMITH_NAME,
    HEIGHTS_NAME,
    WELDING_NAME,
    CARPENTRY_NAME,
    DISINFECTION_NAME,
    OTHERS_NAME,
    convertCodeToTitleByServices
}










