import React, { useEffect, useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import { Appbar, AppbarLogo } from "../../../components/atoms/appbar/Appbar";
import DOLLAR_ICON_SVG from "../../../assets/icons/svg/dollar-sign.svg";
import { NewIconButton } from "../../../components/atoms/button/Button";
import CaseDetailsTemplate from "../../../components/templates/caseDetailsTemplate/CaseDetailsTemplate";
import {
  PS_COTIZACION,
  PRINCIPAL_PATH,
  PS_COTIZACION_GENERAL,
} from "../../../router/routes";
import moment from "moment";
import "moment/locale/es";
import { ShowMediaSource } from "../../../popups/PopupsNewServices.jsx";
import StateButtonModalHook from "../../../modal/StateButtonModalHook.jsx";
import { initialStateRequestService } from "../../../reducers/request.service.reducer";
import {
  getConfigCosts,
  getManagementById,
} from "../../../services-controller/http.cleinte.controller";
import CurrencyFormat from "react-currency-format";
import { SeguimientoOptionsButton } from "../../../components/atoms/button/Button";

const ServiceProviderCotizacionDetails = ({ history, location }) => {
  moment.locale("es");

  const { state } = location;

  let { id } = useParams();

  // state

  const [showMediaSource, setShowMediaSource] = useState(false);

  const [mediaSourceFile, setMediaSourceFile] = useState("");

  const [costs, setCosts] = useState({
    assistant: 0,
    official: 0,
    supervisor: 0,
    visit: 0,
  });

  const [management, setManagement] = useState(null);

  // general

  const currentDate = (date) => moment(date).format("dddd, D MMMM");

  const buildAddress = () => {
    return `${street_type} ${street} # ${corner} - ${number} · ${space} ${locale} ${shopping_center}`;
  };

  const buildObjectUndefined = (object, model) => (!object ? model : object);

  const service = buildObjectUndefined(
    state?.service,
    initialStateRequestService
  );

  const {
    unique_key,
    metadata,
    request: {
      general_description,
      resources,
      schedule: { date_start, time_start },
      priority,
    },
  } = service;

  const {
    location: {
      space,
      address: { street_type, street, corner, number },
    },
    optional: { description, shopping_center, locale },
  } = metadata.property;

  const {
    register_data: {
      name,
      contact: { phone },
    },
  } = metadata.user;

  const fecthConfigurationCosts = async () => {
    const costs = await getConfigCosts();
    setCosts(costs);

    if (metadata.property.management !== "") {
      const management = await getManagementById(metadata.property.management);
      setManagement(management);
    }
  };

  useEffect(() => {
    fecthConfigurationCosts();
    if (!state) history.push(PRINCIPAL_PATH);
  }, []);

  const details = [
    {
      title: "Número de caso",
      text: unique_key,
    },
    {
      title: "Fecha y hora",
      text: `${currentDate(date_start)} - ${time_start}`,
    },
    {
      title: "Cliente",
      text: [name, phone],
    },
    {
      title: "Lugar",
      text: [buildAddress(), description],
    },
  ];

  const callbackNavigator = () => {
    history.push({
      pathname: `${PS_COTIZACION}${id}${PS_COTIZACION_GENERAL}`,
      state: { service: service },
    });
  };

  const callbackViewSource = (mediaFile) => {
    const { name, type, url } = mediaFile;

    const newFile = {
      ...mediaFile,
      file: {
        name,
        type,
        url,
      },
    };
    setMediaSourceFile(newFile);
    setShowMediaSource(true);
  };

  const callbackCloseMediaSource = () => {
    setShowMediaSource(false);
  };

  const whatsappMessageToClient = (text) => {
    const phone_client = service?.metadata?.user?.register_data?.contact?.phone;
    const phone_management = management?.register_data?.contact?.primary_phone;
    const phone = management !== null ? phone_management : phone_client;

    return window.open(
      `https://api.whatsapp.com/send?phone=57${phone}&text=${text}`
    );
  };

  const whatsappMessageToJP = (text) => {
    return window.open(
      `https://api.whatsapp.com/send?phone=573185946378&text=${text}`
    );
  };

  const moreValues = [
    [
      "En camino a la visita",
      () =>
        whatsappMessageToClient(
          `¡Hola! soy el prestador de servicio asignado al caso *${id}* notificando que voy camino a la visita`
        ),
    ],
    [
      "Estoy en la visita",
      () =>
        whatsappMessageToJP(
          `¡Hola! soy el prestador de servicio asignado al caso *${id}* notificando que estoy en la visita`
        ),
    ],
    [
      "No hay nadie",
      () =>
        whatsappMessageToJP(
          `¡Hola! soy el prestador de servicio asignado al caso *${id}* notificando que no hay nadie en el inmueble, adjunto fotos:`
        ),
    ],
    [
      "Finalice la visita",
      () =>
        whatsappMessageToJP(
          `¡Hola! soy el prestador de servicio asignado al caso *${id}* notificando que el proceso de visita a finalizado, procedere a enviar el reporte por la aplicación`
        ),
    ],
    [
      "Diagnostico complejo",
      () =>
        whatsappMessageToJP(
          `¡Hola! soy el prestador de servicio asignado al caso *${id}* notificando que el diagnostico es de una complejidad considerable, por lo tanto no podre enviar el informe hasta que se haya revisado correctamente`
        ),
    ],
  ];

  return (
    <div className="new-service-container">
      <AppbarLogo />

      <div className="desktop-container">
        <div className="new-service-appbar-container">
          <Appbar
            smallText="Servicio"
            bigText={`Caso - ${
              unique_key.split("~").length > 1
                ? unique_key.split("~")[1]
                : unique_key
            }`}
          />
        </div>

        <div className="request-details-container cotizacion-float-button-container">
          <CaseDetailsTemplate
            totalVisit={
              priority === 2 ? (
                <CurrencyFormat
                  value={costs.visit}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              ) : null
            }
            details={details}
            generalDescription={general_description}
            listResources={resources}
            callbackViewSource={callbackViewSource}
            management={management}
          />

          <div className="rs-btn-options">
            <NewIconButton
              icon={DOLLAR_ICON_SVG}
              callback={callbackNavigator}
              txt="Cotizar servicio"
            />
          </div>

          <SeguimientoOptionsButton moreValues={moreValues} />
        </div>

        {/* show media source */}
        {showMediaSource ? (
          <StateButtonModalHook
            component={ShowMediaSource}
            hook={[showMediaSource, setShowMediaSource]}
            object={{
              callbackCloseMediaSource: callbackCloseMediaSource,
              mediaSourceFile: mediaSourceFile,
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default withRouter(ServiceProviderCotizacionDetails);
