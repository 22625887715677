import FROWN_ICON_SVG from "../../../assets/icons/svg/frown.svg";
import PhasesContainer from "../../../components/atoms/time-lines/PhasesContainer";
import PhaseStatusResume from "../../../components/phaseStatusResume/PhaseStatusResume";
const PsSeguimientoContainer = ({ props, activeId }) => {
  return activeId === 0 ? (
    props.entriesList ? (
      <div>
        <PhasesContainer
          list={props.entriesList}
          callback={props.callbackEntryInfo}
          user={props.user}
          serviceStage={props.stage}
        />
      </div>
    ) : (
      <div className="no-entries-container">
        <img src={FROWN_ICON_SVG} className="no-entries-icon" alt="sad face" />
        <div className="no-entries-title">
          Actualmente no hay entradas inscritas.
        </div>
        {props.user !== "client" && (
          <div className="no-entries-text">
            ¡Crea tu primera entrada para este caso! Presiona el botón y empieza
            con el seguimiento.
          </div>
        )}
      </div>
    )
  ) : (
    <PhaseStatusResume props={props} />
  );
};

export default PsSeguimientoContainer;
