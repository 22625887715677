import React from 'react'
import './psRegister.css'
import { Appbar, AppbarLogo } from '../../../components/atoms/appbar/Appbar'
import { UserTypeCard } from '../../../components/atoms/card/Card'
import { IconButton } from '../../../components/atoms/button/Button'
import POINTING_ICON_SVG from '../../../assets/icons/svg/finger-point.svg'
import RolesDropDown from '../../../components/atoms/dropdown/rolesDropDown'
import { useEffect } from 'react'
import { convertRegisterTypeByRouterToNames} from '../../../res/convert.codes'


const PsRegister = ({
    callbackChangeServiceProviderType,
    selectedServiceProviderType,
    callbackPushNavigator,
    areaList,
    callbackChangeEmployesType,
    employesType
}) => {

    useEffect(() => {

    }, [selectedServiceProviderType]);

    return (
        <div className='usertype-main-container appbar-no-background'>
            <AppbarLogo />

            <div className='usertype-container'>

                <div className='appbar-container'>
                    <Appbar
                        smallText='Bienvenido'
                        bigText='¡Regístrate!' />
                </div>

                <div className='text-usertype'>

                    <div>
                        <img src={POINTING_ICON_SVG} id='img-text-usertype' alt={''} />
                    </div>
                    <div>Elige tipo de usuario</div>

                </div>

                <div className='register-type'>
                    <div className="roles-dropdown">
                        <RolesDropDown
                            options={areaList}
                            onSelect={callbackChangeEmployesType}
                            selectedValue={''}
                        />
                    </div>
                    <div  className='register-type-container'>

                        {
                            employesType.map((item, index) => {
                                return (
                                    <div key={item} className='register-type'>
                                        <UserTypeCard
                                            type={convertRegisterTypeByRouterToNames(item)}
                                            id={item}
                                            selected={selectedServiceProviderType}
                                            callback={callbackChangeServiceProviderType} />
                                    </div>
                                )
                            })

                        }
                    </div>



                </div>

                {
                    selectedServiceProviderType === '' ?
                        null :
                        <div className='register-botton-menu'>
                            <IconButton
                                callback={callbackPushNavigator} />
                        </div>
                }

            </div>
        </div>

    )
}

export default PsRegister
