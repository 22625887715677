import { withRouter } from "react-router-dom";
import BOOK_ICON_SVG from "../../../assets/icons/svg/book-open.svg";
import CHAT_ICON_SVG from "../../../assets/icons/svg/chat.svg";
import IMAGE_ICON_SVG from "../../../assets/icons/svg/image.svg";
import TOOL_ICON_SVG from "../../../assets/icons/svg/tool.svg";
import Accordion from "../../../components/atoms/acordion/Accordion";
import {
  AddSquareButton,
  NewIconButton,
} from "../../../components/atoms/button/Button";
import {
  CheckboxInput,
  CheckboxInputActivity,
} from "../../../components/atoms/input/Input";
import MediaSourceContainer from "../../../components/atoms/mediaSource/MediaSourceContainer";
import { BaseTextarea } from "../../../components/atoms/textarea/Textarea";
import "./psNewEntry.css";
import GoBackBtn from "components/GoBackBtn/GoBackBtn";

const PsNewEntry = ({
  idService,
  updateBitacora,
  activitiesSelected,
  callbackSelectedSpace,
  itemTasks,
  callbackSelectTask,
  callbackHiddenEntry,
  shouldReset,
  callbackDescripcionTextarea,
  updateAviable,
  listResources,
  callbackViewSource,
  deleteMediaSource,
  callbackAddFile,
  callbackAddFileError,
  openFileSelector,
  setShowAlertMessage,
  setMessageAlert,
  completePhase,
  updatePhase,
  finished_item,
  entryDescription,
}) => {
  return (
    <div className="new-service-container">
      <div className="desktop-container">
        <div className="new-entry-container">
          <div className="new-entry-appbar-container">
            <GoBackBtn type="default" />
            <div style={{ marginTop: "12px" }} className="ne-appbar-small-text">
              Caso {idService}
            </div>
            <div className="ne-appbar-big-text">Nueva Entrada</div>
          </div>

          <div className="new-entry-content">
            {!finished_item ? (
              <div className="categoria-container">
                <div className="new-service-text">
                  <div>
                    <img
                      src={TOOL_ICON_SVG}
                      id="new-service-icon"
                      alt={"POINTING_ICON_SVG"}
                    />
                  </div>
                  <div> Selecciona la actividad realizada </div>
                </div>

                <div className="select-work-container">
                  {itemTasks.map((activity, index) => {
                    if (activity.activities?.length > 0) {
                      return (
                        <Accordion
                          key={index}
                          indexParent={index}
                          activities={activity.activities}
                          title={activity.group_title}
                          callbackSelectTask={callbackSelectTask}
                          shouldReset={shouldReset}
                          callbackSelectedSpace={callbackSelectedSpace}
                          setShowAlertMessage={setShowAlertMessage}
                          setMessageAlert={setMessageAlert}
                        />
                      );
                    } else {
                      return (
                        <CheckboxInputActivity
                          key={index}
                          index={index}
                          callbackSelectTask={
                            callbackSelectTask.callbackActivity
                          }
                          title={activity.title}
                          isOptional={!activity.isRequired}
                          isSelected={activity.isCompleted}
                          isAvailable={activity.isAvailable}
                          shouldReset={shouldReset}
                          isCanSelected={activity.isCanSelected}
                          setShowAlertMessage={setShowAlertMessage}
                          setMessageAlert={setMessageAlert}
                        />
                      );
                    }
                  })}
                </div>
              </div>
            ) : null}

            <div className="descripcion-container">
              <div className="new-service-text">
                <div>
                  <img
                    src={CHAT_ICON_SVG}
                    id="new-service-icon"
                    alt={"CHAT_ICON_SVG"}
                  />
                </div>
                <div>Descripción de la actividad realizada</div>
              </div>

              <div className="descripcion-input">
                <BaseTextarea
                  text={() => {}}
                  value={entryDescription}
                  callback={callbackDescripcionTextarea}
                  placeholder="Describe el trabajo que realizaste."
                />
              </div>
            </div>
            <div className="select-work-container finished-item">
              <CheckboxInput
                index={1}
                callbackSelectTask={callbackHiddenEntry}
                title="Ocultar entrada."
                isAvailable={true}
                isSelected={false}
                itemSelect={false}
                canBeEdit={true}
                shouldReset={shouldReset}
              />
            </div>

            <div className="dano-foto-container">
              <div className="new-service-text">
                <div>
                  <img
                    src={IMAGE_ICON_SVG}
                    id="new-service-icon"
                    alt={"POINTING_ICON_SVG"}
                  />
                </div>
                <div>Añade fotos, documentos o videos</div>
              </div>

              <div className="dano-foto">
                <div className="add-foto-container" onClick={openFileSelector}>
                  <AddSquareButton />
                </div>
                <MediaSourceContainer
                  sources={listResources}
                  callbackViewSource={callbackViewSource}
                  deleteMediaSource={deleteMediaSource}
                />
              </div>
            </div>

            <div className="register-botton-menu">
              <NewIconButton
                txt="Actualizar bitácora"
                style={`new-entry-button ${
                  !updateAviable ? "update-disable" : ""
                }`}
                icon={BOOK_ICON_SVG}
                callback={updateBitacora}
              />
              {completePhase ? (
                <NewIconButton
                  txt="Finalizar fase"
                  style={`new-entry-button 
                      }`}
                  icon={BOOK_ICON_SVG}
                  callback={updatePhase}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PsNewEntry);
