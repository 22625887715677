import React from "react";
import "./registerTemplate.css";
import { Appbar } from "../../atoms/appbar/Appbar";
import { AddRoundedButton } from "../../atoms/button/Button";
import { ImagePicker } from "react-file-picker";

const RegisterUserTemplate = ({
  fields,
  type,
  bigText,
  callbackSelectImage,
  callbackSelectImageError,
  thumbnail,
}) => {
  return (
    <div className="desktop-container">
      <Appbar smallText={type} bigText={bigText} />

      <div className="register-info-text">Información básica</div>
      <div className="register-info-inputs">
        <div className="foto-container">
          <ImagePicker
            extensions={["jpg", "jpeg", "png"]}
            dims={{
              minWidth: 10,
              maxWidth: 5000,
              minHeight: 10,
              maxHeight: 5000,
            }}
            onChange={callbackSelectImage}
            onError={callbackSelectImageError}
          >
            <div className="foto-btn">
              <AddRoundedButton thumbnail={thumbnail} />
            </div>
          </ImagePicker>

          <div className="foto-text">Subir foto</div>
        </div>

        {fields}
      </div>
    </div>
  );
};

const RegisterPropertyTemplate = ({ fields, type, smallText }) => {
  return (
    <div className="desktop-container">
      <div>
        <Appbar smallText={smallText} bigText={type} />
      </div>
      <div className="register-prop-info-text">Información básica</div>

      <div className="register-info-inputs">{fields}</div>
    </div>
  );
};

const RegisterContactTemplate = ({ fields, type, bigText }) => {
  return (
    <div className="desktop-container">
      <div className="register-info-container">
        <div>
          <Appbar smallText={type} bigText={bigText} />
        </div>
        <div className="register-info-text">Información básica</div>

        <div className="register-info-inputs">{fields}</div>
      </div>
    </div>
  );
};

export {
  RegisterUserTemplate,
  RegisterPropertyTemplate,
  RegisterContactTemplate,
};
