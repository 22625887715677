import { IDENTIFIER_TYPE_DEFAULT_VALUE } from '../res/identifier.type'
import { MARITAL_STATUS_DEFAULT_VALUE } from '../res/marital.status'
import { MAXIMUN_ACADEMIC_LEVEL_DEFAULT_VALUE } from '../res/maximum.academic.level'
import { RELATIONSHIP_DEFAULT_VALUE } from '../res/relationship.types'

const REGISTER_TYPE_ACTION_TYPE = 'set_register_type_action_type_form_service_provider'
const USER_TYPE_ACTION_TYPE = 'set_user_type_action_type_form_service_provider'
const PROVIDER_TYPE_ACTION_TYPE = 'set_provider_action_type_form_service_provider'
const LOCATION_COUNTY_COUNTRY_CODE_ACTION_TYPE = 'set_location_country_country_code_action_type_form_service_provider'
const LOCATION_DEPARTMENT_DEPARTMENT_CODE_ACTION_TYPE = 'set_location_department_department_code_action_type_form_service_provider'
const LOCATION_CITY_CITY_CODE_ACTION_TYPE = 'set_location_city_city_code_action_type_form_service_provider'
const LOCATION_ADDRESS_STREET_TYPE_ACTION_TYPE = 'set_location_address_street_type_action_type_form_service_provider'
const LOCATION_ADDRESS_STREET_ACTION_TYPE = 'set_location_address_street_action_type_form_service_provider'
const LOCATION_ADDRESS_CORNER_ACTION_TYPE = 'set_location_address_corner_action_type_form_service_provider'
const LOCATION_ADDRESS_NUMBER_ACTION_TYPE = 'set_location_address_number_action_type_form_service_provider'
const REGISTER_DATA_MARITAL_STATUS_ACTION_TYPE = 'set_register_data_marital_status_action_type_form_service_provider'
const REGISTER_DATA_THUMBNAIL_ACTION_TYPE = 'set_register_data_thumbnail_action_type_form_service_provider'
const REGISTER_DATA_NAME_ACTION_TYPE = 'set_register_data_name_action_type_form_service_provider'
const REGISTER_DATA_CONTACT_PHONE_ACTION_TYPE = 'set_register_data_contact_phone_action_type_form_service_provider'
const REGISTER_DATA_CONTACT_EMAIL_ASSOCIATED_ACTION_TYPE = 'set_register_data_contact_email_asscociated_action_type_form_service_provider'
const REGISTER_DATA_SECRETS_TYPE_IDENTIFIER_ACTION_TYPE = 'set_register_data_secrets_type_identifier_action_type_form_service_provider'
const REGISTER_DATA_SECRETS_IDENTIFIER_ACTION_TYPE = 'set_register_data_secrets_identifier_action_type_form_service_provider'
const REGISTER_DATA_SECRETS_PASSWORD_ACTION_TYPE = 'set_register_data_secrets_password_action_type_form_service_provider'
const REGISTER_DATA_ACADEMY_MAXIMUN_ACADEMIC_LEVEL_ACTION_TYPE = 'set_register_data_academy_maximun_academic_level_action_type_form_service_provider'
const REGISTER_DATA_ACADEMY_COURSES_ACTION_TYPE = 'set_register_data_academy_courses_action_type_form_service_provider'
const CONTACT_AUXILIARY_DATA_NAME_ACTION_TYPE = 'set_contact_auxiliary_data_name_action_type_form_service_provider'
const CONTACT_AUXILIARY_DATA_RELATIONSHIP_ACTION_TYPE = 'set_contact_auxiliary_data_relationship_action_type_form_service_provider'
const CONTACT_AUXILIARY_CONTACT_PHONE_ACTION_TYPE = 'set_contact_auxiliary_contact_phone_action_type_form_service_provider'
const CONTACT_AUXILIARY_CONTACT_PHONE_AUXILIARY_ACTION_TYPE = 'set_contact_auxiliary_contact_phone_auxiliary_action_type_form_service_provider'
const CONVEYANCE_TYPE_ACTION_TYPE = 'set_conveyance_type_action_type_form_service_provider'
const CONVEYANCE_LICENSE_PLATE_ACTION_TYPE = 'set_conveyance_license_plate_action_type_form_service_provider'
const UPDATE_FORM_SERVICE_PROVIDER_ACTION_TYPE = 'set_update_form_service_provider_action_type_form_service_provider'

const DOCUMENTS_HEIGHT_ACTION_TYPE = 'set_documents_height_action_type_form_service_provider'
const DOCUMENTS_SOCIAL_SECURITY_ACTION_TYPE = 'set_documents_social_security_action_type_form_service_provider'
const DOCUMENTS_PREOSSIONAL_CARD_ACTION_TYPE = 'set_documents_professional_card_action_type_form_service_provider'


const initialStateFormServiceProvider = {
    documents: {
        height: {
            url: '',
            date: ''
        },
        social_security: {
            url: '',
            date: ''
        },
        professional_card: {
            url: '',
            date: ''
        }
    },
    register_type: '',
    is_blocked: true,
    user_type: '',
    provider: '001',
    label:'',
    score:0,
    location: {
        country: {
            country_code: '57'
        },
        department: {
            department_code: '76'
        },
        city: {
            city_code: '001'
        },
        address: {
            street_type: 'Calle',
            street: '',
            corner: '',
            number: ''
        }
    },
    register_data: {
        marital_status: MARITAL_STATUS_DEFAULT_VALUE,
        thumbnail: '',
        name: '',
        contact: {
            phone: '',
            email_associated: ''
        },
        secrets: {
            type_identifier: IDENTIFIER_TYPE_DEFAULT_VALUE,
            identifier: '',
            password: ''
        },
        academy: {
            maximum_academic_level: MAXIMUN_ACADEMIC_LEVEL_DEFAULT_VALUE,
            courses: ''
        }
    },
    contact_auxiliary: {
        data: {
            name: '',
            relationship: RELATIONSHIP_DEFAULT_VALUE
        },
        contact: {
            phone: '',
            phone_auxiliary: ''
        }
    },
    conveyance: {
        type: '',
        license_plate: ''
    }
}

const reducerServieProviderForm = (state =initialStateFormServiceProvider, action) => {

    const { type, payload } = action

    switch (type) {
        case REGISTER_TYPE_ACTION_TYPE:
            return {
                ...state,
                register_type: payload
            }
        case USER_TYPE_ACTION_TYPE:
            return {
                ...state,
                user_type: payload

            }
        case PROVIDER_TYPE_ACTION_TYPE:
            return {
                ...state,
                provider: payload
            }
        case LOCATION_COUNTY_COUNTRY_CODE_ACTION_TYPE:
            return {
                ...state,
                location: {
                    ...state.location,
                    country: {
                        ...state.location.country,
                        country_code: payload
                    }
                }
            }
        case LOCATION_DEPARTMENT_DEPARTMENT_CODE_ACTION_TYPE:
            return {
                ...state,
                location: {
                    ...state.location,
                    department: {
                        ...state.location.department,
                        department_code: payload
                    }
                }
            }
        case LOCATION_CITY_CITY_CODE_ACTION_TYPE:
            return {
                ...state,
                location: {
                    ...state.location,
                    city: {
                        ...state.location.city,
                        city_code: payload
                    }
                }
            }
        case LOCATION_ADDRESS_STREET_TYPE_ACTION_TYPE:
            return {
                ...state,
                location: {
                    ...state.location,
                    address: {
                        ...state.location.address,
                        street_type: payload
                    }
                }
            }
        case LOCATION_ADDRESS_STREET_ACTION_TYPE:
            return {
                ...state,
                location: {
                    ...state.location,
                    address: {
                        ...state.location.address,
                        street: payload
                    }
                }
            }
        case LOCATION_ADDRESS_CORNER_ACTION_TYPE:
            return {
                ...state,
                location: {
                    ...state.location,
                    address: {
                        ...state.location.address,
                        corner: payload
                    }
                }
            }
        case LOCATION_ADDRESS_NUMBER_ACTION_TYPE:
            return {
                ...state,
                location: {
                    ...state.location,
                    address: {
                        ...state.location.address,
                        number: payload
                    }
                }
            }
        case REGISTER_DATA_MARITAL_STATUS_ACTION_TYPE:
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    marital_status: payload

                }
            }
        case REGISTER_DATA_THUMBNAIL_ACTION_TYPE:
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    thumbnail: payload

                }
            }
        case REGISTER_DATA_NAME_ACTION_TYPE:
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    name: payload

                }
            }
        case REGISTER_DATA_CONTACT_PHONE_ACTION_TYPE:
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    contact: {
                        ...state.register_data.contact,
                        phone: payload
                    }

                }
            }
        case REGISTER_DATA_CONTACT_EMAIL_ASSOCIATED_ACTION_TYPE:
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    contact: {
                        ...state.register_data.contact,
                        email_associated: payload
                    }

                }
            }
        case REGISTER_DATA_SECRETS_TYPE_IDENTIFIER_ACTION_TYPE:
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    secrets: {
                        ...state.register_data.secrets,
                        type_identifier: payload
                    }

                }
            }
        case REGISTER_DATA_SECRETS_IDENTIFIER_ACTION_TYPE:
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    secrets: {
                        ...state.register_data.secrets,
                        identifier: payload
                    }

                }
            }
        case REGISTER_DATA_SECRETS_PASSWORD_ACTION_TYPE:
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    secrets: {
                        ...state.register_data.secrets,
                        password: payload
                    }

                }
            }
        case REGISTER_DATA_ACADEMY_MAXIMUN_ACADEMIC_LEVEL_ACTION_TYPE:
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    academy: {
                        ...state.register_data.academy,
                        maximum_academic_level: payload
                    }

                }
            }
        case REGISTER_DATA_ACADEMY_COURSES_ACTION_TYPE:
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    academy: {
                        ...state.register_data.academy,
                        courses: payload
                    }

                }
            }
        case CONTACT_AUXILIARY_DATA_NAME_ACTION_TYPE:
            return {
                ...state,
                contact_auxiliary: {
                    ...state.contact_auxiliary,
                    data: {
                        ...state.contact_auxiliary.data,
                        name: payload
                    }

                }
            }
        case CONTACT_AUXILIARY_DATA_RELATIONSHIP_ACTION_TYPE:
            return {
                ...state,
                contact_auxiliary: {
                    ...state.contact_auxiliary,
                    data: {
                        ...state.contact_auxiliary.data,
                        relationship: payload
                    }

                }
            }
        case CONTACT_AUXILIARY_CONTACT_PHONE_ACTION_TYPE:
            return {
                ...state,
                contact_auxiliary: {
                    ...state.contact_auxiliary,
                    contact: {
                        ...state.contact_auxiliary.contact,
                        phone: payload
                    }

                }
            }
        case CONTACT_AUXILIARY_CONTACT_PHONE_AUXILIARY_ACTION_TYPE:
            return {
                ...state,
                contact_auxiliary: {
                    ...state.contact_auxiliary,
                    contact: {
                        ...state.contact_auxiliary.contact,
                        phone_auxiliary: payload
                    }

                }
            }
        case CONVEYANCE_TYPE_ACTION_TYPE:
            return {
                ...state,
                conveyance: {
                    ...state.conveyance,
                    type: payload

                }
            }
        case CONVEYANCE_LICENSE_PLATE_ACTION_TYPE:
            return {
                ...state,
                conveyance: {
                    ...state.conveyance,
                    license_plate: payload
                }
            }

        //--
        case DOCUMENTS_HEIGHT_ACTION_TYPE:
            return {
                ...state,
                documents: {
                    ...state.documents,
                    height: payload
                }
            }
        case DOCUMENTS_SOCIAL_SECURITY_ACTION_TYPE:
            return {
                ...state,
                documents: {
                    ...state.documents,
                    social_security: payload
                }
            }
        case DOCUMENTS_PREOSSIONAL_CARD_ACTION_TYPE:
            return {
                ...state,
                documents: {
                    ...state.documents,
                    professional_card: payload
                }
            }

        case UPDATE_FORM_SERVICE_PROVIDER_ACTION_TYPE:
            return payload
        default:
            return state;
    }
}

const changeRegisterType = (value) => {
    return { type: REGISTER_TYPE_ACTION_TYPE, payload: value }
}

const changeUserType = (value) => {
    return { type: USER_TYPE_ACTION_TYPE, payload: value }
}

const changeProviderType = (value) => {
    return { type: PROVIDER_TYPE_ACTION_TYPE, payload: value }
}

const changeLocationCountryCode = (value) => {
    return { type: LOCATION_COUNTY_COUNTRY_CODE_ACTION_TYPE, payload: value }
}

const changeLocationCityCode = (value) => {
    return { type: LOCATION_CITY_CITY_CODE_ACTION_TYPE, payload: value }
}

const changeDepartmentCityCode = (value) => {
    return { type: LOCATION_DEPARTMENT_DEPARTMENT_CODE_ACTION_TYPE, payload: value }
}

const changeLocationAddressStreetType = (value) => {
    return { type: LOCATION_ADDRESS_STREET_TYPE_ACTION_TYPE, payload: value }
}

const changeLocationAddressStreet = (value) => {
    return { type: LOCATION_ADDRESS_STREET_ACTION_TYPE, payload: value }
}

const changeLocationAddressCorner = (value) => {
    return { type: LOCATION_ADDRESS_CORNER_ACTION_TYPE, payload: value }
}

const changeLocationAddressNumber = (value) => {
    return { type: LOCATION_ADDRESS_NUMBER_ACTION_TYPE, payload: value }
}

const changeRegisterDataMaritalStatus = (value) => {
    return { type: REGISTER_DATA_MARITAL_STATUS_ACTION_TYPE, payload: value }
}

const changeRegisterDataThumbnail = (value) => {
    return { type: REGISTER_DATA_THUMBNAIL_ACTION_TYPE, payload: value }
}

const changeRegisterDataName = (value) => {
    return { type: REGISTER_DATA_NAME_ACTION_TYPE, payload: value }
}

const changeRegisterDataContactPhone = (value) => {
    return { type: REGISTER_DATA_CONTACT_PHONE_ACTION_TYPE, payload: value }
}

const changeRegisterDataContactEmailAssociated = (value) => {
    return { type: REGISTER_DATA_CONTACT_EMAIL_ASSOCIATED_ACTION_TYPE, payload: value }
}

const changeRegisterDataSecretsTypeIdentifier = (value) => {
    return { type: REGISTER_DATA_SECRETS_TYPE_IDENTIFIER_ACTION_TYPE, payload: value }
}

const changeRegisterDataSecretsIdentifier = (value) => {
    return { type: REGISTER_DATA_SECRETS_IDENTIFIER_ACTION_TYPE, payload: value }
}

const changeRegisterDataSecretsPassword = (value) => {
    return { type: REGISTER_DATA_SECRETS_PASSWORD_ACTION_TYPE, payload: value }
}

const changeRegisterDataAcademyMaximunAcademicLevel = (value) => {
    return { type: REGISTER_DATA_ACADEMY_MAXIMUN_ACADEMIC_LEVEL_ACTION_TYPE, payload: value }
}

const changeRegisterDataAcademyCourses = (value) => {
    return { type: REGISTER_DATA_ACADEMY_COURSES_ACTION_TYPE, payload: value }
}

const changeContactAuxiliaryDataName = (value) => {
    return { type: CONTACT_AUXILIARY_DATA_NAME_ACTION_TYPE, payload: value }
}

const changeContactAuxiliaryDataRelationship = (value) => {
    return { type: CONTACT_AUXILIARY_DATA_RELATIONSHIP_ACTION_TYPE, payload: value }
}

const changeContactAuxiliaryContactPhone = (value) => {
    return { type: CONTACT_AUXILIARY_CONTACT_PHONE_ACTION_TYPE, payload: value }
}

const changeContactAuxiliaryContactPhoneAuxiliary = (value) => {
    return { type: CONTACT_AUXILIARY_CONTACT_PHONE_AUXILIARY_ACTION_TYPE, payload: value }
}

const changeConveyanceType = (value) => {
    return { type: CONVEYANCE_TYPE_ACTION_TYPE, payload: value }
}

const changeConveyanceLicensePlate = (value) => {
    return { type: CONVEYANCE_LICENSE_PLATE_ACTION_TYPE, payload: value }
}

const changeUpdateFormServiceProvider = (value) => {
    return { type: UPDATE_FORM_SERVICE_PROVIDER_ACTION_TYPE, payload: value }
}

const changeDocumentsHeight = (value) => {
    return { type: DOCUMENTS_HEIGHT_ACTION_TYPE, payload: value }
}

const changeDocumentsSocualSecurity = (value) => {
    return { type: DOCUMENTS_SOCIAL_SECURITY_ACTION_TYPE, payload: value }
}

const changeDocumentsProfessionalCard = (value) => {
    return { type: DOCUMENTS_PREOSSIONAL_CARD_ACTION_TYPE, payload: value }
}

export {
    initialStateFormServiceProvider,
    reducerServieProviderForm,
    changeRegisterType,
    changeUserType,
    changeProviderType,
    changeLocationCountryCode,
    changeLocationCityCode,
    changeLocationAddressStreetType,
    changeLocationAddressStreet,
    changeLocationAddressCorner,
    changeLocationAddressNumber,
    changeRegisterDataMaritalStatus,
    changeRegisterDataThumbnail,
    changeRegisterDataName,
    changeRegisterDataContactPhone,
    changeRegisterDataContactEmailAssociated,
    changeRegisterDataSecretsTypeIdentifier,
    changeRegisterDataSecretsIdentifier,
    changeRegisterDataSecretsPassword,
    changeRegisterDataAcademyMaximunAcademicLevel,
    changeRegisterDataAcademyCourses,
    changeContactAuxiliaryDataName,
    changeContactAuxiliaryDataRelationship,
    changeContactAuxiliaryContactPhone,
    changeContactAuxiliaryContactPhoneAuxiliary,
    changeConveyanceType,
    changeConveyanceLicensePlate,
    changeUpdateFormServiceProvider,
    changeDepartmentCityCode,
    changeDocumentsHeight,
    changeDocumentsSocualSecurity,
    changeDocumentsProfessionalCard,
}