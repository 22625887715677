import React from 'react'
import { Appbar, AppbarLogo } from '../../components/atoms/appbar/Appbar'
import { withRouter } from 'react-router-dom'
import './register.css'
import { IconButton } from '../../components/atoms/button/Button'
import { SmallCard } from '../../components/atoms/card/Card'
import CONDOMINIO_ICON_SVG from '../../assets/icons/svg/condominio.svg'
import POINTING_ICON_SVG from '../../assets/icons/svg/finger-point.svg'
import APTO_ICON_SVG from '../../assets/icons/svg/apartamento.svg'
import CASA_ICON_SVG from '../../assets/icons/svg/casa.svg'
import LOCAL_ICON_SVG from '../../assets/icons/svg/local-comercial.svg'
import WAREHOUSE_ICON_SVG from '../../assets/icons/svg/bodega.svg'
import OFFICE_ICON_SVG from '../../assets/icons/svg/oficina.svg'
import OTROS_ICON_SVG from '../../assets/icons/svg/otros.svg'
import { HOUSE, APARTMENT, LOCAL, WAREHOUSE, CONDOMINIUM, OFFICE, OTHER_PROPERTY } from '../../router/routes'


const PropertyType = ({
    callbackChangePropertyType,
    PropertyType,
    callbackPushNavigator,
}) => {

    return (
        <div className='usertype-main-container appbar-no-background'>
            <AppbarLogo />
            <div className='usertype-container'>


                <div className='appbar-container'>
                    <Appbar
                        smallText='Registra tus'
                        bigText='Inmuebles' />
                </div>

                <div className='text-usertype'>

                    <div>
                        <img
                            src={POINTING_ICON_SVG}
                            id='img-text-usertype'
                            alt={'POINTING_ICON_SVG'} />
                    </div>
                    <div className='text-property'>
                        <span>Elige tipo de inmueble</span>
                        <span className='note-property'>(Selecciona 1)</span>
                    </div>

                </div>

                <div className='options-usertype'>

                    <div className='options-container'>
                        <SmallCard
                            icon={CASA_ICON_SVG}
                            id={HOUSE}
                            selected={PropertyType}
                            callback={callbackChangePropertyType}
                            title='Casa' />
                    </div>

                    <div className='options-container'>
                        <SmallCard
                            icon={APTO_ICON_SVG}
                            id={APARTMENT}
                            selected={PropertyType}
                            callback={callbackChangePropertyType}
                            title='Apartamento' />
                    </div>

                    <div className='options-container'>
                        <SmallCard
                            icon={LOCAL_ICON_SVG}
                            id={LOCAL}
                            selected={PropertyType}
                            callback={callbackChangePropertyType}
                            title='Local Comercial' />
                    </div>

                    <div className='options-container'>
                        <SmallCard
                            icon={WAREHOUSE_ICON_SVG}
                            id={WAREHOUSE}
                            selected={PropertyType}
                            callback={callbackChangePropertyType}
                            title='Bodega' />
                    </div>

                    <div className='options-container'>
                        <SmallCard
                            icon={CONDOMINIO_ICON_SVG}
                            id={CONDOMINIUM}
                            selected={PropertyType}
                            callback={callbackChangePropertyType}
                            title='Propiedad horizontal' />
                    </div>

                    <div className='options-container'>
                        <SmallCard
                            icon={OFFICE_ICON_SVG}
                            id={OFFICE}
                            selected={PropertyType}
                            callback={callbackChangePropertyType}
                            title='Oficina' />
                    </div>

                </div>

                <div className='more-usertype'>

                    <div className='options-container'>
                        <SmallCard
                            icon={OTROS_ICON_SVG}
                            id={OTHER_PROPERTY}
                            selected={PropertyType}
                            callback={callbackChangePropertyType}
                            title='Otros' />
                    </div>
                </div>

                {
                    PropertyType === '' ?
                        null :
                        <div className='register-botton-menu'>
                            <IconButton
                                callback={callbackPushNavigator} />
                        </div>

                }

            </div>
        </div>

    )
}

export default withRouter(PropertyType);
