import React, { useState, useEffect } from "react";
import {
  NEW_SERVICE,
  BASE_MENU_PATH,
  PROPERTY_TYPE_PATH,
} from "../../../router/routes";
import { withRouter } from "react-router-dom";
import Home from "./Home.jsx";
import LIST_SERVICES from "../../../res/list.services";
import { changeSelectedServices } from "../../../reducers/request.service.reducer";
import { PopupTwoOptionWithIcon } from "../../../popups/PopupTwoButtons";
import StateButtonModalHook from "../../../modal/StateButtonModalHook.jsx";
import { NO_REGISTERED_PROPERTY, REGISTER, EXIT } from "../../../res/message";
import { PopupSelectProperty } from "../../../popups/PopupSelectProperty.jsx";
import {
  changeCurrentProperty,
  changeQueryProperty,
} from "../../../reducers/app.reducer";
import FROWN_ICON_SVG from "../../../assets/icons/svg/frown.svg";
import { useDispatch, useSelector } from "react-redux";

const HomeController = ({ history }) => {
  const dispatch = useDispatch();

  const state = useSelector((state) => {
    return {
      form_user: state.form_user,
      request_service: state.request_service,
      app: state.app,
    };
  });

  const { form_user, request_service, app } = state;

  const { register_type } = form_user;

  const {
    request: { selected_services },
  } = request_service;

  const { properties, query_property } = app;

  // state
  const [showButton, setShowButton] = useState(false);

  const [showRegisterProperty, setShowRegisterProperty] = useState(false);

  const [selectProperty, setSelectProperty] = useState(false);

  useEffect(() => {
    setShowButton(selected_services.length >= 1);

    return () => { };
  }, [selected_services.length]);
  //____

  //general

  const callbackUpdateList = (list) => dispatch(changeSelectedServices(list));

  const callbackNewService = () => {
    if (properties.length === 0) setShowRegisterProperty(true);
    else setSelectProperty(true);
  };

  const callbackPropertySelected = (property) => {
    dispatch(changeCurrentProperty(property));
    history.push(`${BASE_MENU_PATH}${NEW_SERVICE}`);
  };

  const callbackSelectedService = (id) => {
    const aux = [...selected_services];
    const currentIndex = aux.indexOf(id);
    if (currentIndex === -1) aux.push(id);
    else aux.splice(currentIndex, 1);

    callbackUpdateList(aux);
    setShowButton(aux.length >= 1);
  };

  const isSelectedItem = (id) => selected_services.includes(id);

  const callbackRegisterPositive = () => history.push(`${PROPERTY_TYPE_PATH}`);

  const callbackRegisterNegative = () => setShowRegisterProperty(false);

  const callbackSearchProperty = (e) => {
    const value = e.target.value;
    dispatch(changeQueryProperty(value));
  };

  //____

  return (
    <>
      <Home
        isSelectedItem={isSelectedItem}
        callbackSelectedService={callbackSelectedService}
        callbackNewService={callbackNewService}
        showButton={showButton}
        listServices={LIST_SERVICES}
      />
      {/* register property */}
      {showRegisterProperty ? (
        <StateButtonModalHook
          component={PopupTwoOptionWithIcon}
          hook={[showRegisterProperty, setShowRegisterProperty]}
          object={{
            callbackPositive: callbackRegisterPositive,
            callbackNegative: callbackRegisterNegative,
            popupText: NO_REGISTERED_PROPERTY,
            textPositive: REGISTER,
            textNegative: EXIT,
            popupIcon: FROWN_ICON_SVG,
          }}
        />
      ) : null}
      {/* select property */}
      {selectProperty ? (
        <StateButtonModalHook
          component={PopupSelectProperty}
          hook={[selectProperty, setSelectProperty]}
          object={{
            callbackSearch: callbackSearchProperty,
            query: query_property,
            propertyArr: properties,
            callbackNewProperty: callbackRegisterPositive,
            callbackPropertySelected: callbackPropertySelected,
          }}
        />
      ) : null}
    </>
  );
};

export default withRouter(HomeController);
