import moment from "moment";
import { dateTimeFormater } from "res/utils";

const DateFormat = ({ unexpected, date }) => {
  let dateFormated = moment(date?.split("-")[5], "HH:mm:ss").format(
    "HH:mm A"
  );

  if (dateFormated == "Invalid date") {
    dateFormated = dateTimeFormater({ date, timeStyle: 'short', timeZone: 'America/Bogota', hour12: true, })
  }

  return (
    <div className={`item-content ${unexpected ? 'item-content-unexpected' : ''} `}>
      {dateFormated === 'Fecha inválida' ? '6:00 AM' : dateFormated}
      <div className=''></div>
    </div>
  )
}

export default DateFormat