import { AddSquareButton } from "components/atoms/button/Button";
import MediaSourceContainer from "components/atoms/mediaSource/MediaSourceContainer";
import useFile from "custom-hooks/useFile";
import useReduxState from "custom-hooks/useReduxState";
import React from "react";
import { updateResources } from "reducers/chat.controller.reducer";

const ResourcesAdd = () => {
  const [chatControlState, dispatchChatControl] = useReduxState(
    { updateResources },
    "chatController"
  );

  const { isRecording, showAudio, resources } = chatControlState;
  const { updateResources: updateResourcesAction } = dispatchChatControl;
  const file = useFile(resources, updateResourcesAction, () => {});

  return (
    !isRecording &&
    !showAudio && (
      <div className="dano-foto">
        <div className="add-foto-container" onClick={file.openFileSelector}>
          <AddSquareButton />
        </div>
        <MediaSourceContainer
          sources={resources}
          callbackViewSource={file.callbackViewSource}
          deleteMediaSource={file.callabackDeleteInmediateMediaSource}
        />
      </div>
    )
  );
};

export default ResourcesAdd;
