import React from 'react'
import './psPopups/psPopups.css'
import StyledCalendar from '../components/calendar/StyledCalendar.jsx'
import moment from 'moment'
import { useState } from 'react'
import CALENDAR_ICON_SVG from '../assets/icons/svg/calendar.svg'
import CLOCK_ICON_SVG from '../assets/icons/svg/clock.svg'
import {BaseDropdown} from '../components/atoms/dropdown/Dropdown'

const PopupCalendar = ({ object }) => {

    const {
        callbackSelectDateStart,
        callbackAcceptDate,
        defaultValueDate,
        minDateAviable
    } = object

    return (
        <div className='schedule-calendar popup-calendar'>
            <StyledCalendar
                callbackSelectedDate={callbackSelectDateStart}
                defalutValueDate={defaultValueDate}
                minDate={true}
                minDateAviable={minDateAviable}
                disableDays={true}
            />
            <div className='psi-button psi-button-active btn-select-date-popup' onClick={callbackAcceptDate}>
                Seleccionar fecha
            </div>

        </div>
    )
}

const PopupCalendarWithHour = ({ object }) => {

    const {
        callbackSelectDateStart,
        callbackSave,
        defaultValueDate,
        minDateAviable,
        callbackScheduleStart,
        callbackScheduleEnd,
        SCHEDULE_RANGE=[],
        defaultFromDate,
        defaultUntilDate
    } = object

    return (
        <div className='schedule-calendar popup-calendar'>

            <div className='categoria-container'>

                <div className='valid-time-text'>
                    <div>
                        <img src={CALENDAR_ICON_SVG} id='new-service-icon' alt={'POINTING_ICON_SVG'} />
                    </div>
                    <div> Seleccionar fecha: </div>
                </div>

            </div>

            <StyledCalendar
                callbackSelectedDate={callbackSelectDateStart}
                defalutValueDate={defaultValueDate}
                minDate={true}
                minDateAviable={minDateAviable}
                disableDays={true}
            />

            <div className='categoria-container'>

                <div className='valid-time-text'>
                    <div>
                        <img src={CLOCK_ICON_SVG} id='new-service-icon' alt={'POINTING_ICON_SVG'} />
                    </div>
                    <div> Seleccionar hora: </div>
                </div>

            </div>

            <div className='time-entry-container-client'>

                <div className='new-time-entry'>

                    <div className='time-entry-text'>Desde:</div>

                    <div className='new-entry-dropdown-container'>
                        <BaseDropdown
                            defaultSelected={defaultFromDate}
                            callback={callbackScheduleStart}
                            items={SCHEDULE_RANGE.map((state, index) => (
                                <option
                                    value={state}
                                    key={index}
                                    className='rol-option'>
                                    {state}
                                </option>)
                            )} style='new-entry-dropdown' />
                    </div>

                </div>

                <div className='new-time-entry'>

                    <div className='time-entry-text'>Hasta:</div>

                    <div className='new-entry-dropdown-container'>
                        <BaseDropdown
                            defaultSelected={defaultUntilDate}
                            callback={callbackScheduleEnd}
                            items={SCHEDULE_RANGE.map((state, index) => (
                                <option
                                    value={state}
                                    key={index}
                                    className='rol-option'>{state}
                                </option>)
                            )} style='new-entry-dropdown' />
                    </div>

                </div>

            </div>


            <div className='psi-button psi-button-active btn-select-date-popup' onClick={callbackSave}>
                Guardar
            </div>

        </div>
    )
}

export {
    PopupCalendar,
    PopupCalendarWithHour
}

