import React from "react";
import { Flex, Skeleton, Space } from "antd";

const SkeletonHeaderChat = () => {
  return (
    <Space direction="vertical" style={{ width: "96%" }}>
      <br />
      <Skeleton.Input active size="large" block style={{ marginLeft: "2%" }} />
      <br />
      <Flex
        gap="middle"
        style={{ width: "100%", marginLeft: "2%" }}
        align="center"
        justify="center"
      >
        <Skeleton.Image active style={{ height: 200, width: "300px" }} />

        <Skeleton.Image active style={{ height: 200, width: "300px" }} />
      </Flex>
      <br />
    </Space>
  );
};

export default SkeletonHeaderChat;
