import React from 'react'
import { TwoButtonsPopup,IconTwoButtonsPopup,  OneButtonsPopup} from '../components/atoms/popup/Popup'


const PopupTwoButtons = ({ object }) => {
    const {
        callbackRemove,
        callbackBack,
        popupText
    } = object
    return (
        <TwoButtonsPopup
            text={popupText}
            btnOneText='No'
            btnTwoText='Si'
            btnOneColor='btn-negative'
            btnTwoColor='btn-positive'
            callbackTwo={callbackRemove}
            callbackOne={callbackBack} />
    )
}
const PopupTwoOptionWithIcon = ({ object }) =>{
    const {
        callbackNegative,
        callbackPositive,
        textPositive,
        textNegative,
        popupText,
        popupIcon
    } = object

    return (
        <IconTwoButtonsPopup
        text={popupText}
        btnOneText={textNegative} 
        btnOneColor='btn-negative'
        callbackOne={callbackNegative} 
        btnTwoText={textPositive} 
        btnTwoColor='btn-positive'
        callbackTwo={callbackPositive}
        icon={popupIcon}
        />
    )
}

const PopupSuccessEvent = ({ object }) => {
    const {
        callback,
        popupText
    } = object
    return (
        <OneButtonsPopup
            text={popupText}
            btnOneText='Aceptar'
            btnOneColor='btn-positive'
            callbackOne={(e) => {
                callback()
                e.stopPropagation()
            }} />
    )
}

export {
    PopupTwoButtons,
    PopupTwoOptionWithIcon,
    PopupSuccessEvent
}