import LOCK_ICON_SVG from "../../assets/icons/svg/lock.svg";
import LOGO_SVG from "../../assets/icons/svg/logo.svg";
import USER_ICON_SVG from "../../assets/icons/svg/user.svg";
import { Appbar, AppbarLogo } from "../../components/atoms/appbar/Appbar";
import { PrimaryButton } from "../../components/atoms/button/Button";
import { IconInput } from "../../components/atoms/input/Input";
import "./login.css";

const AuthView = ({
  callbackSiginWithFacebook,
  callbackSiginWithGoogle,
  callbackSiginWithEmailAndPass,
  callbackOnChangeEmail,
  defaultValueEmail,
  callbackOnChangePass,
  defaultValuePass,
  callbackResetPasswordPopup,
}) => {
  return (
    <div className="login-main-container">
      <AppbarLogo />

      <div id="login-container">
        <div id="appbar-container">
          <Appbar smallText="Bienvenido de vuelta," bigText="¡Inicia sesión!" />
        </div>

        <div id="body-login">
          <>
            <div id="logo-container">
              <img id="logo-icon" src={LOGO_SVG} alt={"LOGO_SVG"} />
            </div>

            <div id="input-container">
              <IconInput
                placeholder="Usuario"
                defaultValue={defaultValueEmail}
                callback={callbackOnChangeEmail}
                icon={USER_ICON_SVG}
              />

              <br />

              <IconInput
                placeholder="Contraseña"
                type="password"
                defaultValue={defaultValuePass}
                callback={callbackOnChangePass}
                icon={LOCK_ICON_SVG}
              />
            </div>

            <div id="button-container">
              <PrimaryButton
                callback={callbackSiginWithEmailAndPass}
                txt="Iniciar sesión"
              />

              <div id="register-container">
                <p id="register-text">¿Olvidaste tu contraseña?</p>
                <p onClick={callbackResetPasswordPopup} id="register-link">
                  Recuperar
                </p>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default AuthView;
