import { Progress, Skeleton, Timeline } from "antd";
import useReduxState from "custom-hooks/useReduxState";
import { useMemo } from "react";
import { changeNewAdvanceFormOpen, setSelectedActivity } from "reducers/new.advance.detail";
import { categoriesService } from "res/constans";
import { dateTimeFormater } from "res/utils";

export default function ActivitiesList() {
  const [NewAdvanceDetailState, dispatch] = useReduxState({ setSelectedActivity, changeNewAdvanceFormOpen }, "new_advance_detail");
  const { serviceOperation, isLoading } = NewAdvanceDetailState

  const activitiesByPhase = useMemo(() => {
    if (serviceOperation?.activities?.length) {
      const filteredActivities = serviceOperation.activities.filter(activity => parseInt(activity.phase)) // Exclude elements where phase value is not number
      return Object.groupBy(filteredActivities, ({ phase }) => phase)
    }

    return []
  }, [serviceOperation])

  return (
    <section className="timeline-advance">
      {isLoading
        ? <Skeleton.Input className="timeline-container-skeleton" active />

        : (
          <>
            <h1>
              Cronograma del {dateTimeFormater({ date: serviceOperation?.workplan?.date_init, })}
              {" "}
              al
              {" "}
              {dateTimeFormater({ date: serviceOperation?.workplan?.date_end })}
            </h1>

            <Timeline
              rootClassName="timeline-container"
              mode="left"
              items={Object.entries(activitiesByPhase).map(([phase, activities]) => {
                const existPhase = serviceOperation.workplan?.ideal[phase];
                const phaseDate = existPhase ? new Date(existPhase.date_init) : null;

                return {
                  children: (
                    <section className="timeline-item">
                      <header className="timeline-item-header">
                        <h1>Fase {phase}</h1>
                        {phaseDate ? (
                          <time dateTime={phaseDate}>
                            {dateTimeFormater({ date: phaseDate, dateStyle: "long" })}
                          </time>
                        ) : ""}
                      </header>

                      <Timeline
                        rootClassName="timeline-phase"
                        items={activities.map((activity) => {
                          const { name, to_complete, completed, category, labor_cost, value, id: key } = activity;
                          const categoryService = categoriesService?.find(
                            ({ key }) => key === category
                          );

                          return {
                            children: (
                              <article
                                className="timeline-sub-item"
                                onClick={() => {
                                  dispatch.setSelectedActivity({
                                    name,
                                    to_complete,
                                    completed,
                                    cat: category,
                                    labor_cost,
                                    value,
                                    serviceId: serviceOperation.id,
                                    activityId: key,
                                  });

                                  dispatch.changeNewAdvanceFormOpen(true)
                                }}
                              >
                                {(categoryService?.icon) && <categoryService.icon />}

                                <div className="sub-item-content">
                                  <p className="sub-item-desc">{name}</p>

                                  <footer>
                                    <p>
                                      {to_complete < 1 &&
                                        to_complete + completed < 1 &&
                                        `avanzar un ${to_complete}% en ${name}`}
                                      {to_complete < 1 &&
                                        completed >= 1 &&
                                        `terminar ${name}`}
                                    </p>

                                    <Progress
                                      percent={completed * 100}
                                      size={[192, 5]}
                                      {...(completed < 1 && {
                                        format: (percent) => `${Math.round(percent)}%`
                                      })}
                                    />
                                  </footer>
                                </div>
                              </article>
                            ),
                          };
                        }
                        )}
                      />
                    </section>
                  ),
                };
              })}
            />
          </>
        )}
    </section>
  )
}
