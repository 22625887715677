import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import StateButtonModalHook from "../../modal/StateButtonModalHook.jsx";
import { PopupSuccessEvent } from "../../popups/psPopups/PopupSuccessEvent.jsx";
import changeListByCode from "../../res/setps";
import { PRINCIPAL_PATH } from "../../router/routes";
import StepGuide from "./StepGuide.jsx";

const StepGuideController = ({ history, location }) => {
  const { state } = location;

  //state

  const [listSteps, setListSteps] = useState([]);
  const [selected, setSelect] = useState(0);
  const [showBlockedPopup, setShowBlockedPopup] = useState(false);
  const redirecToPath = () => {
    if (!state) history.push(PRINCIPAL_PATH);
    else setListSteps(changeListByCode(state.type));
  };

  useEffect(() => {
    redirecToPath();

    return () => {};
  }, []);

  //general

  const callbackChange = (e) => setSelect(parseInt(e.target.id));

  const callbackSkeep = () => {
    if (state.type === 200) {
      setShowBlockedPopup(true);
    } else {
      history.push(PRINCIPAL_PATH);
    }
  };

  const callbackClose = () => {
    setShowBlockedPopup(false);
    history.push(PRINCIPAL_PATH);
  };

  return (
    <>
      <StepGuide
        positionSelected={selected}
        callbackChange={callbackChange}
        listSteps={listSteps}
        callbackSkeep={callbackSkeep}
      />

      {showBlockedPopup ? (
        <StateButtonModalHook
          component={PopupSuccessEvent}
          hook={[showBlockedPopup, setShowBlockedPopup]}
          object={{
            message:
              "Gracias por registrate en Justo Pago. Nos estaremos comunicando contigo por medio de una llamada y el correo electrónico, para activar tu cuenta.",
            callback: callbackClose,
            btnText: "Ok ",
          }}
        />
      ) : null}
    </>
  );
};

export default withRouter(StepGuideController);
