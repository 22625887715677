import { AlertOutlined, ClearOutlined, ProductOutlined, TruckOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { ORDER_ADDITIONAL_MATERIAL } from "router/routes";

const CLEAN = 'clean'
const ADDITIONAL_MATERIAL = 'additionals'
const DEBRIS = 'debris'
const UNEXPECTED = 'unexpected'

export default function AddNewOptions({ onHandleNewRequest }) {
  const history = useHistory();

  function onClick(type) {
    switch (type) {
      case CLEAN:
        onHandleNewRequest(CLEAN);
        break;
      case ADDITIONAL_MATERIAL:
        onHandleNewRequest(ADDITIONAL_MATERIAL);
        break;
      case DEBRIS:
        onHandleNewRequest(DEBRIS);
        break;
      case UNEXPECTED:
        break;
      default:

    }
  }

  return (
    <>
      <section className="add-new-option">

        <Button
          size="large"
          icon={<AlertOutlined />}
        >
          Nuevo imprevisto
        </Button>

        <Button
          size="large"
          onClick={() => onClick(ADDITIONAL_MATERIAL)}
          icon={<ProductOutlined />}
        >
          Pedir material adicional
        </Button>

        <Button
          size="large"
          onClick={() => { onClick(CLEAN) }}
          icon={<ClearOutlined />}
        >
          Solicitar servicio de aseo
        </Button>

        <Button
          size="large"
          onClick={() => { onClick(DEBRIS) }}
          icon={<TruckOutlined />}
        >
          Solicitar retiro de escombros
        </Button>
      </section>


    </>
  )
}
