import React from "react";
import MultimediaActions from "./MultimediaActions/MultimediaActions";
import MessageInput from "./MessageInput/MessageInput";
import SendMessageButton from "./SendMessageButton";
import EmojContainer from "./MultimediaActions/EmojContainer";

const ChatController = () => {
  return (
    <>
      <div className="chat-controller">
        <EmojContainer />
        <MessageInput />
        <div className="message-utils">
          <div className="send-message-container">
            <MultimediaActions />
            <SendMessageButton />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatController;
