import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import SVG_JUSTO from "../../../assets/icons/svg/justo_pago_logo_blanco.svg";
import ALERT_SOUND from "../../../assets/sounds/alert.wav";
import { Appbar } from "../../../components/atoms/appbar/Appbar";
import ButtonNavbar from "../../../components/molecules/buttonNavbar/ButtonNavbar";
import { NotificationSound } from "../../../components/molecules/notifications/NotificationSound";
import { NotificationArea } from "../../../components/organisms/notifications/NotificationArea";
import { PushNotification } from "../../../components/organisms/push-notifications/PushNotifications";
import useNotifications from "../../../custom-hooks/useNotifications";
import { NOTIFICATIONS } from "../../../router/routes";

import { countUnreadNotifications } from "../../../utils/utils.notifications";
import "./notifications.css";
const Notifications = ({ history }) => {
  const [pushTitle, setPushTitle] = useState(null);
  const [pushOptions, setPushOptions] = useState(null);
  const [permission, setPermission] = useState(false);

  const {
    notifications,
    unreadNotifications,
    notificationOnClick,
    handleRemoveById,
    handleMarkAsRead,
  } = useNotifications();

  const notificationSound = NotificationSound({ src: ALERT_SOUND });

  useEffect(() => {
    const newUnReadNotificationsCount = countUnreadNotifications(notifications);
    if (unreadNotifications === null) {
      return;
    }
    if (newUnReadNotificationsCount > unreadNotifications) {
      notificationSound.play();
      if (permission) {
        setPushTitle("Nueva Noticia");
        setPushOptions({
          body: "Revisa en la plataforma que hay una nuevo mensaje.",
          icon: SVG_JUSTO,
        });
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  return (
    <div className="notifications-container">
      <div className="services-appbar-container">
        <Appbar smallText="Revisa tus " bigText="Notificaciones" />
      </div>

      <div className="options-notifications-container">
        <NotificationArea
          notifications={notifications}
          psNotification={false}
          onHandleNotiClick={(notification) => {
            notificationOnClick(notification, history, false);
          }}
          handleRemoveById={handleRemoveById}
          handleMarkAsRead={handleMarkAsRead}
        />
      </div>

      <div className="menu-navbar-container">
        <ButtonNavbar
          page={NOTIFICATIONS}
          showBtn={true}
          popupContent={["Servicios", "Garantias"]}
        />
        <PushNotification
          title={pushTitle}
          options={pushOptions}
          requestPermission={true}
          setPermission={setPermission}
        />
        {notificationSound.Render}
      </div>
    </div>
  );
};

export default withRouter(Notifications);
