import React, { useState } from "react";
import "./locationForm.css";
import { BaseInput } from "../../atoms/input/Input";
import { LocationDropdown } from "../../atoms/dropdown/Dropdown";
import MAP_COLOMBIA from ".././../../res/colombia";
import ADDRESS from ".././../../res/address";

const LocationForm = ({
  callbackSelectedDepartment,
  defaultOptionDepartment,
  callbackSelectedCity,
  defaultOptionCity,
  callbackSelectedAddress,
  defaultOptionAddress,
  callbackAddress,
  callbackCornerAddress,
  callbackNumberAddress,
  defaultValueAddress,
  defaultValueCornerAddress,
  defaultValueNumberAddress,
}) => {
  return (
    <div className="location-form-container">

      <div className="location-form-field">
        <LocationDropdown
          defaultOption={defaultOptionDepartment}
          callback={callbackSelectedDepartment}
          items={MAP_COLOMBIA.map((department, index) => (
            <option
              key={index}
              className="rol-option"
              value={department.department_code}
            >
              {department.department_name}
            </option>
          ))}
        />
      </div>

      <div className="location-form-field">
        <LocationDropdown
          defaultOption={defaultOptionCity}
          callback={callbackSelectedCity}
          items={MAP_COLOMBIA.filter(
            (department) =>
              department.department_code === defaultOptionDepartment
          )[0].cities.map((cities, index) => (
            <option key={index} className="rol-option" value={cities.city_code}>
              {cities.city_name}
            </option>
          ))}
        />
      </div>

      <div className="location-form-field full-width-field">
        <LocationDropdown
            defaultOption={defaultOptionAddress}
            callback={callbackSelectedAddress}
            items={ADDRESS.map((address, index) => (
            <option key={index} className="rol-option" value={address}>
                {address}
            </option>
            ))}
        />
      </div>

      <div className="location-form-field third-width-field">
        <BaseInput
          defaultValue={defaultValueAddress}
          callback={callbackAddress}
          style="location-form-input"
        />
      </div>

      <div className="location-form-field third-width-field">
        <BaseInput
          defaultValue={defaultValueCornerAddress}
          callback={callbackCornerAddress}
          placeholder="#"
          style="location-form-input"
        />
      </div>

      <div className="location-form-field third-width-field">
        <BaseInput
          defaultValue={defaultValueNumberAddress}
          callback={callbackNumberAddress}
          placeholder="-"
          style="location-form-input"
        />
      </div>
    </div>
  );
};

export default LocationForm;
