import React from "react";
import "./multimediaModal.css";
import DOWNLOAD_ICON from "../../assets/icons/svg/download-white.svg";

const ModalMultimedia = ({ isOpen, onClose, type, image, name, typeFile }) => {
  if (!isOpen) return null;

  const downloadImage = () => {
    const link = document.createElement("a");
    link.href = image;
    link.download = `${name}.${typeFile}`;
    link.click();
  };

  const getModalContent = () => {
    if (type === "profile") {
      return (
        <div className="modal-profile-image">
          <img src={image} alt="Profile" className="profile-photo-modal" />
        </div>
      );
    } else if (type === "multimedia") {
      return (
        <div className="modal-multimedia-image">
          <img className="image-resource" src={image} alt="Multimedia" />
          <button className="button-download" onClick={downloadImage}>
            <img
              className="image-download"
              src={DOWNLOAD_ICON}
              alt="download"
            />
          </button>
        </div>
      );
    } else {
      return null;
    }
  };

  const isProfile = type === "profile";

  return (
    <div className="modal">
      <div
        className={`${!isProfile ? "modal-content" : "modal-content-profile"}`}
      >
        <span
          className={`${!isProfile ? "close-btn" : "close-btn-profile"}`}
          onClick={onClose}
        >
          &times;
        </span>
        {getModalContent()}
      </div>
    </div>
  );
};

export default ModalMultimedia;
