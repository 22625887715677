import { Button, DatePicker, Form } from "antd";
import GoBackBtn from "components/GoBackBtn/GoBackBtn";
import "./OrderDebrisRemoval.css";

export default function OrderDebrisRemoval() {
  const onSubmit = (values) => {
    console.log(values)
  }

  return (
    <main className="order-debris-removal">
      <GoBackBtn />

      <section className="debris-removal-wrapper">
        <h1>Solicitar retiro de escombros</h1>

        <Form
          onFinish={onSubmit}
          layout="vertical"
          rootClassName="debris-removal-form"
        >
          <div className="debris-input-wrapper">
            <Form.Item
              rules={[
                { required: true, message: "Debes seleccionar la fecha" },
              ]}
              label="Fecha en la que se requiere el servicio"
              name="date_removal_service"
            >
              <DatePicker className='date-picker' />
            </Form.Item>
          </div>

          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className='order-submit-btn'
            block
          >
            Solicitar servicio
          </Button>
        </Form>
      </section>
    </main>
  )
}
