import React, { useState } from "react";
import { withRouter, useParams, Redirect } from "react-router-dom";
import RegisterGeneral from "./userTypes/RegisterGeneral";
// import RegisterType from "./registerType/RegisterType.jsx";
import StateButtonModalHook from "../../modal/StateButtonModalHook.jsx";
import {
  SuccessRegister,
  ErrorRegister,
} from "../../popups/SuccessRegister.jsx";
import { REGEX_EMAIL, REGEX_PASS } from "../../res/regex";
import { createAccountWhitEmailAndPassword } from "../../services-controller/auth";
import { getValueByKey } from "../../persistent-managment/managment.persistent";
import { UID_AUTH } from "../../persistent-managment/key.persistent";
import { createUserById } from "../../services-controller/http.cleinte.controller";
import { uploadImageThumbnail } from "../../services-controller/storage";
import uuidv5 from "uuid/v5";
import { LoadingPopup } from "../../components/atoms/popup/Popup";
import {
  COMPANY,
  PARTICULAR,
  HORIZONTAL_HOUSE,
  HORIZONTAL_PROPERTY,
  REAL_STATE,
  INSURANCE,
  USER_TYPE_PATH,
  OTHER_USER,
  PROPERTY_TYPE_PATH,
  STEPS_PATH,
} from "../../router/routes";
import {
  CREATE_USER_ERROR_MESSAGE,
  PHOTO_VALIDATION_ERROR_MESSAGE,
  PHONE_VALIDATION_ERROR_MESSAGE,
  EMAIL_VALIDATION_ERROR_MESSAGE,
  PASS_VALIDATION_ERROR_MESSAGE,
  EMAIL_PASS_VALIDATION_ERROR_MESSAGE,
} from "../../res/errors";
import {
  PARTICULAR_TYPE,
  COMPANY_TYPE,
  REAL_STATE_TYPE,
  INSURANCE_TYPE,
  NO_LABEL,
  HORIZONTAL_HOUSE_TYPE,
  HORIZONTAL_PROPERTY_TYPE,
  OTHER_USER_TYPE,
} from "../../res/register.type";
import {
  changeRegisterType,
  changeThumbnail,
  changeName,
  changePhone,
  changeEmailAssociated,
  changeIdentifier,
  changePassword,
  changeTypeIdentifier,
} from "../../reducers/form.user.reducer";
import {
  convertUserTypeByRouterToCode,
  convertRegisterTypeByRouterToCode,
} from "../../res/convert.codes";
import { CUSTOMER } from "../../res/register.type";
import { useDispatch, useSelector } from "react-redux";

const RegisterUserController = ({ history }) => {
  const dispatch = useDispatch();
  const form_user = useSelector((state) => state.form_user);

  const {
    user_type,
    register_type,
    register_data: {
      thumbnail,
      name,
      contact: { phone, email_associated },
      secrets: { password, type_identifier, identifier },
    },
  } = form_user;

  //state view

  let { id } = useParams();

  const [isLoader, setIsLoader] = useState(false);

  const [isOpenGeneral, setIsOpenGeneral] = useState(false);

  const [isErrorForm, setIsErrorForm] = useState(false);

  const stateErrorFormCallback = (state) => setIsErrorForm(state);

  const [errorMessage, setErrorMessage] = useState("");

  const [successRegister, setSuccessRegister] = useState(false);

  //start general

  const callbackChangeTypeIdentifier = (e) =>
    dispatch(changeTypeIdentifier(e.target.value));

  /*   const callbackChangeRegisterType = (e) =>
    dispatch(changeRegisterType(e.target.id));

  const callbackPushNavigator = () => history.push(register_type); */

  const callbackUploadThumbnail = async (file) => {
    const uid = uuidv5(new Date().toLocaleString(), uuidv5.URL);
    const res = await uploadImageThumbnail(uid, file);
    const { code } = res;
    if (code === 200) {
      const { url } = res;
      dispatch(changeThumbnail(url));
    }
  };

  const callbackSelectImageError = (_) =>
    showModalError(PHOTO_VALIDATION_ERROR_MESSAGE);

  const callbackChangeName = (e) => dispatch(changeName(e.target.value));

  const callbackChangePhone = (e) => dispatch(changePhone(e.target.value));

  const callbackChangeEmailAssociated = (e) =>
    dispatch(changeEmailAssociated(e.target.value));

  const callbackChangeIdentifier = (e) =>
    dispatch(changeIdentifier(e.target.value));

  const callbackChangePassword = (value) => {
    dispatch(changePassword(value));
  };

  const stringValidator = (str) => str !== "";

  const validatorPhone = (str) => {
    const result = str.length === 7 || str.length === 10 || str.length === 0;
    return result;
  };

  const validatorEmail = (str) => {
    const result = REGEX_EMAIL.test(str);
    return result;
  };

  const validatorPassword = (str) => {
    const result = REGEX_PASS.test(str);
    return result;
  };

  const showModalError = (message) => {
    setIsLoader(false);
    setErrorMessage(message);
    stateErrorFormCallback(true);
  };

  const openModal = async () => {
    setIsLoader(true);
    const phoneEvaluation = validatorPhone(phone);
    const emailEvaluation = validatorEmail(email_associated);
    const passEvaluation = validatorPassword(password);
    const emailAndPassEvaluation = emailEvaluation && passEvaluation;

    if (!phoneEvaluation) showModalError(PHONE_VALIDATION_ERROR_MESSAGE);
    else {
      if (!emailAndPassEvaluation)
        showModalError(EMAIL_PASS_VALIDATION_ERROR_MESSAGE);
      else {
        if (!emailEvaluation) showModalError(EMAIL_VALIDATION_ERROR_MESSAGE);
        else if (!passEvaluation) showModalError(PASS_VALIDATION_ERROR_MESSAGE);
        else {
          const result = await createAccountWhitEmailAndPassword(
            email_associated,
            password
          );
          const { code } = result;
          if (code === 400) {
            const { message } = result;
            showModalError(message);
          } else await savingDatabase();
        }
      }
    }
  };

  const validatorInputs =
    stringValidator(name) &&
    stringValidator(phone) &&
    stringValidator(identifier) &&
    stringValidator(email_associated);

  const savingDatabase = async () => {
    const uid = getValueByKey(UID_AUTH);
    const result = await createUserById(uid, {
      ...form_user,
      register_type: convertRegisterTypeByRouterToCode(CUSTOMER),
      user_type: convertUserTypeByRouterToCode(user_type),
    });
    const { code } = result;
    if (code === 200) {
      setIsLoader(false);
      setIsOpenGeneral(true);
      //AQUI VA EL CAMBIO DEL BOTON DE MENU
      setSuccessRegister(true);
    } else showModalError(CREATE_USER_ERROR_MESSAGE);
  };

  const callbackPushNavigatorRegisterGeneral = () =>
    history.push(PROPERTY_TYPE_PATH);

  const callbackPushNavigatorMenu = () =>
    history.push({
      pathname: STEPS_PATH,
      state: { type: convertRegisterTypeByRouterToCode(CUSTOMER) },
    });

  //__________

  const buildRegisterTypeText = (type) => {
    switch (type) {
      case PARTICULAR:
        return PARTICULAR_TYPE;

      case COMPANY:
        return COMPANY_TYPE;
      case REAL_STATE:
        return REAL_STATE_TYPE;
      case INSURANCE:
        return INSURANCE_TYPE;

      case HORIZONTAL_HOUSE:
        return HORIZONTAL_HOUSE_TYPE;
      case HORIZONTAL_PROPERTY:
        return HORIZONTAL_PROPERTY_TYPE;

      case OTHER_USER:
        return OTHER_USER_TYPE;

      default:
        return NO_LABEL;
    }
  };

  const BuildView = (type) => {
    switch (type) {
      //Register type <Cliente - Prestador de servicios>
      /* case REGISTER_TYPE:
        return (
          <RegisterType
            callbackChangeRegisterType={callbackChangeRegisterType}
            registerType={register_type}
            callbackPushNavigator={callbackPushNavigator}
          />
        ); */

      //RegisterGeneral Form
      case PARTICULAR:
      case COMPANY:
      case REAL_STATE:
      case HORIZONTAL_PROPERTY:
      case HORIZONTAL_HOUSE:
      case INSURANCE:
      case OTHER_USER:
        return (
          <RegisterGeneral
            //Template
            callbackUploadThumbnail={callbackUploadThumbnail}
            callbackSelectImageError={callbackSelectImageError}
            thumbnail={thumbnail}
            //Form
            callbackPushNavigator={openModal}
            isAvailableCallback={validatorInputs}
            callbackChangeName={callbackChangeName}
            defaultValueName={name}
            callbackChangePhone={callbackChangePhone}
            defaultValuePhone={phone}
            callbackChangeEmailAssociated={callbackChangeEmailAssociated}
            defaultValueEmail={email_associated}
            callbackChangeIdentifier={callbackChangeIdentifier}
            defaultValueIdentifier={identifier}
            changePassword={callbackChangePassword}
            defaultValuePassword={identifier}
            type={buildRegisterTypeText(type)}
            validPass={validatorPassword(password)}
            validEmail={validatorEmail(email_associated)}
            callbackTypeIdentity={callbackChangeTypeIdentifier}
            defaultValueTypeIdentify={type_identifier}
            successRegister={successRegister}
            callbackNavigateMenu={callbackPushNavigatorMenu}
          />
        );

      default:
        return <Redirect to={USER_TYPE_PATH} />;
    }
  };

  return (
    <>
      {BuildView(id)}
      {/* success */}
      {
        //RegisterGeneral
        isOpenGeneral ? (
          <StateButtonModalHook
            component={SuccessRegister}
            hook={[isOpenGeneral, setIsOpenGeneral]}
            object={{
              callbackMenu: callbackPushNavigatorMenu,
              callbackRegistrProperty: callbackPushNavigatorRegisterGeneral,
            }}
          />
        ) : null
      }
      {/* error */}
      {isErrorForm ? (
        <StateButtonModalHook
          component={ErrorRegister}
          hook={[isErrorForm, setIsErrorForm]}
          object={{
            message: errorMessage,
            callback: stateErrorFormCallback,
          }}
        />
      ) : null}
      <LoadingPopup state={isLoader} />
    </>
  );
};

export default withRouter(RegisterUserController);
