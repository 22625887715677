import React from 'react'
import './psRegister.css'
import { RegisterUserTemplate } from '../../../components/templates/registerTemplate/RegisterTemplate'
import { IconInput } from '../../../components/atoms/input/Input'
import { IconButton } from '../../../components/atoms/button/Button'
import { BigCard } from '../../../components/atoms/card/Card'
import { IconDropdown, DropdownWithInput } from '../../../components/atoms/dropdown/Dropdown'
import USER_ICON_SVG from '../../../assets/icons/svg/user.svg'
import MAIL_ICON_SVG from '../../../assets/icons/svg/mail.svg'
import LOCK_ICON_SVG from '../../../assets/icons/svg/lock.svg'
import BOOK_ICON_SVG from '../../../assets/icons/svg/book.svg'
import HEART_ICON_SVG from '../../../assets/icons/svg/heart.svg'
import AWARD_ICON_SVG from '../../../assets/icons/svg/award.svg'
import HASH_ICON_SVG from '../../../assets/icons/svg/hash.svg'
import PHONE_ICON_SVG from '../../../assets/icons/svg/phone.svg'
import { IDENTIFIER_TYPE } from '../../../res/identifier.type'
import { MARITAL_STATUS } from '../../../res/marital.status'
import { MAXIMUN_ACADEMIC_LEVEL } from '../../../res/maximum.academic.level'
import { TRANSPORT } from '../../../res/transport'
import {AppbarLogo} from  '../../../components/atoms/appbar/Appbar'

const BasicInfoServiceProvider = ({
    callbackSelectImage,
    callbackSelectImageError,
    thumbnail,
    type,
    callbackPushNavigator,
    callbackChangeName,
    defaultValueName,
    callbackChangeEmail,
    defaultValueEmail,
    callbackChangePassword,
    defaultValuePassword,
    callbackChangeIdentifier,
    defaultValueIdentifier,
    callbackChangeTypeIdentifier,
    defaultValueTypeIdentifier,
    callbackChangeMaritalStatus,
    defaultValueMaritalStatus,
    callbackChangeMaximunLevelAcademic,
    defaultValueMaximunLevelAcademic,
    callbackChangeStudy,
    defaultValueStudy,
    callbackChangeConveyance,
    defaultValueConveyance,
    callbackChangeLicencePlate,
    defaultValueLicencePlate,
    isAvailableCallbackNex,
    validPass,
    validEmail,
    callbackChangePhoneNumber,
    defaultValuePhoneNumber,
}) => {
    return (
        <div className='register-info-container'>
            <AppbarLogo />
            <RegisterUserTemplate
                callbackSelectImage={callbackSelectImage}
                callbackSelectImageError={callbackSelectImageError}
                thumbnail={thumbnail}
                type={type}
                bigText='Información básica'
                fields={
                    <div className='order-form'>

                        <div className='options-fields' >

                            <div className='fields-container'>
                                <IconInput
                                    defaultValue={defaultValueName}
                                    callback={callbackChangeName}
                                    icon={USER_ICON_SVG}
                                    placeholder='Nombre y apellido' />
                            </div>

                            <div className='fields-container'>
                                <IconInput
                                    defaultValue={defaultValuePhoneNumber}
                                    callback={callbackChangePhoneNumber}
                                    icon={PHONE_ICON_SVG}
                                    placeholder='Número de celular' />
                            </div>

                            <div className='fields-container'>
                                <IconInput
                                    type='email'
                                    defaultValue={defaultValueEmail}
                                    callback={callbackChangeEmail}
                                    icon={MAIL_ICON_SVG}
                                    placeholder='Correo electrónico'
                                    validInput={validEmail}
                                />
                            </div>

                            <div className='fields-container'>
                                <IconInput
                                    defaultValue={defaultValuePassword}
                                    callback={callbackChangePassword}
                                    type='password'
                                    icon={LOCK_ICON_SVG}
                                    placeholder='Contraseña'
                                    validInput={validPass} />
                            </div>

                            {
                                validPass ? null : <div className='pass-text'>La contraseña debe tener al menos 6 caracteres.</div>
                            }

                            <div className='fields-container'>

                                <DropdownWithInput
                                    typeInput='number'
                                    callbackInput={callbackChangeIdentifier}
                                    defaultValue={defaultValueIdentifier}
                                    callback={callbackChangeTypeIdentifier}
                                    style='basic-input'
                                    placeholder='Número de identificación'
                                    defaultSelected={defaultValueTypeIdentifier}
                                    items={
                                        IDENTIFIER_TYPE.map((identity, index) => (
                                            <option
                                                key={index}
                                                className='rol-option'>{identity}
                                            </option>)
                                        )
                                    }
                                />

                            </div>

                            <div className='fields-container'>
                                <IconDropdown
                                    callback={callbackChangeMaritalStatus}
                                    icon={HEART_ICON_SVG}
                                    defaultSelected={defaultValueMaritalStatus}
                                    items={
                                        MARITAL_STATUS.map((state, index) => (
                                            <option
                                                key={index}
                                                className='rol-option'>{state}
                                            </option>)
                                        )
                                    } />
                            </div>

                            <div className='fields-container'>
                                <IconDropdown
                                    callback={callbackChangeMaximunLevelAcademic}
                                    icon={BOOK_ICON_SVG}
                                    defaultSelected={defaultValueMaximunLevelAcademic}
                                    items={
                                        MAXIMUN_ACADEMIC_LEVEL.map((education, index) => (
                                            <option
                                                key={index}
                                                className='rol-option'>{education}
                                            </option>)
                                        )
                                    } />
                            </div>

                            <div className='fields-container'>
                                <IconInput
                                    defaultValue={defaultValueStudy}
                                    callback={callbackChangeStudy}
                                    icon={AWARD_ICON_SVG}
                                    placeholder='Estudios realizados' />
                            </div>

                            <div className='fields-container fc-text'>
                                Medio de Transporte
                            </div>

                            <div className='fields-container transport-type-container'>
                                <div className='ps-options-usertype'>
                                    {
                                        TRANSPORT.map((transport, index) => (
                                            <div key={index} className='options-container'>
                                                <BigCard
                                                    selected={transport.id === defaultValueConveyance}
                                                    icon={transport.icon}
                                                    callback={() => callbackChangeConveyance(transport.id)}
                                                    text={transport.text} psStyle='ps-big-car-size' />
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>

                            <div className='fields-container'>
                                <IconInput
                                    defaultValue={defaultValueLicencePlate}
                                    callback={callbackChangeLicencePlate}
                                    icon={HASH_ICON_SVG}
                                    placeholder='Placa' />
                            </div>

                            {
                                isAvailableCallbackNex ?
                                    <div className='fields-container register-button'>
                                        <IconButton
                                            callback={callbackPushNavigator} />
                                    </div> :
                                    null
                            }

                        </div>

                    </div>
                } />
        </div>
    )
}

export default BasicInfoServiceProvider
