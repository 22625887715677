import { Progress, Skeleton, Tooltip } from 'antd';
import { ReactComponent as LaborIcon } from 'assets/icons/svg/labor.svg';
import { ReactComponent as MaterialsIcon } from 'assets/icons/svg/materials.svg';
import GoBackBtn from 'components/GoBackBtn/GoBackBtn';
import { configClass, configTitleIcon } from 'components/molecules/container/Container';
import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { categoriesService } from 'res/constans';
import { moneyFormat } from 'res/utils';

export default function HeaderSection() {
  const newAdvanceState = useSelector((state) => state.new_advance_detail, shallowEqual);
  const { isLoading, serviceBudget, serviceData, serviceOperation } = newAdvanceState;
  const { id } = useParams();

  const parsedActivities = useMemo(() => {
    let result = [];

    if (!serviceOperation?.activities?.length) {
      return []
    }

    for (const activity of serviceOperation.activities) {
      const elementExist = result.find(
        (resultValue) => resultValue?.category === activity.category
      );

      if (!elementExist) {
        result.push({
          category: activity.category,
          percentage: activity.completed
        })

        continue
      }

      const index = result.indexOf(elementExist);

      result.with(index, {
        percentage: elementExist.percentage += activity.completed,
        category: activity.category,
      });
    }

    const groupedActivities = Object.groupBy(serviceOperation?.activities, ({ category }) => category)
    const parsedActivities = result.map((activity) => {
      const { category, percentage } = activity;

      return {
        category,
        percentage: percentage / groupedActivities[category].length
      }
    })

    return parsedActivities

  }, [serviceOperation])

  return (
    <header className="service-details">
      <section className="first-section">
        <GoBackBtn />

        {isLoading
          ?
          <Skeleton.Input
            loading={isLoading}
            active
            className='service-client-info-skeleton'
            size='large'
          />

          :
          <article className="service-client-info">
            <span
              className="client-info"
            >
              {serviceData?.metadata?.user?.register_data?.name} &bull; <span className='id-tag'>{id}</span>
            </span>

            <span
              className={`service-status ${configClass(
                serviceData?.state?.stage
              )}`}
            >
              {configTitleIcon(serviceData?.state?.stage)}
            </span>
          </article>
        }
      </section>

      {isLoading
        ?
        <Skeleton.Input
          active
          className='service-progress-info-skeleton'
        />

        :
        <section className="service-progress-info">
          <article className="money-detail">
            <div>
              <LaborIcon />
              <footer>
                <span>
                  {moneyFormat(serviceBudget?.status?.categories?.labor.available)}
                </span>
                <span>
                  {moneyFormat(
                    serviceBudget?.status?.categories?.labor.subtract_value
                  )}
                </span>
              </footer>
            </div>
            <div>
              <MaterialsIcon />
              <footer>
                <span>
                  {moneyFormat(serviceBudget?.status?.categories?.materials.available)}
                </span>
                <span>
                  {moneyFormat(
                    serviceBudget?.status?.categories?.labor.subtract_value
                  )}
                </span>
              </footer>
            </div>
          </article>

          {/* <article className="phases-status">
            {phases.map(({ progress }, index) => {
            return (
              <div className="progress-wrapper" key={index}>
                <h1 className="progress-format">Fase {index + 1}</h1>
                <Progress percent={progress} size="small" />
              </div>
            );
          })} 
          </article> */}

          <article className="categories-status">
            {parsedActivities?.map((activity, index) => {
              const { percentage, category } = activity
              const parsedPercentage = Math.round(percentage * 100)
              const categoryService = categoriesService.find(
                (item) => item.key === category
              );

              return (
                <Tooltip
                  placement="bottom"
                  title={categoryService?.text || "Sin categoria"}
                  key={index}
                >
                  <div className="status-container">
                    <Progress
                      type="circle"
                      percent={parsedPercentage}
                      strokeLinecap="butt"
                      size={52}
                      format={(percentage) => (
                        <div className="progress-container">
                          {categoryService?.icon && <categoryService.icon />}
                          <span>{percentage}%</span>
                        </div>
                      )}
                    />

                    {categoryService?.text && <span className="container-title">{categoryService.text}</span>}
                  </div>
                </Tooltip>
              );
            })}
          </article>
        </section>
      }
    </header>
  )
}
