import useReduxState from "custom-hooks/useReduxState";
import React from "react";
import { openEmojiPicker } from "reducers/chat.controller.reducer";
import EMOJI_SVG from "assets/icons/svg/emoji.svg";

const EmojiPicker = () => {
  const [chatControlState, dispatchChatControl] = useReduxState(
    { openEmojiPicker },
    "chatController"
  );
  const { showRecorder, isRecording, showEmojiPicker } = chatControlState;
  const { openEmojiPicker: openEmoji } = dispatchChatControl;

  return (
    !isRecording &&
    !showRecorder && (
      <div
        className={`emoji-icons icons-hover ${showEmojiPicker ? "active" : ""}`}
        onClick={() => {
          openEmoji();
        }}
      >
        <img src={EMOJI_SVG} alt="" />
      </div>
    )
  );
};

export default EmojiPicker;
