import React from 'react'
import { MediaFilePopup } from '../components/atoms/popup/Popup'


const ShowMediaSource = ({ object }) => {

    const {
        callbackCloseMediaSource,
        mediaSourceFile
    } = object

    return (
        <MediaFilePopup
            src={mediaSourceFile}
            btnText='Cerrar'
            btnColor='btnYellow'
            callback={callbackCloseMediaSource} />
    )
}


export {
    ShowMediaSource

}