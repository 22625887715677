import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import GoBackBtn from 'components/GoBackBtn/GoBackBtn';
import useReduxState from 'custom-hooks/useReduxState';
import { setActivitiesSectionActive, setChatPhaseSelected, setMediaSectionActive } from 'reducers/bitacle.reducer';
import "./Header.css";

export default function Header() {
  const [{ chatPhaseSelected }, dispatch] = useReduxState({ setMediaSectionActive, setChatPhaseSelected, setActivitiesSectionActive }, "bitacle")
  const btnMoreOptions = [
    {
      key: "1",
      label: (
        <Button
          type="text"
          onClick={() => {
            dispatch.setMediaSectionActive(true)
          }}
        >
          Lista de medios
        </Button>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "2",
      label: (
        <Button
          type="text"
          onClick={() => {
            dispatch.setActivitiesSectionActive(true)
          }}
        >
          Lista de actividades
        </Button>
      ),
    }
  ]

  const activeSummarySection = () => {
    dispatch.setActivitiesSectionActive(true)
  }

  return (
    <header className='bitacle-header'>
      <GoBackBtn
        onClick={() => {
          dispatch.setChatPhaseSelected({ name: "", key: "" })
        }}
      />

      <section className='header-info' >
        <h1
          onClick={activeSummarySection}
          className="info-title-phase"
          data-phase={chatPhaseSelected.key}
        >
          Chat de {chatPhaseSelected.name}
        </h1>


        <Dropdown
          menu={{
            items: btnMoreOptions,
          }}
          rootClassName='dropdown-more'
        >
          <Button
            className="header-btn-more"
            icon={<MoreOutlined />}
            type="text"
            shape="circle"
          >
          </Button>
        </Dropdown> 
      </section>
    </header>
  )
}
