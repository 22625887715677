const MAXIMUN_ACADEMIC_LEVEL_DEFAULT_VALUE = 'Nivel de Estudio'

const MAXIMUN_ACADEMIC_LEVEL = [
    'Educación inicial',
    'Educación preescolar',
    'Educación básica primaria',
    'Educación básica secundaria',
    'Educación media',
    'Técnico Profesional',
    'Tecnólogo',
    'Profesional',
    'Educación Superior',
    'Maestría',
    'Doctorado'
]

export {
    MAXIMUN_ACADEMIC_LEVEL,
    MAXIMUN_ACADEMIC_LEVEL_DEFAULT_VALUE
}