import React from 'react'
import './usageTemplate.css'


const BaseUsageTemplate = ({ image, title, text, btn, step}) => {
    return (
        <div className='container-base-template'>

            <div className='container-image-usage'>
                <img className='image-usage' src={image} alt={title} />
            </div>

            <div className='container-title-usage'>
                {title}
            </div>

            <div className='container-text-usage'>
                {text}
            </div>

            <div className='container-btn-usage'>
                {btn}
            </div>

            <div className='container-step-usage'>
                {step}
            </div>

        </div>
    )
}

export {
    BaseUsageTemplate
}