import { Button } from "antd";
import ProgressBar from "components/atoms/progess-bar/ProgressBar";
import useReduxState from "custom-hooks/useReduxState";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/";
import { setChatPhaseSelected, setPhases } from "reducers/bitacle.reducer";
import {
  getAllEntriesByService,
  getServiceById,
} from "services-controller/http.cleinte.controller";
import { getPercentPhases } from "views/services-provider/ps-new-entry/phases";
import "./BitaclePhase.css";
import Header from "./header/Header";

export default function BitaclePhase() {
  const [, dispatch] = useReduxState(
    { setChatPhaseSelected, setPhases },
    "bitacle"
  );
  const phasesOptions = useSelector((state) => state.bitacle.phases);
  const history = useHistory();
  const { id } = useParams();
  const [service, setService] = useState(null);
  const [phases, totalPercent] = getPercentPhases(service?.state?.globalState);

  useEffect(() => {
    const fetchService = async () => {
      const res = await getServiceById(id);
      setService(res);
    };

    fetchService();
  }, [id]);

  useEffect(() => {
    const getPhases = async () => {
      const response = await getAllEntriesByService(id);
      dispatch.setPhases(response.bitacle[0].entrys);
    };

    getPhases();
  }, [id]);

  function setChat(phase, index) {
    const name = phase.name;
    dispatch.setChatPhaseSelected({ key: index, name });
    const route = `/chat/${id}/fase/${index}`;
    history.push(route);
  }

  return (
    <main className="bitacle-phase-view">
      <Header metadata={service?.metadata} />
      <ProgressBar {...{ phases }} total={totalPercent} />

      <section className="phases-section">
        <h1 className="section-title">Selecciona la fase</h1>

        <div className="phases-options">
          {phasesOptions.map((phase, index) => {
            const { id: key, name } = phase;

            return (
              <Button
                key={index}
                className="phase-option"
                data-phase={key}
                onClick={setChat.bind(this, phase, index)}
              >
                {name}
              </Button>
            );
          })}
        </div>
      </section>
    </main>
  );
}
