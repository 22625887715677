import CLIENT_STEP_1 from '../assets/gifs/01_cliente.gif'
import CLIENT_STEP_2 from '../assets/gifs/02_cliente.gif'
import CLIENT_STEP_3 from '../assets/gifs/02_cliente.gif'
import PS_STEP_1 from '../assets/gifs/01_ps.gif'
import PS_STEP_2 from '../assets/gifs/02_ps.gif'
import PS_STEP_3 from '../assets/gifs/03_ps.gif'

const STEPS_CUSTOMER = [
    {
        image: CLIENT_STEP_1,
        title: 'CONFIANZA',
        message: 'Somos la solución agíl, confiable y moderna para tus reparaciones.'

    },
    {
        image: CLIENT_STEP_2,
        title: 'TRANQUILIDAD',
        message: 'Somos la comunidad preparada y certificada para brindarte el mejor servicio.'

    },
    {
        image: CLIENT_STEP_3,
        title: 'TIEMPO',
        message: 'Estas a un click de solucionar todas tus reparaciones.'

    }
]

const STEPS_SERVICE_PROVIDER = [
    {
        image: PS_STEP_1,
        title: 'OPORTUNIDAD',
        message: 'Con Justo Pago, podras acceder a una mayor cantidad de trabajo teniendo una mejor rentabilidad en cada uno de ellos.'

    },
    {
        image: PS_STEP_2,
        title: 'COMUNIDAD',
        message: 'Unete a la comunidad mas preparada de supervisores y certificate para pertenecer a Justo Pago.'

    },
    {
        image: PS_STEP_3,
        title: 'SUEÑOS',
        message: 'Estas a un click de pertenecer a la comunidad que te impulsa a cumplir tus sueños.'

    }
]

const changeListByCode = (code) => {

    if (code === 200) return STEPS_SERVICE_PROVIDER
    else if (code === 100) return STEPS_CUSTOMER
    else return []
}

export default changeListByCode