import React from 'react'
import { IconDropdown } from '../../../components/atoms/dropdown/Dropdown'
import { IconInput,CurrencyFormatInput } from '../../../components/atoms/input/Input'
import './psCotizacion.css'
import MINUS_ICON_SVG from '../../../assets/icons/svg/minus.svg'


const PsMaterialesTable = ({
    callbackMaterail,
    defaultValueMaterial,
    callbackQuantity,
    defaultValueQuantity,
    callbackQuantityMaterial,
    defaultValueQuantityMaterial,
    callbackUnitMeasure,
    defaultValueUnitMeasure,
    callbackUnitPrice,
    defaultValueUnitPrice,
    defaultValueTotal,
    listUnitsMeasures,
    disable
}) => {

    return (

        <div className='materiales-tabla-container'>

            <div className='row-container'>
                <div className='table-left-side top-left-radius'>
                    Material
                </div>
                <div className='table-right-side'>
                    <div className='table-input-container'>
                        <IconInput
                            callback={callbackMaterail}
                            defaultValue={defaultValueMaterial}
                            disable={disable}
                            icon={MINUS_ICON_SVG}
                            colorStyle='table-icon-color'
                            type='text' />
                    </div>
                </div>
            </div>

            <div className='row-container'>
                <div className='table-left-side '>
                    Unidades
                </div>
                <div className='table-right-side'>
                    <div className='table-input-container'>
                        <IconInput
                            disable={disable}
                            callback={callbackQuantity}
                            defaultValue={defaultValueQuantity}
                            icon={MINUS_ICON_SVG}
                            colorStyle='table-icon-color' />
                    </div>
                </div>
            </div>

            <div className='row-container'>
                <div className='table-left-side '>
                    Cantidad
                </div>
                <div className='table-right-side'>
                    <div className='table-input-container'>
                        <IconInput
                            disable={disable}
                            callback={callbackQuantityMaterial}
                            defaultValue={defaultValueQuantityMaterial}
                            icon={MINUS_ICON_SVG}
                            colorStyle='table-icon-color' />
                    </div>
                </div>
            </div>

            <div className='row-container'>
                <div className='table-left-side '>
                    Unidad de medida
                </div>
                <div className='table-right-side'>
                    <div className='table-input-container'>
                        <IconDropdown
                            disable={disable}
                            callback={callbackUnitMeasure}
                            defaultValue={defaultValueUnitMeasure}
                            defaultSelected={defaultValueUnitMeasure}
                            icon={MINUS_ICON_SVG}
                            colorStyle='table-icon-color'
                            items={
                                listUnitsMeasures.map((state, index) => (
                                    <option
                                        value={state.name}
                                        key={index}
                                        className='rol-option'>{state.name}
                                    </option>)
                                )}
                        />
                    </div>
                </div>
            </div>

            <div className='row-container'>
                <div className='table-left-side '>
                    Precio unitario
                </div>
                <div className='table-right-side'>
                    <div className='table-input-container'>
                        <CurrencyFormatInput
                        disable={disable}
                        callback={callbackUnitPrice}
                        defaultValue={defaultValueUnitPrice}
                        icon={MINUS_ICON_SVG}
                        colorStyle='table-icon-color' 
                        />
                    </div>
                </div>
            </div>

            <div className='row-container'>
                <div className='table-left-side bottom-left-radius'>
                    Precio conjunto
                </div>
                <div className='table-right-side'>
                    <div className='table-input-container'>
                    <CurrencyFormatInput
                        disable={true}
                        defaultValue={defaultValueTotal}
                        icon={MINUS_ICON_SVG}
                        colorStyle='table-icon-color' 
                        />
                    </div>
                </div>
            </div>

        </div>

    )
}

export default PsMaterialesTable