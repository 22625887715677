import React, {useEffect} from 'react'
import './psRegister.css'
import { Appbar,AppbarLogo } from '../../../components/atoms/appbar/Appbar'
import { CompleteCard } from '../../../components/atoms/card/Card'
import { PrimaryButton } from '../../../components/atoms/button/Button'

const SpecialityInfoServiceProvider = ({ 
    type,
    listServices,
    callbackPushNavigator,
    isSelectedItem,
    callbackSelectedService,
    showButton
}) => {
    return (
        <div className='usertype-main-container appbar-no-background'>
            <AppbarLogo />

        <div className='container-speciality'>

            <Appbar smallText={type} bigText='Especialidad de trabajo' />

            <div className='text-usertype-small'>

            (Selecciona una o varias)
                
            </div>

            <div className='ps-options-menu-container'>
                {
                    listServices.map((service) =>
                        <div key={service.id} className='options-items-container'>
                            <CompleteCard
                                isSelected={() => isSelectedItem(service.id)}
                                icon={service.icon}
                                id={service.id}
                                callback={callbackSelectedService}
                                title={service.title}
                                text={service.text} />
                        </div>
                    )
                }

            </div>

            {
                showButton ? 
                    <div className='register-botton-menu'>
                        <PrimaryButton txt='Hacer registro' callback={callbackPushNavigator} />
                    </div>:
                    null
            }

        </div>
        
        </div>
        
    )
}

export default SpecialityInfoServiceProvider