import React from "react";
import "./mediaSource.css";
import PDF_SVG from "../../../assets/icons/svg/pdf-svg-white.svg";
import EXCEL_SVG from "../../../assets/icons/png/excelSVG.png";
import VIDEO_SVG from "../../../assets/icons/svg/MdiVideoVintage.svg";
const IMAGE_SRC = "image";
const PDF_SRC = "pdf";
const WIDTH_VIDEO = "200";
const EXCEL_SRC = "excel";

const ImageSRC = ({ file = {}, url, index, callback, big }) => {
  const { name = "" } = file;
  return (
    <>
      {big ? (
        <div className="bigContentWrapper">
          <img
            className={`mediaContent bigMedia`}
            src={url}
            alt={name}
            key={index}
            onClick={callback}
          />
        </div>
      ) : (
        <img
          className={`mediaContent`}
          src={url}
          alt={name}
          key={index}
          onClick={callback}
        />
      )}
    </>
  );
};

const PdfSRC = ({ file = {}, url, index, callback, big }) => {
  const { name = "" } = file;
  const handleClick = () => {
    window.open(url, "_blank");
  };
  return (
    <>
      {big ? (
        <div className="bigContentWrapper">
          <img
            className={`mediaContent bigMedia`}
            src={PDF_SVG}
            alt={name}
            key={index}
            onClick={handleClick}
          />
        </div>
      ) : (
        <img
          className={`mediaContent`}
          src={PDF_SVG}
          alt={name}
          key={index}
          onClick={handleClick}
        />
      )}
    </>
  );
};

const VideoSRC = ({ file = {}, url, index, callback, widthVideo, big }) => {
  const { name = "" } = file;
  const handleClick = () => {
    window.open(url, "_blank");
  };
  return (
    <>
      {big ? (
        <div className="bigContentWrapper">
          <img
            className={`mediaContent bigMedia`}
            src={VIDEO_SVG}
            alt={name}
            key={index}
            onClick={handleClick}
          />
        </div>
      ) : (
        <img
          className={`mediaContent`}
          src={VIDEO_SVG}
          alt={name}
          key={index}
          onClick={handleClick}
        />
      )}
    </>
  );
};

const ExcelSRC = ({ file = {}, url, index, callback, big }) => {
  const { name = "" } = file;
  const handleClick = () => {
    window.open(url, "_blank");
  };
  return (
    <>
      {big ? (
        <div className="bigContentWrapper">
          <img
            className={`mediaContent bigMedia`}
            src={EXCEL_SVG}
            alt={name}
            key={index}
            onClick={handleClick}
          />
        </div>
      ) : (
        <img
          className={`mediaContent`}
          src={EXCEL_SVG}
          alt={name}
          key={index}
          onClick={handleClick}
        />
      )}
    </>
  );
};

const WrapSource = ({ type, file, url, index, callback, big }) => {
  if (type === IMAGE_SRC)
    return <ImageSRC file={file} url={url} callback={callback} big={big} />;
  else if (type === PDF_SRC)
    return <PdfSRC file={file} url={url} callback={callback} big={big} />;
  else if (type === EXCEL_SRC)
    return <ExcelSRC file={file} url={url} callback={callback} big={big} />;
  else
    return (
      <VideoSRC
        file={file}
        url={url}
        index={index}
        callback={callback}
        widthVideo={WIDTH_VIDEO}
        big={big}
      />
    );
};

const WrapSourceURI = ({ type, file, url, index, callback, big }) => {
  if (type === IMAGE_SRC)
    return <ImageSRC file={file} url={url} callback={callback} big={big} />;
  else if (type === EXCEL_SRC)
    return <ExcelSRC file={file} url={url} callback={callback} big={big} />;
  else
    return (
      <VideoSRC
        file={file}
        url={url}
        index={index}
        callback={callback}
        widthVideo={WIDTH_VIDEO}
        big={big}
      />
    );
};

export { WrapSource, WrapSourceURI };
