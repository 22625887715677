import { useState } from "react";
import { camelCase } from "../../../res/utils";
import {
  CheckboxInputNumbers,
  CheckboxInputSpace,
  CheckboxInputSubActivity,
} from "../input/Input";
import IntegerInput from "../input/IntegerInput";
import SliderInput from "../input/SliderInput";
import "./accordion.css";
const Accordion = ({
  indexParent,
  indexActivity,
  title,
  activities,
  spaces,
  callbackSelectTask,
  shouldReset,
  setShowAlertMessage,
  setMessageAlert,
  snapshotStateValue,
  snapshotStateCallback,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activitiesSpacesParents, setActivitiesSpaceParents] = useState(
    snapshotStateValue || spaces
  );
  const [statusActivities, setStatusActivities] = useState(activities);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleActivitiesSpacesParents = (spaceIndex, lastValueComplete) => {
    let spacesActivities = [...activitiesSpacesParents];

    spacesActivities[spaceIndex].completeValue = lastValueComplete;

    setActivitiesSpaceParents(spacesActivities);
    snapshotStateCallback(indexActivity, spacesActivities);
  };

  const handleStatusActivities = (indexActivity, lastValueComplete) => {
    let statusActivitiesCopy = [...statusActivities];

    let activity = statusActivitiesCopy[indexActivity];

    activity.completeValue = lastValueComplete;

    setStatusActivities(statusActivitiesCopy);
  };

  return (
    <div className="accordion">
      <div className="accordion-header" onClick={toggleAccordion}>
        <span>{camelCase(title)}</span>
        <span className={`accordion-icon ${isOpen ? "open" : ""}`}></span>
      </div>
      {isOpen && (
        <div className="accordion-content">
          {activities
            ? activities.map((activity, indexActivity) => {
                if (activity.spaces?.length > 0) {
                  return (
                    <Accordion
                      indexParent={indexParent}
                      indexActivity={indexActivity}
                      spaces={activity.spaces}
                      title={camelCase(activity.title)}
                      callbackSelectTask={callbackSelectTask}
                      shouldReset={shouldReset}
                      setShowAlertMessage={setShowAlertMessage}
                      setMessageAlert={setMessageAlert}
                      snapshotStateCallback={handleStatusActivities}
                      snapshotStateValue={
                        statusActivities[indexActivity].completeValue ||
                        activity.spaces
                      }
                    />
                  );
                } else {
                  if (activity.hidden === true) return null;
                  else
                    return (
                      <CheckboxInputSubActivity
                        indexParent={indexParent}
                        index={indexActivity}
                        callbackSelectTask={
                          callbackSelectTask.callbackSubActivity
                        }
                        title={camelCase(activity.title)}
                        isSelected={activity.isCompleted}
                        isAvailable={activity.isAvailable}
                        shouldReset={shouldReset}
                        isCanSelected={activity.isCanSelected}
                        setShowAlertMessage={setShowAlertMessage}
                        setMessageAlert={setMessageAlert}
                        handleInputSpaceChange={handleStatusActivities}
                        valueComplete={
                          statusActivities[indexActivity].completeValue || false
                        }
                      />
                    );
                }
              })
            : spaces.map((space, indexSpace) => {
                const valueComplete = parseFloat(
                  space.progress.valueComplete || 0
                );
                const value = space.progress.value || 0;
                if (
                  space.isCompleted &&
                  (space.form_type === "slide" ||
                    (space.form_type === "number" &&
                      space.group !== "demoliciones"))
                ) {
                  return (
                    <CheckboxInputSpace
                      indexParent={indexParent}
                      index={indexActivity}
                      indexSpace={indexSpace}
                      callbackSelectTask={callbackSelectTask.callbackSpace}
                      title={`${camelCase(space.title)}`}
                      info={`ID: ${space.id}`}
                      isSelected={space.isCompleted}
                      isAvailable={space.isAvailable}
                      shouldReset={shouldReset}
                      isCanSelected={false}
                      setShowAlertMessage={setShowAlertMessage}
                      setMessageAlert={setMessageAlert}
                    />
                  );
                } else {
                  switch (space.form_type) {
                    case "check":
                      return (
                        <CheckboxInputSpace
                          indexParent={indexParent}
                          index={indexActivity}
                          indexSpace={indexSpace}
                          callbackSelectTask={callbackSelectTask.callbackSpace}
                          title={`${camelCase(space.title)}`}
                          info={`ID: ${space.id}`}
                          isSelected={space.isCompleted}
                          isAvailable={space.isAvailable}
                          shouldReset={shouldReset}
                          isCanSelected={space.isCanSelected}
                          handleInputSpace={handleActivitiesSpacesParents}
                          valueSpace={
                            activitiesSpacesParents[indexSpace]
                              ?.completeValue || 0
                          }
                          setShowAlertMessage={setShowAlertMessage}
                          setMessageAlert={setMessageAlert}
                        />
                      );
                    case "slide":
                      return (
                        <SliderInput
                          title={`${space.title}`}
                          units={space.units}
                          min={valueComplete}
                          max={value}
                          step={0.000001}
                          activityGroupIndex={indexParent}
                          activityIndex={indexActivity}
                          spaceIndex={indexSpace}
                          initialValue={valueComplete}
                          onChange={callbackSelectTask.callbackSpaceNumber}
                          onSpaceChange={handleActivitiesSpacesParents}
                          valueComplete={
                            activitiesSpacesParents[indexSpace]
                              ?.completeValue || 0
                          }
                        />
                      );

                    case "number":
                      if (space.group !== "demoliciones") {
                        return (
                          <IntegerInput
                            title={`${camelCase(space.title)}`}
                            maxValue={value}
                            initialValue={space.progress.valueComplete || 0}
                            activityGroupIndex={indexParent}
                            activityIndex={indexActivity}
                            spaceIndex={indexSpace}
                            handleInput={callbackSelectTask.callbackSpaceNumber}
                            valueComplete={
                              activitiesSpacesParents[indexSpace]
                                ?.completeValue || 0
                            }
                            handleSpace={handleActivitiesSpacesParents}
                          />
                        );
                      } else {
                        return (
                          <CheckboxInputNumbers
                            title={`${camelCase(space.title)}`}
                            maxValue={value}
                            initialValue={space.progress.valueComplete || 0}
                            activityGroupIndex={indexParent}
                            activityIndex={indexActivity}
                            spaceIndex={indexSpace}
                            handleInput={callbackSelectTask.callbackSpaceNumber}
                            valueComplete={
                              activitiesSpacesParents[indexSpace]
                                ?.completeValue || 0
                            }
                            handleSpace={handleActivitiesSpacesParents}
                          />
                        );
                      }
                    default:
                      return (
                        <CheckboxInputSpace
                          indexParent={indexParent}
                          index={indexActivity}
                          indexSpace={indexSpace}
                          callbackSelectTask={callbackSelectTask.callbackSpace}
                          title={`${camelCase(space.title)}`}
                          info={`ID: ${space.id}`}
                          isSelected={space.isCompleted}
                          isAvailable={space.isAvailable}
                          shouldReset={shouldReset}
                          isCanSelected={space.isCanSelected}
                          setShowAlertMessage={setShowAlertMessage}
                          handleInputSpace={handleActivitiesSpacesParents}
                          valueSpace={
                            activitiesSpacesParents[indexSpace]
                              ?.completeValue || 0
                          }
                          setMessageAlert={setMessageAlert}
                        />
                      );
                  }
                }
              })}
        </div>
      )}
    </div>
  );
};

export default Accordion;
