import React, { useEffect } from "react";
import HeaderInfoContainer from "./HeaderInfoContainer";
import ChatDescription from "./UnexpectedDetails";
import EvidenceEntry from "./EvidenceEntry";
import { getEntryData } from "services-controller/database";
import SkeletonHeaderChat from "./SkeletonHeaderChat";
import { useDispatch } from "react-redux";
import { updateStateEntry } from "reducers/state.entry.reducer";
import { updateEntry } from "services-controller/http.cleinte.controller";

const HeaderChatController = ({ entryId, serviceId, userType, history }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(true);
  const [entry, setEntryData] = React.useState(null);

  const fetchEntryData = async () => {
    const entryData = await getEntryData(serviceId, entryId);
    setEntryData(entryData);
    dispatch(updateStateEntry(entryData, serviceId, userType));
    setLoading(false);
  };

  const callbackHiddenEntry = async () => {
    const newEntry = { ...entry, is_visible: !entry.is_visible };
    try {
      await updateEntry(serviceId, entryId, "admin", newEntry);
    } catch (error) {
      console.error(error);
      throw error;
    }
    history.push(`/services/bitacle/${serviceId}`);
  };

  useEffect(() => {
    setLoading(true);
    fetchEntryData();
  }, [entryId, serviceId]); // eslint-disable-line

  return loading ? (
    <SkeletonHeaderChat />
  ) : (
    <>
      <HeaderInfoContainer
        activities={entry?.activities_selected}
        entryIsVisble={entry?.is_visible}
        callbackHiddenEntry={callbackHiddenEntry}
        entryId={entryId}
        entryTitle={entry?.title}
        entryDate={entry?.date}
      />
      <ChatDescription
        message_unexpected={entry?.message_unexpected}
        description={entry?.description}
      />
      <EvidenceEntry resources={entry?.resources} />
    </>
  );
};

export default HeaderChatController;
