import { Button, ConfigProvider, Modal } from "antd";
import { ReactComponent as BellLightIcon } from "assets/icons/svg/bell-light.svg";
import { ReactComponent as TimelineIcon } from "assets/icons/svg/map-icon.svg";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ALL_SERVICES_ADDED,
  CHECK_QR_CODE,
  NOTIFICATIONS,
  PROFILE,
  PS_BASE_MENU_PATH,
  ROUTE_TIMELINE,
  SERVICES
} from "router/routes";
import "./NavBar.css";

import AddNewOption from "./components/AddNewOptions";
import { ScanOutlined, SendOutlined, UserOutlined } from "@ant-design/icons";

export default function NavBar() {
  const history = useHistory();
  const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false);

  return (
    <>
      <nav className="nav-bar">
        <ConfigProvider
          theme={{
          }}
        >
          <Button
            size="large"
            onClick={() => {
              history.push(CHECK_QR_CODE);
            }}
            data-active={history.location.pathname.includes(CHECK_QR_CODE)}
            className="nav-bar-opt"
          >
            <ScanOutlined />
          </Button>
          <Button
            size="large"
            onClick={() => {
              history.push(ROUTE_TIMELINE);
            }}
            data-active={history.location.pathname.includes(ROUTE_TIMELINE)}
            className="nav-bar-opt"
          >
            <TimelineIcon />
          </Button>

          <Button
            size="large"
            shape="circle"
            onClick={() => { history.push(ALL_SERVICES_ADDED) }}
            className="nav-bar-opt-center"
          >
            <SendOutlined />
          </Button>


          <Button
            size="large"
            onClick={() => {
              history.push(PS_BASE_MENU_PATH + NOTIFICATIONS);
            }}
            data-active={history.location.pathname.includes(NOTIFICATIONS)}
            className="nav-bar-opt"
          >
            <BellLightIcon />
          </Button>

          <Button
            size="large"
            onClick={() => {
              history.push(PS_BASE_MENU_PATH + PROFILE);
            }}
            data-active={history.location.pathname.includes(PROFILE)}
            className="nav-bar-opt"
          >
            <UserOutlined />
          </Button>
        </ConfigProvider>
      </nav>

      <Modal
        centered
        closable
        maskClosable
        footer={null}
        title={null}
        open={isAddNewModalOpen}
        onCancel={() => {
          setIsAddNewModalOpen(false);
        }}
        rootClassName="options-modal"
      >
        <AddNewOption />
      </Modal>
    </>
  );
}
