import React from 'react'
import './newServices.css'
import { Appbar, AppbarLogo } from '../../components/atoms/appbar/Appbar'
import { IconButton, CategoryRoundedButton, AddSquareButton } from '../../components/atoms/button/Button'
import POINTING_ICON_SVG from '../../assets/icons/svg/finger-point.svg'
import CHAT_ICON_SVG from '../../assets/icons/svg/chat.svg'
import IMAGE_ICON_SVG from '../../assets/icons/svg/image.svg'
import { BaseTextarea } from '../../components/atoms/textarea/Textarea'
import { FilePicker } from 'react-file-picker'
import { WarpSoruce } from '../../components/atoms/media/MediaSource'
import PLUS_ICON_SVG from '../../assets/icons/svg/plus-square.svg'

const NewServices = ({
    listResources,
    isAvailableButton,
    listServices,
    callbackPreviusService,
    callbackInitDate,
    callbackCategorySelected,
    callbackAddFile,
    callbackAddFileError,
    callbackNoSelected,
    callbackCgangeTextDescription,
    callbackViewSource,
    deleteMediaSource,
    textDescription,
    instabasica,
    handlePopupInstabasica
}) => {

    const awaitInitRequestDate = async (callbackPreviusService) => {
        await callbackInitDate()
        callbackPreviusService()
    }
    const handleNextService = () => {
        awaitInitRequestDate(callbackPreviusService)
    }

    return (

        <div className='new-service-container'>

            <AppbarLogo />

            <div className='desktop-container'>

                <div className='new-service-appbar-container'>
                    <Appbar smallText='Solicitud de servicio' bigText='Detalles servicio' />
                </div>

                <div className='container-general'>

                    <div className='categoria-container'>

                        <div className='new-service-text'>
                            <div>
                                <img src={POINTING_ICON_SVG} id='new-service-icon' alt={'POINTING_ICON_SVG'} />
                            </div>
                            <div>Elegir categoría</div>
                        </div>

                        <div className='categoria-types'>
                            {listServices.map((obj) => {

                                return (
                                    <div key={obj.id} className='new-service-type'>
                                        <CategoryRoundedButton
                                            id={obj.id}
                                            isSelected={obj.is_selected}
                                            txt={obj.title}
                                            icon={obj.icon}
                                            callbackNoSelected={() => callbackNoSelected(obj.id)}
                                            callback={() => callbackCategorySelected(obj.id)} />
                                    </div>
                                )
                            }
                            )}

                        </div>

                    </div>

                    {
                        instabasica ?
                            <div className='instabasica-container' onClick={handlePopupInstabasica}>
                                <img src={PLUS_ICON_SVG} className='add-instabasica-icon' />
                                <div className='instabasica-text'>Agregar item de instabásicas</div>
                            </div>
                            :
                            null
                    }


                    <div className='descripcion-container'>

                        <div className='new-service-text'>
                            <div>
                                <img src={CHAT_ICON_SVG} id='new-service-icon' alt={'CHAT_ICON_SVG'} />
                            </div>
                            <div>Descripción del daño</div>
                        </div>

                        <div className='descripcion-input'>
                            <BaseTextarea style='service-textarea' value={textDescription} text={textDescription} callback={callbackCgangeTextDescription} placeholder='Dónde se encuentra el daño y qué sucede' />
                        </div>

                    </div>

                    <div className='dano-foto-container'>

                        <div className='new-service-text'>
                            <div>
                                <img src={IMAGE_ICON_SVG} id='new-service-icon' alt={'POINTING_ICON_SVG'} />
                            </div>
                            <div>Añade fotos/vídeos</div>
                        </div>

                        <div className='dano-foto'>
                            <FilePicker
                                maxSize={100}
                                onChange={callbackAddFile}
                                onError={callbackAddFileError}
                            >
                                <div className='add-foto-container'>
                                    <AddSquareButton />

                                </div>
                            </FilePicker>


                            {
                                listResources.map((src, index) => {

                                    const { type, url } = src
                                    const file = fetch(url).then(r => r.blob())

                                    return (
                                        <div key={index} className='foto-source-container'>
                                            <WarpSoruce callback={() => callbackViewSource(src)} type={type} file={file} url={url} index={index} />
                                            <div className='deletePhoto' onClick={() => deleteMediaSource(index)}>x</div>
                                        </div>
                                    )
                                })
                            }


                        </div>


                    </div>

                    {
                        isAvailableButton ?
                            <div className='next-step-container'>
                                <IconButton callback={handleNextService} />
                            </div> :
                            null
                    }
                </div>

            </div>

        </div>
    )
}

export default NewServices