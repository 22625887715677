import React from 'react';
import './rolesDropDown.css';

function RolesDropDown({ options, selectedOption, onSelect }) {
  return (
    <select value={selectedOption} onChange={(event) => onSelect({
      id: event.target.value
    })}>
      {options.map((option) => (
        <option
          id={option}
          key={option}
          value={option}
          className={option === selectedOption ? 'selected-option' : ''}
        >
          {option}
        </option>
      ))}
    </select>
  );
}

export default RolesDropDown;
