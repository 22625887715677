import isEqual from "lodash";
import moment from "moment";
export const countDecimal = (numero) => {
  const numStr = String(numero);

  const puntoDecimal = numStr.indexOf(".");

  if (puntoDecimal === -1) return 0;

  return numStr.length - puntoDecimal - 1;
};

export function camelCase(str) {
  if (typeof str !== "string") {
    return "";
  }
  if (str.includes(" ")) {
    const words = str.split(" ");

    const camelCasedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    return camelCasedWords.join(" ");
  } else {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
}
export const capitalizeWord = (month) => {
  return month ? month.charAt(0).toUpperCase() + month.slice(1) : "Mes";
};

export const arePropsEqual = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
};

export function compararFechas(a, b) {
  const date1 = a?.date?.split("-");
  const date2 = b?.date?.split("-");
  if (!date1 || !date2) {
    return 0;
  }
  const dateHours1 = date1[5].split(":");
  const dateHours2 = date2[5].split(":");

  if (!dateHours1 || !dateHours2) {
    return 0;
  }

  const fechaA = new Date(
    date1[4],
    date1[3],
    date1[1],
    dateHours1[0],
    dateHours1[1],
    dateHours1[2]
  );
  const fechaB = new Date(
    date2[4],
    date2[3],
    date2[1],
    dateHours2[0],
    dateHours2[1],
    dateHours2[2]
  );

  if (fechaA.getTime() < fechaB.getTime()) {
    return 1;
  } else if (fechaA.getTime() > fechaB.getTime()) {
    return -1;
  } else {
    return 0;
  }
}

export function getDay(dateDay) {
  let date = new Date(dateDay);
  // Obtiene la fecha actual
  const today = new Date();

  // Compara si el año, mes y día de la fecha dada coinciden con los de hoy
  if (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  ) {
    return "Hoy";
  }

  // Obtiene la fecha de ayer restando un día a la fecha actual
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  // Compara si el año, mes y día de la fecha dada coinciden con los de ayer
  if (
    date.getFullYear() === yesterday.getFullYear() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getDate() === yesterday.getDate()
  ) {
    return "Ayer";
  }

  // La fecha no es ni hoy ni ayer
  return -1;
}

export function getDayStringFull(day) {
  switch (day) {
    case "lu":
      return "Lun";
    case "ma":
      return "Mar";
    case "mi":
      return "Mie";
    case "ju":
      return "Jue";
    case "vi":
      return "Vie";
    case "sá":
      return "Sáb";
    case "do":
      return "Dom";
    default:
      return "day";
  }
}

export const newDateWithTime = () => {
  const dateForm = moment();
  const dateFormated = dateForm.utcOffset(-5);
  const dateFormatedd = dateFormated.format("YYYY-MM-DD HH:mm:ss");
  return dateFormatedd;
};

export const getStringMonthInit = (monthNumber) => {
  switch (monthNumber) {
    case 0:
      return "Ene";
    case 1:
      return "Feb";
    case 2:
      return "Mar";
    case 3:
      return "Abr";
    case 4:
      return "May";
    case 5:
      return "Jun";
    case 6:
      return "Jul";
    case 7:
      return "Ago";
    case 8:
      return "Sep";
    case 9:
      return "Oct";
    case 10:
      return "Nov";
    case 11:
      return "Dic";
    default:
      return "";
  }
};

export function shellSort(arr, compareFunction) {
  let n = arr.length;

  for (let gap = Math.floor(n / 2); gap > 0; gap = Math.floor(gap / 2)) {
    for (let i = gap; i < n; i += 1) {
      let temp = arr[i];

      let j;
      for (
        j = i;
        j >= gap && compareFunction(arr[j - gap], temp) > 0;
        j -= gap
      ) {
        arr[j] = arr[j - gap];
      }

      arr[j] = temp;
    }
  }
  return arr;
}


//return an js date
export const migrateLegacyDateFormat = (legacyDate) => {
  const months = {
    enero: 0, january: 0,
    febrero: 1, february: 1,
    marzo: 2, march: 2,
    abril: 3, april: 3,
    mayo: 4, may: 4,
    junio: 5, june: 5,
    julio: 6, july: 6,
    agosto: 7, august: 7,
    septiembre: 8, september: 8,
    octubre: 9, october: 9,
    noviembre: 10, november: 10,
    diciembre: 11, december: 11,
  };

  const parts = legacyDate.split('-');
  if (parts.length !== 6) {
    throw new Error("Invalid date format");
  }

  const day = parseInt(parts[1], 10);
  const month = months[parts[2].toLowerCase()];
  const year = parseInt(parts[4], 10);
  const [hour, minutes, seconds] = parts[5].split(':').map(Number);


  if (isNaN(day) || isNaN(year) || isNaN(hour) || isNaN(minutes) || isNaN(seconds)) {
    throw new Error("Invalid numeric values in date");
  }

  if (month === undefined) {
    alert(legacyDate);
    throw new Error("Invalid month");
  }

  const date = new Date(year, month, day, hour, minutes, seconds);

  if (isNaN(date.getTime())) {
    throw new Error("Invalid date created");
  }

  return date;
};

// @params date1, date2: string
// @format date: YYYY-MM-DD HH:mm:ss
//  2023-12-15 10:30:58
export function compareDates(date1, date2) {
  const parsedDate1 = new Date(date1);
  const parsedDate2 = new Date(date2);

  if (isNaN(parsedDate1.getTime()) || isNaN(parsedDate2.getTime())) {
    throw new Error("Fecha inválida proporcionada");
  }

  if (parsedDate1.getTime() > parsedDate2.getTime()) {
    return -1;
  } else if (parsedDate1.getTime() < parsedDate2.getTime()) {
    return 1;
  } else {
    return 0;
  }
}

export const hasCarpentyGroupValidation = (activities) => {
  return activities.some((activity) => activity.group === "carpinteria");
};

export const moneyFormat = (value = "") => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencyDisplay: "narrowSymbol",
  }).format(value);
};

export const dateTimeFormater = ({ date = "", ...attr }) => {

  if (!date) {
    return "Fecha no válida";
  }
  let auxDate = new Date(date);
  const parts = date.split('-');
  if (parts.length === 6) {

    auxDate = migrateLegacyDateFormat(date)

  }

  if (!(auxDate instanceof Date) || isNaN(auxDate.getTime())) {
    return "Fecha no válida";
  }

  return Intl.DateTimeFormat("es-CO", {
    ...attr,
  }).format(auxDate);
};

export function getEmisorFromUser(data) {
  const emisor = {
    id: data?.id,
    name: data?.name || (data?.register_data && data?.register_data?.name),
    thumbnail: data?.imageProfile || (data?.register_data && data?.register_data?.thumbnail),
    user_type: data?.user_type || (data?.user_type === undefined ? null : data?.user_type)
  };
  return emisor;
}


let interval = null
export const customTimeout = ({ func = () => { }, time = 1000 }) => {
  clearInterval(interval)

  interval = setTimeout(() => func(), time);
}

export const isALink = (link = "") => {
  const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/gi
  return regex.test(link)
}

export const timeAgo = (date = "") => {
  const initialDate = new Date(date);
  const currentDate = new Date();

  if (!date || !(initialDate instanceof Date) || isNaN(initialDate.getTime())) {
    return "Fecha con formato incorrecto";
  }

  if (initialDate.getTime() > currentDate.getTime()) {
    return "Fecha mayor a la fecha actual"
  }

  const milisecondsDifference = currentDate.getTime() - initialDate.getTime();
  const seconds = milisecondsDifference / 1000;
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30.416666);
  const years = Math.floor(months / 12);
  const rtf = new Intl.RelativeTimeFormat("es");
  const units = [
    { value: years, text: rtf.format(-years, "year") },
    { value: months, text: rtf.format(-months, "months") },
    { value: days, text: rtf.format(-days, "day") },
    { value: hours, text: rtf.format(-(hours % 24), "hour") },
    { value: minutes, text: rtf.format(-(minutes % 60), "minute") },
    { value: seconds, text: rtf.format(-Math.floor(seconds % 60), "second") },
  ];

  for (const nts of units) {
    if (nts.value) {
      return nts.text;
    }
  }
};
