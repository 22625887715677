import React from "react";
import { withRouter } from "react-router-dom";
import { BaseCard } from "../Card";
import "../card.css";

const PsBigCard = ({ icon, text, id, history }) => {
  const servicesNavigator = () => {
    history.push(`${id}`);
  };

  return (
    <BaseCard
      style="bigCard psStyleBigCard"
      content={
        <React.Fragment>
          <img className="iconBig" src={icon} alt={text} />
          <p className="textBig">{text}</p>
        </React.Fragment>
      }
      callback={servicesNavigator}
      id={id}
    />
  );
};

export default withRouter(PsBigCard);
