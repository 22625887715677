import React from 'react'
import { TwoButtonsPopup } from '../components/atoms/popup/Popup'

const PopupSimpleTextTwoOptions = ({ object }) => {

    const {
        callbackRegisterPositive,
        callbackRegisterNegative,
        message,
        textPositive,
        textNegative
    } = object

    return (
        <TwoButtonsPopup text={message}
            btnOneText={textNegative}
            btnTwoText={textPositive}
            btnOneColor='btnPurple'
            btnTwoColor='btnYellow'
            callbackTwo={callbackRegisterPositive}
            callbackOne={callbackRegisterNegative} />
    )
}

export {
    PopupSimpleTextTwoOptions
}

