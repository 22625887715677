import React, { useState, useEffect } from 'react'
import StateButtonModalHook from '../../../modal/StateButtonModalHook.jsx'
import { withRouter } from 'react-router-dom'
import PsAssistantsList from './PsAssistantsList.jsx'
import { PopupSuccessEvent } from '../../../popups/psPopups/PopupSuccessEvent.jsx'
import PopupInvitationFormController from '../../../popups/psPopups/PopupInvitationForm.jsx'
import { updateStageByServiceId, getAllHelpers, userManagementsById, deleteManagement,getServiceHelpers,updateServiceHelpers,removeServiceHelpers } from '../../../services-controller/http.cleinte.controller'
import { currentUser } from '../../../services-firebase/auth'
import { PRINCIPAL_PATH, MANAGEMENT_EDIT_PATH, BASE_MENU_PATH, PROFILE } from '../../../router/routes.js'
import PopupOptions from '../../../popups/PopupOptions.jsx'
import { PopupTwoOptionWithIcon } from '../../../popups/PopupTwoButtons.jsx'
import TRASH_ICON_SVG from '../../../assets/icons/svg/trash.svg'
import { updateManagement } from '../../../reducers/form.management.reducer'
import { useDispatch, useSelector } from 'react-redux'


const PsAssistantsListController = ({ history, object }) => {

    const dispatch = useDispatch()
    const form_management = useSelector(state => state.form_management)


    const [assistantsList, setAssistantsList] = useState([])

    const [requestAssistants, setRequestAssistants] = useState(false)

    const [inviteAssistant, setInviteAssistant] = useState(false)

    const [invitationSent, setInvitationSent] = useState(false)

    const [showPopupOptions, setShowPopupOptions] = useState(false)

    const [showDeleteVerification, setShowDeleteVerification] = useState(false)

    const [selectedHelpers, setSelectedHelpers] = useState([])

    const [removeAssistants, setRemoveAssistants] = useState(false)

    const [serviceHelpers,setServiceHelpers] = useState([])

    const {
        confirmDelete,
        callbackCloseAssistantsPopup,
        service,
        id,
        removeHelpers
    } = object

    const {
        unique_key
    } = form_management

    useEffect(() => {

        fecthMyHelpers()

    }, [])

    const fecthMyHelpers = async () => {

        const uid = currentUser().uid

        if (uid === null) {
            history.push(PRINCIPAL_PATH)
        } else {
            if (object.user !== undefined) {
                const resList = await userManagementsById(uid)
                setAssistantsList(typeof resList === 'string' ? [] : resList.object)
            } else {
                const resList = await getAllHelpers(uid)
                setAssistantsList(typeof resList === 'string' ? [] : resList)
            }

        }

        const serviceHelpers = await getServiceHelpers(id)
        setServiceHelpers(serviceHelpers)

    }


    const callbackSelectedItem = (object) => {

            const aux = [...selectedHelpers]
            const index = selectedHelpers.indexOf(object)

            if (index === -1) {
                setSelectedHelpers([...selectedHelpers, object])
            } else {
                aux.splice(index, 1)
                setSelectedHelpers([...aux])
            }

    }

    const handleRequestAssistants = async() => {
        const res = await updateServiceHelpers(id,selectedHelpers)
        setServiceHelpers(res)
        setRequestAssistants(true)
    }

    const handleInviteAssistant = () => setInviteAssistant(true)


    const callbackOk = async () => {
        if (service.state.stage === 2) {
            //await updateStageByServiceId(id, 10)
        }
        callbackCloseAssistantsPopup()

    }

    const handleInvitationSent = () => {
        fecthMyHelpers()
        setInviteAssistant(false)
        setInvitationSent(true)
    }

    const showOptions = (object) => {
        dispatch(updateManagement(object))
        setShowPopupOptions(true)
    }

    const callbackEdit = () => {
        history.push(MANAGEMENT_EDIT_PATH)
    }

    const callbackDelete = () => {
        setShowDeleteVerification(true)
    }

    const rejectDelete = () => {
        setShowDeleteVerification(false)
        setShowPopupOptions(false)
    }

    const callbackCloseSuccessInvitation = () => {
        setInvitationSent(false)
    }

    const handleRemoveAssistants = async () => {
        const res = await removeServiceHelpers(id,selectedHelpers)
        setServiceHelpers(res)
        setRemoveAssistants(true)
    }

    return (

        <>
            <PsAssistantsList
                callbackSelectedItem={callbackSelectedItem}
                assistantsList={assistantsList.filter(
                    (assistant)=> !removeHelpers ? !serviceHelpers.includes(assistant.unique_key) : serviceHelpers.includes(assistant.unique_key)            
                )}
                assistantsNumber={selectedHelpers.length}
                handleRequestAssistants={handleRequestAssistants}
                handleInviteAssistant={handleInviteAssistant}
                hideButton={object.id === null}
                bigText={object.user !== undefined ? object.user : 'Ayudantes'}
                showOptions={showOptions}
                removeHelpers={removeHelpers}
                handleRemoveAssistants={handleRemoveAssistants}
            />

            {
                requestAssistants ? <StateButtonModalHook
                    component={PopupSuccessEvent}
                    hook={[requestAssistants, setRequestAssistants]}
                    object={{
                        message: '¡Invitaciones enviadas exitosamente!',
                        callback: callbackOk,
                        btnText: 'Ok '
                    }}
                /> : null

            }

            {
                inviteAssistant ? <StateButtonModalHook
                    component={PopupInvitationFormController}
                    hook={[inviteAssistant, setInviteAssistant]}
                    object={{
                        callback: handleInvitationSent
                    }}
                /> : null

            }

            {
                invitationSent ? <StateButtonModalHook
                    component={PopupSuccessEvent}
                    hook={[invitationSent, setInvitationSent]}
                    object={{
                        message: '¡Invitacion enviada exitosamente!',
                        callback: callbackCloseSuccessInvitation,
                        btnText: 'Ok '
                    }}
                /> : null

            }

            {
                showPopupOptions ? <StateButtonModalHook
                    component={PopupOptions}
                    hook={[showPopupOptions, setShowPopupOptions]}
                    object={{
                        callbackEdit: callbackEdit,
                        callbackDelete: callbackDelete
                    }}
                /> : null

            }

            {
                showDeleteVerification ? <StateButtonModalHook
                    component={PopupTwoOptionWithIcon}
                    hook={[showDeleteVerification, setShowDeleteVerification]}
                    object={{
                        callbackNegative: rejectDelete,
                        callbackPositive: confirmDelete,
                        textPositive: 'Si',
                        textNegative: 'No',
                        popupText: '¿Desea eliminar el encargado seleccionado?',
                        popupIcon: TRASH_ICON_SVG
                    }}
                /> : null

            }

            {
                removeAssistants ? <StateButtonModalHook
                    component={PopupSuccessEvent}
                    hook={[removeAssistants, setRemoveAssistants]}
                    object={{
                        message: '¡Ayudantes removidos exitosamente!',
                        callback: callbackOk,
                        btnText: 'Ok '
                    }}
                /> : null

            }

        </>

    )
}

export default withRouter(PsAssistantsListController)

