import React, { useState, useEffect } from 'react'
import './psProfile.css'
import { withRouter } from 'react-router-dom'
import StateButtonModalHook from '../../../modal/StateButtonModalHook.jsx'
import { PRINCIPAL_PATH } from '../../../router/routes'
import PsButtonNavbar from '../../../components/molecules/buttonNavbar/PsButtonNavbar'
import { Appbar } from '../../../components/atoms/appbar/Appbar'
import USER_ICON_SVG from '../../../assets/icons/svg/user-register.svg'
import DOLLAR_ICON_SVG from '../../../assets/icons/svg/dollar-sign.svg'
import ADD_ICON_SVG from '../../../assets/icons/svg/plus-square.svg'
import { GoalsContainer } from '../../../components/molecules/container/Container'
import { NewIconButton } from '../../../components/atoms/button/Button'
import PopupNewGoal from '../../../popups/psPopups/PopupNewGoal.jsx'
import { currentUser } from '../../../services-firebase/auth'
import { getGoals, createNewGoal, editGoal, deleteGoal } from '../../../services-controller/http.cleinte.controller'
import { DAYS_ARRAY, MONTH_ARRAY, YEARS_ARRAY } from '../../../res/dates.arrays'
import PopupOptions from '../../../popups/PopupOptions'
import { PopupTwoOptionWithIcon } from '../../../popups/PopupTwoButtons.jsx'
import { PopupSuccessEvent } from '../../../popups/psPopups/PopupSuccessEvent.jsx'
import TRASH_ICON_SVG from '../../../assets/icons/svg/trash.svg'
import PopupInvitationCode from '../../../popups/psPopups/PopupInvitationCode.jsx'
import { LoadingPopup } from '../../../components/atoms/popup/Popup'
import ServiceProviderMenuDesktop from '../../../components/molecules/menu/ServiceProviderMenu'
import CurrencyFormat from 'react-currency-format'

const PsGoals = ({ history }) => {

    const [newGoalPopup, setNewGoalPopup] = useState(false)
    const [title, setTilte] = useState('')
    const [amount, setAmount] = useState('')
    const [day, setDay] = useState('')
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')
    const [goals, setGoals] = useState([])
    const [currentGoal, setCurrentGoal] = useState(null)
    const [showOptions, setShowOptions] = useState(false)
    const [showSuccessDeleteEvent, setShowSuccessDeleteEvent] = useState(false)
    const [showDeleteVerification, setShowDeleteVerification] = useState(false)
    const [showEditPopup, setShowEditPopup] = useState(false)
    const [progress, setProgress] = useState(0)
    const [currentGoalIndex, setCurrentGoalIndex] = useState(-1)
    const [progressError, setProgressError] = useState(false)
    const [isLoader, setIsLoader] = useState(false)

    const fetchGoals = async () => {
        try {
            setIsLoader(true)
            const goals = await (getGoals(currentUser().uid))
            setGoals(goals)
            setIsLoader(false)

        } catch (error) {
            history.push(PRINCIPAL_PATH)
        }

    }

    useEffect(() => {
        fetchGoals()
    }, [])

    const calcPercentageCompleted = (totalValue, currentValue) => {
        const progress = (currentValue * 100) / totalValue
        return (
            `${progress}%`
        )
    }

    const newGoal = () => {
        setNewGoalPopup(true)
    }

    const totalAcquired = () => {
        let aquired = 0
        for (let goal of goals) {
            aquired = aquired + parseInt(goal.progress)
        }
        return aquired
    }

    const handleGoalAmount = (values) => {
        const {formattedValue, value} = values;
        //const value = parseInt(e.target.value.replace(/,/gi, ''))
        if (value > 0) {
            setAmount(parseFloat(value))
        } else {
            setAmount(0)
        }
    }

    const handleGoalTitle = (e) => {
        setTilte(e.target.value)
    }

    const handleGoalDay = (e) => {
        setDay(e.target.value)
    }

    const handleGoalMonth = (e) => {
        setMonth(e.target.value)
    }

    const handleGoalYear = (e) => {
        setYear(e.target.value)
    }

    const callbackAddNewGoal = async () => {
        setNewGoalPopup(false)
        setIsLoader(true)
        const object = {
            title: title,
            amount: amount,
            date: `${day}/${month}/${year}`,
            progress: 0
        }
        setGoals([...goals, object])
        await createNewGoal(currentUser().uid, object)
        setIsLoader(false)
    }

    const moreOptions = (index) => {
        setCurrentGoal(goals[index])
        setCurrentGoalIndex(index)
        setProgress(goals[index].progress)
        setShowOptions(true)
    }

    const callbackEdit = () => {
        setShowEditPopup(true)
    }

    const callbackDelete = () => {
        setShowDeleteVerification(true)
    }

    const rejectDelete = () => {
        setShowDeleteVerification(false)
        setShowOptions(false)
    }

    const confirmDelete = async () => {
        setShowDeleteVerification(false)
        setShowOptions(false)
        setIsLoader(true)
        const aux = [...goals]
        const index = aux.indexOf(currentGoal)
        aux.splice(index, 1)
        await deleteGoal(currentUser().uid, currentGoal.unique_key)
        setGoals(aux)
        setIsLoader(false)
        setShowSuccessDeleteEvent(true)
    }

    const callbackCloseSuccessDeleteEvent = () => {
        setShowSuccessDeleteEvent(false)


    }

    const callbackUpdate = async () => {
        setShowEditPopup(false)
        setShowOptions(false)
        setIsLoader(true)
        const aux = [...goals]

        const object = {
            ...currentGoal,
            progress: progress
        }

        await editGoal(currentUser().uid, object, currentGoal.unique_key)

        aux.splice(currentGoalIndex, 1, object)
        setGoals(aux)
        setIsLoader(false)

    }

    const handleUpdateProgress = (values) => {
        const {formattedValue, value} = values;
        //const value = parseInt(e.target.value.replace(/,/gi, ''))
        if (parseFloat(value) > 0) {
            if (parseFloat(value) <= currentGoal.amount) {
                setProgress(parseFloat(value))
                setProgressError(false)
            } else {
                setProgressError(true)
            }
        } else {
            setProgress(0)
        }

    }


    return (

        <div className='usertype-main-container appbar-no-background'>
            <ServiceProviderMenuDesktop/>

            <div className='ps-container'>

                <div className='services-appbar-container'>
                    <Appbar smallText='Listado de tus' bigText='Metas' />
                </div>

                <div className='ps-notifications ps-goals'>

                    <div className='goals-indicator-container'>
                        {/*
                        <div className='goals-user-icon-contatiner'>
                            <img className='goals-user-icon' src={USER_ICON_SVG} />
                        </div>
                        */}

                        <div className='gi-right-container'>

                            <div className='gi-title'>Actualmente, has conseguido:</div>

                            <div className='gi-number-container'>
                                <div className='gi-number-icon-container'>
                                    <img className='gi-number-icon' src={DOLLAR_ICON_SVG} />
                                </div>
                                <div className='gi-number'>{ <CurrencyFormat value={totalAcquired()} thousandSeparator={true} displayType={'text'} />}</div>
                            </div>

                        </div>

                    </div>

                    <div className='documentos-title'>Tus metas</div>

                    <div className='goals-container'>
                        {
                            goals.length > 0 ?


                                goals.map((goal, index) =>
                                    <GoalsContainer
                                        key={index}
                                        title={goal.title}
                                        date={goal.date}
                                        number={index + 1}
                                        percentageCompleted={calcPercentageCompleted(parseInt(goal.amount), parseInt(goal.progress))}
                                        progressValue={goal.progress}
                                        totalValue={goal.amount}
                                        callback={() => { moreOptions(index) }}
                                    />
                                )


                                :
                                null
                        }


                    </div>

                    <div className='register-botton-menu'>
                        <NewIconButton txt='Agregar nueva meta' className='new-entry-button' icon={ADD_ICON_SVG} callback={newGoal} />
                    </div>

                </div>

                <div className='menu-navbar-container'>
                    <PsButtonNavbar />
                </div>

            </div>


            {
                newGoalPopup ? <StateButtonModalHook
                    component={PopupNewGoal}
                    hook={[newGoalPopup, setNewGoalPopup]}
                    object={{
                        callbackAddNewGoal: callbackAddNewGoal,
                        handleGoalAmount: handleGoalAmount,
                        handleGoalTitle: handleGoalTitle,
                        handleGoalDay: handleGoalDay,
                        handleGoalMonth: handleGoalMonth,
                        handleGoalYear: handleGoalYear,
                        title: title,
                        amount: amount,
                        daysArray: DAYS_ARRAY,
                        monthArray: MONTH_ARRAY,
                        yearsArray: YEARS_ARRAY
                    }}


                /> : null
            }

            {
                showOptions ? <StateButtonModalHook
                    component={PopupOptions}
                    hook={[showOptions, setShowOptions]}
                    object={{
                        callbackEdit: callbackEdit,
                        callbackDelete: callbackDelete,
                        textFirstOption: "Actualizar"
                    }}
                /> : null

            }

            {
                showDeleteVerification ? <StateButtonModalHook
                    component={PopupTwoOptionWithIcon}
                    hook={[showDeleteVerification, setShowDeleteVerification]}
                    object={{
                        callbackNegative: rejectDelete,
                        callbackPositive: confirmDelete,
                        textPositive: 'Si',
                        textNegative: 'No',
                        popupText: '¿Desea eliminar la meta seleccionada?',
                        popupIcon: TRASH_ICON_SVG
                    }}
                /> : null

            }

            {
                showSuccessDeleteEvent ? <StateButtonModalHook
                    component={PopupSuccessEvent}
                    hook={[showSuccessDeleteEvent, setShowSuccessDeleteEvent]}
                    object={
                        {
                            message: 'Meta eliminada exitosamente',
                            btnText: 'OK',
                            callback: callbackCloseSuccessDeleteEvent
                        }
                    }
                /> : null
            }

            {
                showEditPopup ? <StateButtonModalHook
                    component={PopupInvitationCode}
                    hook={[showEditPopup, setShowEditPopup]}
                    object={
                        { 
                            text: 'Actualiza el progreso de tu meta',
                            btnText: 'Actualizar',
                            callback: callbackUpdate,
                            notInvitation: true,
                            callbackInput: handleUpdateProgress,
                            placeholder: progress,
                            defaultValue: progress,
                            error: progressError,
                            errorText: "El progreso no puede ser mayor al valor de la meta"
                        }
                    }
                /> : null
            }

            <LoadingPopup state={isLoader} />

        </div>
    )
}

export default withRouter(PsGoals)