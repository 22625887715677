//state entry reducer

const UPDATE_ENTRY = "set_update_entry_action_entry";
const UPDATE_ID = "set_update_id_action_entry";
const UPDATE_USER_TYPE = "set_update_user_type_action_entry";
const UPDATE_STATE_ENTRY = "set_update_state_entry_action_entry";
const UPDATE_ENTRY_FROM_BITACLE = "GET_BITACLE_ENTRY";
const OFF_BITACLE_ENTRY = "OFF_BITACLE_ENTRY";

const initialStateEntry = {
  object: {},
  id: null,
  userType: null,
};

const reducerStateEntry = (state = initialStateEntry, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_ENTRY:
      return {
        ...state,
        entry: payload,
      };

    case UPDATE_ID:
      return {
        ...state,
        id: payload,
      };

    case UPDATE_USER_TYPE:
      return {
        ...state,
        userType: payload,
      };

    case UPDATE_STATE_ENTRY:
      return {
        state: payload,
      };

    case UPDATE_ENTRY_FROM_BITACLE:
      return {
        ...state,
        object: payload,
      };

    case OFF_BITACLE_ENTRY:
      return null;

    default:
      return state;
  }
};

const changeEntry = (value) => {
  return { type: UPDATE_ENTRY, payload: value };
};

const updateId = (value) => {
  return { type: UPDATE_ID, payload: value };
};

const updateUserType = (value) => {
  return { type: UPDATE_USER_TYPE, payload: value };
};

const updateStateEntry = (value) => {
  return { type: UPDATE_STATE_ENTRY, payload: value };
};

export {
  initialStateEntry,
  reducerStateEntry,
  changeEntry,
  updateId,
  updateUserType,
  updateStateEntry,
};
