import moment from "moment";
import "moment/locale/es";
import { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import FROWN_ICON_SVG from "../../../assets/icons/svg/frown.svg";
import LOGO_ICON_SVG from "../../../assets/icons/svg/otros.svg";
import SMILE_ICON_POPUP from "../../../assets/icons/svg/smile.svg";
import { LoadingPopup } from "../../../components/atoms/popup/Popup";
import StateButtonModalHook from "../../../modal/StateButtonModalHook.jsx";
import {
  PopupCalendar
} from "../../../popups/PopupCalendar.jsx";
import { PopupTwoOptionWithIcon } from "../../../popups/PopupTwoButtons.jsx";
import { ShowMediaSource } from "../../../popups/PopupsNewServices.jsx";
import { RejectCotizacion } from "../../../popups/RejectCotizacion.jsx";
import { SelectItems } from "../../../popups/SelectItems.jsx";
import { PopupRejectEvent } from "../../../popups/psPopups/PopupRejectEvent.jsx";
import { PopupSuccessEvent } from "../../../popups/psPopups/PopupSuccessEvent.jsx";
import { convertCodeToTitleByServices } from "../../../res/codes.convert.services";
import {
  convertTypePriority,
  convertTypePropertyByCodeToName,
} from "../../../res/convert.codes";
import { BASE_MENU_PATH, SERVICES } from "../../../router/routes";
import {
  allItemsByService,
  createRejectionFeedback,
  getAllEntriesByService,
  getConfigCosts,
  getServiceById,
  getServiceDetails,
  updateItem,
  updateServiceSchedule,
  updateStageByServiceId,
} from "../../../services-controller/http.cleinte.controller";
import { LoadingScreen } from "../../../views/loading/LoadingScreen.jsx";
import PsSeguimiento from "../../services-provider/ps-seguimiento/PsSeguimiento.jsx";
import ServiceDetails from "./ServiceDetails.jsx";
import ServicesController from "./ServicesController.jsx";

const ServiceDetailsController = ({ history }) => {
  const state = useSelector((state) => state);

  moment.locale("es");

  let { id, stage } = useParams();

  const [service, setService] = useState(null);
  const [itemsList, setItemList] = useState([]);
  const [details, setDetails] = useState([]);

  const [approvalPopup, setApprovalPopup] = useState(false);
  const [generateApproval, setGenerateApproval] = useState(false);
  const [rejectApproval, setRejectApproval] = useState(false);
  const [selectItemsPopup, setSelectItemsPopup] = useState(false);
  const [cotizacionRejectedPopup, setCotizacionRejectedPopup] = useState(false);
  const [itemsSelected, setItemsSelected] = useState([]);
  const [reasonsSelected, setReasonsSelected] = useState([]);

  const [showMediaSource, setShowMediaSource] = useState(false);
  const [mediaSourceFile, setMediaSourceFile] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateStart, setDateStart] = useState(new Date());
  const [timeStart, setTimeStart] = useState("");
  const [timeEnd, setTimeEnd] = useState("");
  const [serviceProvider, setServiceProvider] = useState(null);
  const [costs, setCosts] = useState({
    assistant: 0,
    official: 0,
    supervisor: 0,
    visit: 0,
  });
  const [defaultValueDate, setDefaultValueDate] = useState(null);
  const [minDateAviable, setMinDateAviable] = useState(null);
  const [message, setMessage] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [rejectionFailed, setRejectionFailed] = useState(false);
  const [defaultFromDate, setDefaultFromDate] = useState("");
  const [serviceScheduleUpdated, setServiceScheduleUpdated] = useState(false);
  const [existEntries, setExistEntries] = useState(false);

  const reasonsList = [
    "Precio",
    "Falta de claridad en la cotización",
    "Tiempos de respuesta",
    "La cotización no abarca lo que quería reparar",
    "Otros",
  ];

  const SCHEDULE_RANGE = [
    "08:00 AM",
    "08:30 AM",
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
  ];

  const fetchServiceById = async () => {
    const [service, items, details, costs, entries] = await Promise.all([
      getServiceById(id),
      allItemsByService(id),
      getServiceDetails(id),
      getConfigCosts(),
      getAllEntriesByService(id),
    ]);
    setService(service);
    setItemList(items);
    setCosts(costs);

    if (entries.bitacle.length > 0) {
      setExistEntries(true);
    }
    const {
      service_providers,
      state: { priority },
    } = service;

    setServiceProvider(service_providers);
    if (priority === 2) {
      setDefaultValueDate(new Date(moment(new Date()).add(1, "days")));
      setMinDateAviable(new Date(moment(new Date()).add(1, "days")));
    } else {
      setDefaultValueDate(new Date(moment(new Date()).add(3, "days")));
      setMinDateAviable(new Date(moment(new Date()).add(3, "days")));
    }
  };

  const { iva, aui, discount, comision, publicUrl } = details;

  const { form_user } = state;

  const { user_type } = form_user;

  useEffect(() => {
    fetchServiceById();
    return () => { };
  }, [id]);

  const currentDate = (date) => moment(date).format("dddd, D MMMM");

  const buildListServicesToString = (list) => {
    let aux = "";

    if (!list?.length) {
      return aux
    }

    for (let service of list) {
      aux = aux + ` ${convertCodeToTitleByServices(service)} -`;
    }

    return aux.slice(0, aux.length - 1);
  };

  const buildAddress = () => {
    return `${service.metadata.property.location.address.street_type} 
        ${service.metadata.property.location.address.street} # 
        ${service.metadata.property.location.address.corner} - 
        ${service.metadata.property.location.address.number} · 
        ${service.metadata.property.location.space} 
        ${service.metadata.property.optional.locale} 
        ${service.metadata.property.optional.shopping_center}`;
  };

  const callbackGenerateApproval = () => {
    setApprovalPopup(true);
  };

  const callbackAprove = () => {
    setApprovalPopup(false);
    setSelectItemsPopup(true);
  };

  const callbackReject = () => {
    setApprovalPopup(false);
    setRejectApproval(true);
  };

  const callbackCloseApprovalPopup = () => {
    setGenerateApproval(false);
    history.push(`${BASE_MENU_PATH}${SERVICES}`);
  };

  const enableApproval = () => {
    return (
      itemsSelected.length > 0 &&
      timeEnd !== "" &&
      timeStart !== "" &&
      dateStart !== new Date()
    );
  };

  const callbackAccept = async () => {
    if (enableApproval()) {
      const updates = [];

      for (let item of itemsList) {
        if (itemsSelected.includes(item)) {
          const { unique_key } = item;
          const obj = {
            ...item,
            is_approved: true,
          };
          updates.push(updateItem(id, unique_key, obj));
        } else {
          const { unique_key } = item;
          const obj = {
            ...item,
            is_approved: false,
          };
          updates.push(updateItem(id, unique_key, obj));
        }
      }

      /*
            for (let item of itemsSelected) {
                const { unique_key } = item
                const obj = {
                    ...item,
                    is_approved: true
                }
                updates.push(updateItem(id, unique_key, obj))
            }
            */

      const updatedService = {
        ...service,
        request: {
          ...service.request,
          schedule: {
            ...service.request.schedule,
            date_start: dateStart,
            time_start: `${timeStart} - ${timeEnd}`,
          },
        },
      };

      setIsLoader(true);
      await Promise.all([
        ...updates,
        updateServiceSchedule(id, updatedService),
      ]);
      await updateStageByServiceId(id, 12);

      setIsLoader(false);
      setSelectItemsPopup(false);
      setGenerateApproval(true);
    }
  };

  const callbackSelectItem = (index) => {
    const aux = [...itemsSelected];
    const currentIndex = aux.indexOf(itemsList[index]);
    if (currentIndex === -1) {
      aux.push(itemsList[index]);
    } else {
      aux.splice(currentIndex, 1);
    }
    setItemsSelected([...aux]);
  };

  const callbackCancel = () => {
    setRejectApproval(false);
  };

  const callbackSend = async () => {
    const object = {
      reasons: reasonsSelected,
      message: message,
    };

    await createRejectionFeedback(id, object);

    await updateStageByServiceId(id, 3);

    setRejectApproval(false);
    setCotizacionRejectedPopup(true);
  };

  const callbackTextarea = (e) => {
    setMessage(e.target.value);
  };

  const callbackSelectReason = (reason) => {
    const aux = [...reasonsSelected];
    const currentIndex = aux.indexOf(reason);

    if (currentIndex === -1) aux.push(reason);
    else aux.splice(currentIndex, 1);
    setReasonsSelected([...aux]);
  };

  const callbackCloseReject = () => {
    setCotizacionRejectedPopup(false);
    history.push(`${BASE_MENU_PATH}${SERVICES}`);
  };

  const workforcePlusMaterials = () => {
    let totalWorkforce = 0;
    let totalMateriales = 0;
    let totalGananciaMateriales = 0;
    let totalGananciaWorforce = 0;

    for (let item of itemsList) {
      if (item.is_approved) {
        totalWorkforce = totalWorkforce + item.workforce;

        totalGananciaWorforce =
          totalGananciaWorforce +
          (item.workforce * item.workforce_percent) / 100;

        const listMaterials = item.materials;
        for (let material of listMaterials) {
          const unitPrice = material.price_unit;
          const quantity = material.quantity;
          const total = unitPrice * quantity;

          totalMateriales = totalMateriales + total;
          totalGananciaMateriales =
            totalGananciaMateriales + (total * item.materials_percent) / 100;
        }
      }
    }

    return [
      totalMateriales,
      totalWorkforce,
      totalGananciaMateriales,
      totalGananciaWorforce,
    ];
  };

  const [
    sumMaterials,
    sumWorkforce,
    sumGananciaMateriales,
    sumGananciaWorkforce,
  ] = workforcePlusMaterials();

  const subtotal =
    sumMaterials + sumWorkforce + sumGananciaMateriales + sumGananciaWorkforce;

  const discountValue = subtotal - (subtotal * discount) / 100;

  const comisionValue = discountValue + (discountValue * comision) / 100;

  const aiuValue = comisionValue * (aui / 100);

  const ivaValue = () => {
    const value = aiuValue * (iva / 100);
    if (value === 0) {
      return comisionValue * (iva / 100);
    } else {
      return value;
    }
  };

  const total = Math.round((ivaValue() + comisionValue) * 100) / 100;

  const callbackViewSource = (mediaFile) => {
    const { name, type, url } = mediaFile;

    const newFile = {
      ...mediaFile,
      file: {
        name,
        type,
        url,
      },
    };
    setMediaSourceFile(newFile);
    setShowMediaSource(true);
  };

  const callbackCloseMediaSource = () => {
    setShowMediaSource(false);
  };

  const callbackSelectDate = () => {
    setShowCalendar(true);
  };

  const callbackAcceptDate = () => {
    setShowCalendar(false);
  };

  const callbackSelectDateStart = (e) => {
    setDateStart(e);
    setDefaultValueDate(e);
  };

  const callbackScheduleStart = (e) => {
    setTimeStart(e.target.value);
  };

  const callbackScheduleEnd = (e) => {
    setTimeEnd(e.target.value);
  };

  const callbackShowPdf = () => {
    window.open(publicUrl);
  };

  const callbackCloseRejectionFail = () => {
    setRejectionFailed(false);
  };

  const callbackCloseScheduleUpdate = () => {
    setServiceScheduleUpdated(false);
    history.push(`${BASE_MENU_PATH}${SERVICES}`);
  };

  const callbackCancelService = async () => {
    setIsLoader(true);
    await updateStageByServiceId(id, 13);
    setIsLoader(false);
    history.push(`${BASE_MENU_PATH}${SERVICES}`);
  };

  const BuildView = (stage) => {
    switch (parseInt(stage)) {
      case 0:
      case 8:
      case 3:
        return (
          <ServiceDetails
            stage={stage}
            service={service}
            defaultValueGeneralDescription={service.request.general_description}
            propertyTye={convertTypePropertyByCodeToName(
              service.metadata.property.property_type
            )}
            listServicesSelected={buildListServicesToString(
              service.request.selected_services
            )}
            servicePriority={convertTypePriority(service.request.priority)}
            addressProperty={buildAddress()}
            dateStart={currentDate(service.request.schedule.date_start)}
            timeStart={service.request.schedule.time_start}
            callbackViewSource={callbackViewSource}
            listResources={
              service.request.resources === undefined
                ? []
                : service.request.resources
            }
            serviceProvider={serviceProvider}
            totalVisit={
              <CurrencyFormat
                value={costs.visit}
                thousandSeparator={true}
                displayType={"text"}
              />
            }
            callbackCancel={callbackCancelService}
          />
        );
      case 1:
        return (
          <ServiceDetails
            stage={stage}
            callbackGenerateApproval={callbackGenerateApproval}
            service={service}
            defaultValueGeneralDescription={service.request.general_description}
            propertyTye={convertTypePropertyByCodeToName(
              service.metadata.property.property_type
            )}
            listServicesSelected={buildListServicesToString(
              service.request.selected_services
            )}
            servicePriority={convertTypePriority(service.request.priority)}
            addressProperty={buildAddress()}
            dateStart={currentDate(service.request.schedule.date_start)}
            timeStart={service.request.schedule.time_start}
            callbackViewSource={callbackViewSource}
            listResources={
              service.request.resources === undefined
                ? []
                : service.request.resources
            }
            callbackShowPdf={callbackShowPdf}
            serviceProvider={serviceProvider}
            totalVisit={
              <CurrencyFormat
                value={costs.visit}
                thousandSeparator={true}
                displayType={"text"}
              />
            }
          />
        );
      case 2:
      case 5:
      case 6:
      case 10:
      case 9:
      case 11:
        return <PsSeguimiento user="client" service={service} />;
      case 4:
      case 12:
        if (existEntries) {
          return (
            <PsSeguimiento
              user="client"
              service={service}
              pay={total * 0.4 + total * 0.4 * 0.05}
            />
          );
        } else {
          return (
            <ServiceDetails
              stage={stage}
              service={service}
              defaultValueGeneralDescription={
                service?.request?.general_description
              }
              propertyTye={convertTypePropertyByCodeToName(
                service?.metadata?.property.property_type
              )}
              listServicesSelected={buildListServicesToString(
                service?.request?.selected_services
              )}
              servicePriority={convertTypePriority(service?.request?.priority)}
              addressProperty={buildAddress()}
              dateStart={currentDate(service?.request?.schedule?.date_start)}
              timeStart={service?.request?.schedule?.time_start}
              callbackViewSource={callbackViewSource}
              listResources={
                service?.request?.resources === undefined
                  ? []
                  : service?.request?.resources
              }
              serviceId={id}
              email={
                service?.metadata?.user?.register_data?.contact?.email_associated
              }
              description={`Justo Pago: ${service?.request?.general_description}`}
              pay={total * 0.6 + total * 0.6 * 0.05}
              userType={user_type}
              serviceProvider={serviceProvider}
              totalVisit={
                <CurrencyFormat
                  value={costs.visit}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              }
            />
          );
        }
      case 13:
        return (
          <ServiceDetails
            stage={stage}
            service={service}
            defaultValueGeneralDescription={
              service.request?.general_description
            }
            propertyTye={convertTypePropertyByCodeToName(
              service.metadata.property.property_type
            )}
            listServicesSelected={buildListServicesToString(
              service.request.selected_services
            )}
            servicePriority={convertTypePriority(service.request.priority)}
            addressProperty={buildAddress()}
            dateStart={currentDate(service.request?.schedule.date_start)}
            timeStart={service.request.schedule.time_start}
            callbackViewSource={callbackViewSource}
            listResources={
              service.request.resources === undefined
                ? []
                : service.request.resources
            }
            serviceProvider={serviceProvider}
            totalVisit={
              <CurrencyFormat
                value={costs.visit}
                thousandSeparator={true}
                displayType={"text"}
              />
            }
          />
        );
      default:
        return <ServicesController />;
    }
  };

  return (
    <>
      {service !== null ? (
        <>
          {BuildView(stage)}

          {/*Generar aprobacion popup*/}

          {approvalPopup ? (
            <StateButtonModalHook
              component={PopupTwoOptionWithIcon}
              hook={[approvalPopup, setApprovalPopup]}
              object={{
                callbackPositive: callbackAprove,
                callbackNegative: callbackReject,
                popupText: "¿Desea generar aprobación?",
                textPositive: "Si",
                textNegative: "No",
                popupIcon: SMILE_ICON_POPUP,
              }}
            />
          ) : null}

          {/*Seleccionar items aprobados*/}

          {selectItemsPopup ? (
            <StateButtonModalHook
              component={SelectItems}
              hook={[selectItemsPopup, setSelectItemsPopup]}
              object={{
                callbackSelectItem: callbackSelectItem,
                callbackAccept: callbackAccept,
                itemList: itemsList,
                enableApproval: enableApproval(),
                callbackSelectDate: callbackSelectDate,
                selectedDate: currentDate(dateStart),
                callbackScheduleStart: callbackScheduleStart,
                callbackScheduleEnd: callbackScheduleEnd,
              }}
            />
          ) : null}

          {showCalendar ? (
            <StateButtonModalHook
              component={PopupCalendar}
              hook={[showCalendar, setShowCalendar]}
              object={{
                callbackSelectDateStart: callbackSelectDateStart,
                callbackAcceptDate: callbackAcceptDate,
                defaultValueDate: defaultValueDate,
                minDateAviable: minDateAviable,
              }}
            />
          ) : null}

          {generateApproval ? (
            <StateButtonModalHook
              component={PopupSuccessEvent}
              hook={[generateApproval, setGenerateApproval]}
              object={{
                btnText: "ok",
                message: "Aprobación generada exitosamente",
                callback: callbackCloseApprovalPopup,
              }}
            />
          ) : null}

          {/*Rechazar aprobacion feedback popup*/}

          {rejectApproval ? (
            <StateButtonModalHook
              component={RejectCotizacion}
              hook={[rejectApproval, setRejectApproval]}
              object={{
                callbackCancel: callbackCancel,
                callbackSend: callbackSend,
                reasonsList: reasonsList,
                callbackTextarea: callbackTextarea,
                callbackSelectReason: callbackSelectReason,
              }}
            />
          ) : null}

          {cotizacionRejectedPopup ? (
            <StateButtonModalHook
              component={PopupRejectEvent}
              hook={[cotizacionRejectedPopup, setCotizacionRejectedPopup]}
              object={{
                message: "¡Gracias por tu opinión!",
                btnText: "Ok",
                callback: callbackCloseReject,
                icon: LOGO_ICON_SVG,
              }}
            />
          ) : null}

          {rejectionFailed ? (
            <StateButtonModalHook
              component={PopupRejectEvent}
              hook={[rejectionFailed, setRejectionFailed]}
              object={{
                message:
                  "Lo sentimos, todos los campos son obligatorios. Por favor selecciona al menos una razón y escribe una breve descripción.",
                btnText: "Ok",
                callback: callbackCloseRejectionFail,
                icon: FROWN_ICON_SVG,
              }}
            />
          ) : null}

          {/* show media source */}
          {showMediaSource ? (
            <StateButtonModalHook
              component={ShowMediaSource}
              hook={[showMediaSource, setShowMediaSource]}
              object={{
                callbackCloseMediaSource: callbackCloseMediaSource,
                mediaSourceFile: mediaSourceFile,
              }}
            />
          ) : null}

          {serviceScheduleUpdated ? (
            <StateButtonModalHook
              component={PopupSuccessEvent}
              hook={[serviceScheduleUpdated, setServiceScheduleUpdated]}
              object={{
                btnText: "ok",
                message: "Fecha y hora actualizadas exitosamente",
                callback: callbackCloseScheduleUpdate,
              }}
            />
          ) : null}
        </>
      ) : (
        <LoadingScreen />
      )}

      <LoadingPopup state={isLoader} />
    </>
  );
};

export default withRouter(ServiceDetailsController);
