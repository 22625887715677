import React from 'react'
import { Appbar,AppbarLogo } from '../../components/atoms/appbar/Appbar'
import POINTING_ICON_SVG from '../../assets/icons/svg/finger-point.svg'
import { AddSquareButton, PrimaryButton } from '../../components/atoms/button/Button'
import { FilePicker } from 'react-file-picker'
import { WarpSoruce } from '../../components/atoms/media/MediaSource'

const NewServicesDetails = ({
    listResources,
    callbackAddFile,
    callbackAddFileError,
    callbackViewSource,
    deleteMediaSource,
    callbackPreviusService,
    callbackChangePropertyOption,
    defaultValueGeneralDescription,
    propertyTye,
    listServicesSelected,
    servicePriority,
    addressProperty,
    dateStart,
    timeStart
}) => {

    return (
        <div className='new-service-container'>

            <AppbarLogo />

            <div className='desktop-container'>

                <div className='new-service-appbar-container'>
                    <Appbar smallText='Solicitud de servicio' bigText='Día y hora' />
                </div>

                <div className='container-general'>

                    <div className='text-service-details'>

                        <div>
                            <img src={POINTING_ICON_SVG} id='img-text-service-details' alt={'POINTING_ICON_SVG'} />
                        </div>
                        <div className='text-details'>
                            <span>Detalles</span>
                            <span onClick={callbackChangePropertyOption} className='note-details'>Cambiar dirección</span>
                        </div>

                    </div>

                    <div className='new-service-info-container'>
                        <div>
                            <div className='new-service-info-title leftSide'>Tipo de inmueble</div>
                            <div className='new-service-info-text leftSide'>{propertyTye}</div>
                        </div>
                        <div>
                            <div className='new-service-info-title'>Tipo de servicio</div>
                            <div className='new-service-info-text'>{listServicesSelected}</div>
                        </div>
                        <div>
                            <div className='new-service-info-title leftSide'>Urgencia</div>
                            <div className='new-service-info-text leftSide'>{servicePriority}</div>
                        </div>
                        <div>
                            <div className='new-service-info-title'>Lugar</div>
                            <div className='new-service-info-text'>{addressProperty}</div>
                        </div>
                        <div>
                            <div className='new-service-info-title leftSide'>Fecha</div>
                            <div className='new-service-info-text leftSide'>{dateStart}</div>
                        </div>
                        <div>
                            <div className='new-service-info-title'>Hora</div>
                            <div className='new-service-info-text'>{timeStart}</div>
                        </div>
                    </div>

                    <div className='service-description'>
                        <div className='title-description'>Descripción</div>
                        <div className='text-description'>{defaultValueGeneralDescription}</div>
                    </div>

                    <div className='service-description'>
                        <div className='title-description'>Evidencia</div>
                    </div>

                    <div className='dano-foto'>
                        <FilePicker
                            maxSize={100}
                            onChange={callbackAddFile}
                            onError={callbackAddFileError}
                        >
                            <div className='add-foto-container'>
                                <AddSquareButton />

                            </div>
                        </FilePicker>


                        {
                            listResources.map((src, index) => {

                                const { type, url } = src
                                const file = fetch(url).then(res => res.blob())

                                return (
                                    <div key={index} className='foto-source-container'>
                                        <WarpSoruce callback={() => callbackViewSource(src)} type={type} file={file} url={url} index={index} />
                                        <div className='deletePhoto' onClick={() => deleteMediaSource(index)}>x</div>
                                    </div>
                                )
                            })
                        }


                    </div>

                    <div className='ns-next-step-container'>
                        <PrimaryButton txt='Confirmar servicio' callback={callbackPreviusService} />
                    </div>

                </div>

            </div>
        </div>
    )
}

export default NewServicesDetails