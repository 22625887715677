import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { changeServiceProviderType } from "../../../reducers/app.reducer";
import { changeRegisterType } from "../../../reducers/form.service.provider.js";
import {
  OperationEmployes,
  adminEmployes,
  areaList,
  comercialEmployes,
  designeEmployes,
  planeationEmployes,
} from "../../../res/const.employees.js";
import { convertUserTypeToRegisterTypeCode } from "../../../res/convert.codes.js";
import { BASE_PS_TYPE_PATH, PS_BASIC_INFO } from "../../../router/routes";
import ServiceProviderType from "./ServiceProviderType.jsx";
const ServiceProviderTypeController = ({ history }) => {
  const dispatch = useDispatch();
  const [employesType, setEmployesType] = useState([]);
  const app = useSelector((state) => state.app);
  const { service_provider_type } = app;

  const callbackChangeServiceProviderType = (e) => {
    dispatch(
      changeRegisterType(convertUserTypeToRegisterTypeCode(e.target.id))
    );
    dispatch(changeServiceProviderType(e.target.id));
  };

  const callbackChangeAreaList = (e) => {
    switch (e.id) {
      case "Operaciones":
        setEmployesType(OperationEmployes);
        break;
      case "Planeación":
        setEmployesType(planeationEmployes);
        break;
      case "Diseño":
        setEmployesType(designeEmployes);
        break;
      case "Comercial":
        setEmployesType(comercialEmployes);
        break;
      case "Administración":
        setEmployesType(adminEmployes);
        break;
      default:
        break;
    }
  };

  const callbackPushNavigator = () =>
    history.push(
      `${BASE_PS_TYPE_PATH}${service_provider_type}${PS_BASIC_INFO}`
    );
  // SUPERVISOR
  // OFICIAL
  // AYUDANTE

  return (
    <ServiceProviderType
      callbackChangeServiceProviderType={callbackChangeServiceProviderType}
      selectedServiceProviderType={service_provider_type}
      callbackPushNavigator={callbackPushNavigator}
      areaList={areaList}
      callbackChangeEmployesType={callbackChangeAreaList}
      employesType={employesType}
    />
  );
};

export default withRouter(ServiceProviderTypeController);
