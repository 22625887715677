import { EmojiChat } from "components/atoms/emoji-picker/Emoji-Picker";
import { useDispatcherFunction } from "custom-hooks/useReduxState";
import React from "react";
import { useSelector } from "react-redux";
import { updateTextMessage } from "reducers/chat.controller.reducer";

const EmojContainer = () => {
  const state = useSelector((state) => {
    return {
      chatController: state.chatController,
    };
  });

  const chatControlState = state?.chatController;
  const { messageText, showEmojiPicker } = chatControlState;

  const updateMessageText = useDispatcherFunction(updateTextMessage);

  return (
    showEmojiPicker && (
      <div className="emoji-icons">
        <EmojiChat
          emojiHidden={showEmojiPicker}
          setMessageText={updateMessageText}
          messageText={messageText}
        />
      </div>
    )
  );
};

export default EmojContainer;
