const INSTABASICAS_ARRAY = [
    "Ins de repisa",
    "Ins de cuadro",
    "Ins de persianas",
    "Ins de base TV",
    "Ins de tendero",
    "Ins de lámpara sencilla ",
    "Ins de espejo",
    "Ins de lámpara colgante",
    "Ins de canaleta cables 3m",
    "Ins chapa pomo",
    "Ins chapa principal sin soldar",
    "Ins de mano de pintura m2",
    "Ins de tablero",
    "Otros",
] 

export{
    INSTABASICAS_ARRAY
}