import React from 'react'
import { IconPopup } from '../../components/atoms/popup/Popup'
import FROWN_ICON_SVG from '../../assets/icons/svg/frown.svg'


const PopupServiceNotAviable = ({ object }) => {
    const {
        message,
        btnText,
        callback,
        icon=FROWN_ICON_SVG

    } = object
    return (
        <IconPopup
            icon={icon}
            text={message}
            btnColor='btnPurple'
            btnText={btnText}
            callback={callback} />
    )
}


export {
    PopupServiceNotAviable
}