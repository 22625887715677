import heic2any from "heic2any";

export const convertHeicToJpg = async (file) => {
  const blob = await heic2any({
    blob: file,
    toType: "image/jpeg",
    quality: 0.6,
  });
  return blob;

}