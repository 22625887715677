// Popover.js
import React, { useState } from "react";
import INFO_SVG from ".././assets/icons/svg/info.svg"; // Importa el icono de información
import "./popups.css"; // Importa el archivo de estilos

const PopoverInfo = ({ content }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="popover-container"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="popover-trigger"></div>
      {isHovered && <div className="popover-content">{content}</div>}
    </div>
  );
};

export default PopoverInfo;
