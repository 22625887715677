import React, { useRef, useEffect, useState } from "react";
import Map, { GeolocateControl, Marker, NavigationControl } from "react-map-gl";
import { MAPBOX_ACCESS_TOKEN, MAP_CENTER_DEFAULT } from "utils/utils";
import "./route.css";
import ROUTE from "./route.json";
import GoBackBtn from "components/GoBackBtn/GoBackBtn";
import NavBar from "components/Navbar/NavBar";
import "mapbox-gl/dist/mapbox-gl.css";
import { Popover } from "antd";
import { getUserLocation, startAsignedRoute } from "./logic/functions";
import useRouteDirections from "./logic/useRouteDirections";
import HeaderRouteInfo from "./atoms/HeaderRouteInfo";
import { useSelector } from "react-redux";
import StepRouteInfo from "./atoms/StepRouteInfo";

export function RouteMapController() {
  const route = ROUTE.ruta;
  const mapRef = useRef();
  const directionsRef = useRef(null);

  const [routeStarted, setRouteStarted] = useState(() => {
    const savedRouteStarted = localStorage.getItem("routeStarted");
    return savedRouteStarted ? JSON.parse(savedRouteStarted) : false;
  });

  const [orientation, setOrientation] = useState({
    nextPoint: null,
    bearing: null,
  });
  const [routeGeneralInfo, setRouteGeneralInfo] = useState({
    distance: null,
    duration: null,
  });

  const [stepState, setStepState] = useState({
    currentStep: {
      type: "",
      distance: null,
      duration: null,
      name: "",
      intructions: {
        bearing_after: null,
        location: [],
        intruction: "",
      },
    },
    nextStep: {
      type: "",
      intructions: {
        intruction: "",
      },
    },
    totalSteps: null,
  });

  const [viewState, setViewState] = useState({
    latitude: MAP_CENTER_DEFAULT.latitude,
    longitude: MAP_CENTER_DEFAULT.longitude,
    zoom: 13,
    pitch: 0,
  });
  const [userLocation, setUserLocation] = useState(() => {
    const savedLocation = localStorage.getItem("userLocation");
    return savedLocation ? JSON.parse(savedLocation) : null;
  });

  const { showAsignedRoute, mapInstance } = useRouteDirections(
    mapRef,
    directionsRef,
    MAPBOX_ACCESS_TOKEN,
    route,
    setRouteGeneralInfo,
    setOrientation,
    setStepState
  );

  const auth = useSelector((state) => state.auth);
  const { user } = auth;

  useEffect(() => {
    let watchId;
    if (routeStarted) {
      watchId = getUserLocation((location) => {
        setUserLocation(location);
      });
    }

    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, [routeStarted]);

  const initialiceRoute = async () => {
    const location = await getUserLocation();
    setUserLocation(location);
    setViewState((prevState) => ({
      ...prevState,
      latitude: location.latitude,
      longitude: location.longitude,
      zoom: 19,
    }));
    setRouteStarted(true);
    showAsignedRoute(location);
  };

  useEffect(() => {
    localStorage.setItem("routeStarted", JSON.stringify(routeStarted));
  }, [routeStarted]);

  const contentPopover = (point) => (
    <div className="popover">
      <h3>{point.name}</h3>
      <p>{point.description}</p>
    </div>
  );

  useEffect(() => {
    if (routeStarted && userLocation) {
      startAsignedRoute(mapInstance, orientation, userLocation);
      showAsignedRoute(userLocation);
    }
  }, [routeStarted, userLocation]); // eslint-disable-line

  return (
    <div className="map-container">
      <Map
        {...viewState}
        ref={mapRef}
        projection="globe"
        //mapStyle="mapbox://styles/jenestiven/cl6iia5h5002414rzeuehwge9"
        mapStyle="mapbox://styles/mapbox/standard"
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        onMove={(evt) => setViewState(evt.viewState)}
        onLoad={() => showAsignedRoute(userLocation)}
      >
        {!routeStarted && <NavigationControl showCompass={false} />}
        {userLocation && stepState.totalSteps !== null && (
          <Marker
            latitude={stepState.currentStep.location[1]}
            longitude={stepState.currentStep.location[0]}
            anchor="bottom"
          >
            <img className="avatar" src={user.register_data.thumbnail} alt="" />
          </Marker>
        )}
        {route.map((point, index) => (
          <Marker
            key={point.id}
            latitude={point.coords.lat}
            longitude={point.coords.lng}
            anchor="bottom"
          >
            <Popover content={contentPopover(point)}>
              <span className="route-marker">{index + 1}</span>
            </Popover>
          </Marker>
        ))}
      </Map>
      <GoBackBtn absolute={true} />
      {routeGeneralInfo.distance !== null && !routeStarted && !userLocation && (
        <HeaderRouteInfo
          info={routeGeneralInfo}
          callbackRoute={initialiceRoute}
        />
      )}
      {routeStarted && stepState.totalSteps && (
        <StepRouteInfo step={stepState} />
      )}
      <NavBar />
    </div>
  );
}
