const RELATIONSHIP_DEFAULT_VALUE = 'Parentesco'

const RELATIONSHIP_TYPE = [
    'Mamá',
    'Papá',
    'Esposo/a',
    'Hijo/a',
    'Hermano/a',
    'Tío/a',
    'Amigo/a',
    'Otro'
]

export {
    RELATIONSHIP_TYPE,
    RELATIONSHIP_DEFAULT_VALUE
}