import { useState } from "react";
import { hasCarpentyGroupValidation } from "res/utils";
import ALERT_ICON_SVG from "../../../assets/icons/svg/alert-triangle.svg";
import StateButtonModalHook from "../../../modal/StateButtonModalHook";
import { PopupSuccessEvent } from "../../../popups/PopupTwoButtons";
import ActivitiesTitle from "./entryCard/ActivitiesTitle";
import AlertDot from "./entryCard/AlertDot";
import DateFormat from "./entryCard/DateFormat";
import LockEntrys from "./entryCard/LockEntrys";
import PersonInfo from "./entryCard/ProfileContainer";
import UnexpectedButton from "./entryCard/UnexpectedButton";

const getContainerClasses = (selected, obj) => {
  const classes = ["content-item-container", "action-element"];
  if (selected?.unique_key === obj.unique_key) {
    classes.push("time-line-item-selected");
  }

  if (obj.unexpected) {
    classes.push("unexpected-background");
  }

  if (!obj.is_visible) {
    classes.push("hidden-entry");
  }

  return classes.join(" ");
};

const TimeLineItem = ({
  obj,
  callback,
  selected,
  id,
  lockEntry,
  user,
  key,
}) => {
  const activities = obj.activities_selected;
  const hasCarpenter = hasCarpentyGroupValidation(activities);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const CheckBlock = () => {
    !lockEntry ? callback(obj, id) : setShowModalMessage(true);
  };

  return (
    <div className={`time-line-item-container`} onClick={CheckBlock}>
      <div
        className={getContainerClasses(selected, obj)}
        {...(obj?.phase?.code === 3 && {
          style: {
            backgroundColor: "#ffd700",
            borderColor: "#ffd700",
          }
        })}
      >
        <div
          className={`item-title ${obj.unexpected ? "item-title-unexpected" : ""
            }`}
        >
          <ActivitiesTitle
            key={key}
            activities={activities}
            notPaid={lockEntry}
          />
          <PersonInfo user={obj.user} />
          {lockEntry && <LockEntrys />}
        </div>
        <DateFormat unexpected={obj.unexpected} date={obj?.date} />
        <AlertDot
          hasCarpenter={hasCarpenter}
          user={user}
          statusMessages={obj.statusMessages}
          isNewAdminEntry={obj.isNewAdminEntry}
        />
      </div>
      <UnexpectedButton
        unexpected={obj.unexpected}
        ALERT_ICON_SVG={ALERT_ICON_SVG}
      />
      {showModalMessage && (
        <StateButtonModalHook
          component={PopupSuccessEvent}
          hook={[showModalMessage, setShowModalMessage]}
          object={{
            callback: () => setShowModalMessage(false),
            popupText:
              "No olvides realizar el pago para desbloquear todos los detalles de tu proyecto de remodelación.",
          }}
        />
      )}
    </div>
  );
};

export default TimeLineItem;
