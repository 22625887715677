import React, { useEffect, useState } from "react";
import "./timeLineActivities.css";
import {
  daysRemaining,
  getDiference,
  newDate,
  getRemainingHabilDays,
} from "../../../res/date";
import { convertPhaseNameToSpanishName } from "../../../res/convert.codes";
import { camelCase } from "../../../res/utils";
const TimelineConnector = ({ color }) => {
  return <div className={`line line-${color}`}></div>;
};

const TimeLineDot = ({ colorBackground, colorBorder }) => {
  return (
    <div className={`dot`}>
      <span
        className={`backgroun-${colorBackground} border-${colorBorder}`}
      ></span>
    </div>
  );
};

const TimelineContentResume = ({
  title,
  date_complete,
  dateEnd,
  isCompleted,
  isRequired,
  phaseEnd,
  isLastActivity,
  nextActivity,
  incompleteCount,
}) => {
  const [daysRemain, setDaysRemaining] = useState(0);
  const [daysLater, setDaysLater] = useState(0);
  const [diferenceDays, setDiference] = useState(0);
  const [daysPhaseRemain, setDaysPhaseRemain] = useState(0);

  useEffect(() => {
    const [dayRemain, daysLater] = daysRemaining(date_complete);
    const daysDiference = getDiference(date_complete, dateEnd);
    setDiference(daysDiference);
    setDaysRemaining(dayRemain);
    const daysRemainPhase = getDiference(phaseEnd, newDate());
    setDaysPhaseRemain(daysRemainPhase);
    setDaysLater(daysLater);
  }, [title, date_complete]); // eslint-disable-line react-hooks/exhaustive-deps

  let nexActivityColor = "gray";
  if (nextActivity) {
    if (nextActivity.isCompleted) {
      if (nextActivity.completation_status.completetion_status) {
        nexActivityColor = "green";
      } else if (nextActivity.isCompleted) {
        nexActivityColor = "green";
      }
    } else {
      let [daysRemain, daysLater] = daysRemaining(
        nextActivity.progress.end_date
      );
      if (daysRemain > 0) {
        nexActivityColor = "green";
      } else if (daysLater > 0) {
        nexActivityColor = "red";
      }
    }
  }
  const getDayString = (days) => {
    return days === 1 ? "día" : "días";
  };

  const getSustantive = (days) => {
    let sustantive = days === 1 ? "Falta" : "Faltan";
    return sustantive;
  };

  const getHabilDaysString = (habilDays) => {
    return habilDays === 1 ? "hábil" : "hábiles";
  };

  let content = "";
  let daysContent = "";
  let colorActivity = "";
  let colorBorder = "";
  if (!isCompleted) {
    if (daysRemain >= 0 && isRequired) {
      colorActivity = "white";
      colorBorder = "green";
      content = "A tiempo";
      let habilDays = getRemainingHabilDays(date_complete);
    } else if (daysLater > 0 && isRequired) {
      colorActivity = "white";
      colorBorder = "green";
    } else {
      if (!isRequired) {
        colorActivity = "white";
        colorBorder = "green";
        let habilDays = getRemainingHabilDays(date_complete);
        daysContent = `${getSustantive(
          daysPhaseRemain
        )} ${daysPhaseRemain} ${getDayString(
          daysPhaseRemain
        )} - ${habilDays} ${getDayString(habilDays)} ${getHabilDaysString(
          habilDays
        )}`;
      } else if (daysPhaseRemain < 0 && !isRequired) {
        colorActivity = "white";
        colorBorder = "green";
      }
    }
  } else {
    if (diferenceDays >= 0) {
      colorActivity = "green";
      colorBorder = "gray";
      daysContent = `${diferenceDays} ${getDayString(diferenceDays)} antes`;
    } else {
      colorActivity = "gray";
      colorBorder = "gray";
      daysContent = `${Math.abs(diferenceDays)} ${getDayString(
        diferenceDays
      )} tarde`;
    }
  }

  if (incompleteCount > 1) {
    colorActivity = "gray";
    colorBorder = "gray";
    nexActivityColor = "gray";
    content = "";
  }

  return (
    <div className="time-line-content-resume">
      <div className="time-line-header-content-resume">
        <div className="time-line-status">
          <TimeLineDot
            colorBackground={colorActivity}
            colorBorder={colorBorder}
          />
          {!isLastActivity ? (
            <TimelineConnector color={nexActivityColor} />
          ) : null}
        </div>
        <div className="header-time-line-conten-resume">
          <div className="title-time-line-resume">{camelCase(title)}</div>
          <div className="days-content">
            {daysContent} <span>{!isRequired ? "-" : ""}</span>{" "}
            <span className="span-opcional">
              {!isRequired ? "Opcional" : ""}
            </span>{" "}
          </div>
        </div>
      </div>

      <div className={`content-${content === "A tiempo" ? "time" : "late"}`}>
        {content}
      </div>
    </div>
  );
};

const TimeLineOperationContent = ({
  title,
  dateComplete,
  isCompleted,
  groupEnd,
  isLastActivity,
  nextActivity,
  spaces,
  isSpace,
}) => {
  const [daysRemain, setDaysRemaining] = useState(0);
  const [daysLater, setDaysLater] = useState(0);
  const [diferenceDays, setDiferenceDays] = useState(0);
  useEffect(() => {
    const [dayRemain, daysLater] = daysRemaining(groupEnd);
    const daysDiference = getDiference(groupEnd, dateComplete);
    setDiferenceDays(daysDiference);
    setDaysRemaining(getRemainingHabilDays(groupEnd));
    setDaysLater(getRemainingHabilDays(daysLater));
  }, [title, dateComplete]);

  let colorActivity = "";
  let colorBorder = "";
  let content = "";
  let nexActivityColor = "gray";
  let daysContent = "";

  if (nextActivity) {
    if (nextActivity.isCompleted) {
      if (nextActivity.completation_status.completetion_status) {
        nexActivityColor = "green";
      } else if (nextActivity.isCompleted) {
        nexActivityColor = "green";
      }
    } else {
      let [daysRemain, daysLater] = daysRemaining(
        nextActivity.progress.end_date
      );
      if (daysRemain > 0) {
        nexActivityColor = "green";
      } else if (daysLater > 0) {
        nexActivityColor = "green";
      }
    }
  }
  if (isCompleted) {
    if (diferenceDays >= 0) {
      colorActivity = "green";
      colorBorder = "gray";
    } else {
      colorActivity = "green";
      colorBorder = "gray";
    }
  } else {
    if (daysRemain >= 0) {
      colorActivity = "white";
      colorBorder = "green";
    } else {
      colorActivity = "white";
      colorBorder = "green";
    }
  }

  return (
    <div className="time-line-content-resume">
      <div className="time-line-header-content-resume">
        <div className="time-line-status">
          <TimeLineDot
            colorBackground={colorActivity}
            colorBorder={colorBorder}
          />
          {!isLastActivity ? (
            <TimelineConnector color={nexActivityColor} />
          ) : null}
        </div>
        <div className="header-time-line-content-resume">
          <div className="title-time-line-resume">{title}</div>

          <div className="days-content">
            {spaces?.length > 0
              ? spaces.map((space, index) => {
                  return (
                    <TimeLineItemResume
                      key={index}
                      activity={space}
                      index={index}
                      phaseEnd={groupEnd}
                      isLastActivity={index === spaces.length - 1}
                      nextActivity={spaces[index + 1]}
                      incompleteCount={
                        spaces.filter((space) => !space.isCompleted).length
                      }
                      phasePosition={2}
                      isSpace={true}
                    />
                  );
                })
              : daysContent}
          </div>
        </div>
      </div>
      {!isSpace ? (
        <div className={`content-${content === "A tiempo" ? "time" : "late"}`}>
          {content}
        </div>
      ) : null}
    </div>
  );
};

const TimeLineItemResume = ({
  activity,
  index,
  phaseEnd,
  isLastActivity,
  nextActivity,
  incompleteCount,
  phasePosition,
  isSpace,
}) => {
  const title = camelCase(activity.title);
  const dateComplete = activity.progress?.end_date;
  const dateEnd = activity.completation_status?.completetion_date;
  const isCompleted = activity.isCompleted;
  const isRequired = activity.isRequired;

  return (
    <div key={index} className="time-line-item">
      {phasePosition !== 2 ? (
        <TimelineContentResume
          title={title}
          date_complete={dateComplete}
          dateEnd={dateEnd}
          isCompleted={isCompleted}
          isRequired={isRequired}
          phaseEnd={phaseEnd}
          isLastActivity={isLastActivity}
          nextActivity={nextActivity}
          incompleteCount={incompleteCount}
        />
      ) : (
        <TimeLineOperationContent
          title={title}
          dateComplete={dateEnd}
          isCompleted={isCompleted}
          groupEnd={phaseEnd}
          isLastActivity={isLastActivity}
          nexActivity={nextActivity}
          spaces={activity.spaces ? [...activity.spaces] : []}
          isSpace={isSpace}
        />
      )}
    </div>
  );
};

const TogleActivity = ({ activity, phasePosition, phaseEnd }) => {
  const [open, setOpen] = useState(true);
  let count = 0;

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="togle-activity">
      <div className="togle-activity-title" onClick={handleOpen}>
        <h3>{camelCase(activity.group_title)}</h3>
      </div>
      {open && (
        <div className="togle-content">
          {activity?.activities?.map((activityGroup, index) => {
            if (!activityGroup.isCompleted) {
              count++;
            }
            return (
              <TimeLineItemResume
                key={index}
                activity={activityGroup}
                index={index}
                isLastActivity={index === activity?.activities?.length - 1}
                nextActivity={activity?.activities[index + 1]}
                incompleteCount={count}
                phasePosition={phasePosition}
                phaseEnd={activity.progress?.end_date}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const TimelineResume = ({ activityGroup }) => {
  let count = 0;
  return (
    <div div className="timeline-resume">
      <div className="resume-actual-phase-title">
        <h3>Fase</h3>
        <p>{convertPhaseNameToSpanishName(activityGroup.title)}</p>
      </div>

      {activityGroup.position !== 2
        ? activityGroup?.activities?.map((activity, index) => {
            const isLastActivity =
              index === activityGroup?.activities?.length - 1;

            if (!activity.isCompleted) {
              count++;
            }

            return (
              <>
                <TimeLineItemResume
                  key={index}
                  activity={activity}
                  index={index}
                  phaseEnd={activityGroup.progress_status?.end_date}
                  isLastActivity={isLastActivity}
                  nextActivity={activityGroup?.activities[index + 1]}
                  incompleteCount={count}
                  phasePosition={activityGroup.position}
                />
              </>
            );
          })
        : activityGroup?.activities?.map((activity, index) => {
            return (
              <TogleActivity
                activity={activity}
                phasePosition={activityGroup.position}
                phaseEnd={activityGroup.progress_status?.end_date}
              />
            );
          })}
      <div className="space-timeline"></div>
    </div>
  );
};

export default TimelineResume;
