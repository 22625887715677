import moment from "moment";
import "moment/locale/es";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { LoadingPopup } from "../../../../components/atoms/popup/Popup";
import StateButtonModalHook from "../../../../modal/StateButtonModalHook.jsx";
import { ShowMediaSource } from "../../../../popups/PopupsNewServices.jsx";
import { PopupSuccessEvent } from "../../../../popups/psPopups/PopupSuccessEvent";
import { changeCurrentProperty } from "../../../../reducers/app.reducer";
import { initialStateFormProperty } from "../../../../reducers/form.property.reducer";
import {
  changeRequestService,
  initialStateRequestService,
} from "../../../../reducers/request.service.reducer";
import {
  convertTypePriority,
  convertTypePropertyByCodeToName,
} from "../../../../res/convert.codes";
import {
  PRINCIPAL_PATH,
  PS_BASE_MENU_PATH,
  PS_REQUESTS,
} from "../../../../router/routes";
import { existUser } from "../../../../services-controller/auth";
import { acceptService } from "../../../../services-controller/http.cleinte.controller";
import ServiceProviderMyRequestDetails from "./ServiceProviderMyRequestDetails";

const ServiceProviderMyRequestDetailsController = ({ history }) => {
  moment.locale("es");

  const dispatch = useDispatch();
  const request_service = useSelector((state) => state.request_service);

  const buildCurrentProperty = (property) => {
    if (property === null || property === undefined)
      return initialStateFormProperty;
    else return property;
  };

  const [takeService, setTakeService] = useState(false);

  const {
    metadata,
    unique_key,
    query,
    request: {
      selected_services,
      general_description,
      resources,
      priority,
      schedule: { date_start, time_start },
    },
  } = request_service;

  const {
    property_type,
    location: {
      space,
      address: { street_type, street, corner, number },
    },
    optional: { shopping_center, locale },
  } = buildCurrentProperty(metadata?.property);

  // state

  const [showMediaSource, setShowMediaSource] = useState(false);

  const [mediaSourceFile, setMediaSourceFile] = useState("");

  const [isLoader, setIsLoader] = useState(false);

  const [messageResponse, setMessageResponse] = useState("");

  useEffect(() => {
    if (!existUser() || query === "") history.push(PRINCIPAL_PATH);

    return () => {};
  }, []);

  const PAINT_CODE = 0;
  const ELECTRICITY = 1;
  const BASIC_INSTALLATION = 2;
  const HUMIDITIES = 3;
  const ROOFS = 4;
  const PLUMBING = 5;
  const LOCLSMITH = 6;
  const HEIGHTS = 7;
  const OTHERS = 8;

  const PAINT_CODE_NAME = "Pintura";
  const ELECTRICITY_NAME = "Electricidad";
  const BASIC_INSTALLATION_NAME = "Instabásicas";
  const HUMIDITIES_NAME = "Humedades";
  const ROOFS_NAME = "Techos";
  const PLUMBING_NAME = "Plomería";
  const LOCLSMITH_NAME = "Cerrajería";
  const HEIGHTS_NAME = "Alturas";
  const OTHERS_NAME = "Otros";

  const convertCodeToTitleByServices = (key) => {
    switch (key) {
      case PAINT_CODE:
        return PAINT_CODE_NAME;
      case ELECTRICITY:
        return ELECTRICITY_NAME;
      case BASIC_INSTALLATION:
        return BASIC_INSTALLATION_NAME;
      case HUMIDITIES:
        return HUMIDITIES_NAME;
      case ROOFS:
        return ROOFS_NAME;
      case PLUMBING:
        return PLUMBING_NAME;
      case LOCLSMITH:
        return LOCLSMITH_NAME;
      case HEIGHTS:
        return HEIGHTS_NAME;
      default:
        return OTHERS_NAME;
    }
  };

  //general

  const updateProperty = (property) =>
    dispatch(changeCurrentProperty(property));

  const buildAddress = () => {
    return `${street_type} ${street} # ${corner} - ${number} · ${space} ${locale} ${shopping_center}`;
  };

  const buildListServicesToString = (list) => {
    let aux = "";

    for (let service of list) {
      aux = aux + ` ${convertCodeToTitleByServices(service)} -`;
    }
    return aux.slice(0, aux.length - 1);
  };

  const callbackViewSource = (mediaFile) => {
    const { name, type, url } = mediaFile;

    const newFile = {
      ...mediaFile,
      file: {
        name,
        type,
        url,
      },
    };
    setMediaSourceFile(newFile);
    setShowMediaSource(true);
  };

  const callbackCloseMediaSource = () => {
    setShowMediaSource(false);
  };

  const currentDate = (date) => moment(date).format("dddd, D MMMM");

  const handleTakeService = async () => {
    setIsLoader(true);

    try {
      const result = await acceptService(unique_key);

      const { accepted } = result.data;

      if (accepted) setMessageResponse("Servicio agendado con éxito.");
      else setMessageResponse("El servicio ya ha sido aceptado.");
      setTakeService(true);
    } catch (error) {
      setMessageResponse("Ocurrio un problema");
      setTakeService(true);
    }

    setIsLoader(false);
    updateProperty(null);
    dispatch(changeRequestService(initialStateRequestService));
  };

  const callbackOk = () => {
    setTakeService(false);
    history.push(`${PS_BASE_MENU_PATH}${PS_REQUESTS}`);
  };

  return (
    <>
      <ServiceProviderMyRequestDetails
        listResources={resources}
        callbackViewSource={callbackViewSource}
        defaultValueGeneralDescription={general_description}
        propertyTye={convertTypePropertyByCodeToName(property_type)}
        listServicesSelected={buildListServicesToString(selected_services)}
        servicePriority={convertTypePriority(priority)}
        addressProperty={buildAddress()}
        dateStart={currentDate(date_start)}
        timeStart={time_start}
        handleTakeService={handleTakeService}
      />
      {/* show media source */}
      {showMediaSource ? (
        <StateButtonModalHook
          component={ShowMediaSource}
          hook={[showMediaSource, setShowMediaSource]}
          object={{
            callbackCloseMediaSource: callbackCloseMediaSource,
            mediaSourceFile: mediaSourceFile,
          }}
        />
      ) : null}
      {takeService ? (
        <StateButtonModalHook
          component={PopupSuccessEvent}
          hook={[takeService, setTakeService]}
          object={{
            message: messageResponse,
            callback: callbackOk,
            btnText: "Ok ",
          }}
        />
      ) : null}
      {/* loading */}
      <LoadingPopup state={isLoader} />
    </>
  );
};

export default withRouter(ServiceProviderMyRequestDetailsController);
