import React from "react";

const ChatDescription = ({ message_unexpected, description }) => {
  return (
    <>
      <p className="cei-description">{description}</p>
      {message_unexpected !== "" && (
        <div className="cei-description">
          <span>Imprevisto: </span>
          {message_unexpected}
        </div>
      )}
    </>
  );
};

export default ChatDescription;
