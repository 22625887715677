import { useState } from "react";
import { camelCase } from "res/utils";
import "./SliderInput.css";
const SliderInput = ({
  title,
  units,
  min,
  max,
  step,
  initialValue,
  activityGroupIndex,
  activityIndex,
  spaceIndex,
  onChange,
  onSpaceChange,
  valueComplete,
}) => {
  const isInitiallyComplete = parseFloat(initialValue) >= max * 0.95;
  const [value, setValue] = useState(
    isInitiallyComplete ? max : parseFloat(valueComplete) || parseFloat(min)
  );
  const handleChange = (event) => {
    const newValue = parseFloat(event.target.value);
    setValue(newValue);
    const valueComplete = newValue - initialValue;
    const isAvailable = valueComplete >= 0;
    if (isInitiallyComplete) {
      setValue(max);
      onSpaceChange(spaceIndex, max);
      onChange(activityGroupIndex, activityIndex, spaceIndex, valueComplete);
    }
    if (isAvailable) {
      onChange(activityGroupIndex, activityIndex, spaceIndex, valueComplete);
      onSpaceChange(spaceIndex, newValue);
    }
  };

  return (
    <div className="slider-container">
      <div className="title-slider">
        <h3>{camelCase(title)}</h3>
      </div>
      <div className="slider-content">
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={handleChange}
          className="slider-input"
        />
        <div className="slider-value">{`${value}${units}`}</div>
      </div>
    </div>
  );
};

export default SliderInput;
