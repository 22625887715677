import USER_ICON_SVG from "assets/icons/png/jp-sub-picture.png";
import GoBackBtn from "components/GoBackBtn/GoBackBtn";
import moment from "moment";
import "moment/locale/es";
import { useEffect, useState } from "react";
import Linkify from "react-linkify";
import { ReactMic } from "react-mic";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import CALENDAR_ICON_SVG from "../../assets/icons/svg/calendar.svg";
import EMOJI_SVG from "../../assets/icons/svg/emoji.svg";
import SVG_HIDDEN from "../../assets/icons/svg/eye.svg";
import FROWN_ICON_SVG from "../../assets/icons/svg/frown.svg";
import LOGO_WHITE_TEXT_ICON_SVG from "../../assets/icons/svg/logo-white-text.svg";
import MEH_ICON_SVG from "../../assets/icons/svg/meh.svg";
import MICROPHONE_ICON_SVG from "../../assets/icons/svg/microphone.svg";
import SMILE_ICON_SVG from "../../assets/icons/svg/smile.svg";
import STOP_ICON_SVG from "../../assets/icons/svg/stop1-svg.svg";
import TRASH_ICON_SVG from "../../assets/icons/svg/trash.svg";
import { AddSquareButton } from "../../components/atoms/button/Button";
import { EmojiChat } from "../../components/atoms/emoji-picker/Emoji-Picker";
import ExcelPreview from "../../components/atoms/excelPreview/ExcelPreview";
import CheckIcon from "../../components/atoms/icons/CheckIcon";
import MediaSourceContainer from "../../components/atoms/mediaSource/MediaSourceContainer";
import PdfPreview from "../../components/atoms/pdfPreview/PdfPreview";
import { LoadingPopup } from "../../components/atoms/popup/Popup";
import useFile from "../../custom-hooks/useFile";
import useResourceUploader from "../../custom-hooks/useResourceUploader";
import ModalMultimedia from "../../modal/multimediaModal/MultimediaModal";
import {
  clearMessages,
  editingMessage,
  newMessage,
  updateMessages,
} from "../../reducers/messages.reducer";
import { camelCase, dateTimeFormater, newDateWithTime } from "../../res/utils";
import { PRINCIPAL_PATH } from "../../router/routes";
import { existUser } from "../../services-controller/auth";
import {
  getChangedMessages,
  getInitialMessages,
  getNewMessages,
} from "../../services-controller/database";
import {
  createNewMessage,
  getServiceById,
  readMessages,
  serviceProviderById,
  updateEntry,
} from "../../services-controller/http.cleinte.controller";
import { uploadAudioResource } from "../../services-controller/storage";
import BuildSources from "../loading/BuildSources";
import LazyLoad from "../loading/LazyComponent";
import "../loading/loadingScreen.css";
import "./caseEntryInfo.css";
import { getFormatedDate } from "./formatedDate";
moment.locale("es");

const CommentInfo = ({
  message,
  openMediaFilePopup,
  status_info,
  openModal,
}) => {
  let commentInfoContainer = null;

  if (message?.type === "audio") {
    commentInfoContainer = (
      <div className="audio-message">
        <audio src={message.audioResource?.url} controls={true}></audio>
      </div>
    );
  } else if (message?.type === "imageFiles") {
    commentInfoContainer = (
      <div className="image-message-container">
        {message.text && message.text !== "" && (
          <div className="text-container">
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a
                  className={"message-link"}
                  target="blank"
                  href={decoratedHref}
                  key={key}
                >
                  {decoratedText}
                </a>
              )}
            >
              {message.text}
            </Linkify>
          </div>
        )}
        {message.resources?.map((resource, index) => (
          <div key={index} className="image-message">
            {resource.type === "image" && (
              <LazyLoad
                resource={resource}
                openMediaFilePopup={openMediaFilePopup}
                Component={() => {
                  return (
                    <img
                      src={resource.url}
                      alt={resource.name}
                      onClick={() =>
                        openModal(
                          resource.url,
                          resource.name,
                          "jpg",
                          "multimedia"
                        )
                      }
                    />
                  );
                }}
              />
            )}
            {resource.type === "pdf" && (
              <LazyLoad
                type={"pdf"}
                resource={resource}
                openMediaFilePopup={openMediaFilePopup}
                Component={() => {
                  return (
                    <PdfPreview
                      pdfUrl={resource.url}
                      title={resource.name}
                      onError={() => console.log("error")}
                      key={index}
                    />
                  );
                }}
              />
            )}
            {resource.type === "excel" && (
              <LazyLoad
                resource={resource}
                openMediaFilePopup={openMediaFilePopup}
                type={"excel"}
                Component={() => {
                  return (
                    <ExcelPreview
                      excelUrl={resource.url}
                      title={resource.name}
                      onError={() => console.log("error")}
                      key={index}
                    />
                  );
                }}
              />
            )}
          </div>
        ))}
      </div>
    );
  } else if (
    message?.text &&
    message.text !== "" &&
    (!message.resources || message.resources.length === 0) &&
    !message.audioResource
  ) {
    commentInfoContainer = (
      <div className="text-container">
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              className={"message-link"}
              target="blank"
              href={decoratedHref}
              key={key}
            >
              {decoratedText}
            </a>
          )}
        >
          {message.text}
        </Linkify>
      </div>
    );
  }

  return <div>{commentInfoContainer}</div>;
};

const Comment = ({ message, idEntry, openMediaFilePopup, openModal }) => {
  const user = useSelector((state) => state.auth.user);
  let container = "comment-container";
  let comment_syle_container = "cei-comment-container";
  let perfil_image = "comment-photo-container";
  let comment_info = "botton-info-container";
  let top_info = "top-info-container";
  let hidden_comment = "hidden-comment-emoji";
  let status_info = "status-info";
  let message_info_detail = "message-info-detail";

  if (
    (message.emisor &&
      message?.emisor?.register_data?.name === user?.register_data?.name) ||
    message?.name === user?.register_data?.name
  ) {
    container = "comment-container-m";
    comment_syle_container = "comment-container-mine";
    perfil_image = "comment-photo-container-mine";
    comment_info = "botton-info-container-mine";
    top_info = "top-info-container-mine";
    status_info = "status-info-mine";
    hidden_comment = "hidden-comment-emoji-mine";
    message_info_detail = "message-info-detail-mine";
  }

  const name = message?.emisor?.register_data?.name || message?.name;

  let { id } = useParams();

  const [visible, setVisible] = useState(!message.is_valid);
  const [showDetail, setShowDetail] = useState(false);
  const codeToIcon = (code) => {
    switch (code) {
      case 0:
        return SMILE_ICON_SVG;
      case 1:
        return MEH_ICON_SVG;
      case 2:
        return FROWN_ICON_SVG;
      default:
        return null;
    }
  };
  const showHowLong = (time) => {
    const now = new Date();
    const dateTime = new Date(time);

    const diffInSeconds = Math.floor(
      (now.getTime() - dateTime.getTime()) / 1000
    );

    const timeUnits = [
      { unit: "Año", seconds: 31536000 },
      { unit: "Mes", seconds: 2592000 },
      { unit: "Día", seconds: 86400 },
      { unit: "Hora", seconds: 3600 },
      { unit: "Minuto", seconds: 60 },
      { unit: "Segundo", seconds: 1 },
    ];

    for (const unitData of timeUnits) {
      if (diffInSeconds >= unitData.seconds) {
        const count = Math.floor(diffInSeconds / unitData.seconds);
        return `Hace ${count} ${unitData.unit}${count > 1 ? "s" : ""}`;
      }
    }
  };

  const setStatusCheck = () => {
    if (message.admin_read && message.client_read && message.sp_read) {
      return "#53bdeb";
    } else if (
      (message.admin_read && message.sp_read) ||
      (message.admin_read && message.client_read) ||
      (message.client_read && message.sp_read)
    ) {
      return "#FD941B";
    } else if (message.admin_read || message.client_read || message.sp_read) {
      return "#652D52";
    } else {
      return "rgb(111, 111, 111)";
    }
  };

  const newMessageCheck =
    user.register_type === 200 ? !message.sp_read : !message.client_read;
  return (
    <div className={`${container} ${newMessageCheck ? "" : ""}`}>
      <div
        className={`${comment_syle_container} ${visible ? "" : "hide-comment"}`}
      >
        <div className={`${perfil_image}`}>
          <img
            className={`comment-photo ${message.thubmnail !== "" ? "" : "comment-photo"
              }`}
            src={message.thubmnail !== "" ? message.thubmnail : USER_ICON_SVG}
            onClick={() => {
              openModal(
                message.thubmnail !== "" ? message.thubmnail : USER_ICON_SVG,
                name,
                "jpg",
                "profile"
              );
            }}
            alt="perfil-profile"
          />
        </div>

        <div className="comment-info-container">
          <div className={`${top_info}`}>
            <div className="comment-user-info">
              <div className="comment-user-name">{camelCase(name)}</div>
            </div>
          </div>

          <div className={`${comment_info}`}>
            <CommentInfo
              message={message}
              openMediaFilePopup={openMediaFilePopup}
              status_info={status_info}
              openModal={openModal}
            />

            <div
              className={`${message_info_detail} ${showDetail ? "active" : ""
                } `}
            >
              <div className="separate-line"></div>
              <div className="read-detail">
                {message.admin_read ? (
                  <span>
                    {message.admin_lector ? message.admin_lector : "JustoPago"}:{" "}
                    {getFormatedDate(message.admin_read_date)}
                  </span>
                ) : null}
                {message.sp_read ? (
                  <span>
                    Residente: {getFormatedDate(message.sp_read_date)}
                  </span>
                ) : null}
                {message.client_read ? (
                  <span>
                    Cliente: {getFormatedDate(message.client_read_date)}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className={`${status_info}`}>
            <div className="commenr-user-date">{showHowLong(message.date)}</div>
            <div
              className="check-icon-message"
              onClick={() => setShowDetail(!showDetail)}
            >
              <CheckIcon
                className="check-icon"
                color={`${setStatusCheck()}`}
                height="15px"
                width="15px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CaseEntryInfo = ({ location, history }) => {
  const dispatch = useDispatch();
  const [setEmojiHidenn, showEmojiHidden] = useState(false);
  const [isActive, setIsActive] = useState(false);
  let idService = useParams().id;
  const emojiHidden = () => {
    showEmojiHidden(true);
    setIsActive(!isActive);

    if (setEmojiHidenn) {
      showEmojiHidden(false);
      setIsActive(false);
    }
  };
  const state = useSelector((state) => {
    return {
      messages_list: state.messages_list,
      form_user: state.form_user,
      form_service_provider: state.form_service_provider,
      auth: state.auth,
      state_entry: state.state_entry,
    };
  });

  const user = state.auth.user;

  const stateEntry = useSelector((state) => state.state_entry.state);
  const { object, id, userType } = stateEntry;

  const form_entry = useSelector((state) => state.form_entry);
  const userAuth = useSelector((state) => state.auth);

  const { messages_list, form_user, form_service_provider } = state;

  const { messageList } = messages_list;

  const [, /*resourcesLink*/ processResources] = useResourceUploader();
  const [messageText, setMessageText] = useState("");
  const [isLoader, setIsLoader] = useState(true);
  const [service, setService] = useState(null);
  const [serviceProvider, setServiceProvider] = useState([]);
  const [showMediaSource, setShowMediaSource] = useState(false);
  const [mediaSourceFile, setMediaSourceFile] = useState("");
  const [resources, setResources] = useState([]);
  const [recording, setRecording] = useState(false);
  const [showAudio, setAudio] = useState(false);
  const [showRecorder, setShowRecorder] = useState(false);
  const [recorded, setRecorded] = useState(null);

  const [typeFile, setTypeFile] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [multimediaType, setMultimediaType] = useState("");
  const [nameFile, setNameFile] = useState("");
  const [multimediaLink, setMultimediaLink] = useState("");

  const [approvalPopup, setApprovalPopup] = useState(false);

  const [generateApproval, setGenerateApproval] = useState(false);

  const icons = [SMILE_ICON_SVG, MEH_ICON_SVG, FROWN_ICON_SVG];

  const callbackHiddenEntry = async () => {
    const newEntry = { ...object, is_visible: !object.is_visible };
    try {
      await updateEntry(id, newEntry.unique_key, "admin", newEntry);
    } catch (error) {
      console.error(error);
      throw error;
    }
    history.push(`/services/bitacle/${id}`);
  };

  const fetchServiceById = async () => {
    setIsLoader(true);
    const service = await getServiceById(id);
    setService(service);
    const { service_provider } = service;
    //dispatch(updateMessages(object.messages))

    if (service_provider !== "") {
      const serviceProvider = await serviceProviderById(service_provider);
      //setServiceProvider(serviceProvider)
    }

    setServiceProvider(service.service_providers);

    setIsLoader(false);
  };

  const openModal = (link, name, typeFile, multimediaType) => {
    setMultimediaLink(link);
    setNameFile(name);
    setTypeFile(typeFile);
    setMultimediaType(multimediaType);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const activities = object?.activities_selected;

  useEffect(() => {
    if (!existUser()) {
      history.push(PRINCIPAL_PATH);
    } else {
      fetchServiceById();
    }

    return () => { };
  }, [id]); // eslint-disable-line

  const handleNewMessages = async (messages) => {
    dispatch(updateMessages(messages));
  };

  const changedMessages = (snapshot) => {
    dispatch(editingMessage(snapshot.val()));
  };

  const markMessageAsRead = async (entry, isClient, service) => {
    if (entry?.statusMessages?.newSpMessage && !isClient) {
      await readMessages(id, entry?.unique_key, "sp", user);
    } else if (entry?.statusMessages?.newClientMessage && isClient) {
      await readMessages(id, entry?.unique_key, "client", user);
    }
  };

  useEffect(() => {
    const listenerMessages = getChangedMessages(
      id,
      object.unique_key,
      async (snapshot) => {
        changedMessages(snapshot);
      }
    );

    let newMessageListener = () => { };
    getInitialMessages(id, object.unique_key).then((messages) => {
      handleNewMessages(messages);
      newMessageListener = getNewMessages(
        id,
        object.unique_key,
        (snapshot) => {
          dispatch(newMessage(snapshot.val()));
        },
        messages.length
      );
    });

    /*setTimeout(() => {
      markMessageAsRead(object, userType)
    }, 5000)*/

    return () => {
      newMessageListener();
      listenerMessages();
      markMessageAsRead(object, userType, service);
      dispatch(clearMessages());
    };
  }, []);

  const capitalizeWord = (month) => {
    return month ? month.charAt(0).toUpperCase() + month.slice(1) : "Mes";
  };
  const dateMoment = moment(object?.date?.split("-")[5], "HH:mm:ss");
  const dateFormated = dateMoment.format("HH:mm A");
  const handleResize = (event) => {
    event.target.style.height = "auto";
    event.target.style.height = `${Math.min(event.target.scrollHeight, 70)}px`;
  };

  const handleMessage = (e) => {
    setMessageText(e.target.value);
  };

  const setResourcingList = (resources) => {
    setResources(resources);
  };
  const file = useFile(resources, setResourcingList, setIsLoader);

  const onRecording = async () => {
    setAudio(false);
    await setShowRecorder(true);
    setRecording(true);
  };

  const pauseRecording = () => {
    setRecording(false);
  };

  const onStopRecording = (recordedBlob) => {
    setRecorded(recordedBlob);
    setAudio(true);
    setShowRecorder(false);
  };

  const onData = (recordedBlob) => {
    setRecorded(recordedBlob);
  };

  const deleteAudio = () => {
    setRecorded(null);
    setAudio(false);
  };

  const sendMessage = async () => {
    let resourcesLink = [];
    let audioResource = null;
    let messageType = "";

    if (messageText === "" && recorded === null && resources.length === 0) {
      return;
    } else if (resources.length > 0 && recorded === null) {
      resourcesLink = await processResources(resources, id);

      messageType = "imageFiles";
    } else if (recorded !== null && resources.length === 0) {
      audioResource = await uploadAudioResource(recorded?.blob);
      messageType = "audio";
    } else if (
      messageText !== "" &&
      recorded === null &&
      resources.length === 0
    ) {
      messageType = "text";
    }

    let obj = {
      date: newDateWithTime(),
      is_valid: false,
      state: 1,
      emisor: user,
      name: user.name,
      text: messageText,
      thubmnail: user.register_data.thumbnail,
      admin_read: false,
      admin_read_date: "",
      client_read: userType === "client" ? true : false,
      client_read_date: userType ? newDateWithTime() : "",
      type: messageType,
      audioResource: audioResource,
      resources: resourcesLink,
      sp_read: userType === "sp" ? true : false,
      sp_read_date: userType ? newDateWithTime() : "",
      phase: {
        name: object.phase.name,
        code: object.phase.code,
      },
    };
    setMessageText("");
    const user_type = userType;
    const userInfo = {
      name: user.register_data.name,
      id: user.id,
    };

    await createNewMessage(id, object.unique_key, user_type, obj, userInfo);

    setRecorded(null);
    setResources([]);
    showEmojiHidden(false);
    file.utils.clear();
    setIsLoader(false);
  };

  const openMediaFilePopup = () => { };
  return Object.keys(serviceProvider).length > 0 ? (
    <div className="new-service-container">
      <div className="desktop-container">
        {
          <div className="entry-info-container background-image-class">
            <div className="header-info-container">
              <div className="cei-header-container">
                <div className="cei-header-right">
                  <div className="cei-header-title">
                    {activities
                      ? activities.map((activity, index) => {
                        if (index >= 3) return null;
                        else
                          return (
                            <div key={index} className="title-entrie">
                              {" "}
                              <span>{activity.title} </span>
                            </div>
                          );
                      })
                      : object.title}
                  </div>

                  <div className="cei-header-extra-info">
                    <div className="cei-calendar-icon-container">
                      <img
                        className="cei-calendar-icon"
                        src={CALENDAR_ICON_SVG}
                        alt=""
                      />
                    </div>
                    {userAuth.userType === "sp" && (
                      <div
                        style={{
                          position: "absolute",
                          right: 70,
                          top: 25,
                        }}
                      >
                        <img
                          src={SVG_HIDDEN}
                          alt=""
                          onClick={callbackHiddenEntry}
                        />
                      </div>
                    )}

                    <div className="cei-date-container">
                      <div className="cei-date">
                        {dateTimeFormater({ date: object.date, month: "long", day: "numeric", })}
                      </div>

                      <div className="cei-date">
                        {dateTimeFormater({ date: object.date, timeStyle: 'short', timeZone: 'America/Bogota', hour12: true, })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <GoBackBtn type="default" />
            </div>

            <p className="cei-description">{object.description}</p>
            {object.message_unexpected !== "" && (
              <div className="cei-description">
                <span>Imprevisto: </span>
                {object.message_unexpected}
              </div>
            )}

            {/**GREFIC EVIDENCE*/}

            <div className="graphic-evidence-container">
              {object.resources?.map((evidence, key) => (
                <BuildSources
                  evidence={evidence}
                  key={key}
                  openModal={openModal}
                />
              ))}
            </div>

            {/**COMMENTS*/}
            <div className="message-container">
              {messageList?.map((message, key) =>
                message.is_valid ? null : (
                  <Comment
                    key={key}
                    message={message}
                    idEntry={object.unique_key}
                    openMediaFilePopup={openMediaFilePopup}
                    openModal={openModal}
                  />
                )
              )}
            </div>

            <div className="chat-controller">
              {setEmojiHidenn && (
                <div className={"emoji-icons"}>
                  <EmojiChat
                    emojiHidden={emojiHidden}
                    setMessageText={setMessageText}
                    messageText={messageText}
                  />
                </div>
              )}

              <div className="">
                <div className="message-input-container">
                  {!showAudio && !recording ? (
                    <textarea
                      id="messageInput"
                      placeholder="Escribe Un Nuevo mensaje"
                      value={messageText}
                      className="cei-textarea"
                      onChange={handleMessage}
                      onInput={handleResize}
                    />
                  ) : null}
                  {showRecorder ? (
                    <ReactMic
                      record={recording}
                      className="sound-wave"
                      mimeType="audio/mp3"
                      onStop={onStopRecording}
                      onData={onData}
                      strokeColor="#FD941B"
                      backgroundColor="white"
                    />
                  ) : null}
                  {showAudio ? (
                    <div className="audio-recorder">
                      <audio src={recorded?.blobURL} controls={true} />
                      <div
                        className="delete-entry-container"
                        onClick={deleteAudio}
                      >
                        <img
                          alt=""
                          src={TRASH_ICON_SVG}
                          className="delete-entry-icon"
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="message-utils">
                  <div className="send-message-container">
                    <div className="multimedia-buttons">
                      {!recording && !showAudio ? (
                        <div className="dano-foto">
                          <div
                            className="add-foto-container"
                            onClick={file.openFileSelector}
                          >
                            <AddSquareButton />
                          </div>
                          <MediaSourceContainer
                            sources={resources}
                            callbackViewSource={file.callbackViewSource}
                            deleteMediaSource={
                              file.callabackDeleteInmediateMediaSource
                            }
                          />
                        </div>
                      ) : null}

                      <div className="recording-icons">
                        {!resources.length > 0 ? (
                          recording ? (
                            <div className="stop-icon" onClick={pauseRecording}>
                              <img src={STOP_ICON_SVG} alt="" />
                            </div>
                          ) : (
                            <div className="micro-icon" onClick={onRecording}>
                              <img src={MICROPHONE_ICON_SVG} alt="" />
                            </div>
                          )
                        ) : null}
                      </div>

                      {!recording && !showRecorder && (
                        <div
                          className={`emoji-icons icons-hover ${isActive ? "active" : ""
                            }`}
                          onClick={() => {
                            emojiHidden();
                          }}
                        >
                          <img src={EMOJI_SVG} alt="" />
                        </div>
                      )}
                    </div>
                    {!showRecorder ? (
                      <button
                        className="send-message-button"
                        onClick={() => {
                          sendMessage();
                        }}
                      >
                        Enviar Mensaje
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        <LoadingPopup state={isLoader} />
      </div>

      <ModalMultimedia
        isOpen={showModal}
        onClose={closeModal}
        type={multimediaType}
        image={multimediaLink}
        name={nameFile}
        typeFile={typeFile}
      />
    </div>
  ) : (
    <div className="loading-screen">
      <img
        src={LOGO_WHITE_TEXT_ICON_SVG}
        className="loading-logo"
        alt={"LOGO_WHITE_TEXT_ICON_SVG"}
      />
    </div>
  );
};

export default withRouter(CaseEntryInfo);
