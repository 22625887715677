//Type properties code
const HOUSE_CODE = 100
const LOCAL_CODE = 110
const WAREHOSE_CODE = 111
const OFFICE_CODE = 112
const APARTMENT_CODE = 120
const HOUSE_CONDOMINIUM_CODE = 121
const OTHER_PROPERTY_CODE = 200

//User type code
const PARTICULAR_CODE = 100
const COMPANY_CODE = 110
const REAL_STATE_CODE = 111
const INSURANCE_CODE = 112
const HORIZONTAL_HOUSE_CODE = 120
const HORIZONTAL_PROPERTY_CODE = 121
const OTHER_USER_CODE = 200

//Management type code
const HOUSE_PERSONAL_CODE = 100
const PROPERTY_PERSONAL_CODE = 101
const COMPANY_PERSONAL_CODE = 110
const REAL_STATE_PERSONAL_CODE = 111
const INSURANCE_PERSONAL_CODE = 112
const NEW_PERSONAL_PERSONAL_CODE = 200

//Register type code
const CUSTOMER_CODE = 100
const SERVICE_PROVIDER_CODE = 200
const SERVICE_PROVIDER_SUPERVISOR_CODE = 210
const SERVICE_PROVIDER_OFFICIAL_CODE = 211
const SERVICE_PROVIDER_ASSITANT_CODE = 212
//NEW ROLES
const EMPLPOYEE_CODE = 300
const COO_CODE = 220
const OPERATION_COORDINATOR_CODE = 221
const RESIDENT_CODE = 222
const CARPENTRY_COORDINATOR_CODE = 230
const CARPENTRY_LEADER_CODE = 231
const CARPENTRY_ASSISTANT_CODE = 232

//ADMINISTRATION
const CFO_CODE = 240
const ADMIN_COORDINATOR_CODE = 241
const ADMIN_ASISTANT_CODE = 242
const HUMAN_RESOURCES_CODE = 243

//PLANEATION
const CSO_CODE = 250
const PLANEATION_COORDINATOR_CODE = 251
const PLANEATION_ASISTANT_CODE = 252
const DOCUMENTATION_ASISTANT_CODE = 253
const PLANEATION_AUXILIARY_CODE = 254
const POST_SOLD_AUXILIARY_CODE = 255

const DESIGN_COORDINATOR_CODE = 260
const ARCHITECT_DESIGN_CODE = 261
const DESIGN_AUXILIARY_CODE = 262

const COMERCIAL_DIRECTOR_CODE = 270
const COMERCIAL_COORDINATOR_CODE = 271
const COMERCIAL_ASISTANT_CODE = 272
const MARKETING_COORDINATOR_CODE = 280



//Priority type code
const MEDIUM_CODE = 0
const HIGH_CODE = 1
const PRIORITY_CODE = 2

export {
    HOUSE_CODE,
    LOCAL_CODE,
    WAREHOSE_CODE,
    OFFICE_CODE,
    APARTMENT_CODE,
    HOUSE_CONDOMINIUM_CODE,
    OTHER_PROPERTY_CODE,
    PARTICULAR_CODE,
    COMPANY_CODE,
    REAL_STATE_CODE,
    INSURANCE_CODE,
    HORIZONTAL_HOUSE_CODE,
    HORIZONTAL_PROPERTY_CODE,
    OTHER_USER_CODE,
    HOUSE_PERSONAL_CODE,
    PROPERTY_PERSONAL_CODE,
    COMPANY_PERSONAL_CODE,
    REAL_STATE_PERSONAL_CODE,
    INSURANCE_PERSONAL_CODE,
    NEW_PERSONAL_PERSONAL_CODE,
    CUSTOMER_CODE,
    SERVICE_PROVIDER_SUPERVISOR_CODE,
    SERVICE_PROVIDER_OFFICIAL_CODE,
    SERVICE_PROVIDER_ASSITANT_CODE,
    MEDIUM_CODE,
    HIGH_CODE,
    PRIORITY_CODE,
    SERVICE_PROVIDER_CODE,
    COO_CODE,
    OPERATION_COORDINATOR_CODE,
    RESIDENT_CODE,
    CARPENTRY_COORDINATOR_CODE,
    CARPENTRY_LEADER_CODE,
    CARPENTRY_ASSISTANT_CODE,
    CFO_CODE,
    ADMIN_COORDINATOR_CODE,
    ADMIN_ASISTANT_CODE,
    HUMAN_RESOURCES_CODE,
    CSO_CODE,
    PLANEATION_COORDINATOR_CODE,
    PLANEATION_ASISTANT_CODE,
    DOCUMENTATION_ASISTANT_CODE,
    PLANEATION_AUXILIARY_CODE,
    POST_SOLD_AUXILIARY_CODE,
    DESIGN_COORDINATOR_CODE,
    ARCHITECT_DESIGN_CODE,
    DESIGN_AUXILIARY_CODE,
    COMERCIAL_DIRECTOR_CODE,
    COMERCIAL_COORDINATOR_CODE,
    COMERCIAL_ASISTANT_CODE,
    MARKETING_COORDINATOR_CODE,
    EMPLPOYEE_CODE
}