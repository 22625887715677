const CREATE_USER_ERROR_MESSAGE = 'No se pudo crear el usuario, intente mas tarde'
const PHOTO_VALIDATION_ERROR_MESSAGE = 'La imagen no cumple con las condiciones requeridas\nMínima: 100 x 100 - Máximo 500 x 500'
const PHONE_VALIDATION_ERROR_MESSAGE = 'El número de teléfono está mal formateado.'
const EMAIL_VALIDATION_ERROR_MESSAGE = 'La dirección de correo electrónico está mal formateada.'
const PASS_VALIDATION_ERROR_MESSAGE = 'La contraseña debe tener 6 caracteres o más.'
const EMAIL_PASS_VALIDATION_ERROR_MESSAGE = `La dirección de correo electrónico está mal formateada o ${PASS_VALIDATION_ERROR_MESSAGE}`
const REQUIRED_ERROR_MESSAGE = 'Todos los campos son requeridos'

export {
    CREATE_USER_ERROR_MESSAGE,
    PHOTO_VALIDATION_ERROR_MESSAGE,
    PHONE_VALIDATION_ERROR_MESSAGE,
    EMAIL_VALIDATION_ERROR_MESSAGE,
    PASS_VALIDATION_ERROR_MESSAGE,
    EMAIL_PASS_VALIDATION_ERROR_MESSAGE,
    REQUIRED_ERROR_MESSAGE
}