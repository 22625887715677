const getValueByKey = (key) => localStorage.getItem(key);
const setValueByKey = (key, value) => localStorage.setItem(key, value);
const getObjectByKey = (key) => JSON.parse(localStorage.getItem(key));
const setObjectByKey = (key, value) =>
  localStorage.setItem(key, JSON.stringify(value));
const isAuthorized = (key) => {
  const result = getObjectByKey(key);
  return result !== null;
};
const removeValueByKey = (key) => localStorage.removeItem(key);

export {
  getObjectByKey,
  getValueByKey,
  isAuthorized,
  removeValueByKey,
  setObjectByKey,
  setValueByKey,
};
