import React from "react";
import "../route.css";
import { formatDistance, formatDuration } from "./HeaderRouteInfo";
import TURN_RIGHT from "assets/icons/svg/turn_right.svg";
import TURN_U from "assets/icons/svg/turn_U_left.svg";
import TURN_LEFT from "assets/icons/svg/turn_left.svg";
import SLIDE_RIGHT from "assets/icons/svg/slide_right.svg";
import SLIDE_LEFT from "assets/icons/svg/slide_left.svg";
import CONTINUE from "assets/icons/svg/straight.svg";
import COMPLETE_WAYPOINT from "assets/icons/svg/complete-waypoint.svg";
import COMPLETE_ROUTE from "assets/icons/svg/complete-route.svg";
import SHARP_RIGHT from "assets/icons/svg/sharp-right.svg";
import SHARP_LEFT from "assets/icons/svg/sharp-left.svg";

const StepRouteInfo = ({ step }) => {
  const convertType = (type) => {
    switch (type) {
      case "depart":
        return "Inicio de la ruta";
      case "waypoint":
        return "Punto de paso";
      case "arrive":
        return "Llegada a destino";
      default:
        break;
    }
  };

  const convertToIcon = (modifier, type) => {
    switch (type) {
      case "waypoint":
        return COMPLETE_WAYPOINT;
      case "arrive":
        return COMPLETE_ROUTE;

      default:
        switch (modifier) {
          case "":
            return CONTINUE;
          case "right":
            return TURN_RIGHT;
          case "left":
            return TURN_LEFT;
          case "uturn":
            return TURN_U;
          case "slight right":
            return SLIDE_RIGHT;
          case "slight left":
            return SLIDE_LEFT;
          case "sharp right":
            return SHARP_RIGHT;
          case "sharp left":
            return SHARP_LEFT;

          default:
            return null;
        }
    }
  };

  return (
    <div className="contain-steps">
      <div className="first-contain-step">
        <p>{convertType(step.currentStep.type)}</p>
        <h3>{step.currentStep.instruction}</h3>
        <span>
          <p>{formatDuration(step.currentStep.duration)}</p>
          <p>{formatDistance(step.currentStep.distance)}</p>
        </span>
      </div>
      <div className="next-step">
        <p>{step.nextStep.instruction}</p>
        <img
          width={55}
          src={convertToIcon(step.nextStep.modifier, step.nextStep.type)}
          alt=""
        />
      </div>
    </div>
  );
};

export default StepRouteInfo;
