import React from "react";
import "./psCotizacion.css";
import { Appbar, AppbarLogo } from "../../../components/atoms/appbar/Appbar";
import {
  NewIconButton,
  AddSquareButton,
} from "../../../components/atoms/button/Button";
import POINTING_ICON_SVG from "../../../assets/icons/svg/finger-point.svg";
import CHAT_ICON_SVG from "../../../assets/icons/svg/chat.svg";
import RULE_ICON_SVG from "../../../assets/icons/svg/medida.svg";
import TOOL_ICON_SVG from "../../../assets/icons/svg/tool.svg";
import IMAGE_ICON_SVG from "../../../assets/icons/svg/image.svg";
import LIST_ICON_SVG from "../../../assets/icons/svg/list.svg";
import PIN_ICON_SVG from "../../../assets/icons/svg/map-pin.svg";
import SAD_ICON_SVG from "../../../assets/icons/svg/frown.svg";
import AREA_ICON_SVG from "../../../assets/icons/svg/area.svg";
import HOME_ICON_SVG from "../../../assets/icons/svg/home.svg";
import HASH_ICON_SVG from "../../../assets/icons/svg/hash.svg";
import DOLLAR_ICON_SVG from "../../../assets/icons/svg/dollar-sign.svg";
import CLOCK_ICON_SVG from "../../../assets/icons/svg/clock.svg";
import PARAGRAPH_ICON_SVG from "../../../assets/icons/svg/parrafo.svg";
import SHIELD_ICON_SVG from "../../../assets/icons/svg/shield.svg";
import ADD_USER_ICON_SVG from "../../../assets/icons/svg/user-plus.svg";
import PLUS_ICON_SVG from "../../../assets/icons/svg/plus-square.svg";
import ADD_CIRCLE_ICON_SVG from "../../../assets/icons/svg/plus-circle.svg";
import SAVE_ICON_SVG from "../../../assets/icons/svg/save.svg";
import EYE_ICON_SVG from "../../../assets/icons/svg/eye.svg";
import TRASH_ICON_SVG from "../../../assets/icons/svg/trash.svg";
import { IconTextarea } from "../../../components/atoms/textarea/Textarea";
import { FilePicker } from "react-file-picker";
import {
  WarpSoruce,
  WarpSoruceURI,
} from "../../../components/atoms/media/MediaSource";
import { IconDropdown as IconDropdown2 } from "../../../components/atoms/dropdown/Dropdown";
import {
  IconInput,
  CurrencyFormatInput,
} from "../../../components/atoms/input/Input";
import X_ICON_SVG from "../../../assets/icons/svg/x.svg";
import MaterialesTable from "./MaterialesTable.jsx";

const CotizacionProblemas = ({
  damageType = [],
  callbackSelectedType,
  selectedType,
  callbackDescription,
  descriptionAndOrigin,
  callbackStep,
  listSolutionSteps,
  currentStep,
  callbackAddStep,
  callbackDelateStep,
  damageLocations = [],
  callbackDamageLocation,
  damageLocation,
  listFloorLevels = [],
  callbackFloorLevels,
  floorLevel,
  measureUnits = [],
  callbackMeasureUnits,
  measureUnit,
  callbackArea,
  defaultValueArea,
}) => {
  return (
    <>
      <div className="new-service-text ps-new-entry-title">
        Problema a solucionar
      </div>

      <div className="categoria-container">
        <div className="new-service-text">
          <div>
            <img
              src={POINTING_ICON_SVG}
              id="new-service-icon"
              alt={"POINTING_ICON_SVG"}
            />
          </div>
          <div> Tipo de daño </div>
        </div>
      </div>

      <div className="new-entry-dropdown-container cotizacion-space-fields">
        <IconDropdown2
          callback={callbackSelectedType}
          defaultSelected={selectedType}
          valueSelected={selectedType}
          icon={SAD_ICON_SVG}
          style="new-entry-dropdown"
          items={damageType.map((state, index) => (
            <option value={state.name} key={index} className="rol-option">
              {state.name}
            </option>
          ))}
        />
      </div>

      <div className="descripcion-container">
        <div className="new-service-text">
          <div>
            <img
              src={CHAT_ICON_SVG}
              id="new-service-icon"
              alt={"CHAT_ICON_SVG"}
            />
          </div>
          <div>Descripción y origen del daño</div>
        </div>

        <div className="cotizacion-textarea-container">
          <IconTextarea
            icon={PARAGRAPH_ICON_SVG}
            callback={callbackDescription}
            defaultValue={descriptionAndOrigin}
            placeholder="Ingresa descripción y origen del daño"
          />
        </div>
      </div>

      <div className="categoria-container">
        <div className="new-service-text">
          <div>
            <img
              src={LIST_ICON_SVG}
              id="new-service-icon"
              alt={"POINTING_ICON_SVG"}
            />
          </div>
          <div> Descripción de la solución </div>
        </div>
      </div>

      <div className="descripcion-solucion-container">
        <div className="solucion-input">
          <IconInput
            callback={callbackStep}
            defaultValue={currentStep}
            icon={TOOL_ICON_SVG}
            placeholder="Ingrese un nuevo paso"
          />
        </div>

        <div className="solucion-add-button-container">
          <img
            src={ADD_CIRCLE_ICON_SVG}
            className="solucion-add-button"
            onClick={callbackAddStep}
            alt="add"
          />
        </div>
      </div>

      {listSolutionSteps
        .filter((item) => item.is_visible).slice().sort((a,b)=>b?.position - a?.position).reverse()
        .map((object, index) => (
          <div key={index} className="descripcion-solucion-container">
            <div className="solucion-input">
              {/* CAMBIAR POR UN COMPONENTE QUE SOLO MUESTRE EL VALOR, DESDE AQUI NO SE PUEDE MODIFICAR */}
              <IconInput
                defaultValue={object.title}
                icon={TOOL_ICON_SVG}
                placeholder={object.title}
              />
            </div>

            <div className="solucion-add-button-container">
              <img
                src={X_ICON_SVG}
                className="solucion-add-button"
                onClick={() => callbackDelateStep()}
                alt="delet"
              />
            </div>
          </div>
        ))}

      <div className="categoria-container">
        <div className="new-service-text">
          <div>
            <img
              src={PIN_ICON_SVG}
              id="new-service-icon"
              alt={"POINTING_ICON_SVG"}
            />
          </div>
          <div>Ubicación del daño</div>
        </div>
      </div>

      <div className="new-entry-dropdown-container cotizacion-space-fields">
        <IconDropdown2
          callback={callbackDamageLocation}
          defaultSelected={damageLocation}
          valueSelected={damageLocation}
          icon={HOME_ICON_SVG}
          style="new-entry-dropdown"
          items={damageLocations.map((state, index) => (
            <option key={index} value={state.name} className="rol-option">
              {state.name}
            </option>
          ))}
        />
      </div>

      <div className="new-entry-dropdown-container cotizacion-space-fields">
        <IconDropdown2
          callback={callbackFloorLevels}
          defaultSelected={floorLevel}
          valueSelected={floorLevel}
          icon={HASH_ICON_SVG}
          items={listFloorLevels.map((state, index) => (
            <option key={index} value={state.name} className="rol-option">
              {state.name}
            </option>
          ))}
          style="new-entry-dropdown"
        />
      </div>

      <div className="categoria-container">
        <div className="new-service-text">
          <div>
            <img
              src={PIN_ICON_SVG}
              id="new-service-icon"
              alt={"POINTING_ICON_SVG"}
            />
          </div>
          <div> Área afectada </div>
        </div>
      </div>

      <div className="area-entry-container">
        <div className="new-time-entry">
          <div className="time-entry-text">Área a intervenir</div>

          <div className="new-entry-dropdown-container area-fields-container">
            <IconInput
              defaultValue={defaultValueArea}
              callback={callbackArea}
              type="number"
              icon={AREA_ICON_SVG}
              placeholder="Área"
            />
          </div>
        </div>

        <div className="new-time-entry">
          <div className="time-entry-text">Unidad de medida</div>

          <div className="new-entry-dropdown-container area-fields-container">
            <IconDropdown2
              callback={callbackMeasureUnits}
              defaultSelected={measureUnit}
              valueSelected={measureUnit}
              icon={RULE_ICON_SVG}
              items={measureUnits.map((state, index) => (
                <option value={state.name} key={index} className="rol-option">
                  {state.name}
                </option>
              ))}
              style="new-entry-dropdown"
            />
          </div>
        </div>
      </div>
    </>
  );
};

const CotizacionMateriales = ({
  metadata,
  addMaterial,
  numMateriales,
  // callbacks
  callbackMaterail,
  defaultValueNameMaterial,
  callbackQuantity,
  defaultValueQuantity,
  callbackUnitMeasure,
  defaultValueUnitMeasure,
  callbackUnitPrice,
  defaultValueUnitPrice,
  callbackQuantityMaterial,
  defaultValueQuantityMaterial,
  deleteMaterial,
}) => {
  return (
    <>
      <div className="new-service-text ps-new-entry-title title-bottom-margin">
        Materiales Item
      </div>

      <MaterialesTable
        listUnitsMeasures={metadata.measure_units}
        callbackMaterail={(e) => callbackMaterail(e)}
        defaultValueMaterial={defaultValueNameMaterial}
        callbackQuantity={(e) => callbackQuantity(e)}
        defaultValueQuantity={defaultValueQuantity}
        callbackQuantityMaterial={(e) => callbackQuantityMaterial(e)}
        defaultValueQuantityMaterial={defaultValueQuantityMaterial}
        callbackUnitMeasure={(e) => callbackUnitMeasure(e)}
        defaultValueUnitMeasure={defaultValueUnitMeasure}
        callbackUnitPrice={(e) => callbackUnitPrice(e)}
        defaultValueUnitPrice={defaultValueUnitPrice}
        defaultValueTotal={defaultValueUnitPrice * defaultValueQuantity}
      />

      <div className="add-material-container" onClick={addMaterial}>
        <div className="ai-icon-container">
          <img className="ai-icon" src={PLUS_ICON_SVG} alt="plus"/>
        </div>
        <div className="ai-text">Agregar material</div>
      </div>

      {numMateriales.filter((item) => item.is_visible).map((material, index) => (
        //CAMBIAR LOS VALORES POR TEXTOS Y NO INPUTS
        <div key={index}>
          <MaterialesTable
            key={index}
            defaultValueMaterial={material.material}
            listUnitsMeasures={metadata.measure_units}
            defaultValueQuantityMaterial={material.quantity_material}
            defaultValueQuantity={material.quantity}
            defaultValueUnitMeasure={material.measure_unit}
            defaultValueUnitPrice={material.price_unit}
            defaultValueTotal={material.price_unit * material.quantity}
            disable={true}
          />

          <div className="remove-material-container">
            <div className="ai-icon-container" onClick={() => {}}>
              <img className="ai-icon" src={TRASH_ICON_SVG} alt="trash"/>
            </div>
            <div
              className="ai-text"
              onClick={() => {
                deleteMaterial(material);
              }}
            >
              Eliminar material
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const CotizacionCosto = ({
  callbackWorkforce,
  defaultValueWorkforce,
  callbackDuration,
  defaultValueDuration,
  callbackUnforeseen,
  defaultValueUnforeseen,
  callbackWorkersHelpers,
  defaultValueWorkersHelpers,
  callbackWorkersOfficials,
  defaultValueWorkersOfficials,
}) => {
  return (
    <>
      <div className="new-service-text ps-new-entry-title title-bottom-margin">
        Costos
      </div>

      <div className="categoria-container">
        <div className="new-service-text">
          <div>
            <img
              src={TOOL_ICON_SVG}
              id="new-service-icon"
              alt={"POINTING_ICON_SVG"}
            />
          </div>
          <div> Mano de obra </div>
        </div>
      </div>

      <div className="new-entry-dropdown-container cotizacion-space-fields">
        <CurrencyFormatInput
          callback={callbackWorkforce}
          defaultValue={defaultValueWorkforce}
          icon={DOLLAR_ICON_SVG}
          placeholder="Mano de obra"
        />
      </div>

      <div className="new-entry-dropdown-container cotizacion-space-fields">
        <IconInput
          callback={callbackDuration}
          defaultValue={defaultValueDuration}
          type="number"
          icon={CLOCK_ICON_SVG}
          placeholder="Tiempo de ejecución en horas"
        />
      </div>

      <div className="categoria-container">
        <div className="new-service-text">
          <div>
            <img
              src={ADD_USER_ICON_SVG}
              id="new-service-icon"
              alt={"POINTING_ICON_SVG"}
            />
          </div>
          <div> Equipo de trabajo </div>
        </div>
      </div>

      <div className="new-entry-dropdown-container cotizacion-space-fields">
        <IconInput
          callback={callbackWorkersHelpers}
          defaultValue={defaultValueWorkersHelpers}
          type="number"
          icon={SHIELD_ICON_SVG}
          placeholder="Número de ayudantes"
        />
      </div>

      <div className="new-entry-dropdown-container cotizacion-space-fields">
        <IconInput
          callback={callbackWorkersOfficials}
          defaultValue={defaultValueWorkersOfficials}
          type="number"
          icon={SHIELD_ICON_SVG}
          placeholder="Número de oficiales"
        />
      </div>
    </>
  );
};

const CotizacionMedia = ({
  callbackAddFile,
  callbackAddFileError,
  listResources,
  callbackViewSource,
  deleteMediaSource,
  isEdit,
}) => {
  return (
    <>
      <div className="new-service-text ps-new-entry-title title-bottom-margin">
        Evidencia fotográfica
      </div>

      <div className="dano-foto-container">
        <div className="new-service-text">
          <div>
            <img
              src={IMAGE_ICON_SVG}
              id="new-service-icon"
              alt={"POINTING_ICON_SVG"}
            />
          </div>
          <div>Fotografías y vídeos</div>
        </div>

        <div className="dano-foto">
          <FilePicker
            maxSize={100}
            onChange={callbackAddFile}
            onError={callbackAddFileError}
          >
            <div className="add-foto-container">
              <AddSquareButton />
            </div>
          </FilePicker>

          {listResources.map((src, index) => {
            if (!isEdit) {

              const { type, url } = src;
              const file = fetch(url).then((r) => r.blob());
              return (
                <div key={index} className="foto-source-container">
                  <WarpSoruce
                    callback={() => callbackViewSource(src)}
                    type={type}
                    file={file}
                    url={url}
                    index={index}
                  />
                  <div
                    className="deletePhoto"
                    onClick={() => deleteMediaSource(index)}
                  >
                    x
                  </div>
                </div>
              );
            } else {

              const { type, url } = src;
              return (
                <div key={index} className="foto-source-container">
                  <WarpSoruceURI
                    callback={() => callbackViewSource(src)}
                    type={type}
                    file={src}
                    url={url}
                    index={index}
                  />
                  <div
                    className="deletePhoto"
                    onClick={() => deleteMediaSource(index)}
                  >
                    x
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </>
  );
};

const CotizacionObservaciones = ({
  callbackObservations,
  defaultValueObservations,
}) => {
  return (
    <div className="observaciones-container">
      <div className="new-service-text ps-new-entry-title title-bottom-margin">
        Observaciones
      </div>

      <div className="descripcion-container">
        <div className="new-service-text">
          <div>
            <img
              src={EYE_ICON_SVG}
              id="new-service-icon"
              alt={"CHAT_ICON_SVG"}
            />
          </div>
          <div>Observaciones del item</div>
        </div>

        <div className="cotizacion-textarea-container">
          <IconTextarea
            icon={PARAGRAPH_ICON_SVG}
            defaultValue={defaultValueObservations}
            callback={callbackObservations}
            placeholder="Ingresa las observaciones"
          />
        </div>
      </div>
    </div>
  );
};

const ServiceProviderCotizacion = ({
  metadata,
  serviceId,
  itemNumber = "1",
  addMaterial,
  numMateriales,
  navigatorCotizacionItem,
  deleteMaterial,
  //table
  callbackMaterail,
  defaultValueNameMaterial,
  callbackQuantity,
  defaultValueQuantity,
  callbackUnitMeasure,
  defaultValueUnitMeasure,
  callbackUnitPrice,
  defaultValueUnitPrice,
  callbackQuantityMaterial,
  defaultValueQuantityMaterial,

  // damage
  selectedType,
  callbackSelectedType,
  callbackDescription,
  descriptionAndOrigin,
  listSolutionSteps,
  callbackStep,
  currentStep,
  callbackAddStep,
  callbackDelateStep,
  callbackDamageLocation,
  damageLocation,
  callbackFloorLevels,
  floorLevel,
  callbackMeasureUnits,
  measureUnit,
  price,
  callbackArea,
  defaultValueArea,
  callbackWorkforce,
  defaultValueWorkforce,
  callbackDuration,
  defaultValueDuration,
  callbackUnforeseen,
  defaultValueUnforeseen,
  callbackWorkersHelpers,
  defaultValueWorkersHelpers,
  callbackWorkersOfficials,
  defaultValueWorkersOfficials,
  callbackObservations,
  defaultValueObservations,
  //media
  callbackAddFile,
  callbackAddFileError,
  listResources,
  callbackViewSource,
  deleteMediaSource,
  isEdit,
  isAvailableCallback,
  callbackError,
}) => {
  return (
    <div className="ps-services-main-container new-service-container">
      <AppbarLogo />

      <div className="desktop-container">
        <div className="new-service-appbar-container">
          <Appbar
            smallText="Cotización"
            bigText={`Caso - ${
              serviceId.split("~").length > 1
                ? serviceId.split("~")[1]
                : serviceId
            }`}
          />
        </div>

        <div className="cotizacion-item-container">
          <div className="cotizacion-item-name">Item {itemNumber}</div>
        </div>

        <div className="cotizacion-form-container">
          <div className="new-entry-content">
            {/*PROBLEMAS*/}
            <CotizacionProblemas
              damageType={metadata.damage_type}
              callbackSelectedType={callbackSelectedType}
              selectedType={selectedType}
              callbackDescription={callbackDescription}
              descriptionAndOrigin={descriptionAndOrigin}
              listSolutionSteps={listSolutionSteps}
              callbackStep={callbackStep}
              currentStep={currentStep}
              callbackAddStep={callbackAddStep}
              callbackDelateStep={callbackDelateStep}
              damageLocations={metadata.damage_locations}
              callbackDamageLocation={callbackDamageLocation}
              damageLocation={damageLocation}
              listFloorLevels={metadata.floor_levels}
              callbackFloorLevels={callbackFloorLevels}
              floorLevel={floorLevel}
              measureUnits={metadata.measure_units}
              callbackMeasureUnits={callbackMeasureUnits}
              measureUnit={measureUnit}
              callbackArea={callbackArea}
              defaultValueArea={defaultValueArea}
            />

            {/*MATERIALES*/}
            <CotizacionMateriales
              metadata={metadata}
              numMateriales={numMateriales}
              addMaterial={addMaterial}
              callbackMaterail={callbackMaterail}
              defaultValueNameMaterial={defaultValueNameMaterial}
              callbackQuantity={callbackQuantity}
              defaultValueQuantity={defaultValueQuantity}
              callbackUnitMeasure={callbackUnitMeasure}
              defaultValueUnitMeasure={defaultValueUnitMeasure}
              callbackUnitPrice={callbackUnitPrice}
              defaultValueUnitPrice={defaultValueUnitPrice}
              callbackQuantityMaterial={callbackQuantityMaterial}
              defaultValueQuantityMaterial={defaultValueQuantityMaterial}
              deleteMaterial={deleteMaterial}
            />

            {/*COSTOS*/}
            <CotizacionCosto
              callbackWorkforce={callbackWorkforce}
              defaultValueWorkforce={defaultValueWorkforce}
              callbackDuration={callbackDuration}
              defaultValueDuration={defaultValueDuration}
              callbackUnforeseen={callbackUnforeseen}
              defaultValueUnforeseen={defaultValueUnforeseen}
              callbackWorkersHelpers={callbackWorkersHelpers}
              defaultValueWorkersHelpers={defaultValueWorkersHelpers}
              callbackWorkersOfficials={callbackWorkersOfficials}
              defaultValueWorkersOfficials={defaultValueWorkersOfficials}
            />

            {/*MEDIA*/}
            <CotizacionMedia
              callbackAddFile={callbackAddFile}
              callbackAddFileError={callbackAddFileError}
              listResources={listResources}
              callbackViewSource={callbackViewSource}
              deleteMediaSource={deleteMediaSource}
              isEdit={isEdit}
            />
            {/*MEDIA*/}

            {/*OBSERVACIONES*/}
            <CotizacionObservaciones
              callbackObservations={callbackObservations}
              defaultValueObservations={defaultValueObservations}
            />
            {/*OBSERVACIONES*/}

            <div className="cotizacion-cost-container">
              <div className="cotizacion-cost-title">Total del Item</div>
              <div className="cotizacion-cost-price">$ {price}</div>
            </div>

            <div className="register-botton-menu">
              <NewIconButton
                txt="Guardar Item"
                style="ab-style"
                icon={SAVE_ICON_SVG}
                callback={
                  isAvailableCallback ? navigatorCotizacionItem : callbackError
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceProviderCotizacion;
