import React from 'react'
import { useParams, Redirect } from 'react-router-dom'
import {
  PS_MENU_PATH, PS_SERVICES, PS_NOTIFICATIONS, PS_PROFILE, PS_MY_SERVICES,
  PS_REQUESTS, PS_REQUEST_DETAILS, PS_MY_SERVICES_SEARCH, PS_GOALS, PS_DOCUMENTATION
} from '../../router/routes'
import ServiceProviderServicesMainController from './ps-services/ServiceProviderServicesMainController.jsx'
import ServiceProviderProfileController from './ps-profile/ServiceProviderProfileController.jsx'
import PsNotifications from './ps-notifications/PsNotifications.jsx'
import ServiceProviderMyServicesController from './ps-services/my-services/ServiceProviderMyServicesController.jsx'
import ServiceProviderMyRequestDetailsController from './ps-services/my-requests/ServiceProviderMyRequestDetailsController.jsx'
import ServiceProviderMyRequestsController from './ps-services/my-requests/ServiceProviderMyRequestsController.jsx'
import ServiceProviderMyServicesSearchController from './ps-services/my-services/ServiceProviderMyServicesSearchController.jsx'
import PsGoals from './ps-profile/PsGoals.jsx'
import PsDocumentation from './ps-profile/PsDocumentation'
import NavBar from 'components/Navbar/NavBar'

const BuildView = (type) => {

  switch (type) {

    case PS_SERVICES: return <ServiceProviderServicesMainController />
    case PS_REQUESTS: return <ServiceProviderMyRequestsController />
    case PS_REQUEST_DETAILS: return <ServiceProviderMyRequestDetailsController />
    case PS_MY_SERVICES: return <ServiceProviderMyServicesController />
    case PS_MY_SERVICES_SEARCH: return <ServiceProviderMyServicesSearchController />

    case PS_GOALS: return <PsGoals />
    case PS_DOCUMENTATION: return <PsDocumentation />

    case PS_NOTIFICATIONS: return <PsNotifications />

    case PS_PROFILE: return <ServiceProviderProfileController />
    default: return <Redirect to={PS_MENU_PATH} />
  }

}

const ServiceProviderMenuController = () => {

  let { id } = useParams();

  return <>
    {BuildView(id)}
    <NavBar />
  </>
}

export default ServiceProviderMenuController
