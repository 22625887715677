import React, { useState } from 'react'
import './card.css'
import DOTS_V_ICON_SVG from '../../../assets/icons/svg/more-vertical.svg'
import {convertTypePropertyByCodeToIcon} from '../../../res/convert.codes'

const labelStart = 'De'
const labelEnd = 'A'

const BaseCard = ({ callback, content, style, id }) => {
    return <div className={`baseCard ${style}`} onClick={callback} id={id}>{content}</div>
}

const SmallCard = ({ callback, icon, selected, id, title }) => {

    return (
        <React.Fragment>
            <BaseCard id={id} style={`iconSmallContainer ${selected === id ? 'selected' : ''}`}
                content={
                    <img id={id} className='iconSmall' alt={title} src={icon} />
                } callback={callback} />
            <p id={id} className='textSmall'>{title}</p>
        </React.Fragment>
    )

}

const BigCard = ({ callback, icon, text, psStyle, selected }) => {

    return (
        <BaseCard style={`bigCard ${selected ? 'selected' : ''} ${psStyle}`} content={
            <React.Fragment>
                <img className='iconBig' src={icon} alt={text} />
                <p className='textBig'>{text}</p>
            </React.Fragment>
        } callback={callback} />

    )

}


const CompleteCard = ({ callback, icon, title, text, id, isSelected }) => {

    const [selected, setSelect] = useState(isSelected)


    const handleSelect = () => {
        callback(id)
        setSelect(!selected)
    }

    return (
        <BaseCard id={id} style={`completeCard ${selected ? 'selected' : ''}`} content={
            <>
                <img id={id} className='iconComplete' src={icon} alt={title} />
                <p id={id} className='titleComplete'>{title}</p>
                <p id={id} className='textComplete'>{text}</p>
            </>
        } callback={handleSelect} />

    )

}

const AviableTimeCard = ({ start, end, callback, id, selected }) => {

    return (
        <BaseCard id={id} style={`timesCard ${selected === `${start} - ${end}` ? 'selectedTime' : ''}`} content={
            <div id={id} className='card-times-contianer'>
                <div id={id} className='starting-time'>{labelStart} {start}.</div>
                <div id={id} className='ending-time'>{labelEnd} {end}.</div>
            </div>
        } callback={callback} />
    )
}

const UserTypeCard = ({ type, id, selected, callback }) => {

    return (
        <BaseCard id={id} style={`userTypeCard ${selected === id ? 'selectedUser' : ''}`} content={
            <div id={id} className='card-type'>
                {type}
            </div>
        } callback={callback} />
    )
}

const PropertyInfoCard = ({ object, name, icon, direccion, callback }) => {

    return (
        <div className='prop-card-container' onClick={() => callback(object)}>
            <div className='prop-card-icon-container' >
                <img src={convertTypePropertyByCodeToIcon(icon)} className='prop-card-icon' />
            </div>
            <div className='prop-card-info'>
                <div className='prop-card-name'>
                    {name}
                </div>
                <div className='prop-card-direccion'>
                    {direccion}
                </div>
            </div>
            {/*
            <div className='prop-card-more'>
                <img src={DOTS_V_ICON_SVG} className='prop-card-more-icon' />
            </div>
            */}
        </div>
    )
}


export {
    BaseCard,
    SmallCard,
    BigCard,
    CompleteCard,
    AviableTimeCard,
    UserTypeCard,
    PropertyInfoCard
}

