import { Modal, Result } from "antd";
import React from "react";

const QrModalReponse = ({ open, onCancel, response }) => {
  console.log(response);
  return (
    <Modal centered open={open} onCancel={onCancel} onOk={onCancel}>
      <Result
        status={response?.error ? "error" : "success"}
        title={response?.error ? "Ups" : "Pedido entregado exitosamente"}
        subTitle={response?.message}
      />
    </Modal>
  );
};

export default QrModalReponse;
