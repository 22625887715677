import React, { useRef } from "react";

export const NotificationSound = ({ src }) => {
  const audioRef = useRef(null);

  const play = () => {
    const playPromise = audioRef.current.play();
    if (playPromise !== undefined) {
      playPromise.catch((error) =>
        console.error("Error al reproducir el sonido:", error)
      );
    }
  };

  return { play, Render: <audio ref={audioRef} src={src}></audio> };
};
