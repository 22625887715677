import React from 'react'
import { IconPopup } from '../components/atoms/popup/Popup'
import { IconInput } from '../components/atoms/input/Input'
import LOGO_ICON_SVG from '../assets/icons/svg/otros.svg'
import MAIL_ICON_SVG from '../assets/icons/svg/mail.svg'

const PopupOneButton = ({ object }) => {

    const {
        callback,
        callbackOnChangeResetEmail,
        defaultValueEmail
    } = object
    return (
        <IconPopup

            btnText='Recuperar contraseña'
            btnColor='btn-yellow'
            callback={callback}
            icon={LOGO_ICON_SVG}
            text={
                <>
                    <div className='purple-text'>
                        ¿Has perdido o no recuerdas tu contraseña?
                    </div>
                    <div className='yellow-text'>
                        Recupérala ingresando tu correo.
                    </div>
                    <div className='popup-fp-title'>
                        Ingresa correo
                    </div>
                    <div className='fp-input-container'>
                        <IconInput
                            placeholder='Correo'
                            defaultValue={defaultValueEmail}
                            callback={callbackOnChangeResetEmail}
                            icon={MAIL_ICON_SVG} />
                    </div>
                </>
            }

        />
    )
}

export default PopupOneButton