import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";

import firebaseConfig from "./firebase.config";

const firebaseApp = firebase.initializeApp(firebaseConfig);

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
  facebookProvider: new firebase.auth.FacebookAuthProvider(),
};

const firebaseAppAuth = firebaseApp.auth();
const firebaseStorage = firebaseApp.storage();
const firebaseRealTime = firebaseApp.database();
const firebaseCloudFirestore = firebaseApp.firestore();

export {
  firebase,
  firebaseAppAuth,
  firebaseCloudFirestore,
  firebaseRealTime,
  firebaseStorage,
  providers,
};
