import React from 'react'
import '../psServices.css'
import { withRouter } from 'react-router-dom'
import { Appbar,AppbarLogo } from '../../../../components/atoms/appbar/Appbar'
import { StyledPrimaryButton } from '../../../../components/atoms/button/Button'
import { WarpSoruceURI } from '../../../../components/atoms/media/MediaSource'
import POINTING_ICON_SVG from '../../../../assets/icons/svg/finger-point.svg'

const ServiceProviderMyRequestDetails = ({
    listResources = [],
    callbackViewSource,
    defaultValueGeneralDescription,
    propertyTye,
    listServicesSelected,
    servicePriority,
    addressProperty,
    dateStart,
    timeStart,
    handleTakeService
}) => {

    return (
        <div className='new-service-container '>

            <AppbarLogo />

            <div className='desktop-container'>

                <div className='new-service-appbar-container'>
                    <Appbar smallText='Solicitud de servicio' bigText='Día y hora' />
                </div>

                <div className='container-general'>

                    <div className='text-service-details'>

                        <div>
                            <img src={POINTING_ICON_SVG} id='img-text-service-details' alt={'POINTING_ICON_SVG'} />
                        </div>
                        <div className='text-details'>
                            <span>Detalles</span>
                        </div>

                    </div>

                    {/* esto esta mal */}

                    <div className='new-service-info-container'>
                        <div>
                            <div className='new-service-info-title leftSide'>Tipo de inmueble</div>
                            <div className='new-service-info-text leftSide'>{propertyTye}</div>
                        </div>
                        <div>
                            <div className='new-service-info-title'>Tipo de servicio</div>
                            <div className='new-service-info-text'>{listServicesSelected}</div>
                        </div>
                        <div>
                            <div className='new-service-info-title leftSide'>Urgencia</div>
                            <div className='new-service-info-text leftSide'>{servicePriority}</div>
                        </div>
                        <div>
                            <div className='new-service-info-title'>Lugar</div>
                            <div className='new-service-info-text'>{addressProperty}</div>
                        </div>
                        <div>
                            <div className='new-service-info-title leftSide'>Fecha</div>
                            <div className='new-service-info-text leftSide'>{dateStart}</div>
                        </div>
                        <div>
                            <div className='new-service-info-title'>Hora</div>
                            <div className='new-service-info-text'>{timeStart}</div>
                        </div>
                    </div>

                    <div className='service-description'>
                        <div className='title-description'>Descripción</div>
                        <div className='text-description'>{defaultValueGeneralDescription}</div>
                    </div>

                    <div className='dano-foto'>

                        {
                            listResources.map((src, index) => {

                                const { type, url } = src

                                return (
                                    <div key={index} className='foto-source-container'>
                                        <WarpSoruceURI callback={() => callbackViewSource(src)} type={type} file={src} url={url} index={index} />
                                    </div>
                                )
                            })
                        }

                    </div>

                    <div className='rs-btn-options'>
                        <div className='rs-btn-container'>
                            <StyledPrimaryButton
                                txt='Aceptar'
                                style='ri-btn-aceptar'
                                callback={handleTakeService} />
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default withRouter(ServiceProviderMyRequestDetails)



