import React from "react";
import MessageTextTarea from "./MessageTextTarea";
import RecordingComponent from "./RecordingComponent";

const MessageInput = () => {
  return (
    <div className="message-input-container">
      <MessageTextTarea />
      <RecordingComponent />
    </div>
  );
};

export default MessageInput;
