import AMEX_LOGO from "../../../assets/icons/payment/amex.png";
import BALOTO_LOGO from "../../../assets/icons/payment/baloto.png";
import BANCO_BOGOTA_LOGO from "../../../assets/icons/payment/banco-bogota.png";
import CODENSA_LOGO from "../../../assets/icons/payment/codensa.jpg";
import DAVIVIENDA_LOGO from "../../../assets/icons/payment/davivienda.png";
import DINNER_CLUB_LOGO from "../../../assets/icons/payment/dinners-club.svg";
import EFECTY_LOGO from "../../../assets/icons/payment/efecty.png";
import GRUPO_AVAL_LOGO from "../../../assets/icons/payment/grupo-aval.png";
import BANCOLOMBIA_LOGO from "../../../assets/icons/payment/logo-bancolombia.png";
import MASTER_LOGO from "../../../assets/icons/payment/mastercard.png";
import PSE_LOGO from "../../../assets/icons/payment/pse.png";
import SURED_LOGO from "../../../assets/icons/payment/unnamed.png";
import VISA_LOGO from "../../../assets/icons/payment/visa.png";
import APROBACION_ICON_SVG from "../../../assets/icons/svg/aprobacion.svg";
import DOLLAR_ICON_SVG from "../../../assets/icons/svg/dollar-sign.svg";
import DOWNLOAD_ICON_SVG from "../../../assets/icons/svg/download.svg";
import INFO_ICON_SVG from "../../../assets/icons/svg/info.svg";
import USER_ICON_SVG from "../../../assets/icons/svg/user.svg";
import X_ICON_SVG from "../../../assets/icons/svg/x.svg";
import { Appbar, AppbarLogo } from "../../../components/atoms/appbar/Appbar";
import {
  BtnPayPayU,
  NewIconButton,
} from "../../../components/atoms/button/Button";
import { WarpSoruceURI } from "../../../components/atoms/media/MediaSource";
import { convertServiceProviderTypeByCodeToName } from "../../../res/convert.codes";
import "./services.css";

const ServiceDetails = ({
  listResources,
  callbackViewSource,
  defaultValueGeneralDescription,
  propertyTye,
  listServicesSelected,
  servicePriority,
  addressProperty,
  dateStart,
  timeStart,
  stage,
  callbackGenerateApproval,
  callbackPayment,
  serviceId,
  email,
  description,
  pay,
  userType,
  callbackShowPdf,
  serviceProvider,
  totalVisit,
  callbackUpdateDateAndHour,
  callbackCancel,
}) => {
  const shortDescription = (descriptionString) => {
    let arr = descriptionString.split("");
    if (arr.length > 200) {
      arr.splice(200, arr.length - 200, "...");
      return arr.join("");
    } else {
      return descriptionString;
    }
  };

  return (
    <div className="new-service-container">
      <AppbarLogo />

      <div className="desktop-container">
        <div className="new-service-appbar-container">
          <Appbar smallText="Solicitud de" bigText="Servicio" />
        </div>

        <div className="container-general">
          <div className="text-service-details">
            <div>
              <img
                src={INFO_ICON_SVG}
                id="img-text-service-details"
                alt={"POINTING_ICON_SVG"}
              />
            </div>
            <div className="text-details">Detalles</div>
          </div>

          {/* esto esta mal */}

          <div className="new-service-info-container">
            <div>
              <div className="new-service-info-title leftSide">
                Tipo de inmueble
              </div>
              <div className="new-service-info-text leftSide">
                {propertyTye}
              </div>
            </div>
            <div>
              <div className="new-service-info-title">Tipo de servicio</div>
              <div className="new-service-info-text">
                {listServicesSelected}
              </div>
            </div>
            <div>
              <div className="new-service-info-title leftSide">Urgencia</div>
              <div className="new-service-info-text leftSide">
                {servicePriority}
              </div>
            </div>
            <div>
              <div className="new-service-info-title">Lugar</div>
              <div className="new-service-info-text">{addressProperty}</div>
            </div>
            <div>
              <div className="new-service-info-title leftSide">Fecha</div>
              <div className="new-service-info-text leftSide">{dateStart}</div>
            </div>
            <div>
              <div className="new-service-info-title">Hora</div>
              <div className="new-service-info-text">{timeStart}</div>
            </div>
          </div>

          {parseInt(stage) === 0 && serviceProvider !== null ? (
            <div
              className="fields-container update-service-details"
              onClick={callbackUpdateDateAndHour}
            >
              <div className="acceso-cubierta-info">
                <div>Cambiar fecha y hora</div>
                <div className="interrogation">!</div>
              </div>
            </div>
          ) : null}

          <div className="service-description">
            <div className="title-description">Descripción</div>
            <div className="text-description">
              {defaultValueGeneralDescription}
            </div>
          </div>

          <div className="service-description">
            <div className="title-description">Fotos</div>
          </div>

          {/* de aqui para abajo esta bien */}

          <div className="sd-dano-foto">
            {listResources.map((src, index) => {
              const { type, url } = src;

              return (
                <div key={index} className="foto-source-container">
                  <WarpSoruceURI
                    callback={() => callbackViewSource(src)}
                    type={type}
                    file={src}
                    url={url}
                    index={index}
                  />
                </div>
              );
            })}
          </div>

          {serviceProvider !== null ? (
            <>
              <div className="text-service-details">
                <div>
                  <img
                    src={USER_ICON_SVG}
                    id="img-text-service-details"
                    alt={"POINTING_ICON_SVG"}
                  />
                </div>
                <div className="text-details">Prestador de Servicios</div>
              </div>

              <div className="new-service-info-sp sp-type">
                <span>
                  {convertServiceProviderTypeByCodeToName(
                    serviceProvider.user.user_type
                  )}
                </span>
              </div>
              <div className="service-provider-img">
                <img
                  alt=""
                  className={`ag-sp-image ${
                    serviceProvider.user.register_data.thumbnail === ""
                      ? "ag-icon-user"
                      : null
                  }`}
                  src={
                    serviceProvider.user.register_data.thumbnail !== ""
                      ? serviceProvider.user.register_data.thumbnail
                      : USER_ICON_SVG
                  }
                />
              </div>

              <div className="new-service-info-sp">
                <span>{serviceProvider.user.register_data.name}</span>
              </div>
              <div className="new-service-info-sp sp-margin-botton">
                {serviceProvider.user.register_data.contact.phone}
              </div>
            </>
          ) : null}

          {parseInt(stage) === 12 ? (
            <>
              <div className="text-service-details">
                <div>
                  <img
                    src={DOLLAR_ICON_SVG}
                    id="img-text-service-details"
                    alt={"POINTING_ICON_SVG"}
                  />
                </div>
                <div className="text-details">Metodos de pago</div>
              </div>

              <div className="text-service-details text-flex">
                <div className="text-details text-margin">
                  Tarjetas de crédito
                </div>
                <div className="payment-methods-container">
                  <img
                    className="payment-method"
                    src={VISA_LOGO}
                    alt="metodo de pago"
                  />
                  <img
                    className="payment-method"
                    src={MASTER_LOGO}
                    alt="metodo de pago"
                  />
                  <img
                    className="payment-method"
                    src={AMEX_LOGO}
                    alt="metodo de pago"
                  />
                  <img
                    className="payment-method"
                    src={DINNER_CLUB_LOGO}
                    alt="metodo de pago"
                  />
                  <img
                    className="payment-method"
                    src={CODENSA_LOGO}
                    alt="metodo de pago"
                  />
                </div>
              </div>

              <div className="text-service-details text-flex">
                <div className="text-details text-margin">Efectivo</div>
                <div className="payment-methods-container">
                  <img
                    className="payment-method"
                    src={EFECTY_LOGO}
                    alt="metodo de pago"
                  />
                  <img
                    className="payment-method"
                    src={SURED_LOGO}
                    alt="metodo de pago"
                  />
                  <img
                    className="payment-method"
                    src={BALOTO_LOGO}
                    alt="metodo de pago"
                  />
                </div>
              </div>

              <div className="text-service-details text-flex">
                <div className="text-details text-margin">Pago en bancos</div>
                <div className="payment-methods-container">
                  <img
                    className="payment-method bigger-img"
                    src={DAVIVIENDA_LOGO}
                    alt="metodo de pago"
                  />
                  <img
                    className="payment-method"
                    src={BANCOLOMBIA_LOGO}
                    alt="metodo de pago"
                  />
                  <img
                    className="payment-method"
                    src={BANCO_BOGOTA_LOGO}
                    alt="metodo de pago"
                  />
                  <img
                    className="payment-method"
                    src={GRUPO_AVAL_LOGO}
                    alt="metodo de pago"
                  />
                </div>
              </div>

              <div className="text-service-details text-flex">
                <div className="text-details text-margin">
                  Transferencias bancarias
                </div>
                <div className="payment-methods-container">
                  <img
                    className="payment-method pse-icon"
                    src={PSE_LOGO}
                    alt="metodo de pago"
                  />
                </div>
              </div>
            </>
          ) : null}

          {servicePriority !== "Prioritaria" ? null : (
            <div className="service-visit-container">
              <div className="service-visit-title visit-price-margin-top">
                Valor de la visita
              </div>
              <div className="service-visit-price">${totalVisit} COP</div>
            </div>
          )}

          {parseInt(stage) === 1 ? (
            <>
              <div className="sd-button-container">
                <NewIconButton
                  txt="Descargar cotizacion"
                  style="ab-style purplePrimaryBtn"
                  callback={callbackShowPdf}
                  icon={DOWNLOAD_ICON_SVG}
                />
              </div>

              <div className="sd-button-container">
                <NewIconButton
                  txt="Generar aprobación"
                  style="ab-style"
                  callback={callbackGenerateApproval}
                  icon={APROBACION_ICON_SVG}
                />
              </div>
            </>
          ) : parseInt(stage) === 12 ? (
            <BtnPayPayU
              callbackPayment={callbackPayment}
              serviceId={serviceId}
              email={email}
              description={shortDescription(description)}
              pay={pay}
            />
          ) : parseInt(stage) === 0 && serviceProvider !== null ? (
            <div className="sd-button-container">
              <NewIconButton
                txt="Cancelar servicio"
                style="ab-style purplePrimaryBtn"
                callback={callbackCancel}
                icon={X_ICON_SVG}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
