import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Profile from "./Profile.jsx";
import {
  PRINCIPAL_PATH,
  PROPERTY_TYPE_PATH,
  PROPERTY_EDIT_PATH,
  DETAILS,
} from "../../../router/routes";
import StateButtonModalHook from "../../../modal/StateButtonModalHook.jsx";
import { ErrorRegister } from "../../../popups/SuccessRegister.jsx";
import { PHOTO_VALIDATION_ERROR_MESSAGE } from "../../../res/errors";
import uuidv5 from "uuid/v5";
import { uploadImageThumbnail } from "../../../services-controller/storage";
import { changeThumbnail } from "../../../reducers/form.user.reducer";
import { PopupSelectProperty } from "../../../popups/PopupSelectProperty.jsx";
import {
  changeQueryProperty,
  changeUpdateApp,
  initialStateProperties,
  changePropertiesUser,
} from "../../../reducers/app.reducer";
import { UID_AUTH } from "../../../persistent-managment/key.persistent";
import {
  removeValueByKey,
  getValueByKey,
} from "../../../persistent-managment/managment.persistent";
import {
  initialStateFormUser,
  changeUser,
} from "../../../reducers/form.user.reducer";
import { convertTypeRegisterToNames } from "../../../res/convert.codes";
import CLOCK_ICON_SVG from "../../../assets/icons/svg/clock.svg";
import LOG_OUT_ICON_SVG from "../../../assets/icons/svg/log-out.svg";
import USER_ICON_SVG from "../../../assets/icons/svg/user.svg";
import USERS_ICON_SVG from "../../../assets/icons/svg/users.svg";
import HOUSE_ICON_SVG from "../../../assets/icons/svg/inmuebles.svg";
import SHIELD_ICON_SVG from "../../../assets/icons/svg/shield.svg";
import { signOut } from "../../../services-firebase/auth";
import PsAssistantsListController from "../../services-provider/ps-assistants/PsAssistantsListController.jsx";
import {
  getAnalyticsClient,
  validateCode,
  deleteManagement,
  deleteProperty,
  getWarrantiesByUser,
  updateClientProfile,
  getRecordByProperty
} from "../../../services-controller/http.cleinte.controller";
import PopupInvitationCode from "../../../popups/psPopups/PopupInvitationCode.jsx";
import { PopupSuccessEvent } from "../../../popups/psPopups/PopupSuccessEvent.jsx";
import { PopupRejectEvent } from "../../../popups/psPopups/PopupRejectEvent.jsx";
import FROWN_ICON_SVG from "../../../assets/icons/svg/frown.svg";
import ADD_ICON_SVG from "../../../assets/icons/svg/add.svg";
import PopupOptions from "../../../popups/PopupOptions.jsx";
import { PopupTwoOptionWithIcon } from "../../../popups/PopupTwoButtons.jsx";
import TRASH_ICON_SVG from "../../../assets/icons/svg/trash.svg";
import { updateProperty } from "../../../reducers/form.property.reducer";
import { LoadingPopup } from "../../../components/atoms/popup/Popup";
import PopupWarranties from "../../../popups/PopupWarranties";
import { useDispatch, useSelector } from "react-redux";
import { camelCase } from '../../../res/utils'

const ProfileController = ({ history }) => {

  const dispatch = useDispatch()
  const state = useSelector(state => {
    return {
      form_user: state.form_user,
      app: state.app,
      form_management: state.form_management
    }
  })
  const { form_user, app, form_management } = state;

  const {
    register_type,
    user_type,
    register_data: {
      thumbnail,
      name,
      contact: { email_associated },
    },
  } = form_user;

  const { query_property, properties } = app;

  //state

  const [isErrorForm, setIsErrorForm] = useState(false);

  const [selectProperty, setSelectProperty] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [showSelectedAttendant, setShowSelectedAttendant] = useState(false);

  const [showEnterCode, setShowEnterCode] = useState(false);
  const [showSuccessEvent, setShowSuccessEvent] = useState(false);
  const [showRejectEvent, setShowRejectEvent] = useState(false);
  const [showPopupOptions, setShowPopupOptions] = useState(false);
  const [showDeleteVerification, setShowDeleteVerification] = useState(false);
  const [showSuccessDeleteEvent, setShowSuccessDeleteEvent] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(false);
  const [
    showSuccessDeleteManagement,
    setShowSuccessDeleteManagement,
  ] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [showWarranties, setShowWarranties] = useState(false);
  const [warratiesList, setWarrantiesList] = useState([]);
  const [propertyReportList, setPropertyReportList] = useState([]);
  const [showPropertyReport, setShowPropertyReport] = useState(false);

  const [analytics, setAnalytics] = useState({
    request: 0,
    runnung: 0,
    properties: 0,
  });

  const id = getValueByKey(UID_AUTH);

  const fetchAnalytics = async () => {
    const res = await getAnalyticsClient(id);
    setAnalytics(res);
  };

  useEffect(() => {
    fetchAnalytics();
  }, []);

  useEffect(() => {
    if (register_type === "" || user_type === "") callbackUserNoIdntify();
  }, [register_type, user_type]);

  //general

  const stateErrorFormCallback = (state) => setIsErrorForm(state);

  const callbackUserNoIdntify = () => history.push(PRINCIPAL_PATH);

  const callbackViewProperties = () => setSelectProperty(true);

  const callbackViewManagements = () => {
    setShowSelectedAttendant(true);
  };

  const callbackViewTenats = () => {};

  const callbackViewWarranties = async () => {
    const warranties = await getWarrantiesByUser(id);
    setWarrantiesList(warranties);
    setShowWarranties(true);
  };

  const callbackLogOut = async () => {
    removeValueByKey(UID_AUTH);
    dispatch(changeUpdateApp(initialStateProperties));
    dispatch(changeUser(initialStateFormUser));
    await signOut();
    history.push(PRINCIPAL_PATH);
  };

  const showModalError = (message) => {
    setErrorMessage(message);
    stateErrorFormCallback(true);
  };

  const callbackSelectImage = async (file) => {
    const uid = uuidv5(new Date().toLocaleString(), uuidv5.URL);
    const res = await uploadImageThumbnail(uid, file);
    const { code } = res;
    if (code === 200) {
      const { url } = res;

      const object = {
        ...form_user,
        register_data: {
          ...form_user.register_data,
          thumbnail: url,
        },
      };

      await updateClientProfile(id, object);

      dispatch(changeThumbnail(url));
    }
  };

  const callbackSelectImageError = (_) =>    showModalError(PHOTO_VALIDATION_ERROR_MESSAGE);

  const callbackRegisterPositive = () => history.push(`${PROPERTY_TYPE_PATH}`);

  const callbackPropertySelected = (property) => {
    setSelectedProperty(property);
    setShowPopupOptions(true);
  };

  const callbackSearchProperty = (e) => {
    const value = e.target.value;
    dispatch(changeQueryProperty(value));
  };

  const callbackEnterCode = () => {
    setShowEnterCode(true);
  };

  const appbarButtonsContent = [
    {
      type: "Servicios solicitados",
      info: analytics.request,
    },
    {
      type: "Servicios en curso",
      info: analytics.runnung,
    },
    {
      type: "Número de inmuebles",
      info: analytics.properties,
    },
  ];

  const menuButtonsContent = [
    {
      icon: HOUSE_ICON_SVG,
      txt: "Inmuebles",
      type: 1,
      callback: callbackViewProperties,
    },
    {
      icon: SHIELD_ICON_SVG,
      txt: "Garantias",
      type: 2,
      callback: callbackViewWarranties,
    },
    {
      icon: USERS_ICON_SVG,
      txt: "Encargados e inquilinos",
      type: 3,
      callback: callbackViewManagements,
    },
    {
      icon: ADD_ICON_SVG,
      txt: "Invitación",
      type: 4,
      callback: callbackEnterCode,
    },
    {
      icon: LOG_OUT_ICON_SVG,
      txt: "Cerrar sesion",
      callback: callbackLogOut,
    },
  ];

  const callbackSendCode = async (code) => {
    setShowEnterCode(false);
    const res = await validateCode(email_associated, code);
    if (res.message === "success") {
      setShowSuccessEvent(true);
    } else {
      setShowRejectEvent(true);
    }
  };

  const callbackCloseSuccessEvent = () => {
    setShowSuccessEvent(false);
  };

  const callbackCloseRejectEvent = () => {
    setShowRejectEvent(false);
  };

  const callbackEdit = () => {
    dispatch(updateProperty(selectedProperty));
    history.push(`${PROPERTY_EDIT_PATH}`);
  };

  const callbackDelete = () => {
    setShowDeleteVerification(true);
  };

  const rejectDelete = () => {
    setShowDeleteVerification(false);
    setShowPopupOptions(false);
  };

  const confirmDelete = async () => {
    const aux = [...properties];
    const index = aux.indexOf(selectedProperty);
    aux.splice(index, 1);
    await deleteProperty(selectedProperty.unique_key);
    dispatch(changePropertiesUser(aux));
    setShowSuccessDeleteEvent(true);
  };

  const callbackCloseSuccessDeleteEvent = () => {
    setShowSuccessDeleteEvent(false);
    setShowDeleteVerification(false);
    setShowPopupOptions(false);
    setSelectProperty(false);
  };

  const confirmDeleteManagement = async () => {
    await deleteManagement(form_management.unique_key);
    setShowSuccessDeleteManagement(true);
  };

  const callbackCloseSuccessDeleteManagement = () => {
    setShowSuccessDeleteManagement(false);
    setShowSelectedAttendant(false);
  };

  const navigatorService = (id, stage) => {
    history.push(`${DETAILS}/${id}/${stage}`);
  };

  const callbackShowRepport = async () => {
    const res = await getRecordByProperty(selectedProperty.unique_key)
    setPropertyReportList(res)
    setShowPropertyReport(true);
    setShowPopupOptions(false);
    setSelectProperty(false);
  };

  return (
    <>
      <Profile
        callbackSelectImage={callbackSelectImage}
        callbackSelectImageError={callbackSelectImageError}
        thumbnail={thumbnail}
        name={camelCase(name)}
        userType={convertTypeRegisterToNames(user_type)}
        callbackViewProperties={callbackViewProperties}
        callbackViewManagements={callbackViewManagements}
        callbackViewTenats={callbackViewTenats}
        callbackViewWarranties={callbackViewWarranties}
        callbackLogOut={callbackLogOut}
        appbarButtonsContent={appbarButtonsContent}
        menuButtonsContent={menuButtonsContent}
      />
      {/* error */}
      {isErrorForm ? (
        <StateButtonModalHook
          component={ErrorRegister}
          hook={[isErrorForm, setIsErrorForm]}
          object={{
            message: errorMessage,
            callback: stateErrorFormCallback,
          }}
        />
      ) : null}
      {/* select property */}
      {selectProperty ? (
        <StateButtonModalHook
          component={PopupSelectProperty}
          hook={[selectProperty, setSelectProperty]}
          object={{
            callbackSearch: callbackSearchProperty,
            query: query_property,
            propertyArr: properties,
            callbackNewProperty: callbackRegisterPositive,
            callbackPropertySelected: callbackPropertySelected,
          }}
        />
      ) : null}

      {showSelectedAttendant ? (
        <StateButtonModalHook
          component={PsAssistantsListController}
          hook={[showSelectedAttendant, setShowSelectedAttendant]}
          object={{
            id: null,
            user: "Encargados",
            confirmDelete: confirmDeleteManagement,
          }}
        />
      ) : null}

      {showEnterCode ? (
        <StateButtonModalHook
          component={PopupInvitationCode}
          hook={[showEnterCode, setShowEnterCode]}
          object={{
            callback: callbackSendCode,
          }}
        />
      ) : null}

      {showSuccessEvent ? (
        <StateButtonModalHook
          component={PopupSuccessEvent}
          hook={[showSuccessEvent, setShowSuccessEvent]}
          object={{
            message: "Codigo ingresado exitosamente",
            btnText: "OK",
            callback: callbackCloseSuccessEvent,
          }}
        />
      ) : null}

      {showRejectEvent ? (
        <StateButtonModalHook
          component={PopupRejectEvent}
          hook={[showRejectEvent, setShowRejectEvent]}
          object={{
            message:
              "Lo sentimos, el codigo ingresado no es valido. Verifica el codigo e intenta nuevamente.",
            btnText: "OK",
            callback: callbackCloseRejectEvent,
            icon: FROWN_ICON_SVG,
          }}
        />
      ) : null}

      {showPopupOptions ? (
        <StateButtonModalHook
          component={PopupOptions}
          hook={[showPopupOptions, setShowPopupOptions]}
          object={{
            callbackEdit: callbackEdit,
            callbackDelete: callbackDelete,
            callbackShowRepport: callbackShowRepport,
            showReportOption: true,
          }}
        />
      ) : null}

      {showDeleteVerification ? (
        <StateButtonModalHook
          component={PopupTwoOptionWithIcon}
          hook={[showDeleteVerification, setShowDeleteVerification]}
          object={{
            callbackNegative: rejectDelete,
            callbackPositive: confirmDelete,
            textPositive: "Si",
            textNegative: "No",
            popupText: "¿Desea eliminar la propiedad seleccionada?",
            popupIcon: TRASH_ICON_SVG,
          }}
        />
      ) : null}

      {showSuccessDeleteEvent ? (
        <StateButtonModalHook
          component={PopupSuccessEvent}
          hook={[showSuccessDeleteEvent, setShowSuccessDeleteEvent]}
          object={{
            message: "Propiedad eliminada exitosamente",
            btnText: "OK",
            callback: callbackCloseSuccessDeleteEvent,
          }}
        />
      ) : null}

      {showSuccessDeleteManagement ? (
        <StateButtonModalHook
          component={PopupSuccessEvent}
          hook={[showSuccessDeleteManagement, setShowSuccessDeleteManagement]}
          object={{
            message: "Encargado/Inquilino eliminado exitosamente",
            btnText: "OK",
            callback: callbackCloseSuccessDeleteManagement,
          }}
        />
      ) : null}

      {showWarranties ? (
        <StateButtonModalHook
          component={PopupWarranties}
          hook={[showWarranties, setShowWarranties]}
          object={{
            warratiesList: warratiesList,
            navigatorService: navigatorService,
          }}
        />
      ) : null}

      {showPropertyReport ? (
        <StateButtonModalHook
          component={PopupWarranties}
          hook={[showPropertyReport, setShowPropertyReport]}
          object={{
            warratiesList: propertyReportList,
            navigatorService: navigatorService,
            textBig: selectedProperty?.nickname,
            textSmall:"Historial de",
            feedbackEmpty:"Actualmente, el inmueble seleccionado no posee un historial con Justo Pago."
          }}
        />
      ) : null}

      <LoadingPopup state={isLoader} />
    </>
  );
};

export default withRouter(ProfileController);
