import { message, Modal, Result } from "antd"
import "./OrderCleaningService.css"
import { DatePicker, Dialog } from 'antd-mobile'
import moment from 'moment'
import { useCallback } from 'react'
import { useState } from 'react'
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { useSelector } from "react-redux"
import { getEmisorFromUser } from "res/utils"
import { createNewServiceRequest } from "services-controller/http.cleinte.controller"

export default function OrderRequestService({ requestType, open, closeRequest, closeConfirm, isDialogToConfirm, openDialogToConfirm, setCloseModal, setOpenDateForm }) {

  const typesService = {
    clean: "limpieza",
    debris: "retiro de escombros",
    meson: "Toma medidas de mesón",
    carpentry: "Carpintería",
    postSale: "Post venta",
  }

  const id = useParams().id
  const [date, setDate] = useState(null)
  const [result, setResult] = useState({ status: "success", description: "Servicio solicitado con éxito", title: "Solicitud exitosa" })
  const [isShowResult, setIsShowResult] = useState(false)

  const user = useSelector(state => state.auth.user)
  const emisor = getEmisorFromUser(user)
  const onSubmit = async () => {
    try {
      closeConfirm()
      closeRequest()

      const requestObj = { date_to: date, type: requestType }

      const response = await createNewServiceRequest(id, requestObj, emisor);

      if (!response.error) {
        setResult({ status: "success", description: "Servicio solicitado con éxito", title: "Solicitud exitosa" })
      }

    } catch (error) {

      if (error.response.data.message) {
        setResult({ status: "error", description: error.response?.data?.message, title: "Error al solicitar" })
      } else {
        setResult({ status: "error", description: "Error al solicitar el servicio", title: "Error al solicitar" })
      }

    } finally {

      setIsShowResult(true)
      setTimeout(() => {
        setIsShowResult(false)
        setOpenDateForm()
      }, 3000)

      setCloseModal()
    }

  }

  const onCancelConfirm = () => {
    setOpenDateForm()
    closeConfirm()
  }

  const now = new Date()

  //add 3months to the current date
  const max = new Date(now.getFullYear(), now.getMonth() + 4, now.getDate())

  const getMonthByNumber = (number) => {
    let month = moment().month(number - 1).format('MMMM')
    return month
  }

  const onSetData = (data) => {
    const date = new Date(data)
    setDate(date);
    //if date is lower 2 days from now 

    const twoDays = 2 * 24 * 60 * 60 * 1000
    const dateToCompare = new Date(date)
    if (dateToCompare.getTime() < now.getTime() + twoDays) {
      message.warning("Si solicitas el servicio con menos de 2 días de anticipacion, bajara la calificación de tu perfil")
    }
    closeRequest()
    openDialogToConfirm()
  }

  const labelRenderer = useCallback((type, data) => {
    switch (type) {
      case 'month':
        return getMonthByNumber(data)
      default:
        return data
    }
  }, [])

  const service = typesService[requestType]

  const Content = () => {
    return (
      <div className="content-container">
        <p className="content-info">Esta seguro que desea solicitar el servicio de {service} para el <span>
          {moment(date).format('LL')}?</span></p>
      </div>
    )
  }

  return (
    <>
      <Dialog visible={isDialogToConfirm} content={<Content />} actions={
        [
          {
            text: 'Confirmar',
            onClick: onSubmit
          },
          {
            text: 'Cancelar',
            onClick: onCancelConfirm,
            danger: true
          }
        ]
      } />

      <DatePicker
        onCancel={closeRequest}
        renderLabel={labelRenderer}
        min={now}
        max={max}
        cancelText={"Cancelar"}
        confirmText='Guardar'
        title={`Seleccione la fecha para el servicio de ${service}`}
        visible={open}
        onConfirm={onSetData}
      />

      <Modal centered open={isShowResult} footer={false}>
        <Result status={result.status} title={result.description} description={result.description} />
      </Modal>
    </>
  )
}
