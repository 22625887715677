export const getFormatedDate = (date) => {
  const now = new Date(date); // obtiene la fecha y hora actual
  const year = now.getFullYear(); // obtiene el año actual (4 dígitos)
  const month = String(now.getMonth() + 1).padStart(2, '0'); // obtiene el mes actual (2 dígitos)
  const day = String(now.getDate()).padStart(2, '0'); // obtiene el día actual (2 dígitos)
  const hours = String(now.getHours()).padStart(2, '0'); // obtiene la hora actual (en formato de 24 horas, 2 dígitos)
  const minutes = String(now.getMinutes()).padStart(2, '0'); // obtiene los minutos actuales (2 dígitos)
  const seconds = String(now.getSeconds()).padStart(2, '0'); // obtiene los segundos actuales (2 dígitos)

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // genera la cadena de caracteres con el formato deseado
  return formattedDate;
}