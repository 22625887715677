import React, { useEffect,useState } from 'react'
import NewServicesSchedule from './NewServicesSchedule.jsx'
import { withRouter } from 'react-router-dom'
import { SERVICES_DETAILS, BASE_MENU_PATH } from '../../router/routes'
import {
    changeRequestPriority, changeRequestScheduleDateStart, changeRequestScheduleVisitingTime, changeRequestScheduleTimeStart,
} from '../../reducers/request.service.reducer'
import {getConfigCosts} from '../../services-controller/http.cleinte.controller'
import { LoadingScreen } from '../../views/loading/LoadingScreen.jsx'
import moment from 'moment'
import 'moment/locale/es'
import { useDispatch, useSelector } from 'react-redux'

moment.locale('es')

const NewServicesScheduleController = ({ history }) => {

    const dispatch = useDispatch()
    const state = useSelector(state => state.request_service)
    const request_service  = state

    const [costs,setCosts] = useState(null)

    const fecthConfigurationCosts = async () =>{
        const costs = await getConfigCosts()
        setCosts(costs)
    }

    useEffect(() => {
        fecthConfigurationCosts()
        return () => {
            
        }

    }, [])


    const {
        request: {
            priority,
            schedule: {
                time_start,
                date_start
            }
        }
    } = request_service

    //start general

    const currentMoment = new Date()

    const today = moment(new Date()).format('dddd, D MMMM')
    const tommorrow = moment(new Date()).add(1,'days').format('dddd, D MMMM')
    const dayAfterTomorrow = moment(new Date()).add(2,'days').format('dddd, D MMMM')

    const hour = currentMoment.getHours()
    const minut = currentMoment.getMinutes()

    const filterSchedule = (item) => {
        const fragmentMinut = minut / 100
        const keyTime = hour + fragmentMinut

        const keyTimeInitItem = item.key_init
        const keyTimeEndItem = item.key_end
        
        const dayReducer = time_start
        const dayCurrent = currentMoment.getDate()

        if (dayReducer === dayCurrent) {
            if (keyTime <= keyTimeInitItem || keyTime <= keyTimeEndItem) return true
            else return false
        } else return true

    }

    const providersSpecificTime = [
        { key_init: 8, key_end: 9.3, inicio: '8:00 am', final: '9:30 am' },
        { key_init: 8.3, key_end: 11, inicio: '9:30 am', final: '11:00 am' },
        { key_init: 11, key_end: 12.3, inicio: '11:00 am', final: '12:30 pm' },
        { key_init: 12.3, key_end: 14, inicio: '12:30 pm', final: '02:00 pm' },
        { key_init: 14, key_end: 15.3, inicio: '02:00 pm', final: '03:30 pm' },
        { key_init: 15.3, key_end: 17, inicio: '03:30 pm', final: '05:00 pm' },
        { key_init: 17, key_end: 18, inicio: '05:00 pm', final: '06:00 pm' }
    ].filter(filterSchedule)


    const callbackGoNext = () => history.push(`${BASE_MENU_PATH}${SERVICES_DETAILS}`)

    const callbackSelectTypePriority = (e) => {
        const value = parseInt(e.target.id)

        const aux = moment(date_start).format('dddd, D MMMM')

        if(value !== 2){
            if (aux === today || aux === tommorrow || aux === dayAfterTomorrow){
                dispatch(changeRequestPriority(2))
            }else{
                dispatch(changeRequestPriority(value))
            }          
        }else{
            dispatch(changeRequestPriority(value))
            dispatch(changeRequestScheduleDateStart(currentMoment))
            dispatch(changeRequestScheduleVisitingTime(currentMoment))
        }      
        
    }
    const callbackSelectedDate = (date) => {
        
        dispatch(changeRequestScheduleDateStart(date))
        dispatch(changeRequestScheduleVisitingTime(date))

        const aux = moment(date).format('dddd, D MMMM')

        if(aux === today || aux === tommorrow || aux === dayAfterTomorrow){
            dispatch(changeRequestPriority(2))
        }else{
            if(priority === 2){
                dispatch(changeRequestPriority(0))
            }else{
                dispatch(changeRequestPriority(priority))
            }
            
        }
    }

    const callbackSelectedTimeStart = (object) => {
        const start = object.inicio
        const end = object.final
        dispatch(changeRequestScheduleTimeStart(`${start} - ${end}`))
    }
    //_____________


    return (

        costs === null ?
        <LoadingScreen/> :
        <NewServicesSchedule
            callbackGoNext={callbackGoNext}
            callbackSelectTypePriority={callbackSelectTypePriority}
            defaultValuePriority={priority}
            providersSpecificTime={providersSpecificTime}
            callbackSelectedTimeStart={callbackSelectedTimeStart}
            defaultValueTimeStart={time_start}
            callbackSelectedDate={callbackSelectedDate}
            defalutValueDate={date_start}
            visitValue={costs.visit}
            sendAviable={time_start !== ''}
            disableDays={true}
        />
    )
}

export default withRouter(NewServicesScheduleController)