import React from "react";
import MICROPHONE_ICON_SVG from "assets/icons/svg/microphone.svg";
import STOP_ICON_SVG from "assets/icons/svg/stop1-svg.svg";
import useReduxState from "custom-hooks/useReduxState";
import { chatRecordingActions } from "reducers/chat.controller.reducer";

const StopButton = ({ pauseRecording }) => {
  return (
    <div className="stop-icon" onClick={pauseRecording}>
      <img src={STOP_ICON_SVG} alt="" />
    </div>
  );
};

const MicrophoneButton = ({ onRecording }) => {
  return (
    <div className="micro-icon" onClick={onRecording}>
      <img src={MICROPHONE_ICON_SVG} alt="" />
    </div>
  );
};

const RecordingButtons = () => {
  const [chatControlState, dispatchChatControl] = useReduxState(
    chatRecordingActions,
    "chatController"
  );

  const { isRecording, resources } = chatControlState;
  const { stopRecording, onRecording, showRecording } = dispatchChatControl;

  const initRecording = () => {
    showRecording();
    setTimeout(() => {
      onRecording();
    }, 50);
  };

  const pauseRecording = () => {
    stopRecording();
  };

  const condition = !resources.length > 0;
  return (
    <div className="recording-icons">
      {condition && (
        <>
          {isRecording ? (
            <StopButton pauseRecording={pauseRecording} />
          ) : (
            <MicrophoneButton onRecording={initRecording} />
          )}
        </>
      )}
    </div>
  );
};

export default RecordingButtons;
