import useReduxState from "custom-hooks/useReduxState";
import { validateActivity } from "helpers/helpers";
import { memo } from "react";
import Timeline from "react-time-line";
import { setGlobalState } from "reducers/globalState.reducer";
import { camelCase, getDayStringFull } from "res/utils";
import TimeLineItem from "../TimeLineITem";

const EntryComponent = memo(
  ({
    obj,
    getDay,
    callback,
    entry,
    navigateUnexpected,
    user,
    serviceStage,
    lastEntry,
    nexEntry,
  }) => {
    const [globalState, dispatchState] = useReduxState(
      { setGlobalState },
      "globalState"
    );
    const response = validateActivity(
      globalState.paidStatus,
      obj.date,
      obj.steps_selected
    );

    return (
      <div>
          <div className="services-tracker-container">
            <div className="date-container">
              {getDay(obj.date) === "Hoy" ? (
                <span className="date-name">Hoy</span>
              ) : getDay(obj.date) === "Ayer" ? (
                <span className="date-name">Ayer</span>
              ) : (
                <>
                  <div className="date-number">
                    {camelCase(obj.date.split("-")[2].substring(0, 3))}
                  </div>
                  <div className="date-number">{obj.date.split("-")[1]}</div>
                  <div className="date-name">
                    {getDayStringFull(obj.date.split("-")[0])}
                  </div>
                </>
              )}
            </div>

            <div className="ps-services-container">
              {obj.is_approved ? (
                <div className="services-done-container">
                  <Timeline
                    items={[
                      {
                        ts: "",
                        text: (
                          <TimeLineItem
                            user={user}
                            obj={obj}
                            lockEntry={response}
                            callback={callback}
                            selected={entry.entry}
                            navigateUnexpected={navigateUnexpected}
                            serviceStage={serviceStage}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              ) : (
                <Timeline
                  items={[
                    {
                      ts: "",
                      text: (
                        <TimeLineItem
                          user={user}
                          obj={obj}
                          lockEntry={response}
                          callback={callback}
                          selected={entry.entry}
                          navigateUnexpected={navigateUnexpected}
                          serviceStage={serviceStage}
                        />
                      ),
                    },
                  ]}
                />
              )}
            </div>
          </div>
      </div>
    );
  }
);

export default EntryComponent;
