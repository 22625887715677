import React from 'react'
import { OneButtonsPopup } from '../components/atoms/popup/Popup'
import './psPopups/psPopups.css'

const PopupEncargadoInquilino = ({ object }) => {
    const {
        encargadoText,
        inquilinoText,
        textPositive,
        callbackPositive,

    } = object
    return (
        <OneButtonsPopup
            text={
                <>
                    <div className='message-subtitle'>
                        Encargado
                    </div>
                    <div className='message-text'>{encargadoText}</div>
                    <br/>
                    <div className='message-subtitle'>
                        Inquilino
                    </div>
                    <div className='message-text'>{inquilinoText}</div>
                </>
            }
            btnOneColor='btnYellow'
            btnOneText={textPositive}
            callbackOne={callbackPositive} />
    )
}


export {
    PopupEncargadoInquilino
}