import React from "react";
import "./caseDetailsTemplate.css";
import INFO_ICON_SVG from "../../../assets/icons/svg/info.svg";
import USER_ICON_SVG from "../../../assets/icons/svg/user.svg";
import USERS_ICON_SVG from "../../../assets/icons/svg/users.svg";
import PHONE_ICON_SVG from "../../../assets/icons/svg/phone.svg";
import { WarpSoruceURI } from "../../../components/atoms/media/MediaSource";

const CaseDetailsTemplate = ({
  listResources = [],
  details,
  generalDescription,
  callbackViewSource,
  totalVisit,
  management,
}) => {
  return (
    <>
      <div className="text-service-details">
        <div>
          <img
            src={INFO_ICON_SVG}
            id="img-text-service-details"
            alt={"POINTING_ICON_SVG"}
          />
        </div>
        <div className="text-details">Detalles</div>
      </div>

      <div className="re-info-container">
        {details.map((obj, index) => (
          <div key={index}>
            <div
              className={`new-service-info-title ${
                (index + 1) % 2 !== 0 ? "leftSide" : null
              }`}
            >
              {obj.title}
            </div>
            <div
              className={`new-service-info-text ${
                (index + 1) % 2 !== 0 ? "leftSide" : null
              }`}
            >
              {obj.title === "Lugar" || obj.title === "Cliente"
                ? obj.text.map((i, index) => <div key={index}>{i}</div>)
                : obj.text}
            </div>
          </div>
        ))}
      </div>

      <div className="re-description">
        <div className="title-description">Descripción</div>
        <div className="text-description">{generalDescription}</div>
      </div>

      <div className="service-fotos">
        <div className="service-foto-title">Fotos</div>
        <div className="dano-foto">
          {listResources.map((src, index) => {
            const { type, url } = src;

            return (
              <div key={index} className="foto-source-container">
                <WarpSoruceURI
                  callback={() => callbackViewSource(src)}
                  type={type}
                  file={src}
                  url={url}
                  index={index}
                />
              </div>
            );
          })}
        </div>
      </div>

      {management !== null ? (
        <>
          <div className="text-service-details">
            <div>
              <img
                src={USER_ICON_SVG}
                id="img-text-service-details"
                alt={"POINTING_ICON_SVG"}
              />
            </div>
            <div className="text-details">Encargado</div>
          </div>

          <div className="basic-info-item-container">
            <div className="ag-icon-container-info">
              <img className="ag-icon-info" src={USERS_ICON_SVG} alt="" />
            </div>
            <div className="basic-info-text">
              {management.register_data.name}
            </div>
          </div>

          <div className="basic-info-item-container">
            <div className="ag-icon-container-info">
              <img className="ag-icon-info" src={PHONE_ICON_SVG} alt="" />
            </div>
            <div className="basic-info-text">
              {management.register_data.contact.primary_phone}
            </div>
          </div>
        </>
      ) : null}

      {totalVisit === null ? null : (
        <div className="service-visit-container">
          <div className="service-visit-title">Valor de la visita</div>
          <div className="service-visit-price">${totalVisit} COP</div>
        </div>
      )}
    </>
  );
};

export default CaseDetailsTemplate;
