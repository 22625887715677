import { produce } from "immer";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import StateButtonModalHook from "../../modal/StateButtonModalHook.jsx";
import PopupInstabasicas from "../../popups/PopupInstabasicas";
import { PopupTwoButtons } from "../../popups/PopupTwoButtons";
import { ShowMediaSource } from "../../popups/PopupsNewServices.jsx";
import {
  changeDateSort,
  changeRequestScheduleDateStart,
  changeRequestScheduleVisitingTime,
  changeResourceList,
  changeSelectedServices,
  changeStateServiceCreationDate,
  changeTextDescription,
} from "../../reducers/request.service.reducer";
import { INSTABASICAS_ARRAY } from "../../res/instabasicas";
import LIST_SERVICES from "../../res/list.services";
import { BASE_MENU_PATH, SCHEDULE_SERVICE } from "../../router/routes";
import NewServices from "./NewServices.jsx";

const NewServicesController = ({ history }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.request_service);

  const request_service = state;

  const {
    request: { selected_services, general_description, resources },
  } = request_service;

  //state

  const [isUnselectedItem, setIsUnselectedItem] = useState(false);
  const [keyUselected, setKeyUnselected] = useState("");

  const [removeMedia, setRemoveMedia] = useState(false);
  const [showMediaSource, setShowMediaSource] = useState(false);
  const [mediaSourceFile, setMediaSourceFile] = useState("");
  const [indexResource, setIndexResorunce] = useState(-1);
  const [showPopupInstabasica, setShowPopupInstabasica] = useState(false);
  const [instabasicaQuantity, setInstabasicaQuantity] = useState(0);
  const [instabasicaType, setInstabasicaType] = useState("");
  const [selectedInstabasicas, setSelectedInstabasicas] = useState([]);
  const [quantityError, setQuantityError] = useState(false);

  //general

  const callbackPreviusService = () =>
    history.push(`${BASE_MENU_PATH}${SCHEDULE_SERVICE}`);

  const callbackUpdateList = (list) => dispatch(changeSelectedServices(list));

  const callbackUpdateResourceList = (list) =>
    dispatch(changeResourceList(list));

  const callbackCgangeTextDescription = (e) =>
    dispatch(changeTextDescription(e.target.value));

  const validationText = (str) => str !== "";

  const isAvailableButton = () => {
    const textDescription = validationText(general_description);
    return textDescription && selected_services.length >= 1;
  };

  const callbackInitRequestDate = () => {
    const init_date_start = () =>
      moment(new Date()).day() === 5
        ? new Date(moment(new Date()).add(4, "days"))
        : moment(new Date()).add(3, "days").day() !== 0
        ? new Date(moment(new Date()).add(3, "days"))
        : new Date(moment(new Date()).add(4, "days"));
    const dayReducer = init_date_start();

    //dispatching initial values for the next view
    dispatch(changeDateSort(new Date()));
    dispatch(changeStateServiceCreationDate(Date.now()));
    dispatch(changeRequestScheduleDateStart(dayReducer));
    dispatch(changeRequestScheduleVisitingTime(new Date()));
  };

  const sliceAndPushItemInList = (list, id) => {
    return produce(list, (draftList) => {
      const currentIndex = draftList.indexOf(id);
      if (currentIndex === -1) draftList.push(id);
      else draftList.splice(currentIndex, 1);
    });
  };

  const callbackSelectedService = (id) => {
    const aux = sliceAndPushItemInList(selected_services, id);
    callbackUpdateList(aux);
  };

  const callbackViewSource = (mediaFile) => {
    setMediaSourceFile(mediaFile);
    setShowMediaSource(true);
  };

  const callbackCloseMediaSource = () => {
    setShowMediaSource(false);
  };

  const deleteMediaSource = (index) => {
    setIndexResorunce(index);
    setRemoveMedia(true);
  };

  const callbackKeepMediaSource = () => {
    setRemoveMedia(false);
  };

  const callbackRemoveMediaSource = () => {
    if (indexResource !== -1) {
      const resourcesUpdate = produce(resources, (draftResources) => {
        draftResources.splice(indexResource, 1);
      });
      callbackUpdateResourceList(resourcesUpdate);
      setRemoveMedia(false);
    }
    setIndexResorunce(-1);
  };

  const callbackRemove = () => {
    let aux = [];

    if (keyUselected !== "")
      aux = sliceAndPushItemInList(selected_services, keyUselected);

    callbackUpdateList(aux);
    setKeyUnselected("");
    setIsUnselectedItem(false);

    if (keyUselected === 2) {
      dispatch(changeTextDescription(""));
    }
  };

  const callbackBack = () => {
    setKeyUnselected("");
    setIsUnselectedItem(false);
  };

  const callbackCategorySelected = (id) => {
    const isIncludes = selected_services.includes(id);

    if (isIncludes) {
      setKeyUnselected(id);
      setIsUnselectedItem(true);
    }
  };

  const buildSRCMedia = (type, url) => {
    return {
      type,
      url,
    };
  };

  const callbackNoSelected = (id) => callbackSelectedService(id);

  const callbackAddFile = (e) => {
    const { type } = e;
    const typeFile = type.split("/")[0];

    const resourcesUpdate = produce(resources, (draftResources) => {
      draftResources.push(buildSRCMedia(typeFile, URL.createObjectURL(e)));
    });
    callbackUpdateResourceList(resourcesUpdate);
  };

  const callbackAddFileError = (e) => {};

  const isSelectedService = (list) => {
    const joinServices = [];

    LIST_SERVICES.forEach((service) => {
      const object = {
        ...service,
        is_selected: list.includes(service.id),
      };

      joinServices.push(object);
    });

    return joinServices;
  };

  const orderSetsServicesSelected = (list) => {
    const servicesSelected = isSelectedService(list);

    return servicesSelected
      .sort((a, b) => {
        if (a.is_selected > b.is_selected) return 1;
        if (a.is_selected < b.is_selected) return -1;
        return 0;
      })
      .reverse();
  };

  const listServices = orderSetsServicesSelected(selected_services);

  const isAvailable = isAvailableButton();

  const handlePopupInstabasica = () => {
    setShowPopupInstabasica(true);
  };

  const callbackQuantity = (e) => {
    const value = parseInt(e.target.value);
    if (value > 0) {
      setInstabasicaQuantity(value);
    } else {
      setInstabasicaQuantity(0);
    }
  };

  const callbackType = (e) => {
    setInstabasicaType(e.target.value);
  };

  const instabasicaText = () => {
    let text = "";
    for (let inst of selectedInstabasicas) {
      if (text === "") {
        text = `${inst.type} (${inst.quantity})`;
      } else {
        text = `${text}, ${inst.type} (${inst.quantity})`;
      }
    }
    return text;
  };

  const callbackAdd = () => {
    if (instabasicaQuantity !== 0) {
      setSelectedInstabasicas([
        ...selectedInstabasicas,
        { type: instabasicaType, quantity: instabasicaQuantity },
      ]);
      setInstabasicaQuantity(0);
      setQuantityError(false);
    } else {
      setQuantityError(true);
    }
  };

  const callbackRemoveInstabasica = (index) => {
    const copySelectedInstabasicas = produce(selectedInstabasicas, (draft) => {
      draft.splice(index, 1);
    });

    setSelectedInstabasicas(copySelectedInstabasicas);
  };

  const closeInstabasicasPopup = () => {
    if (selectedInstabasicas.length > 0) {
      dispatch(
        changeTextDescription(
          `Instabásicas: ${instabasicaText()}. \n ${general_description}`
        )
      );
    }
    setShowPopupInstabasica(false);
  };

  //___

  return (
    <>
      <NewServices
        textDescription={general_description}
        callbackAddFileError={callbackAddFileError}
        listResources={resources}
        isAvailableButton={isAvailable}
        callbackInitDate={callbackInitRequestDate}
        callbackCgangeTextDescription={callbackCgangeTextDescription}
        listServices={listServices}
        callbackPreviusService={callbackPreviusService}
        callbackCategorySelected={callbackCategorySelected}
        callbackAddFile={callbackAddFile}
        callbackNoSelected={callbackNoSelected}
        callbackViewSource={callbackViewSource}
        deleteMediaSource={deleteMediaSource}
        instabasica={selected_services.indexOf(2) !== -1}
        handlePopupInstabasica={handlePopupInstabasica}
      />
      {/* unselected */}
      {isUnselectedItem ? (
        <StateButtonModalHook
          component={PopupTwoButtons}
          hook={[isUnselectedItem, setIsUnselectedItem]}
          object={{
            callbackRemove: callbackRemove,
            callbackBack: callbackBack,
            popupText:
              "Tienes este servicio seleccionado, ¿quieres quitarlo de tu lista?",
          }}
        />
      ) : null}
      {/* remove media source */}
      {removeMedia ? (
        <StateButtonModalHook
          component={PopupTwoButtons}
          hook={[removeMedia, setRemoveMedia]}
          object={{
            callbackBack: callbackKeepMediaSource,
            callbackRemove: callbackRemoveMediaSource,
            popupText: "¿Desea eliminar el archivo de forma permanente?",
          }}
        />
      ) : null}
      {/* show media source */}
      {showMediaSource ? (
        <StateButtonModalHook
          component={ShowMediaSource}
          hook={[showMediaSource, setShowMediaSource]}
          object={{
            callbackCloseMediaSource: callbackCloseMediaSource,
            mediaSourceFile: mediaSourceFile,
          }}
        />
      ) : null}

      {showPopupInstabasica ? (
        <StateButtonModalHook
          component={PopupInstabasicas}
          hook={[showPopupInstabasica, setShowPopupInstabasica]}
          object={{
            instabasicasArray: INSTABASICAS_ARRAY,
            defaultValue: instabasicaQuantity,
            callbackQuantity: callbackQuantity,
            callbackType: callbackType,
            callbackAdd: callbackAdd,
            selectedInstabasicas: selectedInstabasicas,
            callbackRemove: callbackRemoveInstabasica,
            callback: closeInstabasicasPopup,
            error: quantityError,
          }}
        />
      ) : null}
    </>
  );
};

export default withRouter(NewServicesController);
