import { WrapSource } from "./MediaSource";

function MediaSourceContainer({
  sources,
  callbackViewSource,
  deleteMediaSource,
}) {
  return (
    <>
      {sources.map((source, index) => {
        const { type, file, url } = source;

        return (
          <div key={index} className="foto-source-container">
            <WrapSource
              callback={() => callbackViewSource(source)}
              type={type}
              file={file}
              url={url}
              index={index}
            />
            <div
              className="deletePhoto"
              onClick={() => deleteMediaSource(index)}
            >
              x
            </div>
          </div>
        );
      })}
    </>
  );
}

export default MediaSourceContainer;
