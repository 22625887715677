import CALENDAR_ICON_SVG from "assets/icons/svg/calendar.svg";
import moment from "moment";
import { dateTimeFormater } from "res/utils";

const ExtraInfo = ({ date }) => {
  const dateMoment = moment(date?.split("-")[5], "HH:mm:ss");
  const dateFormated = dateMoment.format("HH:mm A");
  return (
    <div className="cei-header-extra-info">
      <div className="cei-calendar-icon-container">
        <img className="cei-calendar-icon" src={CALENDAR_ICON_SVG} alt="" />
      </div>
      <div className="cei-date-container">
        <div className="cei-date">
          {dateTimeFormater({ date, month: "long", day: "numeric", })}
        </div>

        <div className="cei-date">
          {dateTimeFormater({ date, timeStyle: 'short', timeZone: 'America/Bogota', hour12: true, })}
        </div>
      </div>
    </div>
  );
};

export default ExtraInfo;
