import CANDADO_SVG from "../../../../assets/icons/svg/candado.svg";
import "./lockEntrys.css";
const LockEntrys = ({ callback }) => {
  return (
    <>
      <div className="lock-entry-container">
        <img src={CANDADO_SVG} className="lock-entry-icon" alt="lock" />
      </div>
    </>
  );
};

export default LockEntrys;
