import { Avatar, Button, Timeline } from "antd";
import GoBackBtn from "components/GoBackBtn/GoBackBtn";
import NavBar from "components/Navbar/NavBar";
import "./DeliveryProgress.css";

export default function DeliveryProgress() {
  return (
    <main className="delivery-progress">
      <GoBackBtn />

      <header>
        <Avatar size={64} src="https://i.pravatar.cc/100" />

        <span>Hola, <strong>Diego Perez</strong></span>
      </header>

      <section className="delivery-timeline">
        <p>Esta es tu ruta el día de hoy</p>

        <article className="delivery-timeline-container">
          <Timeline
            items={[
              {
                color: 'green',
                children: 'Create a services site 2015-09-01',
              },
              {
                color: 'green',
                children: 'Create a services site 2015-09-01',
              },
              {
                color: 'red',
                children: (
                  <>
                    <Timeline
                      pending="Recording..."
                      items={[
                        {
                          children: 'Create a services site 2015-09-01',
                        },
                        {
                          children: 'Solve initial network problems 2015-09-01',
                        },
                        {
                          children: 'Technical testing 2015-09-01',
                        },
                      ]}
                    />
                  </>
                ),
              },
              {
                children: (
                  <>
                    <p>Technical testing 1</p>
                    <p>Technical testing 2</p>
                    <p>Technical testing 3 2015-09-01</p>
                  </>
                ),
              },
              {
                color: 'gray',
                children: (
                  <>
                    <p>Technical testing 1</p>
                    <p>Technical testing 2</p>
                    <p>Technical testing 3 2015-09-01</p>
                  </>
                ),
              },
              {
                color: 'gray',
                children: (
                  <>
                    <p>Technical testing 1</p>
                    <p>Technical testing 2</p>
                    <p>Technical testing 3 2015-09-01</p>
                  </>
                ),
              },
              {
                color: '#00CCFF',
                children: <p>Custom color testing</p>,
              },
              {
                color: 'gray',
                children: (
                  <>
                    <p>Technical testing 1</p>
                    <p>Technical testing 2</p>
                    <p>Technical testing 3 2015-09-01</p>
                  </>
                ),
              },
              {
                color: 'gray',
                children: (
                  <>
                    <p>Technical testing 1</p>
                    <p>Technical testing 2</p>
                    <p>Technical testing 3 2015-09-01</p>
                  </>
                ),
              },
              {
                color: '#00CCFF',
                children: <p>Custom color testing</p>,
              },
            ]}
          />
        </article>

        <Button size="large">Empezar ruta</Button>
      </section>

      <NavBar />
    </main>
  )
}
