import React from "react";
import BuildSources from "views/loading/BuildSources";

const EvidenceEntry = ({ resources }) => {
  return (
    <div className="graphic-evidence-container">
      {resources?.map((evidence, key) => (
        <BuildSources evidence={evidence} key={key} />
      ))}
    </div>
  );
};

export default EvidenceEntry;
