import { useSelector, useDispatch } from "react-redux";

const useReduxState = (dispatchers, stateSelector) => {
  const state = useSelector((state) => state[stateSelector]);
  const dispatch = useDispatch();

  const dispatchState = Object.keys(dispatchers).reduce((acc, dispatcher) => {
    acc[dispatcher] = (...args) => dispatch(dispatchers[dispatcher](...args));
    return acc;
  }, {});

  return [state, dispatchState];
};

const useDispatcher = (dispatchers) => {
  const dispatch = useDispatch();

  const dispatchState = Object.keys(dispatchers).reduce((acc, dispatcher) => {
    acc[dispatcher] = (...args) => dispatch(dispatchers[dispatcher](...args));
    return acc;
  }, {});

  return dispatchState;
};

const useDispatcherFunction = (dispatchFunction) => {
  const dispatch = useDispatch();
  return (...args) => dispatch(dispatchFunction(...args));
};

export default useReduxState;

export { useDispatcher, useDispatcherFunction };
