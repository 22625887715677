import EXCEL_SVG from "../../../assets/icons/png/excelSVG.png";
import "../excelPreview/excelPreview.css";

const ExcelPreview = ({ title, excelUrl, key, onError }) => {
  const onExcelClick = () => {
    window.open(excelUrl, "_blank");
  };

  return (
    <div key={key} className="excelPreview-container" onClick={onExcelClick}>
      <img
        src={EXCEL_SVG}
        alt=""
        style={{ height: 30, width: 30, marginLeft: 4, marginRight: 8 }}
      />
      <h4>{`${title}.excel`}</h4>
    </div>
  );
};

export default ExcelPreview;
