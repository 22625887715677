import React, { useState } from 'react'
import { withRouter, useParams, Redirect } from 'react-router-dom'
import './psAssistants.css'
import { Appbar } from '../../../components/atoms/appbar/Appbar'
import { PsAssistantsContainer, ManagementsContainer } from '../../../components/molecules/container/Container'
import PLUS_USER_ICON_SVG from '../../../assets/icons/svg/user-plus.svg'
import SEND_ICON_SVG from '../../../assets/icons/svg/send.svg'
import FROWN_ICON_SVG from '../../../assets/icons/svg/frown.svg'
import { AssistantsButton,PrimaryButton } from '../../../components/atoms/button/Button'

const PsAssistantsList = ({
    assistantsList,
    assistantsNumber,
    handleRequestAssistants,
    handleInviteAssistant,
    callbackSelectedItem,
    hideButton,
    bigText,
    showOptions,
    removeHelpers = false,
    handleRemoveAssistants

}) => {

    return (
        <div className='ps-services-assistants-container'>

            <div className='appbar popup-appbar'>

                <div className='small-text-container'>
                    Listado de
                </div>
                <div className='big-text-container'>
                    {bigText}
                </div>
            </div>

            <div className='assistans-container'>
                <div className='helpers-cards-container'>
                {
                    assistantsList.length > 0 ?
                        assistantsList.map((obj, index) =>

                            bigText === 'Encargados' ?
                                <ManagementsContainer
                                    key={index}
                                    name={obj.register_data.name}
                                    email={obj.register_data.contact.email_associated}
                                    phone={obj.register_data.contact.primary_phone}
                                    id={obj.unique_key}
                                    callback={() => { showOptions(obj) }}

                                /> :

                                <PsAssistantsContainer
                                    key={index}
                                    callback={() => callbackSelectedItem(obj.unique_key)}
                                    name={obj.register_data.name}
                                    photo={obj.register_data.thumbnail}
                                    specialty={obj.register_data.academy.maximum_academic_level}
                                    certificate={obj.certificate}
                                    id={obj.unique_key}
                                    disableClickAction={hideButton}

                                />

                        ) :

                        <div className='no-assistants-container'>
                            <img src={FROWN_ICON_SVG} className='no-entries-icon' />
                            <div className='no-entries-title'>{`Actualmente no tienes ${bigText.toLowerCase()} registrados.`}</div>
                        </div>

                }
                </div>

                <div className='assistants-buttons-container'>

                    {
                        bigText === 'Encargados' ? null :

                            !removeHelpers ?
                                <div className='assistants-invitation text-label-sp' >
                                    <div className='ai-icon-container' onClick={handleInviteAssistant}>
                                        <img className='ai-icon' src={PLUS_USER_ICON_SVG} />
                                    </div>
                                    <div className='ai-text' onClick={handleInviteAssistant}>
                                        Invitar un nuevo ayudante
                                    </div>
                                </div> :
                                null

                    }

                    {
                        !hideButton ?
                            !removeHelpers ?
                            <div className='assistants-list-button'>
                                <AssistantsButton
                                    icon={SEND_ICON_SVG}
                                    callback={assistantsNumber > 0 ? handleRequestAssistants : null}
                                    number={assistantsNumber}
                                    txt='Invitar ayudantes'
                                    style={assistantsNumber > 0 ? '' : 'assistants-btn-disable'} />
                            </div> 
                            :
                            <div className='assistants-list-button'>
                                <PrimaryButton
                                    callback={assistantsNumber > 0 ? handleRemoveAssistants : null}
                                    txt='Remover ayudantes'
                                    style={assistantsNumber > 0 ? '' : 'assistants-btn-disable'}
                                />
                            </div> 
                            : 
                            null
                    }


                </div>

            </div>

        </div>
    )
}

export default withRouter(PsAssistantsList)