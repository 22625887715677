import React from 'react'
import './textarea.css'

const BaseTextarea = ({ style, placeholder, callback, text = '',value }) => {

    return (
        <textarea className={`baseTextarea ${style}`} placeholder={placeholder} onChange={callback} value={value}/>
    )
}

const IconTextarea = ({ placeholder, icon, callback, defaultValue = '' }) => {
    return (
        <div className='containerIconTextarea'>
            <div className='containerIconText'>
                <img src={icon} className='icon' alt={placeholder} />
            </div>
            <BaseTextarea value={defaultValue} placeholder={placeholder} className='iconTextarea' callback={callback} />
        </div>
    )
}

export {
    BaseTextarea,
    IconTextarea
}
