import React from "react";
import { withRouter, useParams } from "react-router-dom";

import "../loading/loadingScreen.css";
import "../caseEntryInfo/caseEntryInfo.css";
import ChatView from "./ChatView";
import { useSelector } from "react-redux";

const ChatViewController = ({ history }) => {
  const auth = useSelector((state) => state.auth);
  const { idEntry, idService } = useParams();

  return (
    <ChatView
      history={history}
      userType={auth.userType}
      entryId={idEntry}
      id={idService}
    />
  );
};

export default withRouter(ChatViewController);
