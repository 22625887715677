import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { LoadingPopup } from "../../components/atoms/popup/Popup";
import useFile from "../../custom-hooks/useFile";
import useResourceUploader from "../../custom-hooks/useResourceUploader";
import StateButtonModalHook from "../../modal/StateButtonModalHook";
import PopupTwoOptionWithIcon from "../../popups/PopupTwoOptionsWithIcon";
import { ShowMediaSource } from "../../popups/PopupsNewServices";
import { PopupSuccessEvent } from "../../popups/psPopups/PopupSuccessEvent";
import {
  changeActivitites,
  changeDate,
  changeDescription,
  changeIsVisble,
  changePhase,
  changeResourceList,
  changeRole,
  changeTitle,
  resetToInitialState,
} from "../../reducers/form.entry.reducer";
import NewUnexpected from "./NewUnexpected";

import TRASH_ICON_SVG from "../../assets/icons/svg/trash.svg";
import {
  convertPhaseNameToSpanishName,
  convertPhaseToColor,
} from "../../res/convert.codes";
import {
  createNewEntry,
  createUnexpectedActivity,
  getPhases,
} from "../../services-controller/http.cleinte.controller";
import { processPhases } from "../../views/services-provider/ps-new-entry/phases";

const NewUnexpectedController = ({ newEntryCallback }) => {
  const history = useHistory();
  const { idService } = useParams();
  const dispatch = useDispatch();
  const unexpected_entry = useSelector((state) => state.form_entry);
  const userAuth = useSelector((state) => state.auth.user);
  const [resourcelink, processResources] = useResourceUploader();
  const [bitacoraUpdated, setBitacoraUpdated] = useState(false);
  const [unexpectedActivity, setUnexpectedActivity] = useState({
    labor_cost: 0,
    material_cost: 0,
    days: 0,
    phase_position: 0,
    phase_id: 0,
    name: "",
    description: "",
  });
  const [isLoader, setIsLoader] = useState(false);
  const [phases, setPhases] = useState([]);
  const [actualPhaseName, setActualPhaseName] = useState("");
  const [colorPhase, setColorPhase] = useState("");
  const [isOverCost, setIsOverCost] = useState(false);
  const {
    date,
    description,
    id,
    is_approved,
    steps_selected,
    time_end,
    time_start,
    phase,
    title,
    activities_selected,
    user,
    messages,
    resources,
    unexpected,
    finished_item,
    is_visible,
  } = unexpected_entry;

  const file = useFile(
    resources,
    (list) => dispatch(changeResourceList(list)),
    setIsLoader
  );

  const fetchServiceById = async () => {
    try {
      setIsLoader(true);

      const result = await getPhases(idService);
      const statusService = result;
      // Realiza operaciones adicionales con 'statusService'
      if (statusService === null || statusService === undefined) {
      } else {
        const processedPhases = await processPhases(
          statusService,
          userAuth.user_type
        );

        setPhases(processedPhases);
        dispatch(changeDate(moment().format("dd-DD-MMMM-MM-YYYY-HH:mm:ss")));
        dispatch(changeRole(userAuth));
      }

      const phaseName = statusService?.globalState?.state?.currentPhase;
      const phaseCode = statusService?.globalState?.state?.currentPhaseIndex;

      const phaseSelected = result?.phases?.find(
        (phase) => phase.position === phaseCode
      );

      setUnexpectedActivity({
        ...unexpectedActivity,
        phase_position: phaseSelected.id,
        phase_id: phaseSelected.unique_key,
      });

      setActualPhaseName(convertPhaseNameToSpanishName(phaseName));
      setColorPhase(convertPhaseToColor(phaseName));

      dispatch(
        changePhase({
          name: phaseSelected.title,
          code: phaseSelected.id,
          id: phaseSelected.unique_key,
        })
      );
    } catch (error) {
      console.log("Ocurrió un error:", error);
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    fetchServiceById();
    return () => {
      dispatch(resetToInitialState());
    };
  }, [idService]); // eslint-disable-line react-hooks/exhaustive-deps

  const isAvailable = () => {
    if (isOverCost) {
      return (
        unexpectedActivity.name !== "" &&
        unexpectedActivity.description !== "" &&
        unexpectedActivity.labor_cost !== 0 &&
        unexpectedActivity.material_cost !== 0 &&
        phase !== undefined &&
        unexpectedActivity.phase_position !== undefined &&
        unexpectedActivity.phase_id !== undefined
      );
    } else {
      return (
        unexpectedActivity.name !== "" &&
        unexpectedActivity.description !== "" &&
        phase !== undefined &&
        unexpectedActivity.phase_position !== undefined &&
        unexpectedActivity.phase_id !== undefined
      );
    }
  };

  const sendUnexpected = async () => {
    const unexpected = {
      title: `Imprevisto en ${phase.name}`,
      code: phase.code,
      id: phase.id,
    };
    const title = {
      title: unexpectedActivity.name,
      code: phase.code,
      id: phase.id,
    };
    const activitiesTitle = [unexpected, title];

    if (isAvailable()) {
      setIsLoader(true);

      const resourcesLink = await processResources(resources, id);

      const unexpected = {
        ...unexpected_entry,
        resources: resourcesLink,
        unexpected: true,
        unexpectedDescription: unexpectedActivity,
        activities_selected: activitiesTitle,
      };

      const userInfo = {
        name: userAuth.register_data.name,
        user_type: "sp",
        id: userAuth.id,
      };

      const result = await createNewEntry(
        idService,
        "admin",
        unexpected,
        userInfo
      );

      if (result) {
        await createUnexpectedActivity(idService, unexpectedActivity);
      }
    }
    setIsLoader(false);
    setBitacoraUpdated(!bitacoraUpdated);
  };

  const callbackItemsDropdown = (item) => {
    setUnexpectedActivity({
      ...unexpectedActivity,
      phase_position: item.code,
      phase_id: item.id,
    });
    setActualPhaseName(item.title);
    dispatch(changePhase({ name: item.title, code: item.code, id: item.id }));

    const unexpected = {
      title: `Nuevo Imprevisto en ${item.title}`,
      code: item.code,
      id: item.id,
    };

    dispatch(changeActivitites([unexpected]));
  };

  const callbackDescripcionTextarea = (e) => {
    dispatch(changeDescription(e.target.value));
    setUnexpectedActivity({
      ...unexpectedActivity,
      description: e.target.value,
    });
  };

  const callbackHiddenEntry = () => {
    dispatch(changeIsVisble(!is_visible));
  };

  const callbackCloseBitacoraPopup = () => {
    history.push(`/services/${idService}`);
    setBitacoraUpdated(!bitacoraUpdated);
    dispatch(changeActivitites([]));
  };

  const callbackIsOverCost = () => {
    setIsOverCost(!isOverCost);
  };

  const handleUnexpectedValue = (e, type) => {
    if (e.floatValue !== undefined) {
      setUnexpectedActivity({
        ...unexpectedActivity,
        [type]: parseFloat(e.floatValue),
      });
    } else {
      setUnexpectedActivity({
        ...unexpectedActivity,
        [type]: e.target.value,
      });
      if (type === "name") {
        dispatch(changeTitle(e.target.value));
      }
    }
  };

  return (
    <>
      <NewUnexpected
        idService={idService}
        phaseName={actualPhaseName}
        colorPhase={colorPhase}
        phaseSelected={phase}
        phases={phases}
        file={file}
        callbackItemsDropdow={callbackItemsDropdown}
        callbackDescripcionTextarea={callbackDescripcionTextarea}
        callbackHiddenEntry={callbackHiddenEntry}
        handleUnexpectedValue={handleUnexpectedValue}
        callbackIsOverCost={callbackIsOverCost}
        isOverCost={isOverCost}
        callbackNewEntry={newEntryCallback}
        listResources={resources}
        unexpected={unexpectedActivity}
        updateAviable={isAvailable()}
        updateBitacora={sendUnexpected}
      />
      {bitacoraUpdated && (
        <StateButtonModalHook
          component={PopupSuccessEvent}
          hook={[bitacoraUpdated, setBitacoraUpdated]}
          object={{
            btnText: "ok",
            message: "Bitácora actualizada",
            callback: callbackCloseBitacoraPopup,
          }}
        />
      )}

      {/* remove media source */}
      {file.removeMedia && (
        <StateButtonModalHook
          component={PopupTwoOptionWithIcon}
          hook={[file.removeMedia, file.setRemoveMedia]}
          object={{
            callbackNegative: file.callbackKeepMediaSource,
            callbackPositive: file.callbackRemoveMediaSource,
            textPositive: "Si",
            textNegative: "No",
            popupText: "¿Desea eliminar el archivo de forma permanente?",
            popupIcon: TRASH_ICON_SVG,
          }}
        />
      )}
      {/* show media source */}
      {file.showMediaSource && (
        <StateButtonModalHook
          component={ShowMediaSource}
          hook={[file.showMediaSource, file.setShowMediaSource]}
          object={{
            callbackCloseMediaSource: file.callbackCloseMediaSource,
            mediaSourceFile: file.mediaSourceFile,
          }}
        />
      )}

      <LoadingPopup state={isLoader} />
    </>
  );
};

export default NewUnexpectedController;
