import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import SpecialityInfoServiceProvider from "./SpecialityInfoServiceProvider.jsx";
import { PRINCIPAL_PATH, STEPS_PATH } from "../../../router/routes";
import LIST_SERVICES from "../../../res/list.services";
import { changeServiceProviderCoverageServices } from "../../../reducers/app.reducer";
import {
  convertRegisterTypeByRouterToNames,
  convertUserTypeToRegisterTypeCode,
} from "../../../res/convert.codes";
import { createServiceProviderById } from "../../../services-controller/http.cleinte.controller";
import { createAccountWhitEmailAndPassword } from "../../../services-controller/auth";
import { getValueByKey } from "../../../persistent-managment/managment.persistent";
import { UID_AUTH } from "../../../persistent-managment/key.persistent";
import { convertRegisterTypeByRouterToCode } from "../../../res/convert.codes";
import { SERVICE_PROVIDER_CODE } from "../../../router/codes.type.properties";
import {
  CREATE_USER_ERROR_MESSAGE,
  EMAIL_VALIDATION_ERROR_MESSAGE,
  PASS_VALIDATION_ERROR_MESSAGE,
  EMAIL_PASS_VALIDATION_ERROR_MESSAGE,
} from "../../../res/errors";
import { REGEX_EMAIL, REGEX_PASS } from "../../../res/regex";
import { LoadingPopup } from "../../../components/atoms/popup/Popup";
import StateButtonModalHook from "../../../modal/StateButtonModalHook.jsx";
import { ErrorRegister } from "../../../popups/SuccessRegister.jsx";
import { useSelector, useDispatch } from "react-redux";

const SpecialityInfoServiceProviderController = ({ history }) => {
  const dispatch = useDispatch();
  const { app, form_service_provider } = useSelector((state) => {
    return {
      app: state.app,
      form_service_provider: state.form_service_provider,
    };
  });

  const {
    service_provider_type,
    service_provider_coverage: { services },
  } = app;

  const {
    register_data: {
      contact: { email_associated },
      secrets: { password },
    },
  } = form_service_provider;

  //state

  const [showButton, setShowButton] = useState(false);

  const [isLoader, setIsLoader] = useState(false);

  const [isErrorForm, setIsErrorForm] = useState(false);

  const stateErrorFormCallback = (state) => setIsErrorForm(state);

  const [errorMessage, setErrorMessage] = useState("");

  const redirectPath = () => {
    if (service_provider_type === "") history.push(PRINCIPAL_PATH);
  };

  useEffect(() => {
    redirectPath();

    return () => {};
  }, []);

  // general

  const callbackUpdateList = (list) =>
    dispatch(changeServiceProviderCoverageServices(list));

  const callbackPushNavigator = async () =>
    await callbackCreateServiceProvider();

  const isSelectedItem = (id) => services.includes(id);

  const callbackSelectedService = (id) => {
    const aux = [...services];
    const currentIndex = aux.indexOf(id);
    if (currentIndex === -1) aux.push(id);
    else aux.splice(currentIndex, 1);

    callbackUpdateList(aux);
    setShowButton(aux.length >= 1);
  };

  const showModalError = (message) => {
    setIsLoader(false);
    setErrorMessage(message);
    stateErrorFormCallback(true);
  };

  const validatorEmail = (str) => {
    const result = REGEX_EMAIL.test(str);
    return result;
  };

  const validatorPassword = (str) => {
    const result = REGEX_PASS.test(str);
    return result;
  };

  const callbackCreateServiceProvider = async () => {
    setIsLoader(true);
    const emailEvaluation = validatorEmail(email_associated);
    const passEvaluation = validatorPassword(password);
    const emailAndPassEvaluation = emailEvaluation && passEvaluation;

    if (!emailAndPassEvaluation)
      showModalError(EMAIL_PASS_VALIDATION_ERROR_MESSAGE);
    else {
      if (!emailEvaluation) showModalError(EMAIL_VALIDATION_ERROR_MESSAGE);
      else if (!passEvaluation) showModalError(PASS_VALIDATION_ERROR_MESSAGE);
      else {
        const result = await createAccountWhitEmailAndPassword(
          email_associated,
          password
        );
        const { code } = result;
        if (code === 400) {
          const { message } = result;
          showModalError(message);
        } else await savingDatabase();
      }
    }
  };

  const savingDatabase = async () => {
    const uid = getValueByKey(UID_AUTH);
    const result = await createServiceProviderById(
      uid,
      {
        ...form_service_provider,
        register_type: convertUserTypeToRegisterTypeCode(service_provider_type),
        user_type: convertRegisterTypeByRouterToCode(service_provider_type),
      },
      {
        ...app.service_provider_coverage,
      }
    );
    const { status } = result;
    if (status === 200)
      history.push({
        pathname: STEPS_PATH,
        state: { type: SERVICE_PROVIDER_CODE },
      });
    else showModalError(CREATE_USER_ERROR_MESSAGE);
  };

  return (
    <>
      <SpecialityInfoServiceProvider
        type={convertRegisterTypeByRouterToNames(service_provider_type)}
        callbackPushNavigator={callbackPushNavigator}
        listServices={LIST_SERVICES}
        isSelectedItem={isSelectedItem}
        callbackSelectedService={callbackSelectedService}
        showButton={showButton}
      />
      {/* error */}
      {isErrorForm ? (
        <StateButtonModalHook
          component={ErrorRegister}
          hook={[isErrorForm, setIsErrorForm]}
          object={{
            message: errorMessage,
            callback: stateErrorFormCallback,
          }}
        />
      ) : null}
      <LoadingPopup state={isLoader} />
    </>
  );
};

export default withRouter(SpecialityInfoServiceProviderController);
