import React, { useEffect, useState } from "react";
import { IconInput } from "../../../components/atoms/input/Input";
import { RegisterUserTemplate } from "../../../components/templates/registerTemplate/RegisterTemplate";
import {
  IconButton,
  PrimaryButton,
} from "../../../components/atoms/button/Button";
import USER_ICON_SVG from "../../../assets/icons/svg/user.svg";
import PHONE_ICON_SVG from "../../../assets/icons/svg/phone.svg";
import MAIL_ICON_SVG from "../../../assets/icons/svg/mail.svg";
import { IDENTIFIER_TYPE } from "../../../res/identifier.type";
import { DropdownWithInput } from "../../../components/atoms/dropdown/Dropdown";
import "../register.css";
import { AppbarLogo } from "../../../components/atoms/appbar/Appbar";
import { useRef } from "react";
import { arePropsEqual } from "../../../res/utils";

const RegisterGeneral = ({
  type,
  callbackUploadThumbnail,
  callbackSelectImageError,
  thumbnail,
  callbackPushNavigator,
  isAvailableCallback,
  callbackChangeName,
  defaultValueName,
  callbackChangePhone,
  defaultValuePhone,
  callbackChangeEmailAssociated,
  defaultValueEmail,
  callbackChangeIdentifier,
  defaultValueIdentifier,
  changePassword,
  defaultValuePassword,
  validPass,
  validEmail,
  callbackTypeIdentity,
  defaultValueTypeIdentify,
  successRegister,
  callbackNavigateMenu,
}) => {
  const [identifier, setDefaultValueIdentifier] = React.useState(
    defaultValueIdentifier
  );

  useEffect(() => {
    if (defaultValueIdentifier !== "") {
      changePassword(defaultValueIdentifier);
    }
  }, [identifier, defaultValueIdentifier]); // El array de dependencias contiene las variables que, al cambiar, dispararán el efecto.

  return (
    <div className="register-info-container">
      <AppbarLogo />
      <RegisterUserTemplate
        callbackSelectImage={callbackUploadThumbnail}
        callbackSelectImageError={callbackSelectImageError}
        thumbnail={thumbnail}
        type={type}
        bigText="¡Completa tu perfil de usuario "
        fields={
          <div className="order-form">
            <div className="options-fields">
              <div className="fields-container">
                <IconInput
                  defaultValue={defaultValueName}
                  callback={callbackChangeName}
                  icon={USER_ICON_SVG}
                  placeholder="Nombre y apellido"
                />
              </div>

              <div className="fields-container">
                <DropdownWithInput
                  typeInput="number"
                  callbackInput={callbackChangeIdentifier}
                  defaultValue={defaultValueIdentifier}
                  callback={callbackTypeIdentity}
                  // eslint-disable-next-line react/style-prop-object
                  style="basic-input"
                  placeholder="Número de identificación"
                  defaultSelected={defaultValueTypeIdentify}
                  items={IDENTIFIER_TYPE.map((identity, index) => (
                    <option key={index} className="rol-option">
                      {identity}
                    </option>
                  ))}
                />
              </div>
              <div className="fields-container">
                <IconInput
                  defaultValue={defaultValuePhone}
                  callback={callbackChangePhone}
                  type="number"
                  icon={PHONE_ICON_SVG}
                  placeholder="Teléfono"
                />
              </div>

              <div className="fields-container">
                <IconInput
                  defaultValue={defaultValueEmail}
                  callback={callbackChangeEmailAssociated}
                  icon={MAIL_ICON_SVG}
                  placeholder="Correo electrónico"
                  validInput={validEmail}
                />
              </div>

              {!validPass ? null : (
                <div className="pass-text">
                  La contraseña Es El Numero De Identificacion
                </div>
              )}

              {isAvailableCallback ? (
                <div className=" register-button">
                  {successRegister ? (
                    <PrimaryButton
                      txt="Ir a menu"
                      callback={callbackNavigateMenu}
                    />
                  ) : (
                    <IconButton callback={callbackPushNavigator} />
                  )}
                </div>
              ) : null}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default RegisterGeneral;
