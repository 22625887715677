import React, { useEffect } from "react";
import Portal from "../portal/Portal.jsx";
import "./modal.css";

const BasePortal = (props) => {
  return (
    <>
      <div className="background-container" onClick={props.callback} />
    </>
  );
};

const Modal = ({ component: Component, ...rest }) => {
  return (
    <Portal>
      <BasePortal {...rest} />
      <div className="modal-container">
        <Component {...rest} />
      </div>
    </Portal>
  );
};

const StateButtonModalHook = ({ component: Component, object, hook }) => {
  const [state, setState] = hook;
  const callback = () => setState(!state);

  useEffect(() => {
    return () => {};
  }, [state]);

  return (
    <div>
      {state ? (
        <Modal callback={callback} object={object} component={Component} />
      ) : null}
    </div>
  );
};

export default StateButtonModalHook;

export const StateMomentButtonModalHook = ({
  component: Component,
  object,
  hook,
}) => {
  const [state, setState] = hook;
  const callback = () => setState(!state);
  useEffect(() => {
    setTimeout(() => {
      callback();
    }, 5000);
  }, []);
  return (
    <div>
      {state ? (
        <Modal callback={callback} object={object} component={Component} />
      ) : null}
    </div>
  );
};
