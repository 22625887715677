import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Badge, Button, Empty, Form, Image, Input, InputNumber, message, Select } from "antd";
import { ReactComponent as ArrowRightIcon } from "assets/icons/svg/arrow-right.svg";
import GoBackBtn from "components/GoBackBtn/GoBackBtn";
import { useEffect, useState } from "react";
import { getAllMaterials } from "services-controller/http.cleinte.controller";
import "./OrderAdditionalMaterial.css";

export default function OrderAdditionalMaterial() {
  const [form] = Form.useForm();
  const [materials, setMaterials] = useState({ isLoading: false, isSuccess: false, isError: false, data: [], parsedData: [], subParsedData: [] });
  const materialsSelected = Form.useWatch("materialList", form);
  const [submitConfirmation, setSubmitConfirmation] = useState(false);

  const onSubmit = (values) => {
    console.log(values);
  }

  const getMaterials = () => {
    (async () => {
      setMaterials(prev => ({ ...prev, isLoading: true }));

      try {
        const res = await getAllMaterials()
        setMaterials(prev => ({ ...prev, isSuccess: true, data: res }))
      } catch {
        setMaterials(prev => ({ ...prev, isError: true }))
        message.error("Hubo un error al recuperar los materiales")
      } finally {
        setMaterials(prev => ({ ...prev, isLoading: false }))
      }
    })()
  }

  useEffect(() => {
    if (materials.data.length) {
      const selectOptions = materials.data.map(material => {
        const materialImage = material.images?.at(0)?.url ?? material.options?.at(0).images?.at(0)?.url;

        return {
          label: (
            <div className="select-option-wrapper">
              {materialImage && (
                <Image
                  src={materialImage}
                  alt={material.name}
                  className="material-image"
                  width={52}
                  height={52}
                  preview={false}
                />
              )}

              <span className="select-option-name">
                {material.name}
              </span>
            </div>
          ),
          value: material.id,
          text: material.name,
        }
      })

      setMaterials(prev => ({ ...prev, parsedData: selectOptions }))
    }
  }, [materials.data])

  const onAdd = () => {
    const materialList = form.getFieldValue("materialList");
    materialList.push({ material: null, quantity: null });
    form.setFieldsValue({ materialList });
  }

  return (
    <main className="order-additional-material">
      <GoBackBtn />

      <section className="material-wrapper">
        <h1>Material(es) adicional(es)</h1>

        <Form
          form={form}
          onFinish={onSubmit}
          initialValues={{ materialList: [{}] }}
          layout="vertical"
          rootClassName="material-form"
        >
          <Form.List name="materialList">
            {(fields, { remove }) => (
              <section className="material-list">
                {!fields.length && (<Empty description="No hay materiales adicionales" />)}

                {fields.map(({ key, name, ...restField }) => {

                  return (
                    <article className="material-list-item" key={key}>
                      <Button
                        onClick={() => remove(name)}
                        danger
                        size="large"
                        type="primary"
                        className="remove-item-btn"
                        icon={<DeleteOutlined />}
                      />

                      <div className="material-item-wrapper">
                        <Form.Item
                          {...restField}
                          rules={[
                            { required: true, message: "Debes seleccionar el material" },
                          ]}
                          name={[name, "material_id"]}
                          label="Selecciona el material"
                        >
                          <Select
                            onDropdownVisibleChange={(value) => {
                              if (value && !materials.data.length) {
                                getMaterials()
                              }
                            }}
                            options={materials.parsedData}
                            loading={materials.isLoading}
                            showSearch
                            optionFilterProp="text"
                            filterSort={(optionA, optionB) => {
                              return (optionA?.text ?? '').toLowerCase().localeCompare((optionB?.text ?? '').toLowerCase())
                            }}
                            allowClear
                            className="material-select"
                          />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          rules={[
                            { required: true, message: "La cantidad del material es necesaria" },
                          ]}
                          name={[name, "quantity"]}
                          label="Cantidad"
                        >
                          <InputNumber className="quantity-input" />
                        </Form.Item>
                      </div>
                    </article>
                  )
                })}
              </section>
            )}
          </Form.List>

          <footer>
            <Form.Item
              rules={[
                { required: true, message: "Debe escribir la razon por la cual necesitas este material" },
              ]}
              name={"reason"}
              label="¿Porqué necesitas este material?"
            >
              <Input.TextArea className="reason-input" />
            </Form.Item>

            <div className="form-actions-section">
              <Badge
                count={materialsSelected?.length}
                classNames={{ root: "badge-wrapper", count: "badge-count" }}
                styles={{
                  root: {
                    ...(!submitConfirmation && { flex: "1" })
                  }
                }}
              >
                <Button
                  onClick={() => {
                    if (submitConfirmation) {
                      setSubmitConfirmation(false)
                      return
                    }

                    onAdd()
                  }}
                  rootClassName="add-new-item-btn"
                  size="large"
                  {...(submitConfirmation && { icon: <PlusOutlined /> })}
                >
                  {!submitConfirmation && "Agregar material"}
                </Button>
              </Badge>

              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="order-submit-btn"
                {...(!submitConfirmation && { icon: <ArrowRightIcon /> })}
                onClick={() => {
                  setSubmitConfirmation(true)
                }}
                style={{
                  ...(submitConfirmation && { flex: "1" })
                }}
              >
                {submitConfirmation && "Realizar pedido"}
              </Button>
            </div>
          </footer>
        </Form>
      </section>
    </main>
  )
}
