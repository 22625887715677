import React from 'react'
import LOGO_ICON_SVG from '../../assets/icons/svg/otros.svg'
import { PrimaryButton } from '../../components/atoms/button/Button'
import { IconInput,CurrencyFormatInput } from '../../components/atoms/input/Input'
import './psPopups.css'
import PARAGRAPH_ICON_SVG from '../../assets/icons/svg/parrafo.svg'
import DOLLAR_ICON_SVG from '../../assets/icons/svg/dollar-sign.svg'
import AWARD_ICON_SVG from '../../assets/icons/svg/award.svg'
import CALENDAR_ICON_SVG from '../../assets/icons/svg/calendar.svg'
import { BaseDropdown } from '../../components/atoms/dropdown/Dropdown'

const PopupNewGoal = ({ object }) => {

    const {
        callbackAddNewGoal: callbackAddNewGoal,
        handleGoalAmount,
        handleGoalTitle,
        handleGoalDay,
        handleGoalMonth,
        handleGoalYear,
        amount,
        title,
        daysArray,
        monthArray,
        yearsArray
    } = object

    return (
        <div className='popup-goal-container'>

            <div className='png-container'>
                <img src={LOGO_ICON_SVG} className='iconPopup ceteredIcon' />
            </div>

            <div className='goal-popup-text'>
                Te ayudamos a cumplir tus metas, ¡no olvides registrarlas para hacerlas realidad!
            </div>

            <div className='text-goal-details'>

                <div>
                    <img src={AWARD_ICON_SVG} className='img-text-goal-details' alt={'CALENDAR_SVG_ICON'} />
                </div>
                <div className='text-details'>
                    Datos de la meta
                </div>

            </div>

            <div className='goal-input-container'>
                <IconInput 
                placeholder='Nombre de la meta' 
                icon={PARAGRAPH_ICON_SVG} 
                callback={handleGoalTitle} 
                value={title}
                defaultValue={title}
                />
            </div>

            <div className='goal-input-container'>
                <CurrencyFormatInput 
                placeholder='Monto' 
                icon={DOLLAR_ICON_SVG} 
                callback={handleGoalAmount} 
                value={amount}
                defaultValue={amount}
                />
            </div>

            <div className='text-goal-details'>

                <div>
                    <img src={CALENDAR_ICON_SVG} className='img-text-goal-details' alt={'CALENDAR_SVG_ICON'} />
                </div>
                <div className='text-details'>
                    Elegir fecha límite
                </div>

            </div>

            <div className='deadline-container'>

                <div className='deadline-container-dropdown'>
                    <BaseDropdown items={daysArray.map((state, index) => (
                        <option
                            key={index}
                            className='rol-option'>{state}
                        </option>)
                    )} defaultSelected='Dia' style='new-entry-dropdown goal-dropdown' callback={handleGoalDay} />
                </div>

                <div className='deadline-container-dropdown'>
                    <BaseDropdown items={monthArray.map((state, index) => (
                        <option
                            key={index}
                            className='rol-option'>{state}
                        </option>)
                    )} defaultSelected='Mes' style='new-entry-dropdown goal-dropdown' callback={handleGoalMonth} />
                </div>

                <div className='deadline-container-dropdown'>
                    <BaseDropdown items={yearsArray.map((state, index) => (
                        <option
                            key={index}
                            className='rol-option'>{state}
                        </option>)
                    )} defaultSelected='Año' style='new-entry-dropdown goal-dropdown' callback={handleGoalYear} />
                </div>

            </div>

            <PrimaryButton txt='Agregar nueva meta' callback={callbackAddNewGoal} style='new-goal-button-style' />

        </div>
    )

}

export default PopupNewGoal