import { produce } from "immer";
import React, { useEffect, useMemo, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import FROWN_ICON_SVG from "../../../assets/icons/svg/frown.svg";
import X_ICON_SVG from "../../../assets/icons/svg/x.svg";
import { LoadingPopup } from "../../../components/atoms/popup/Popup";
import StateButtonModalHook from "../../../modal/StateButtonModalHook.jsx";
import { PopupTwoButtons } from "../../../popups/PopupTwoButtons.jsx";
import { ShowMediaSource } from "../../../popups/PopupsNewServices.jsx";
import { PopupRejectEvent } from "../../../popups/psPopups/PopupRejectEvent.jsx";
import { PopupSuccessEvent } from "../../../popups/psPopups/PopupSuccessEvent.jsx";
import {
  addQuoteItem,
  changeArea,
  changeDamageLocation,
  changeDamageType,
  changeDescription,
  changeDuration,
  changeFloorLevels,
  changeMaterials,
  changeMeasureUnit,
  changeObservation,
  changeResources,
  changeTasks,
  changeUnforeseen,
  changeUpdateItem,
  changeWorkersHelpers,
  changeWorkersOfficials,
  changeWorkforce,
  quote_item_initial,
} from "../../../reducers/quote.item.reducer";
import {
  PRINCIPAL_PATH,
  PS_COTIZACION,
  PS_COTIZACION_EDIT,
  PS_COTIZACION_FORM,
  PS_COTIZACION_GENERAL,
  PS_MENU_PATH,
} from "../../../router/routes";
import {
  allItemsByService,
  allMetadataByFormQuote,
  createItemInQuote,
  updateItem,
  updateStageByServiceId,
} from "../../../services-controller/http.cleinte.controller";
import { uploadMultipleEvidenceResources } from "../../../services-controller/storage";
import ServiceProviderCotizacion from "./ServiceProviderCotizacion.jsx";
import ServiceProviderCotizacionItems from "./ServiceProviderCotizacionItems.jsx";

const FORM_QUOTE = "form";
const EDIT_QUOTE = "edit";

const BuildView = (path, history, service) => {
  let { id } = useParams();

  const dispatch = useDispatch();
  const quote_items = useSelector(
    (state) => state.form_quotes_item.quote_items
  );

  const getInitItems = async () => {
    const data = await allItemsByService(id);
    dispatch(addQuoteItem(data));
  };

  useEffect(() => {
    getInitItems();
  }, []);

  // state
  const [quote_item, setQuoteItem] = useState(quote_item_initial);

  const {
    damage_type,
    description,
    tasks,
    damage_locations,
    floor_levels,
    area,
    measure_unit,
    materials,
    workforce,
    duration,
    unforeseen,
    workers_helper,
    workers_oficial,
    resources,
    observation,
  } = quote_item;

  const [metadata, setMetadata] = useState({
    damage_type: [],
    damage_locations: [],
    floor_levels: [],
    measure_units: [],
  });

  const [itemsList, setItemsList] = useState([]);

  const [sendCotizacion, setSendCotizacion] = useState(false);

  const [rejectSendCotizacion, setRejectSendCotizacion] = useState(false);

  const [isLoader, setIsLoader] = useState(false);

  const [currentStep, setCurrentStep] = useState("");

  const [indexItemEdit, setIndexItemEdit] = useState(0);

  const [deleteItemPopup, setDeleteItemPopup] = useState(false);

  const [deleteItemInfo, setDeleteItemInfo] = useState({});

  const [deleteItemIndex, setDeleteItemIndex] = useState(-1);

  const [errorValidator, setErrorValidator] = useState(false);

  //form material state

  const [nameMaterial, setNameMaterial] = useState("");

  const [unitsMaterial, setUnitsMaterial] = useState(0);

  const [quantityMaterial, setQuantityMaterial] = useState(0);

  const [unitMeasure, setUnitMeasure] = useState("Medida");

  const [unitPrice, setUnitPrice] = useState(0);

  // media

  const [showMediaSource, setShowMediaSource] = useState(false);

  const [mediaSourceFile, setMediaSourceFile] = useState("");

  const [removeMedia, setRemoveMedia] = useState(false);

  const [indexResource, setIndexResorunce] = useState(-1);

  const [incompleteVars, setIncompleteVars] = useState();

  useEffect(() => {
    getValues();
  }, [path, quote_items]);

  // general

  const callbackDismisErrorValidator = () => setErrorValidator(false);

  const callbackValidationParams = useMemo(() => {
    let incompleteVars = [];

    if (damage_type === "Tipo de daño") {
      incompleteVars.push("Tipo de daño");
    }

    if (tasks.length === 0) {
      incompleteVars.push("Agregar pasos");
    }

    if (damage_locations === "Selecciona la ubicación del daño") {
      incompleteVars.push("Ubicación del daño");
    }

    if (floor_levels === "Piso o nivel de ubicación") {
      incompleteVars.push("Piso o nivel de ubicación");
    }

    if (area === "") {
      incompleteVars.push("Área");
    }

    if (measure_unit === "Medida") {
      incompleteVars.push("Unidad de medida");
    }

    if (workforce === "") {
      incompleteVars.push("Fuerza de trabajo");
    }

    if (duration === "") {
      incompleteVars.push("Duración");
    }

    // if (unforeseen === "") {
    //   incompleteVars.push("Imprevistos");
    // }

    if (workers_helper === "") {
      incompleteVars.push("Trabajadores ayudantes");
    }

    if (workers_oficial === "") {
      incompleteVars.push("Trabajadores oficiales");
    }
    if (observation === "") {
      incompleteVars.push("Observaciones");
    }

    setIncompleteVars(incompleteVars);
    return incompleteVars.length === 0;
  }, [
    observation,
    damage_type,
    tasks,
    damage_locations,
    floor_levels,
    area,
    measure_unit,
    workforce,
    duration,
    workers_helper,
    workers_oficial,
  ]);

  const callbackErrorParams = () => setErrorValidator(true);

  const calcTotalItem = () => {
    let totalMaterialSum = 0;
    if (materials.length > 0) {
      for (let material of materials) {
        if (material.is_visible) {
          totalMaterialSum =
            totalMaterialSum + material.quantity * material.price_unit;
        }
      }
    }

    return totalMaterialSum + (workforce === "" ? 0 : workforce);
  };

  const getValues = () => {
    setIsLoader(true);

    getMetadata();
    getItems(id);

    setIsLoader(false);
  };

  const getItems = (id) => {
    const res = produce(quote_items, (draft) => draft);
    setItemsList(res);
  };

  const getMetadata = async () => {
    const res = await allMetadataByFormQuote();
    if (res !== null) setMetadata(res);
  };

  const calculateTotal = (listItems) => {
    let workForce = 0;
    let totalMaterial = 0;

    for (let item of listItems) {
      workForce = workForce + item.workforce;

      const materials = item.materials === undefined ? [] : item.materials;

      for (let material of materials) {
        const sum = material.quantity * material.price_unit;

        totalMaterial = totalMaterial + sum;
      }
    }

    return workForce + totalMaterial;
  };

  const callbackSelectedType = (e) => {
    setQuoteItem(changeDamageType(quote_item, e.target.value));
  };

  const callbackDescription = (e) =>
    setQuoteItem(changeDescription(quote_item, e.target.value));

  const callbackStep = (e) => setCurrentStep(e.target.value);

  const callbackAddStep = () => {
    const object = {
      title: currentStep,
      is_done: true,
      is_visible: true,
      position: Date.now(),
    };
    const tasksCopy = produce(tasks, (draft) => {
      draft.push(object);
    });
    setQuoteItem(changeTasks(quote_item, tasksCopy));
    setCurrentStep("");
  };

  const callbackDelateStep = async (index) => {
    if (index !== -1) {
      const taskCopy = produce(tasks, (draft) => {
        draft.splice(index, 1);
      });
      setQuoteItem(changeTasks(quote_item, taskCopy));
    }
  };

  const callbackDamageLocation = (e) =>
    setQuoteItem(changeDamageLocation(quote_item, e.target.value));

  const callbackFloorLevels = (e) =>
    setQuoteItem(changeFloorLevels(quote_item, e.target.value));

  const callbackArea = (e) => {
    const value = parseFloat(e.target.value);
    setQuoteItem(changeArea(quote_item, value));
  };

  const callbackMeasureUnits = (e) =>
    setQuoteItem(changeMeasureUnit(quote_item, e.target.value));

  const callbackWorkforce = (values) => {
    const { formattedValue, value } = values;
    setQuoteItem(changeWorkforce(quote_item, parseFloat(value)));
  };

  const callbackDuration = (e) => {
    const value = parseFloat(e.target.value);
    setQuoteItem(changeDuration(quote_item, value));
  };

  const callbackUnforeseen = (e) => {
    const value = parseFloat(e.target.value);
    setQuoteItem(changeUnforeseen(quote_item, value));
  };

  const callbackWorkersHelpers = (e) => {
    const value = parseFloat(e.target.value);
    setQuoteItem(changeWorkersHelpers(quote_item, value));
  };

  const callbackWorkersOfficials = (e) => {
    const value = parseFloat(e.target.value);
    setQuoteItem(changeWorkersOfficials(quote_item, value));
  };

  const callbackObservations = (e) =>
    setQuoteItem(changeObservation(quote_item, e.target.value));

  // START MATERIAL

  const callbackMaterail = (e) => setNameMaterial(e.target.value);

  const callbackQuantity = (e) =>
    setUnitsMaterial(parseInt(e.target.value === "" ? 0 : e.target.value));

  const callbackQuantityMaterial = (e) =>
    setQuantityMaterial(parseInt(e.target.value === "" ? 0 : e.target.value));

  const callbackUnitMeasure = (e) => setUnitMeasure(e.target.value);

  const callbackUnitPrice = (values) => {
    const { formattedValue, value } = values;
    setUnitPrice(parseFloat(value));
  };

  const clearState = () => {
    setNameMaterial("");
    setUnitsMaterial(0);
    setQuantityMaterial(0);
    setUnitMeasure("Medida");
    setUnitPrice(0);
  };

  const addMaterial = () => {
    const newMaterial = {
      material: nameMaterial,
      quantity: unitsMaterial,
      quantity_material: quantityMaterial,
      measure_unit: unitMeasure,
      price_unit: unitPrice,
      is_visible: true,
    };
    const updatedMaterial = produce(materials, (draft) => {
      draft.push(newMaterial);
    });

    setQuoteItem(changeMaterials(quote_item, updatedMaterial));
    clearState();
  };

  const deleteMaterial = (object) => {
    const position = materials.findIndex((item) => item === object);
    if (position !== -1) {
      const updatedMaterial = produce(materials, (draft) => {
        draft.splice(position, 1);
      });

      setQuoteItem(changeMaterials(quote_item, updatedMaterial));
      clearState();
    }
  };

  const addQuote = (quote_item) => {
    const quoteItemsUpdated = produce(quote_items, (draft) => {
      draft.push(quote_item);
    });
    return quoteItemsUpdated;
  };
  const navigationCotizacionGoForm = () => {
    setQuoteItem(changeUpdateItem(quote_item, quote_item_initial));

    setIndexItemEdit(itemsList.length);
    history.push({
      pathname: `${PS_COTIZACION}${id}${PS_COTIZACION_FORM}`,
      state: { service: service },
    });
  };

  const navigationCreateItem = async () => {
    setIsLoader(true);
    dispatch(addQuoteItem(addQuote(quote_item)));
    setQuoteItem(changeUpdateItem(quote_item, quote_item_initial));

    // const resorucesLink = await uploadMultipleEvidenceResources(resources);

    history.push({
      pathname: `${PS_COTIZACION}${id}${PS_COTIZACION_GENERAL}`,
      state: { service: service },
    });
    // setItemsList([...itemsList, { ...newObjectResources, unique_key: res.id }]);

    // let cleanObjectQuote = { ...initialStateQuoteItem };
    // delete cleanObjectQuote.tasks;
    // delete cleanObjectQuote.materials;
    // delete cleanObjectQuote.resources;

    // const newObject = {
    //   ...cleanObjectQuote,
    //   tasks: [],
    //   materials: [],
    //   resources: [],
    // };

    // dispatch(changeUpdateItem(newObject));
    setIsLoader(false);
  };

  const updateItemQuote = (index, item) => {
    const quoteItemsUpdated = produce(quote_items, (draft) => {
      draft[index] = item;
    });
    dispatch(addQuoteItem(quoteItemsUpdated));
  };

  const navigatorUpdateItem = async () => {
    setIsLoader(true);
    updateItemQuote(indexItemEdit, quote_item);

    // const resorucesLink = await uploadMultipleEvidenceResources(resources);

    // const { unique_key } = form_quotes_item;
    // let object = {
    //   ...form_quotes_item,
    //   resources: resorucesLink,
    // };

    // delete object.unique_key;

    //await updateItem(id, unique_key, object);

    // let aux = [...itemsList];

    // aux[indexItemEdit] = form_quotes_item;
    // setItemsList(aux);

    // let cleanObjectQuote = { ...initialStateQuoteItem };
    // delete cleanObjectQuote.tasks;
    // delete cleanObjectQuote.materials;
    // delete cleanObjectQuote.resources;

    // const newObject = {
    //   ...cleanObjectQuote,
    //   tasks: [],
    //   materials: [],
    //   resources: [],
    // };

    // dispatch(changeUpdateItem(newObject));

    setIsLoader(false);

    history.push({
      pathname: `${PS_COTIZACION}${id}${PS_COTIZACION_GENERAL}`,
      state: { service: service },
    });
  };

  const navigattionEditItem = (index) => {
    setIndexItemEdit(index);
    if (index !== -1) {
      const item = quote_items[index];
      setQuoteItem(changeUpdateItem(quote_item, item));
    }

    history.push({
      pathname: `${PS_COTIZACION}${id}${PS_COTIZACION_EDIT}`,
      state: { service: service },
    });
  };

  const callbackSuccess = () => {
    setSendCotizacion(false);
    history.push(PS_MENU_PATH);
  };

  const deleteItem = (item, index) => {
    setDeleteItemIndex(index);
    setDeleteItemInfo(item);
    setDeleteItemPopup(true);
  };

  const callbackDeleteItem = async () => {
    setIsLoader(true);
    const updatedQuoteItems = produce(quote_items, (draft) => {
      draft.splice(deleteItemIndex, 1);
    });
    dispatch(addQuoteItem(updatedQuoteItems));

    setIsLoader(false);
    setDeleteItemPopup(false);
  };

  const callbackDontDeleteItem = () => {
    setDeleteItemPopup(false);
  };

  const navigatorSendCotizacion = async () => {
    setIsLoader(true);

    if (itemsList.length > 0) {
      for (let item of quote_items) {
        const resorucesLink = await uploadMultipleEvidenceResources(
          item.resources
        );
        const newObjectResources = {
          ...item,
          resources: resorucesLink,
          workers_helper: item.workers_helper === "" ? 0 : item.workers_helper,
          workers_oficial:
            item.workers_oficial === "" ? 0 : item.workers_oficial,
          workforce: item.workforce === "" ? 0 : item.workforce,
          position: Date.now(),
        };
        if (item.unique_key) {
          await updateItem(id, item.unique_key, newObjectResources);
        } else {
          await createItemInQuote(id, newObjectResources);
        }
        await updateStageByServiceId(id, 8);
        setSendCotizacion(true);
      }
    } else {
      setRejectSendCotizacion(true);
    }
    setIsLoader(false);
  };

  const callbackReturn = () => {
    setRejectSendCotizacion(false);
  };

  // MEDIA

  const callbackUpdateResourceList = (list) =>
    setQuoteItem(changeResources(quote_item, list));

  const callbackRemoveMediaSource = () => {
    if (indexResource !== -1) {
      const updatedResources = produce(resources, (draft) => {
        draft.splice(indexResource, 1);
      });
      callbackUpdateResourceList(updatedResources);
      setRemoveMedia(false);
    }

    setIndexResorunce(-1);
  };

  const callbackKeepMediaSource = () => {
    setRemoveMedia(false);
  };

  const callbackCloseMediaSource = () => {
    setShowMediaSource(false);
  };

  const buildSRCMedia = (type, url) => {
    return {
      type,
      url,
    };
  };

  const callbackAddFile = (e) => {
    if (e) {
      const { type } = e;
      const typeFile = type.split("/")[0];

      const updatedResources = produce(resources, (draft) => {
        draft.push(buildSRCMedia(typeFile, URL.createObjectURL(e)));
      });

      callbackUpdateResourceList(updatedResources);
    }
  };

  const callbackAddFileError = (e) => {
    console.log("Error al cargar el archivo");
  };

  const callbackViewSource = (mediaFile) => {
    setMediaSourceFile(mediaFile);
    setShowMediaSource(true);
  };

  const deleteMediaSource = (index) => {
    setIndexResorunce(index);
    setRemoveMedia(true);
  };

  switch (path) {
    case FORM_QUOTE:
    case EDIT_QUOTE:
      return (
        <>
          <ServiceProviderCotizacion
            price={
              <CurrencyFormat
                value={calcTotalItem()}
                thousandSeparator={true}
                displayType={"text"}
              />
            }
            serviceId={id}
            metadata={metadata}
            itemNumber={indexItemEdit + 1}
            //damage
            callbackSelectedType={callbackSelectedType}
            selectedType={damage_type}
            callbackDescription={callbackDescription}
            descriptionAndOrigin={description}
            listSolutionSteps={tasks}
            callbackStep={callbackStep}
            currentStep={currentStep}
            callbackAddStep={callbackAddStep}
            callbackDelateStep={callbackDelateStep}
            callbackDamageLocation={callbackDamageLocation}
            damageLocation={damage_locations}
            callbackFloorLevels={callbackFloorLevels}
            floorLevel={floor_levels}
            callbackMeasureUnits={callbackMeasureUnits}
            measureUnit={measure_unit}
            callbackArea={callbackArea}
            defaultValueArea={area}
            callbackWorkforce={callbackWorkforce}
            defaultValueWorkforce={workforce}
            callbackDuration={callbackDuration}
            defaultValueDuration={duration}
            callbackUnforeseen={callbackUnforeseen}
            defaultValueUnforeseen={unforeseen}
            callbackWorkersHelpers={callbackWorkersHelpers}
            defaultValueWorkersHelpers={workers_helper}
            callbackWorkersOfficials={callbackWorkersOfficials}
            defaultValueWorkersOfficials={workers_oficial}
            callbackObservations={callbackObservations}
            defaultValueObservations={observation}
            //table
            addMaterial={addMaterial}
            numMateriales={materials}
            deleteMaterial={deleteMaterial}
            callbackMaterail={callbackMaterail}
            defaultValueNameMaterial={nameMaterial}
            callbackQuantity={callbackQuantity}
            defaultValueQuantity={unitsMaterial}
            callbackQuantityMaterial={callbackQuantityMaterial}
            defaultValueQuantityMaterial={quantityMaterial}
            callbackUnitMeasure={callbackUnitMeasure}
            defaultValueUnitMeasure={unitMeasure}
            callbackUnitPrice={callbackUnitPrice}
            defaultValueUnitPrice={unitPrice}
            //general
            navigatorCotizacionItem={
              path === FORM_QUOTE ? navigationCreateItem : navigatorUpdateItem
            }
            //media
            callbackAddFile={callbackAddFile}
            callbackAddFileError={callbackAddFileError}
            listResources={resources}
            callbackViewSource={callbackViewSource}
            deleteMediaSource={deleteMediaSource}
            isEdit={path === EDIT_QUOTE}
            isAvailableCallback={callbackValidationParams}
            callbackError={callbackErrorParams}
          />
          <LoadingPopup state={isLoader} />
          {/* Error validator */}
          {errorValidator ? (
            <StateButtonModalHook
              component={PopupRejectEvent}
              hook={[errorValidator, setErrorValidator]}
              object={{
                callback: callbackDismisErrorValidator,
                message: `${
                  incompleteVars.length > 5
                    ? "Ups, te faltan muchos campos por completar!"
                    : "Ups, aun te faltan por completar estos campos: " +
                      incompleteVars.join(", ")
                }`,
                btnText: "Ok ",
                icon: X_ICON_SVG,
              }}
            />
          ) : null}
          {/* remove media source */}
          {removeMedia ? (
            <StateButtonModalHook
              component={PopupTwoButtons}
              hook={[removeMedia, setRemoveMedia]}
              object={{
                callbackBack: callbackKeepMediaSource,
                callbackRemove: callbackRemoveMediaSource,
                popupText: "¿Desea eliminar el archivo de forma permanente?",
              }}
            />
          ) : null}
          {/* show media source */}
          {showMediaSource ? (
            <StateButtonModalHook
              component={ShowMediaSource}
              hook={[showMediaSource, setShowMediaSource]}
              object={{
                callbackCloseMediaSource: callbackCloseMediaSource,
                mediaSourceFile: mediaSourceFile,
              }}
            />
          ) : null}
        </>
      );

    default:
      return (
        <>
          <ServiceProviderCotizacionItems
            editItem={navigattionEditItem}
            totalPrice={calculateTotal(itemsList)}
            itemsList={itemsList}
            deleteItem={deleteItem}
            navigatorCotizacion={navigationCotizacionGoForm}
            navigatorSendCotizacion={navigatorSendCotizacion}
            serviceId={id}
          />
          {sendCotizacion ? (
            <StateButtonModalHook
              component={PopupSuccessEvent}
              hook={[sendCotizacion, setSendCotizacion]}
              object={{
                message: "¡Cotización enviada exitosamente!",
                callback: callbackSuccess,
                btnText: "Ok ",
              }}
            />
          ) : null}
          {rejectSendCotizacion ? (
            <StateButtonModalHook
              component={PopupRejectEvent}
              hook={[rejectSendCotizacion, setRejectSendCotizacion]}
              object={{
                message:
                  "Debes crear por lo menos un item para enviar la cotizacón",
                callback: callbackReturn,
                btnText: "Ok ",
                icon: FROWN_ICON_SVG,
              }}
            />
          ) : null}
          {deleteItemPopup ? (
            <StateButtonModalHook
              component={PopupTwoButtons}
              hook={[deleteItemPopup, setDeleteItemPopup]}
              object={{
                popupText: "¿Deseas eliminar este item?",
                callbackRemove: callbackDeleteItem,
                callbackBack: callbackDontDeleteItem,
              }}
            />
          ) : null}
          <LoadingPopup state={isLoader} />
        </>
      );
  }
};

const ServiceProviderCotizacionController = ({
  history,
  location,
  state,
  dispatch,
}) => {
  let path = useParams().state;

  if (location?.state)
    return BuildView(path, history, location?.state?.service, state, dispatch);
  else {
    history.push(PRINCIPAL_PATH);
    return null;
  }
};

export default withRouter(ServiceProviderCotizacionController);
