import React, { useEffect, useRef } from "react";
import TRASH_ICON_SVG from "assets/icons/svg/trash.svg";
import WaveSurfer from "wavesurfer.js";

const AudioPlayerComponent = ({ recorded, deleteAudio }) => {
  const waveSurferRef = useRef(null);

  useEffect(() => {
    if (recorded && recorded.url) {
      if (!waveSurferRef.current) {
        waveSurferRef.current = WaveSurfer.create({
          container: "#waveform",
          waveColor: "black",
          progressColor: "#FD941B",
          cursorWidth: 1,
          height: 0,
        });
      }
      waveSurferRef.current.load(recorded.url);
    }

    return () => {
      if (waveSurferRef.current) {
        waveSurferRef.current = null;
      }
    };
  }, [recorded]);

  return (
    <div className="audio-recorder">
      <div id="waveform"></div>
      <audio src={recorded.url} controls style={{ width: "100%" }}>
        <source type="audio/mp3" />
      </audio>
      <div className="delete-entry-container" onClick={deleteAudio}>
        <img alt="Delete" src={TRASH_ICON_SVG} className="delete-entry-icon" />
      </div>
    </div>
  );
};

export default AudioPlayerComponent;
