import { getDiference, newDate } from "../res/date";

const validateActivity = (paidStatus, dateEntry, entrySteps) => {
  if (paidStatus?.isPaid) return false;
  const hasPaymentStep = entrySteps?.some((step) =>
    step.type ? step.type === "payment" : false
  );
  if (hasPaymentStep) return false;

  const nowDate = newDate();
  const diferenceToday = getDiference(nowDate, paidStatus?.emisionDate);
  const isMoreThan5days = diferenceToday > 5;
  return isMoreThan5days;
};

export { validateActivity };
