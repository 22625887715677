import PropTypes from "prop-types";
import React, { useState } from "react";
import { arePropsEqual } from "res/utils";
import "./filter.css";
const TextFilterComponent = ({ onFilter }) => {
  const [text, setText] = useState("");
  const [status, setStatus] = useState("");

  const handleInputChange = (e) => {
    const newText = e.target.value;
    setText(newText);
    onFilter(newText, status);
  };

  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    setStatus(newStatus);
    onFilter(text, newStatus);
  };

  return (
    <div className="container-filter">
      <input
        type="text"
        className="input-filter"
        value={text}
        onChange={handleInputChange}
        placeholder="Escribe para filtrar"
      />
      <select
        id="options"
        className="status-filter"
        value={status}
        onChange={handleStatusChange}
      >
        <option className="option-filter" value="">
          Todos
        </option>

        <option className="option-filter" value="0">
          Solicitado
        </option>
        <option className="option-filter" value="1">
          Por Aprobar
        </option>
        <option className="option-filter" value="2">
          Aprobado
        </option>
        <option className="option-filter" value="2">
          Rechazado
        </option>
        <option className="option-filter" value="4">
          Reajuste
        </option>
        <option className="option-filter" value="5">
          En Obra
        </option>
        <option className="option-filter" value="6">
          Completado
        </option>
        <option className="option-filter" value="7">
          Garantía
        </option>
        <option className="option-filter" value="8">
          Cotizandose
        </option>
        <option className="option-filter" value="9">
          En Diseño
        </option>
        <option className="option-filter" value="10">
          En Planeacion
        </option>
        <option className="option-filter" value="11">
          En Pausa
        </option>
        <option className="option-filter" value="12">
          Por Pagar
        </option>
      </select>
    </div>
  );
};

TextFilterComponent.propTypes = {
  onFilter: PropTypes.func.isRequired,
};

export default React.memo(TextFilterComponent, arePropsEqual);
