import { convertTypePropertyByRouterToCode } from '../res/convert.codes'
import { SCHEDULE_RANGE_DEFAULT_VALUE } from '../res/schedule.range'

const SET_PROPERTY_TYPE_ACTION = 'set_property_type_action_form_property'
const SET_PROPERTY_TYPE_TYPE_ACTION = 'set_property_type_type_action_form_property'
const SET_NICKNAME_TYPE_ACTION = 'set_nickname_type_action_form_property'
const SET_QUERY_TYPE_ACTION = 'set_query_type_action_form_property'
const SET_CONTACT_PHONE_TYPE_ACTION = 'set_contact_phone_type_action_form_property'
const SET_LOCATION_SPACE_TYPE_ACTION = 'set_location_space_type_action_form_property'
const SET_LOCATION_DEPARTMENT_DEPARTMENT_CODE_TYPE_ACTION = 'set_location_department_department_code_type_action_form_property'
const SET_LOCATION_CITY_CITY_CODE_TYPE_ACTION = 'set_location_city_city_code_type_action_form_property'
const SET_LOCATION_ADDRESS_STREET_TYPE_TYPE_ACTION = 'set_location_address_steet_type_type_action_form_property'
const SET_LOCATION_ADDRESS_STREET_TYPE_ACTION = 'set_location_address_steet_type_action_form_property'
const SET_LOCATION_ADDRESS_CORNER_TYPE_ACTION = 'set_location_address_corner_type_action_form_property'
const SET_LOCATION_ADDRES_NUMBER_TYPE_ACTION = 'set_location_address_number_type_action_form_property'
const SET_METADATA_NUMBER_FLOORS_TYPE_ACTION = 'set_metadata_number_floors_type_action_form_property'
const SET_METADATA_DECK_ACCESS_TYPE_ACTION = 'set_metadata_deck_access_type_action_form_property'
const SET_METADATA_DESCRIPTION_TYPE_ACTION = 'set_metadata_description_type_action_form_property'
const SET_OPTIONAL_SHOPPING_CENTER_TYPE_ACTION = 'set_optional_shopping_center_type_action_form_property'
const SET_OPTIONAL_OFFICE_HOURS_START_TYPE_ACTION = 'set_optional_office_hours_start_type_action_form_property'
const SET_OPTIONAL_OFFICE_HOURS_FINAL_TYPE_ACTION = 'set_optional_office_hours_final_type_action_form_property'
const SET_PROPERTY = 'set_property_action_property'
const SET_PROPERTY_MANAGEMENT = 'set_property_management'

const initialStateFormProperty = {
    property_type: '',
    nickname: '',
    management: '',
    id: '',
    query: '',
    contact: {
        phone: ''
    },
    location: {
        space: '',
        country: {
            country_code: '57'
        },
        department: {
            department_code: 'Departamento'
        },
        city: {
            city_code: '001'
        },
        address: {
            street_type: 'Calle',
            street: '',
            corner: '',
            number: ''
        }
    },
    metadata: {
        number_floors: '',
        deck_access: false,
        description: ''
    },
    optional: {
        shopping_center: '',
        office_hours: {
            start: SCHEDULE_RANGE_DEFAULT_VALUE,
            final: SCHEDULE_RANGE_DEFAULT_VALUE
        }
    }
}

const reducerFormProperty = (state=initialStateFormProperty, action) => {

    const { type, payload } = action

    switch (type) {
        case SET_PROPERTY:
            return{       
                ...payload
            }
        case SET_PROPERTY_TYPE_TYPE_ACTION:
            return {
                ...state,
                property_type: payload
            }
        case SET_NICKNAME_TYPE_ACTION:
            return {
                ...state,
                nickname: payload
            }
        case SET_QUERY_TYPE_ACTION:
            return {
                ...state,
                query: payload
            }
        case SET_CONTACT_PHONE_TYPE_ACTION:
            return {
                ...state,
                contact: {
                    ...state.contact,
                    phone: payload
                }
            }
        case SET_LOCATION_SPACE_TYPE_ACTION:
            return {
                ...state,
                location: {
                    ...state.location,
                    space: payload
                }
            }
        case SET_LOCATION_DEPARTMENT_DEPARTMENT_CODE_TYPE_ACTION:
            return {
                ...state,
                location: {
                    ...state.location,
                    department: {
                        ...state.location.department,
                        department_code: payload
                    }
                }
            }
        case SET_LOCATION_CITY_CITY_CODE_TYPE_ACTION:
            return {
                ...state,
                location: {
                    ...state.location,
                    city: {
                        ...state.location.city,
                        city_code: payload
                    }
                }
            }
        case SET_LOCATION_ADDRESS_STREET_TYPE_TYPE_ACTION:
            return {
                ...state,
                location: {
                    ...state.location,
                    address: {
                        ...state.location.address,
                        street_type: payload
                    }
                }
            }
        case SET_LOCATION_ADDRESS_STREET_TYPE_ACTION:
            return {
                ...state,
                location: {
                    ...state.location,
                    address: {
                        ...state.location.address,
                        street: payload
                    }
                }
            }
        case SET_LOCATION_ADDRESS_CORNER_TYPE_ACTION:
            return {
                ...state,
                location: {
                    ...state.location,
                    address: {
                        ...state.location.address,
                        corner: payload
                    }
                }
            }
        case SET_LOCATION_ADDRES_NUMBER_TYPE_ACTION:
            return {
                ...state,
                location: {
                    ...state.location,
                    address: {
                        ...state.location.address,
                        number: payload
                    }
                }
            }
        case SET_METADATA_NUMBER_FLOORS_TYPE_ACTION:
            return {
                ...state,
                metadata: {
                    ...state.metadata,
                    number_floors: payload
                }
            }
        case SET_METADATA_DECK_ACCESS_TYPE_ACTION:
            return {
                ...state,
                metadata: {
                    ...state.metadata,
                    deck_access: payload
                }
            }
        case SET_METADATA_DESCRIPTION_TYPE_ACTION:
            return {
                ...state,
                metadata: {
                    ...state.metadata,
                    description: payload
                }
            }

        case SET_OPTIONAL_SHOPPING_CENTER_TYPE_ACTION:
            return {
                ...state,
                optional: {
                    ...state.optional,
                    shopping_center: payload
                }
            }
        case SET_OPTIONAL_OFFICE_HOURS_START_TYPE_ACTION:
            return {
                ...state,
                optional: {
                    ...state.optional,
                    office_hours: {
                        ...state.optional.office_hours,
                        start: payload
                    }
                }
            }
        case SET_OPTIONAL_OFFICE_HOURS_FINAL_TYPE_ACTION:
            return {
                ...state,
                optional: {
                    ...state.optional,
                    office_hours: {
                        ...state.optional.office_hours,
                        final: payload
                    }
                }
            }
        case SET_PROPERTY_TYPE_ACTION:
            return payload
        case SET_PROPERTY_MANAGEMENT:
            return {
                ...state,
                management: payload,
            }
        default:
            return state
    }
}

const updateProperty = (value) => {
    return { type: SET_PROPERTY, payload: value }
}

const changePropertyType = (value) => {
    return { type: SET_PROPERTY_TYPE_TYPE_ACTION, payload: convertTypePropertyByRouterToCode(value) }
}

const changeNiackname = (value) => {
    return { type: SET_NICKNAME_TYPE_ACTION, payload: value }
}

const changeQuery = (value) => {
    return { type: SET_QUERY_TYPE_ACTION, payload: value }
}

const changePhone = (value) => {
    return { type: SET_CONTACT_PHONE_TYPE_ACTION, payload: value }
}

const changeSpace = (value) => {
    return { type: SET_LOCATION_SPACE_TYPE_ACTION, payload: value }
}

const changeDepartmentCode = (value) => {
    return { type: SET_LOCATION_DEPARTMENT_DEPARTMENT_CODE_TYPE_ACTION, payload: value }
}

const changeCityCode = (value) => {
    return { type: SET_LOCATION_CITY_CITY_CODE_TYPE_ACTION, payload: value }
}

const changeAddressStreetType = (value) => {
    return { type: SET_LOCATION_ADDRESS_STREET_TYPE_TYPE_ACTION, payload: value }
}

const changeAddressStreet = (value) => {
    return { type: SET_LOCATION_ADDRESS_STREET_TYPE_ACTION, payload: value }
}

const changeAddressCorner = (value) => {
    return { type: SET_LOCATION_ADDRESS_CORNER_TYPE_ACTION, payload: value }
}

const changeAddressNumber = (value) => {
    return { type: SET_LOCATION_ADDRES_NUMBER_TYPE_ACTION, payload: value }
}

const changeNumberFloors = (value) => {
    return { type: SET_METADATA_NUMBER_FLOORS_TYPE_ACTION, payload: value }
}

const changeDeckAccess = (value) => {
    return { type: SET_METADATA_DECK_ACCESS_TYPE_ACTION, payload: value }
}

const changeDescription = (value) => {
    return { type: SET_METADATA_DESCRIPTION_TYPE_ACTION, payload: value }
}

const changeShoppingCenter = (value) => {
    return { type: SET_OPTIONAL_SHOPPING_CENTER_TYPE_ACTION, payload: value }
}

const changeOfficHoursStart = (value) => {
    return { type: SET_OPTIONAL_OFFICE_HOURS_START_TYPE_ACTION, payload: value }
}

const changeOfficeHourFinal = (value) => {
    return { type: SET_OPTIONAL_OFFICE_HOURS_FINAL_TYPE_ACTION, payload: value }
}

const changeProperty = (value) => {
    return { type: SET_PROPERTY_TYPE_ACTION, payload: value }
}

const changeManagement = (value) => {
    return {type: SET_PROPERTY_MANAGEMENT, payload: value}
}

export {
    initialStateFormProperty,
    reducerFormProperty,
    changePropertyType,
    changeNiackname,
    changeQuery,
    changePhone,
    changeSpace,
    changeDepartmentCode,
    changeCityCode,
    changeAddressStreetType,
    changeAddressStreet,
    changeAddressCorner,
    changeAddressNumber,
    changeNumberFloors,
    changeDeckAccess,
    changeDescription,
    changeShoppingCenter,
    changeOfficHoursStart,
    changeOfficeHourFinal,
    changeProperty,
    updateProperty,
    changeManagement
}