import React from "react";

import BACK_ICON_SVG from "../../assets/icons/svg/arrow_back.svg";
import POINTING_ICON_SVG from "../../assets/icons/svg/finger-point.svg";
import TOOL_ICON_SVG from "../../assets/icons/svg/tool.svg";
import BOOK_ICON_SVG from "../../assets/icons/svg/book-open.svg";
import CHAT_ICON_SVG from "../../assets/icons/svg/chat.svg";
import IMAGE_ICON_SVG from "../../assets/icons/svg/image.svg";

import { BaseUnexpectedDropdown } from "../../components/atoms/dropdown/Dropdown";
import { CheckboxInput } from "../../components/atoms/input/Input";
import { BaseTextarea } from "../../components/atoms/textarea/Textarea";
import MediaSourceContainer from "../../components/atoms/mediaSource/MediaSourceContainer";
import { NewIconButton } from "../../components/atoms/button/Button";
import CurrencyFormat from "react-currency-format";
import { AddSquareButton } from "../../components/atoms/button/Button";
import PopoverInfo from "../../popups/InformationPopup";

import { convertPhaseNameToSpanishName } from "../../res/convert.codes";
import GoBackBtn from "components/GoBackBtn/GoBackBtn";

const NewUnexpected = ({
  idService,
  phaseName,
  colorPhase,
  phaseSelected,
  callbackNewEntry,
  callbackItemsDropdow,
  callbackDescripcionTextarea,
  callbackHiddenEntry,
  handleKeyDown,
  phases,
  shouldReset,
  updateBitacora,
  updateAviable,
  file,
  isOverCost,
  callbackIsOverCost,
  listResources,
  unexpected,
  handleUnexpectedValue,
}) => {
  return (
    <div className="new-service-container">
      <div className="desktop-container">
        <div className="new-entry-container">
          <div className="new-entry-appbar-container">
            <GoBackBtn type="default" />
            <div style={{ marginTop: "12px" }} className="ne-appbar-small-text">
              Caso {idService}
            </div>
            <div className="ne-appbar-big-text">
              Nuevo Imprevisto en{" "}
              <span className="color-name">{phaseName}</span>{" "}
            </div>
          </div>

          <div className="new-entry-content">
            <div className="categoria-container">
              <div className="new-service-text">
                <div>
                  <img
                    src={POINTING_ICON_SVG}
                    id="new-service-icon"
                    alt={"POINTING_ICON_SVG"}
                  />
                </div>
                <div> Cambia la Fase del Imprevisto </div>
              </div>
              <div className="new-entry-dropdown-container">
                <BaseUnexpectedDropdown
                  callback={callbackItemsDropdow}
                  items={phases
                    .slice()
                    .sort((a, b) => b?.position - a?.position)
                    .reverse()
                    .map((phase, index) => ({
                      title: convertPhaseNameToSpanishName(phase.title),
                      index: index,
                      code: phase.position,
                      id: phase.unique_key,
                    }))}
                  defaultSelected={phaseSelected}
                  style="new-entry-dropdown"
                />
              </div>
            </div>
            {
              <div className="categoria-container">
                <div className="new-service-text">
                  <div>
                    <img
                      src={TOOL_ICON_SVG}
                      id="new-service-icon"
                      alt={"POINTING_ICON_SVG"}
                    />
                  </div>
                  <div> Rellena La Información del Imprevisto </div>
                </div>

                <div className="select-work-container unexpected-form">
                  <div className="unexpected-info">
                    <div className="input-section-name">
                      <div className="name-form-container">
                        <label className="label-input">Nombre</label>
                        <PopoverInfo
                          content={
                            <div className="popover-info-container">
                              <h4>
                                El nombre debe de ser la solución del imprevisto
                              </h4>
                              <span className="popover-span"> Ejemplo: </span>
                              <span className="popover-span-example">
                                {" "}
                                - Pintar pared de la habitación de color rojo{" "}
                              </span>
                            </div>
                          }
                        />
                      </div>
                      <input
                        type="text"
                        value={unexpected.name}
                        onChange={(event) =>
                          handleUnexpectedValue(event, "name")
                        }
                        placeholder="Nombre del imprevisto"
                      />
                    </div>
                    <div className="inputs-unexpected">
                      <div className="inputs-unexpected-day">
                        <label className="label-input">
                          Días a Adicionar al Calendario
                        </label>
                        <input
                          type="number"
                          onKeyDown={handleKeyDown}
                          value={unexpected.days}
                          onChange={(event) =>
                            handleUnexpectedValue(event, "days")
                          }
                          placeholder="Dias"
                        />
                      </div>
                    </div>
                    <div className="over-cost-check">
                      <CheckboxInput
                        index={1}
                        callbackSelectTask={callbackIsOverCost}
                        title={"¿Genera un Sobre Costo?"}
                        canBeEdit={true}
                        isAvailable={true}
                        itemSelect={false}
                      />
                    </div>
                    {isOverCost && (
                      <div className="inputs-unexpected">
                        <label className="label-input">Costo M. de Obra</label>
                        <CurrencyFormat
                          prefix="$"
                          thousandSeparator={true}
                          value={unexpected.labor_cost}
                          onValueChange={(values) =>
                            handleUnexpectedValue(values, "labor_cost")
                          }
                          placeholder="Costo M. de Obra"
                          hintText={unexpected.labor_cost}
                        />
                      </div>
                    )}
                    {isOverCost && (
                      <div className="inputs-unexpected">
                        <label className="label-input">Material Costo</label>

                        <CurrencyFormat
                          prefix="$"
                          value={unexpected.material_cost}
                          thousandSeparator={true}
                          onValueChange={(values) => {
                            handleUnexpectedValue(values, "material_cost");
                          }}
                          hintText={unexpected.material_cost}
                          placeholder="Material Costo"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            }

            <div className="descripcion-container">
              <div className="new-service-text">
                <div>
                  <img
                    src={CHAT_ICON_SVG}
                    id="new-service-icon"
                    alt={"CHAT_ICON_SVG"}
                  />
                </div>
                <div>Describe que ocasionó el Imprevisto</div>
              </div>

              <div className="descripcion-input">
                <BaseTextarea
                  text={() => {}}
                  callback={callbackDescripcionTextarea}
                  placeholder="Describe que ocasionó el imprevisto"
                />
              </div>
            </div>
            <div className="select-work-container finished-item">
              <CheckboxInput
                index={1}
                callbackSelectTask={callbackHiddenEntry}
                title="Ocultar entrada."
                isAvailable={true}
                isSelected={false}
                itemSelect={false}
                canBeEdit={true}
                shouldReset={shouldReset}
              />
            </div>

            <div className="dano-foto-container">
              <div className="new-service-text">
                <div>
                  <img
                    src={IMAGE_ICON_SVG}
                    id="new-service-icon"
                    alt={"POINTING_ICON_SVG"}
                  />
                </div>
                <div>Añade fotos, documentos o videos</div>
              </div>

              <div className="dano-foto">
                <div
                  className="add-foto-container"
                  onClick={file.openFileSelector}
                >
                  <AddSquareButton />
                </div>
                <MediaSourceContainer
                  sources={listResources}
                  callbackViewSource={file.callbackViewSource}
                  deleteMediaSource={file.deleteMediaSource}
                />
              </div>
            </div>

            <div className="register-botton-menu">
              <NewIconButton
                txt="Actualizar bitácora"
                style={`new-entry-button ${
                  !updateAviable ? "update-disable" : ""
                }`}
                icon={BOOK_ICON_SVG}
                callback={updateBitacora}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewUnexpected;
