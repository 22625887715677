import { useState } from "react";
import {
  uploadMultipleExcels,
  uploadMultipleEvidenceResources,
  uploadMultiplePDFs,
} from "../services-controller/storage";
const useResourceUploader = () => {
  const [resourcesLink, setResourcesLink] = useState([]);

  const uploadResourcesByType = async (
    resourcesFiles,
    type,
    uploadFunction,
    id
  ) => {
    const filteredResources = resourcesFiles.filter(
      (resource) => resource.type === type
    );
    let uploadedResources = [];
    if (filteredResources.length > 0) {
      uploadedResources = await uploadFunction(filteredResources, id);
    }

    return uploadedResources;
  };

  const processResources = async (resourcesFiles, id) => {
    try {
      const pdfListResources = await uploadResourcesByType(
        resourcesFiles,
        "pdf",
        uploadMultiplePDFs,
        id
      );
      const imageListResources = await uploadResourcesByType(
        resourcesFiles,
        "image",
        uploadMultipleEvidenceResources
      );
      const excelListResources = await uploadResourcesByType(
        resourcesFiles,
        "excel",
        uploadMultipleExcels
      );

      const combinedResourcesLink = [
        ...excelListResources,
        ...pdfListResources,
        ...imageListResources,
      ];

      setResourcesLink(combinedResourcesLink);
      return combinedResourcesLink;
    } catch (error) {
      console.error("Ocurrió un error:", error);
    }
  };

  return [resourcesLink, processResources];
};

export default useResourceUploader;
