

const initialState = { 
}

const globalStateReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_GLOBAL_STATE':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export default globalStateReducer

const setGlobalState = (payload) => {
    return {
        type: 'SET_GLOBAL_STATE',
        payload
    }
} 

export {
    setGlobalState
}

