const UNIQUE_KEY = "unique_key";

const convertSnapshotToList = (snapshot) => {
  const aux = [];

  for (let id in snapshot) {
    let object = snapshot[id];
    object[UNIQUE_KEY] = id;
    const date = Date.parse(object.date_sort);
    const date_sort = new Date(date);
    const copy = { ...object, date_sort: date_sort };
    aux.push(copy);
  }

  const order = aux.slice().sort((a, b) => b.date_sort - a.date_sort);

  return order;
};

const convertSnapshotToListV2 = (snapshot) => {
  const aux = [];

  for (let id in snapshot) {
    let object = snapshot[id];
    object[UNIQUE_KEY] = id;
    aux.push(object);
  }

  return aux;
};

export { convertSnapshotToList, convertSnapshotToListV2 };
