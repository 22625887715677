import React, { useEffect, useState } from 'react';
import { Modal, Button, message, notification } from 'antd';
import { checkConnectionQuality, getPendingRequests, resendPendingRequests } from '../../../utils/offline.requests';
import { CloudUploadOutlined } from '@ant-design/icons';

const ResendAdvancesModal = () => {

  const [show, setShow] = useState(false);
  const [pendingRequests, setPendingRequests] = useState([]);
  const handleClose = () => {
    setShow(false)
    setLoading(false);
    message.destroy();
  };
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);



  const fetchPendingRequests = async () => {
    const pendingRequests = await getPendingRequests();
    setPendingRequests(pendingRequests);
  }
  const resendAdvances = async () => {
    if (pendingRequests.length === 0) {
      notification.warning({
        message: 'No hay avances pendientes',
      });
      return;
    }
    setLoading(true);

    try {
      const networkStatus = await checkConnectionQuality();
      if (networkStatus === 'moderate') {
        notification.warning({
          message: 'Si tarda mucho, dale cancelar',
        });
      }
      if (networkStatus == 'offline' || networkStatus == 'bad') {
        notification.error({
          message: 'No se puede reenviar en este momento',
          description: 'Verifica tu conexión a internet',
        });
        setLoading(false);
        return;
      }
      await resendPendingRequests();
      setLoading(false);
      fetchPendingRequests();
      message.destroy();

    } catch (error) {
      console.error('Error al reenviar solicitudes pendientes:', error);
      notification.error({
        message: 'Error al reenviar solicitudes pendientes',
        description: error.message,
      });
    }
  }
  useEffect(() => {
    fetchPendingRequests();

  }, []);



  return (
    <div>
      <Button onClick={handleShow}> <CloudUploadOutlined style={{ color: '#fd941b' }} /></Button>
      <Modal

        title={<span>Avances pendientes</span>}
        open={show}
        onOk={resendAdvances}
        onCancel={handleClose}
        confirmLoading={loading}


        okText="Reenviar"
      >

        <p>{pendingRequests.length} avances por enviar</p>
        <ul>
          {pendingRequests.map((request, index) => (
            <li key={index}> <span style={{ color: "#1677ff" }}>{request.requestData.data.context_name}</span></li>
          ))}
        </ul>


      </Modal>
    </div>

  );
};

export default ResendAdvancesModal;