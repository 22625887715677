import { useState } from "react";
import { withRouter } from "react-router-dom";
import BELL_ICON_SVG from "../../../assets/icons/svg/bell.svg";
import TOOL_ICON_SVG from "../../../assets/icons/svg/tool.svg";
import USER_ICON_SVG from "../../../assets/icons/svg/user.svg";
import {
  PS_BASE_MENU_PATH,
  PS_NOTIFICATIONS,
  PS_PROFILE,
  PS_SERVICES,
} from "../../../router/routes";
import {
  NavbarButton,
  SeguimientoOptionsButton,
} from "../../atoms/button/Button";
import "./buttonNavbar.css";

const PsButtonNavbar = ({
  history,
  page,
  callback,
  callbackWhatsappButton,
  moreValues,
  showSeguimientoOptionsButton,
}) => {
  const [selected, setSelect] = useState(page);

  const handleSelected = (e) => {
    setSelect(e.target.id);
    history.push(`${PS_BASE_MENU_PATH}${e.target.id}`);
  };

  return (
    <>
      <div className="moreContainer">
        {
          /*
                page === HOME ? <NextRoundedButton show={showBtn} callback={callback}/> :
                    <MoreRoundedButton moreValues={popupContent} callback={() => { }} />
                */
          showSeguimientoOptionsButton ? (
            <SeguimientoOptionsButton
              callback={callbackWhatsappButton}
              moreValues={moreValues}
            />
          ) : null
        }
      </div>

      {/* <div className="ps-button-navbar-container">
        <div className="buttonContainer">
          <NavbarButton
            icon={TOOL_ICON_SVG}
            txt="Servicios"
            id={PS_SERVICES}
            selected={selected}
            callback={handleSelected}
          />
        </div>

        <div className="buttonContainer">
          <NavbarButton
            icon={BELL_ICON_SVG}
            txt="Notificaciones"
            id={PS_NOTIFICATIONS}
            selected={selected}
            callback={handleSelected}
          />
        </div>

        <div className="buttonContainer">
          <NavbarButton
            icon={USER_ICON_SVG}
            txt="Perfil"
            id={PS_PROFILE}
            selected={selected}
            callback={handleSelected}
          />
        </div>
      </div> */}
    </>
  );
};

export default withRouter(PsButtonNavbar);
