export const styles = [
  {
    id: "directions-route-line-alt",
    type: "line",
    source: "directions",
    layout: {
      "line-cap": "butt",
      "line-join": "round",
    },
    paint: {
      "line-color": "#652d52",
      "line-width": 4,
    },
    filter: [
      "all",
      ["in", "$type", "LineString"],
      ["in", "route", "alternate"],
    ],
  },
  {
    id: "directions-route-line-casing",
    type: "line",
    source: "directions",
    layout: {
      "line-cap": "butt",
      "line-join": "round",
    },
    paint: {
      "line-width": 0,
    },
    filter: ["all", ["in", "$type", "LineString"], ["in", "route", "selected"]],
  },
  {
    id: "directions-route-line",
    type: "line",
    source: "directions",
    layout: {
      "line-cap": "butt",
      "line-join": "round",
    },
    paint: {
      "line-color": "#fd941b",
      "line-width": 4,
    },
    filter: ["all", ["in", "$type", "LineString"], ["in", "route", "selected"]],
  },
  {
    id: "directions-origin-point",
    type: "circle",
    source: "directions",
    paint: {
      "circle-radius": 0,
      "circle-color": "#fff",
    },
  },
  {
    id: "directions-destination-point",
    type: "circle",
    source: "directions",
    paint: {
      "circle-radius": 0,
      "circle-color": "#fff",
    },
  },
];
