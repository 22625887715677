import { Drawer } from "antd";
import useReduxState from "custom-hooks/useReduxState";
import { Fragment, useEffect, useRef } from "react";
import { setActivitiesSectionActive } from "reducers/bitacle.reducer";
import { customTimeout, dateTimeFormater } from "res/utils";
import "./ActivitiesSection.css";

const activitiesOptions = [
  {
    title: "Primera reunion",
    autor: "Andres Perez",
    date: "2024-09-27T19:30:00Z",
  },
  {
    title: "Segunda reunion",
    autor: "Andres Perez",
    date: "2024-09-27T19:40:00Z",
  },
  {
    title: "Tercera reunion",
    autor: "Andres Perez",
    date: "2024-09-27T19:50:00Z",
  },
  {
    title: "Cuarta reunion",
    autor: "Andres Perez",
    date: "2024-09-27T20:00:00Z",
  },
  {
    title: "Segunda reunion",
    autor: "Andres Perez",
    date: "2024-09-27T19:40:00Z",
  },
  {
    title: "Tercera reunion",
    autor: "Andres Perez",
    date: "2024-09-27T19:50:00Z",
  },
  {
    title: "Cuarta reunion",
    autor: "Andres Perez",
    date: "2024-09-27T20:00:00Z",
  },
  {
    title: "Primera reunion",
    autor: "Andres Perez",
    date: "2024-09-27T19:30:00Z",
  },
  {
    title: "Segunda reunion",
    autor: "Andres Perez",
    date: "2024-09-27T19:40:00Z",
  },
  {
    title: "Tercera reunion",
    autor: "Andres Perez",
    date: "2024-09-27T19:50:00Z",
  },
  {
    title: "Cuarta reunion",
    autor: "Andres Perez",
    date: "2024-09-27T20:00:00Z",
  },
  {
    title: "Segunda reunion",
    autor: "Andres Perez",
    date: "2024-09-27T19:40:00Z",
  },
  {
    title: "Tercera reunion",
    autor: "Andres Perez",
    date: "2024-09-27T19:50:00Z",
  },
  {
    title: "Cuarta reunion",
    autor: "Andres Perez",
    date: "2024-09-27T20:00:00Z",
  }
]

export default function ActivitiesSection() {
  const [{ isActivitiesSectionActive }, dispatch] = useReduxState({ setActivitiesSectionActive, }, "bitacle")
  const activityCardRef = useRef(null)

  useEffect(() => {
    if (!isActivitiesSectionActive && activityCardRef.current) {
      customTimeout({
        func: () => {
          activityCardRef.current?.scrollTo(0, activityCardRef.current?.scrollHeight)
        },
        time: 200,
      })
    }

  }, [isActivitiesSectionActive])

  return (
    <Drawer
      open={isActivitiesSectionActive}
      placement="right"
      closable={true}
      onClose={() => {
        dispatch.setActivitiesSectionActive(false)
      }}
      title="Lista de actividades"
      size='large'
      maskClosable={false}
      destroyOnClose
      classNames={{
        body: "activities-section-body",
      }}
    >
      <section className="activities-list" ref={activityCardRef}>
        {activitiesOptions.map((option, index) => {
          const { title, autor, date } = option

          return (
            <Fragment key={index}>
              {index >= 1 && (
                <div className="list-card-divider"></div>
              )}

              <article className="activity-card">
                <h1 className="list-card-title">{title}</h1>

                <span className="list-card-responsible">Iniciado por <strong>{autor}</strong></span>

                <time dateTime={date} className="list-card-date">
                  {dateTimeFormater({ date, dateStyle: "long" })}
                </time>
              </article>
            </Fragment>
          )
        })}
      </section>
    </Drawer>
  )
}
