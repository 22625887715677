import React, { useEffect, useState } from 'react'
import {useParams } from 'react-router-dom'
import { PrimaryButton } from '../../components/atoms/button/Button'
import CaseDetailsTemplate from '../../components/templates/caseDetailsTemplate/CaseDetailsTemplate'
import moment from 'moment'
import 'moment/locale/es'
import { ShowMediaSource } from '../../popups/PopupsNewServices.jsx'
import StateButtonModalHook from '../../modal/StateButtonModalHook.jsx'
import { getManagementById } from '../../services-controller/http.cleinte.controller'


const PopupServiceDetails = ({ object }) => {
    console.log('object', object);

    const {
        service,
        callbackClose
    } = object

    moment.locale('es')

    let { idService } = useParams()

    // state

    const [showMediaSource, setShowMediaSource] = useState(false)

    const [mediaSourceFile, setMediaSourceFile] = useState('')

    const [management, setManagement] = useState(null)

    // general

    const currentDate = (date) => moment(date).format('dddd, D MMMM')

    const buildAddress = () => {
        return `${street_type} ${street} # ${corner} - ${number} · ${space} ${locale} ${shopping_center}`

    }

    const {
        metadata,
        request: {
            general_description,
            resources,
            schedule: {
                date_start,
                time_start,
            }
        }
    } = service

    const {

        location: {
            space,
            address: {
                street_type,
                street,
                corner,
                number
            }
        },
        optional: {
            description,
            shopping_center,
            locale
        }
    } = metadata.property


    const {


        register_data: {
            name,
            contact: {
                phone,
            }
        }
    } = metadata.user

    const fecthManagement = async () => {

        if (metadata.property.management !== '') {
            const management = await getManagementById(metadata.property.management)
            setManagement(management)
        }

    }

    useEffect(() => {
        fecthManagement()
    }, [])

    const details = [
        {
            title: 'Número de caso',
            text: idService
        },
        {
            title: 'Fecha y hora',
            text: `${currentDate(date_start)} - ${time_start}`
        },
        {
            title: 'Cliente',
            text: [name, phone]
        }, {
            title: 'Lugar',
            text: [buildAddress(), description]
        }
    ]

    const callbackViewSource = (mediaFile) => {
        const {
            name,
            type,
            url
        } = mediaFile

        const newFile = {
            ...mediaFile,
            file: {
                name,
                type,
                url
            }
        }
        setMediaSourceFile(newFile)
        setShowMediaSource(true)
    }


    const callbackCloseMediaSource = () => {
        setShowMediaSource(false)
    }

    return (
        <>
            <div className='request-details-container details-popup'>

                <CaseDetailsTemplate
                    totalVisit={null}
                    details={details}
                    generalDescription={general_description}
                    listResources={resources}
                    callbackViewSource={callbackViewSource}
                    management={management}
                />

                <div className='rs-btn-options'>
                    <PrimaryButton
                        callback={callbackClose}
                        txt='Ok' />
                </div>

            </div>

            {/* show media source */}
            {

                showMediaSource ? <StateButtonModalHook
                    component={ShowMediaSource}
                    hook={[showMediaSource, setShowMediaSource]}
                    object={
                        {
                            callbackCloseMediaSource: callbackCloseMediaSource,
                            mediaSourceFile: mediaSourceFile
                        }
                    }
                /> : null
            }

        </>
    )
}

export default PopupServiceDetails