const SET_USER_PROPERTIES = 'set_user_properties_type_app'
const SET_NEW_PROPERTY_IN_PROPERTIES = 'set_new_property_in_properties_type_app'
const SET_PROPERTY_TYPE = 'set_property_type_app'
const SET_MANAGEMENTS = 'set_managements_type_app'
const SET_NEW_MANAGEMENT_IN_MANAGEMENTS = 'set_new_management_in_managements_type_app'
const SET_CURRENT_MANAGEMENTS = 'set_current_managements_type_app'
const SET_CURRENT_PROPERTY = 'set_current_property_type_app'
const SET_QUERY_PROPERTY = 'set_query_property_type_app'
const SET_UPDATE_APP = 'set_update_app_type_app'
const SET_SERVICE_PROVIDER_TYPE = 'set_service_provider_type_type_app'
const SET_SERVICE_PROVIDER_COVERAGE = 'set_service_provider_coverage_type_app'
const SET_SERVICE_PROVIDER_COVERAGE_SERVICES = 'set_service_provider_coverage__servicestype_app'
const SET_SERVICE_PROVIDER_COVERAGE_CANCELED = 'set_service_provider_coverage_canceled_type_app'
const SET_SERVICE_PROVIDER_COVERAGE_SUCCESSED = 'set_service_provider_coverage_successed_type_app'
const SET_SERVICE_PROVIDER_COVERAGE_IS_AVAILABLE = 'set_service_provider_coverage_is_available_type_app'
const SET_SERVICE_POOL = 'set_services_pool_type_app'

const initialStateProperties = {
    properties: [],
    property_type: '',
    managements: [],
    current_management: null,
    current_property: null,
    query_property: '',
    service_provider_type: '',
    service_provider_coverage: {
        services: [],
        services_canceled: 0,
        services_soccessed: 0,
        is_available: false
    },
    services_pool: []
}

const reducerProperties = (state = initialStateProperties, action) => {
    const { type, payload } = action

    switch (type) {
        case SET_USER_PROPERTIES:
            return {
                ...state,
                properties: payload
            }
        case SET_PROPERTY_TYPE:
            return {
                ...state,
                property_type: payload
            }
        case SET_NEW_PROPERTY_IN_PROPERTIES:
            return {
                ...state,
                properties: [...state.properties, payload]
            }
        case SET_MANAGEMENTS:
            return {
                ...state,
                managements: payload
            }
        case SET_NEW_MANAGEMENT_IN_MANAGEMENTS:
            return {
                ...state,
                managements: [...state.managements, payload]
            }
        case SET_CURRENT_MANAGEMENTS:
            return {
                ...state,
                current_management: payload
            }
        case SET_CURRENT_PROPERTY:
            return {
                ...state,
                current_property: payload
            }
        case SET_QUERY_PROPERTY:
            return {
                ...state,
                query_property: payload
            }
        case SET_SERVICE_PROVIDER_TYPE:
            return {
                ...state,
                service_provider_type: payload
            }
        case SET_SERVICE_PROVIDER_COVERAGE:
            return {
                ...state,
                service_provider_coverage: payload
            }

        case SET_SERVICE_PROVIDER_COVERAGE_SERVICES:
            return {
                ...state,
                service_provider_coverage: {
                    ...state.service_provider_coverage,
                    services: payload
                }
            }
        case SET_SERVICE_PROVIDER_COVERAGE_CANCELED:
            return {
                ...state,
                service_provider_coverage: {
                    ...state.service_provider_coverage,
                    services_canceled: payload
                }
            }
        case SET_SERVICE_PROVIDER_COVERAGE_SUCCESSED:
            return {
                ...state,
                service_provider_coverage: {
                    ...state.service_provider_coverage,
                    services_soccessed: payload
                }
            }
        case SET_SERVICE_PROVIDER_COVERAGE_IS_AVAILABLE:
            return {
                ...state,
                service_provider_coverage: {
                    ...state.service_provider_coverage,
                    is_available: payload
                }
            }
        case SET_SERVICE_POOL:
            return {
                ...state,
                services_pool: payload
            }
        case SET_UPDATE_APP:
            return payload
        default:
            return state
    }
}

const changePropertiesUser = (value) => {
    return { type: SET_USER_PROPERTIES, payload: value }
}

const changePropertyType = (value) => {
    return { type: SET_PROPERTY_TYPE, payload: value }
}

const changePropertiesAddProperty = (value) => {
    return { type: SET_NEW_PROPERTY_IN_PROPERTIES, payload: value }
}

const changeManagements = (value) => {
    return { type: SET_MANAGEMENTS, payload: value }
}

const changeManagementsAddManagement = (value) => {
    return { type: SET_NEW_MANAGEMENT_IN_MANAGEMENTS, payload: value }
}

const changeCurrentManagement = (value) => {
    return { type: SET_CURRENT_MANAGEMENTS, payload: value }
}

const changeCurrentProperty = (value) => {
    return { type: SET_CURRENT_PROPERTY, payload: value }
}

const changeQueryProperty = (value) => {
    return { type: SET_QUERY_PROPERTY, payload: value }
}

const changeServiceProviderType = (value) => {
    return { type: SET_SERVICE_PROVIDER_TYPE, payload: value }
}

const changeUpdateApp = (value) => {
    return { type: SET_UPDATE_APP, payload: value }
}

const changeServiceProviderCoverage = (value) => {
    return { type: SET_SERVICE_PROVIDER_COVERAGE, payload: value }
}

const changeServiceProviderCoverageServices = (value) => {
    return { type: SET_SERVICE_PROVIDER_COVERAGE_SERVICES, payload: value }
}

const changeServiceProviderCoverageCanceled = (value) => {
    return { type: SET_SERVICE_PROVIDER_COVERAGE_CANCELED, payload: value }
}

const changeServiceProviderCoverageSuccessed = (value) => {
    return { type: SET_SERVICE_PROVIDER_COVERAGE_SUCCESSED, payload: value }
}

const changeServiceProviderCoverageIsAvailable = (value) => {
    return { type: SET_SERVICE_PROVIDER_COVERAGE_IS_AVAILABLE, payload: value }
}

const changeServicesPool = (value) => {
    return { type: SET_SERVICE_POOL, payload: value }
}

export {
    initialStateProperties,
    reducerProperties,
    changePropertiesUser,
    changePropertiesAddProperty,
    changePropertyType,
    changeManagements,
    changeManagementsAddManagement,
    changeCurrentManagement,
    changeCurrentProperty,
    changeQueryProperty,
    changeUpdateApp,
    changeServiceProviderType,
    changeServiceProviderCoverage,
    changeServiceProviderCoverageServices,
    changeServiceProviderCoverageCanceled,
    changeServiceProviderCoverageSuccessed,
    changeServiceProviderCoverageIsAvailable,
    changeServicesPool
}

