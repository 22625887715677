import React, { useState, useEffect } from "react";
import "./psProfile.css";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StateButtonModalHook from "../../../modal/StateButtonModalHook.jsx";
import PsButtonNavbar from "../../../components/molecules/buttonNavbar/PsButtonNavbar";
import { Appbar } from "../../../components/atoms/appbar/Appbar";
import { DocumentsContainer } from "../../../components/molecules/container/Container";
import TRASH_ICON_SVG from "../../../assets/icons/svg/trash.svg";
import ALTURAS_ICON_SVG from "../../../assets/icons/svg/documento-alturas.svg";
import SEGURIDAD_ICON_SVG from "../../../assets/icons/svg/seguridads.svg";
import TARJETA_P_ICON_SVG from "../../../assets/icons/svg/tprofesional.svg";
import { PopupTwoOptionWithIcon } from "../../../popups/PopupTwoButtons.jsx";
import { PopupSuccessEvent } from "../../../popups/psPopups/PopupSuccessEvent.jsx";
import { PopupRejectEvent } from "../../../popups/psPopups/PopupRejectEvent.jsx";
import { toBase64, uploadFile } from "../../../services-controller/storage";
import { currentUser } from "../../../services-firebase/auth";
import moment from "moment";
import {
  changeDocumentsHeight,
  changeDocumentsProfessionalCard,
  changeDocumentsSocualSecurity,
} from "../../../reducers/form.service.provider";
import { editDocument } from "../../../services-controller/http.cleinte.controller";
import { PRINCIPAL_PATH } from "../../../router/routes";
import ServiceProviderMenuDesktop from "../../../components/molecules/menu/ServiceProviderMenu";
import { LoadingPopup } from "../../../components/atoms/popup/Popup";

//"11/07/2019"
const PsDocumentation = ({ history }) => {
  const [deleteDocument, setDeleteDocument] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [indexDelete, setIndexDelete] = useState(-1);
  const [isLoader, setIsLoader] = useState(false);
  const [successDocumentUpload, setSuccessDocumentUpload] = useState(false);
  const [rejectDocumentUpload, setRejectDocumentUpload] = useState(false);

  const [documentHeight, setDocumentHight] = useState({
    date: "",
    uploaded: false,
    url: "",
  });

  const [documentSocialSecurity, setDocumentSocialSecurity] = useState({
    date: "",
    uploaded: false,
    url: "",
  });

  const [documentProfessionalCard, setDocumentProfessionalCard] = useState({
    date: "",
    uploaded: false,
    url: "",
  });


  const dispatch = useDispatch()
  const form_service_provider = useSelector((state) => state.form_service_provider);
  const fetchDocuments = () => {
    try {
      const id = currentUser().uid;
      const altura = form_service_provider.documents.height;
      const seguridad = form_service_provider.documents.social_security;
      const tarjeta = form_service_provider.documents.professional_card;
      if (altura.url !== "") {
        setDocumentHight({
          date: altura.date,
          uploaded: true,
          url: altura.url,
        });
      }
      if (seguridad.url !== "") {
        setDocumentSocialSecurity({
          date: seguridad.date,
          uploaded: true,
          url: seguridad.url,
        });
      }
      if (tarjeta.url !== "") {
        setDocumentProfessionalCard({
          date: tarjeta.date,
          uploaded: true,
          url: tarjeta.url,
        });
      }
    } catch (error) {
      history.push(PRINCIPAL_PATH);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [form_service_provider]);

  const callbackDelete = (index) => {
    setIndexDelete(index);
    setDeleteDocument(true);
  };

  const callbackReloadHigh = async (file) => {
    setIsLoader(true);
    const base64 = await toBase64(file);
    const object = await uploadFile(
      `${currentUser().uid}/altutas`,
      "documentation",
      "pdf",
      base64
    );
    if (object.code === 200) {
      const url = object.url;
      const date = moment(new Date()).format("MM/DD/YYYY");
      dispatch(changeDocumentsHeight({ url, date }));
      setDocumentHight({ date, uploaded: true, url });
      const res = await editDocument(currentUser().uid, {
        type: 0,
        date,
        url,
      });
      setIsLoader(false);
      if (res) {
        setSuccessDocumentUpload(true);
      } else {
        setRejectDocumentUpload(false)
      }

    }
    setIsLoader(false);
  };

  const callbackReloadSocial = async (file) => {
    setIsLoader(true);
    const base64 = await toBase64(file);
    const object = await uploadFile(
      `${currentUser().uid}/reguridadsocial`,
      "documentation",
      "pdf",
      base64
    );
    if (object.code === 200) {
      const url = object.url;
      const date = moment(new Date()).format("MM/DD/YYYY");
      dispatch(changeDocumentsSocualSecurity({ url, date }));
      setDocumentSocialSecurity({ date, uploaded: true, url });
      const res = await editDocument(currentUser().uid, {
        type: 2,
        date,
        url,
      });
      setIsLoader(false);
      if (res) {
        setSuccessDocumentUpload(true);
      } else {
        setRejectDocumentUpload(false)
      }
    }
    setIsLoader(false);
  };

  const callbackReloadPro = async (file) => {
    setIsLoader(true);
    const base64 = await toBase64(file);
    const object = await uploadFile(
      `${currentUser().uid}/tarjetaprofesional`,
      "documentation",
      "pdf",
      base64
    );
    if (object.code === 200) {
      const url = object.url;
      const date = moment(new Date()).format("MM/DD/YYYY");
      dispatch(changeDocumentsProfessionalCard({ url, date }));
      setDocumentProfessionalCard({ date, uploaded: true, url });
      const res = await editDocument(currentUser().uid, {
        type: 1,
        date,
        url,
      });
      setIsLoader(false);
      if (res) {
        setSuccessDocumentUpload(true);
      } else {
        setRejectDocumentUpload(false)
      }
    }
    setIsLoader(false);
  };

  const callbackDontDelete = () => {
    setDeleteDocument(false);
  };

  const callbackDoDelete = async () => {
    if (indexDelete === 0) {
      dispatch(changeDocumentsHeight({ url: "", date: "" }));
      setDocumentHight({ date: "", uploaded: false, url: "" });
      await editDocument(currentUser().uid, {
        type: 0,
        date: "",
        url: "",
      });
    } else if (indexDelete === 1) {
      dispatch(changeDocumentsSocualSecurity({ url: "", date: "" }));
      setDocumentSocialSecurity({ date: "", uploaded: false, url: "" });
      await editDocument(currentUser().uid, {
        type: 2,
        date: "",
        url: "",
      });
    } else if (indexDelete === 2) {
      dispatch(changeDocumentsProfessionalCard({ url: "", date: "" }));
      setDocumentProfessionalCard({ date: "", uploaded: false, url: "" });
      await editDocument(currentUser().uid, {
        type: 1,
        date: "",
        url: "",
      });
    }
    setDeleteDocument(false);
  };

  const callbackError = (msg) => { };

  const callbackCloseSuccessDelete = () => {
    setSuccessDelete(false);
    setDeleteDocument(false);
  };

  const callbackCloseSuccessDocumentUpload = () => {
    setSuccessDocumentUpload(false);
  };

  const callbackCloseRejectDocumentUpload = () => {
    setRejectDocumentUpload(false)
  }

  return (
    <>
      <LoadingPopup state={isLoader} />
      <div className="usertype-main-container appbar-no-background">
        <ServiceProviderMenuDesktop />

        <div className="ps-container">
          <div className="services-appbar-container">
            <Appbar smallText="Listado de tus" bigText="Documentos" />
          </div>

          <div className="ps-notifications ps-goals">
            <div className="documentos-title">Documentos</div>

            <div className="documents-main-container">
              <DocumentsContainer
                url={documentHeight.url}
                name="Certificado de altura"
                date={documentHeight.date}
                uploaded={documentHeight.uploaded}
                icon={ALTURAS_ICON_SVG}
                callbackDelete={() => callbackDelete(0)}
                callbackReload={callbackReloadHigh}
                callbackError={callbackError}
              />

              <DocumentsContainer
                url={documentSocialSecurity.url}
                name="Seguridad social"
                date={documentSocialSecurity.date}
                uploaded={documentSocialSecurity.uploaded}
                icon={SEGURIDAD_ICON_SVG}
                callbackDelete={() => callbackDelete(1)}
                callbackReload={callbackReloadSocial}
                callbackError={callbackError}
              />

              <DocumentsContainer
                url={documentProfessionalCard.url}
                name="Tarjeta profesional"
                date={documentProfessionalCard.date}
                uploaded={documentProfessionalCard.uploaded}
                icon={TARJETA_P_ICON_SVG}
                callbackDelete={() => callbackDelete(2)}
                callbackReload={callbackReloadPro}
                callbackError={callbackError}
              />
            </div>
          </div>

          <div className="menu-navbar-container">
            <PsButtonNavbar />
          </div>
        </div>

        {deleteDocument ? (
          <StateButtonModalHook
            component={PopupTwoOptionWithIcon}
            hook={[deleteDocument, setDeleteDocument]}
            object={{
              callbackNegative: callbackDontDelete,
              callbackPositive: callbackDoDelete,
              textPositive: "Si",
              textNegative: "No",
              popupText: "¿Desea eliminar el documento? ",
              popupIcon: TRASH_ICON_SVG,
            }}
          />
        ) : null}

        {successDelete ? (
          <StateButtonModalHook
            component={PopupSuccessEvent}
            hook={[successDelete, setSuccessDelete]}
            object={{
              callback: callbackCloseSuccessDelete,
              message: "Documento eliminado correctamente",
              btnText: "Ok",
            }}
          />
        ) : null}

        {successDocumentUpload ? (
          <StateButtonModalHook
            component={PopupSuccessEvent}
            hook={[successDocumentUpload, setSuccessDocumentUpload]}
            object={{
              callback: callbackCloseSuccessDocumentUpload,
              message: "Documento actualizado correctamente",
              btnText: "Ok",
            }}
          />
        ) : null}

        {rejectDocumentUpload ? (
          <StateButtonModalHook
            component={PopupRejectEvent}
            hook={[rejectDocumentUpload, setRejectDocumentUpload]}
            object={{
              callback: callbackCloseRejectDocumentUpload,
              message: "El documento no se pudo actualizar, por favor intenta nuevamente",
              btnText: "Ok",
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default withRouter(PsDocumentation);
